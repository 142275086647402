












import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';

export default Vue.extend({
  name: 'date-0401',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (props, context) => {
    const state = reactive({
      msg: '/2021/date-0401.vue',
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '2021',
          linkUrl: '/ec/special/2021',
          disabled: true
        },
        {
          path: '0401',
          linkUrl: '',
          disabled: true
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
