// マイページ：ご利用履歴 種類
export const BUY_ORDER_SEASONS = [
  {
    text: '直近1年',
    value: 0
  }
];
export const SELL_ORDER_SEASONS = [
  {
    text: '直近1年',
    value: 0
  }
];
