































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';

import BackToTop from '@/components/common/back-to-top.vue';
import SnsLink from '@/components/footer/sns-link.vue';
import ServiceLink from '@/components/footer/service-link.vue';
import ProductLink from '@/components/footer/product-link.vue';
import UsedCategoryLink from '@/components/footer/used-category-link.vue';
import CopyRight from '@/components/footer/copyright.vue';

export default Vue.extend({
  name: 'app-footer',
  components: {
    'back-to-top': BackToTop,
    'sns-link': SnsLink,
    'service-link': ServiceLink,
    'product-link': ProductLink,
    'used-category-link': UsedCategoryLink,
    'copy-right': CopyRight
  },
  setup: (_, context) => {
    const state = reactive({
      simpleFooter: false,
      showUsedCategory: false,
      showBackToTopButton: true
    });

    watch(
      () => context.root.$route.meta,
      () => {
        const meta = context.root.$route.meta;
        state.simpleFooter = meta?.simpleFooter != null ? meta.simpleFooter : false;
        state.showUsedCategory = meta?.showFooterUsedCategory != null ? meta.showFooterUsedCategory : false;
        state.showBackToTopButton = meta?.showBackToTopButton != null ? meta.showBackToTopButton : true;
      },
      { immediate: true }
    );

    return {
      ...toRefs(state)
    };
  }
});
