import { render, staticRenderFns } from "./rf800mmf56-rf1200mmf8.vue?vue&type=template&id=79ab31db&scoped=true&"
import script from "./rf800mmf56-rf1200mmf8.vue?vue&type=script&lang=ts&"
export * from "./rf800mmf56-rf1200mmf8.vue?vue&type=script&lang=ts&"
import style0 from "./rf800mmf56-rf1200mmf8.vue?vue&type=style&index=0&id=79ab31db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ab31db",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
