




























































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import Urlcopy from '@/components/common/special/url-copy.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'telescope-feature',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    orangeBtn: OrangeBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '星空を見上げよう！天体望遠鏡特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '天体望遠鏡とカメラで天体写真を撮るなら、赤道儀がおすすめ。最軽量クラスのAPシリーズ、長時間の天体観測や本格的な天体写真撮影にSXD2シリーズ、高剛性・高精度のハイエンド赤道儀・SXシリーズ、自分の機材で星空を撮影したい方にAPフォトガイダーなど、天体望遠鏡・赤道儀ならキタムラにおまかせください。'
      );
    const state = reactive({
      naviList: [
        { naviItem: 'APシリーズ 赤道儀', href: '#t01' },
        { naviItem: 'SXD2シリーズ 赤道儀', href: '#t02' },
        // { naviItem: 'APフォトガイダー', href: '#t03' },
        // { naviItem: '星座観察用双眼鏡', href: '#t04' },
        { naviItem: '関連商品&アプリ', href: '#t05' },
        { naviItem: '天体写真ギャラリー', href: '#t06' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/hosizora',
          img: 'https://shopimg.kitamura.jp/images/banner/5032.png',
          alt: '星空総合ページ'
        },
        {
          href: '/ec/special/general/photocon-learn',
          img: 'https://shopimg.kitamura.jp/images/banner/2180.gif',
          alt: 'フォトコンテスト受賞作品から学ぶ'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント',
          target: 'blank'
        }
      ],
      recommendedBinoculars: [
        {
          name: 'ビクセン 星空観察用双眼鏡 SG2.1x42H',
          href: '/ec/pd/4955295191765',
          img: 'https://shopimg.kitamura.jp/images/pd/853/2a1/85b/362/010/6ba/63d/d98/09e/4f8/127/kt5/ic7/m/TN.JPG'
        },
        {
          name: 'ビクセン 6倍双眼鏡 アトレックライトII BR6×30WP',
          href: 'https://shop.kitamura.jp/ec/pd/4955295147076',
          img: 'https://shopimg.kitamura.jp/images/pd/235/804/860/53d/c12/964/f5b/2c0/9f8/227/4f3/9ea/6sw/b/TN.jpg'
        },
        {
          name: 'ひとみ径 6mm ビクセン双眼鏡',
          href: '/ec/pd/4955295156504',
          img: 'https://shopimg.kitamura.jp/images/pd/c10/482/306/edb/293/d2a/287/25a/4c9/6af/4bs/u9n/0ru/i/TN.jpg'
        },
        {
          name: 'ひとみ径 7mm ビクセン双眼鏡',
          href: '/ec/pd/4955295156207',
          img: 'https://shopimg.kitamura.jp/images/pd/5fe/1c3/92b/048/45f/213/ff1/e94/583/752/88f/p9d/hch/l/TN.jpg'
        }
      ],
      astronomicalTelescopes: [
        {
          name: 'ビクセン A70Lf モバイルポルタ 天体望遠鏡',
          href: '/ec/pd/4955295399055',
          img: '/ec/images2/special/camera/astronomical/telescope-feature/4955295399055.jpg'
        },
        {
          name: 'スペースアイ600&スマホ用カメラアダプターセット',
          href: '/ec/pd/2239530007670',
          img: 'https://shopimg.kitamura.jp/images/pd/b44/0ee/e6d/e4a/b40/f86/7c9/36d/865/e15/2a2/b0w/nut/c/M.jpg'
        },
        {
          name: 'ビクセン ポルタII A80Mf 天体望遠鏡',
          href: '/ec/pd/4955295399529',
          img: '/ec/images2/special/camera/astronomical/telescope-feature/4955295399529.jpg'
        },
        {
          name: 'ビクセン 天体望遠鏡 スペースアイ600',
          href: '/ec/pd/4955295327539',
          img: '/ec/images2/special/camera/astronomical/telescope-feature/4955295327539.jpg'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '天体観測スケジュール',
          linkUrl: '/ec/special/camera/astronomical',
          disabled: false
        },
        {
          path: '星空を見上げよう！天体望遠鏡特集',
          disabled: true
        }
      ],
      productJanCodeList: [
        '4955295399529',
        '4955295327539',
        '4955295392049',
        '4955295391998',
        '4955295156207',
        '4955295430239',
        '4955295880003',
        '4955295399697',
        '4955295840908',
        '4955295372256',
        '4955295710898'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
