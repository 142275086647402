


















































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'eosr8',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    watchInVideo: WatchInVideo,
    shasha: ShaSha,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！キヤノン EOS R8 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'キヤノン EOS R8 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2023/02/8 00:00',
      fanValidTo: '2023/02/10 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/02/10 10:00',
      reserveValidTo: '2023/04/13 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/04/14 0:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price2' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'キヤノン EOS R ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292119282',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/ab6/e8c/5a7/403/235/b13/f37/31f/c79/2c5/39w/ob7/yhm/f/L.jpg',
        txt: 'キヤノン EOS R8',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr8/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'キヤノン EOS R ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292119282' },
          { name: 'キヤノン EOS RP ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292132144' },
          { name: 'キヤノン EOS 6D MarkII ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292083897' },
          { name: 'キヤノン EOS 90D ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292138313' }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: 'フルサイズならではの豊かな表現力と小型・軽量ボディーを両立',
          contents: [
            '有効画素数最大約2420万画素フルサイズCMOSセンサーと、映像エンジン「DIGIC X」により高画質を実現し、大きなボケ味や広いダイナミックレンジなどフルサイズならではの豊かな表現が可能',
            '静止画撮影時に常用で最高ISO102400の高感度撮影を実現。夜間や室内の暗いシーンでも、自由度の高い撮影表現が可能',
            'レンズの収差などを補正し、解像感を向上する「デジタルレンズオプティマイザ」に対応。これにより、質感や立体感の表現力も向上',
            '「HDR PQ」方式での静止画／動画記録や、高速でブラケット撮影を行い、カメラ内で3枚の写真を1枚に合成する「HDRモード（Dレンジ優先）」に対応。さらに「HDRモード（動体優先）」を搭載し、人物や動物、飛行機や鉄道などの動いている被写体に対しても、1枚で広いダイナミックレンジで記録することが可能',
            '大きさは約132.5mm（幅）×86.1mm（高さ）×70.0mm（奥行き）、質量はEOS R シリーズのフルサイズミラーレスカメラで最軽量となる約461gの小型・軽量ボディーを実現'
          ]
        },
        {
          subtitle: '決定的な瞬間を捉える高速AF・高速連写',
          contents: [
            '「デュアルピクセル CMOS AF II」による幅広い撮影領域をカバーする高速・高精度なAFや優れた被写体検出など、上位機種である「EOS R6 Mark ll」の高い基本性能を継承',
            'ディープラーニング技術を活用した「EOS iTR AF X」により、優れた被写体検出性能とトラッキング性能を実現。「人物」では人の瞳・顔・頭部・胴体、「動物優先」では犬・猫・鳥・馬の瞳・顔・全身、「乗り物優先」ではモータースポーツ（車・バイク）・鉄道・飛行機（ジェット機・ヘリコプター）の全体・スポット検出に対応',
            '被写体の検出対象をメニューで切り替えることなく、カメラが自動で被写体を選択する「自動」設定を搭載',
            '電子シャッターによる撮影時は、AF／AE追従で最高約40コマ/秒の高速連写を実現',
            '電子先幕による撮影時は、AF／AE追従で最高約6コマ/秒の連続撮影が可能',
            '「RAWバーストモード」を搭載。電子シャッター、RAW記録による最高約30コマ/秒、AF追従の連続撮影を実現。また、シャッターボタンを押す直前（約0.5秒前）から記録を開始する「プリ撮影」にも対応。撮影開始のタイミングが予測しづらい場面でも、一瞬のシャッターチャンスを逃すことなく撮影が可能'
          ]
        },
        {
          subtitle: '幅広いユーザーの使いやすさを追求した操作性や通信機能',
          contents: [
            '静止画撮影／動画撮影切り替えスイッチを採用。どちらの撮影状態か容易に確認でき、設定の間違いを防ぐことが可能。また、電源スイッチを上部右側に配置することで、主要な操作が片手で完結するなど、快適な操作性を実現',
            'バリアングル液晶モニターを採用。ハイアングル／ローアングル、横位置／縦位置など、自由な視点で静止画、動画を撮影することが可能',
            'ファインダーをのぞいたまま指先でフォーカス対象を変更できる「タッチ＆ドラッグAF」を搭載。直感的かつスピーディーにAFフレームを移動させることが可能',
            '防じん・防滴構造を採用し、水滴や砂じんのカメラ内部への侵入を抑制し、信頼性を確保',
            'カメラからキヤノンのクラウドサービス「image.canon」への画像自動転送に対応。「image.canon」では、「Google Photos」、「Adobe Photoshop Lightroom」、「Frame.io」などの外部のウェブサービスへのデータ転送ができ、撮影した静止画や動画を手軽にバックアップ・編集することが可能'
          ]
        },
        {
          subtitle: 'Vlogなど多様な撮影ニーズに応える動画性能',
          contents: [
            'クロップなしの6Kオーバーサンプリングによる高画質な4K／60P動画撮影を実現',
            'クロップなしのフルHD／180Pハイフレームレート動画撮影に対応。再生時1/6倍速の印象的なスロー効果を実現',
            '「プレ記録」機能の搭載により、動画撮影ボタンを押す5秒前または3秒前から動画を記録することが可能。不意に訪れるシーンやシーンの変化を待つ間に記録し続ける必要がないため、不要な動画データが減り、ファイルサイズの節約や編集作業の負荷軽減などに貢献',
            '「デュアルピクセルCMOS AF II」により、静止画同様のAFエリア設定、被写体検出性能、トラッキング性能を実現。また、動画撮影時、常用ISO25600により、暗いシーンでもノイズを抑えた高画質な映像が撮影可能',
            '「EOS iTR AF X」による被写体検出機能を生かし、動き回る被写体でも広い範囲で追尾することが可能',
            '30分以上の連続撮影が可能。最長2時間まで記録することができ、長尺の撮影にも対応',
            '静止画を撮影するだけで、静止画と同時に、撮影直前の約2～4秒のシーンが動画として記録され、1日をまとめた短編動画（ダイジェスト動画）が自動記録される「プラスムービーオートモード」に対応',
            'フォーカシングによる画角の変化を光学設計値に基づいてカメラが補正するフォーカスブリージング補正に対応し、高品位な動画撮影を実現',
            'UVC（USB Video Class）／UAC（USB Audio Class）に対応し、ウェブカメラとして使用可能。ストリーミング配信やリモート会議で、フルサイズカメラならではの高画質な映像やレンズのボケ味を生かした映像の配信を実現',
            'キヤノン独自のガンマ特性「Canon Log 3」に対応。従来の「Canon Log」よりもハイライトの階調が飛びにくく、コントラストの高いシーンでも豊かな階調表現が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R8',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292204834', '4549292204872'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4549292204834', '4549292157253', '4548736121614', '4960759905901'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292204834', '4549292157253', '4548736121614', '4960759905901'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };

    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
