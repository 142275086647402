
































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'eosr6mk2',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    watchInVideo: WatchInVideo,
    shasha: ShaSha,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！キヤノン EOS R6 MarkII| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'キヤノン EOS R6 MarkII価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/11/2 00:00',
      fanValidTo: '2022/11/4 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/11/4 10:00',
      reserveValidTo: '2022/12/14 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/12/15 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'キヤノン EOS R6 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157253',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/731/a75/19f/abf/b41/d16/33c/43a/e3f/44e/b3i/o8z/04r/h/L.jpg',
        txt: 'キヤノン EOS R6 MarkII',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr6mk2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'キヤノン EOS R6 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157253' },
          { name: 'キヤノン EOS R5 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157345' },
          { name: 'キヤノン EOS R ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292119282' }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '新開発のCMOSセンサーや強力な手ブレ補正などにより、多様な撮影シーンで高画質を実現',
          contents: [
            '新開発の有効画素数最大約2420万画素フルサイズCMOSセンサーと映像エンジン「DIGIC X」により、高い解像感と常用最高ISO102400（静止画撮影時）の高感度を両立',
            'ボディー内5軸手ブレ補正機構を搭載しており、レンズ内光学式手ブレ補正機構を搭載したRFレンズとの協調制御により8.0 段の手ブレ補正効果を発揮',
            '従来の「HDR PQ」方式での静止画／動画記録や、高速でブラケット撮影を行い、カメラ内で3枚の写真を1枚に合成する「HDR モード（D レンジ優先）」に加え、「HDR モード（動体優先）」を新たに搭載',
            'レンズの収差などを補正し、解像感を向上する「デジタルレンズオプティマイザ」に対応。解像感に加え、質感や立体感の表現力も向上',
            'Windows 版の「Digital Photo Professional」で使用できる新サービス 「Neural network Image Processing Tool（有償）」に対応し、より高画質を追求した現像処理が可能'
          ]
        },
        {
          subtitle: '決定的な瞬間を捉える先進の高性能AFや高速連写',
          contents: [
            '「デュアルピクセル CMOS AF II」により、広範囲で追従性に優れた高速・高精度なAFを実現',
            '新たに馬・鉄道・飛行機（ジェット機・ヘリコプター）が被写体検出機能に追加。ディープラーニング技術を活用し、さらに進化した「EOS iTR AF X」により、優れた被写体検出性能とトラッキング性能を実現',
            '被写体の検出対象をメニューで切り替えることなくカメラが自動で被写体を選択する「自動」設定を新たに追加',
            'メカシャッター／電子先幕撮影時は、AF／AE追従で最高約 12 コマ/秒の高速連写。電子シャッター撮影時は、AF／AE追従で最高約 40 コマ/秒の高速連写を実現',
            'フルサイズCMOSセンサー搭載のEOSシリーズで初めて、「RAWバーストモード」を搭載。電子シャッター、RAW記録による最高約 30 コマ/秒、AF追従の連続撮影を実現'
          ]
        },
        {
          subtitle: '多様化する制作ニーズに応える動画性能や快適な撮影をサポートする通信性・拡張性',
          contents: [
            'クロップなしの6Kオーバーサンプリングによる高画質な4K／60P動画撮影を実現。クロップなしのフルHD／180Pハイフレームレート動画撮影にも対応',
            'キヤノン独自のガンマ特性「Canon Log 3」に対応。従来の「Canon Log」よりもハイライトの階調が飛びにくく、コントラストの高いシーンでも豊かな階調表現が可能',
            '30分以上の連続撮影が可能。最長6時間まで記録することができ、長尺の撮影にも対応',
            'UVC（USB Video Class）／UAC（USB Audio Class）に対応し、ウェブカメラとして使用可能。ストリーミング配信やリモート会議で、フルサイズカメラならではの高画質な映像の配信を実現',
            'Wi-Fi／Bluetooth®によるスマホとの無線接続に対応。5GHz 帯の無線 LAN 規格にも対応し、高速な画像転送を実現',
            'スマホとの有線接続に対応。キヤノン専用アプリケーション「Camera Connect」をインストールすることで、スマホからのカメラ操作だけでなくカメラからスマホへの画像の自動転送も可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R6 MarkII',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292200508', '4549292200577', '4549292200652'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', '', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292200508', '4549292157253', '4548736133730', '4960759905901'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
