var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-info d-flex mb-3",class:{
    'pa-4': _vm.$vuetify.breakpoint.mdAndUp,
    'pa-2': _vm.$vuetify.breakpoint.smAndDown
  }},[(_vm.type === _vm.ORDER_DETAIL_TYPES.BUY_DELIVERY || _vm.type === _vm.ORDER_DETAIL_TYPES.BUY_SHOP)?[_c('div',{staticClass:"order-info-1"},[_c('div',{staticClass:"order-info-text"},[_vm._v("注文日："+_vm._s(_vm._f("date")(_vm.info.odrDttm,'YYYY/MM/DD')))]),_c('div',{staticClass:"order-info-text"},[_vm._v("注文番号："+_vm._s(_vm.info.odrId))]),_c('div',{staticClass:"order-info-text"},[_vm._v("注文合計："+_vm._s(_vm._f("price")(_vm.info.totalPaymentIncTax))+"円")])]),(_vm.type === _vm.ORDER_DETAIL_TYPES.BUY_DELIVERY)?_c('div',{staticClass:"order-info-2",class:{
        'pt-8': _vm.$vuetify.breakpoint.mdAndUp
      }},[(_vm.deliveryTrackingUrl)?_c('div',{staticClass:"order-info-text"},[_vm._v(" 配送伝票番号："),_c('a',{staticClass:"order-info-text-link",attrs:{"href":_vm.deliveryTrackingUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.info.delivTrackingId))])]):_c('div',{staticClass:"order-info-text"},[_vm._v("配送伝票番号："+_vm._s(_vm.info.delivTrackingId))]),_c('div',{staticClass:"order-info-text"},[_vm._v("発送日："+_vm._s(_vm.info.shippedDt))])]):_vm._e()]:(_vm.type === _vm.ORDER_DETAIL_TYPES.SELL)?[_c('div',{staticClass:"order-info-1"},[_c('div',{staticClass:"order-info-text"},[_vm._v("種類：直送買取")]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込み日："+_vm._s(_vm._f("date")(_vm.info.entiryDttm,'YYYY/MM/DD')))]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込みID："+_vm._s(_vm.info.estimateEntryId))]),_c('div',{staticClass:"order-info-text"},[_vm._v(" 買取合計： "),((_vm.info.estimateStatus !== 90 && _vm.info.estimateStatus !== 900) || _vm.info.totalPraice === 0)?_c('span',[_vm._v("-")]):(_vm.info.totalPraice < 0)?_c('span',[_vm._v("（未査定）")]):_c('span',[_vm._v(_vm._s(_vm._f("price")(_vm.info.totalPraice))+"円")])])]),_c('div',{staticClass:"order-info-2",class:{
        'pt-8': _vm.$vuetify.breakpoint.mdAndUp
      }},[(_vm.info.transferAccountUrl)?_c('div',{staticClass:"order-info-text"},[_vm._v(" 査定結果の確認："),_c('a',{staticClass:"order-info-link",attrs:{"href":_vm.info.transferAccountUrl,"target":"_blank"}},[_vm._v("確認ページ")])]):_vm._e()])]:(_vm.type === _vm.ORDER_DETAIL_TYPES.TRADE)?[_c('div',{staticClass:"order-info-1"},[_c('div',{staticClass:"order-info-text"},[_vm._v("種類：下取り(店舗受取)")]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込み日："+_vm._s(_vm._f("date")(_vm.info.odrYmd,'YYYY/MM/DD')))]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込みID："+_vm._s(_vm.info.odrId))])])]:(_vm.type === _vm.ORDER_DETAIL_TYPES.TOKUTOKU_NEED_KIT || _vm.type === _vm.ORDER_DETAIL_TYPES.TOKUTOKU_NO_NEED_KIT)?[_c('div',{staticClass:"order-info-1"},[_c('div',{staticClass:"order-info-text"},[_vm._v("種類：トクトク交換")]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込み日："+_vm._s(_vm._f("date")(_vm.info.odrYmd,'YYYY/MM/DD')))]),_c('div',{staticClass:"order-info-text"},[_vm._v("お申込みID："+_vm._s(_vm.info.odrId))])]),_c('div',{staticClass:"order-info-2",class:{
        'pt-1': _vm.$vuetify.breakpoint.mdAndUp
      }},[(_vm.type === _vm.ORDER_DETAIL_TYPES.TOKUTOKU_NEED_KIT)?_c('div',{staticClass:"order-info-text"},[_vm._v("梱包キット：希望あり")]):(_vm.type === _vm.ORDER_DETAIL_TYPES.TOKUTOKU_NO_NEED_KIT)?_c('div',{staticClass:"order-info-text"},[_vm._v("梱包キット：希望なし")]):_vm._e(),(_vm.info.transferAccountUrl)?_c('div',{staticClass:"order-info-text"},[_vm._v(" 査定結果の確認："),_c('a',{staticClass:"order-info-link",attrs:{"href":_vm.info.transferAccountUrl,"target":"_blank"}},[_vm._v("確認ページ")])]):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }