import { render, staticRenderFns } from "./z7z6.vue?vue&type=template&id=cc71c968&scoped=true&"
import script from "./z7z6.vue?vue&type=script&lang=ts&"
export * from "./z7z6.vue?vue&type=script&lang=ts&"
import style0 from "./z7z6.vue?vue&type=style&index=0&id=cc71c968&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc71c968",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VBtn,VCol,VHover,VImg,VRow,VSheet})
