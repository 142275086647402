























































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'telescope',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    tableOfContentsNavi: TableOfContentsNavi,
    shoppingCredit: ShoppingCredit,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '初心者にもおすすめ天体望遠鏡の選び方 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '天体望遠鏡の最適な選び方とおすすめ商品をご紹介します。初めての方にも分かりやすいように、月や星を楽しむための選び方や種類、倍率の違いによる見え方を解説しました。天体観測を始めたい方は、ぜひ参考にして理想の天体望遠鏡を見つけてください。'
      );
    const state = reactive({
      naviList: [
        { naviItem: '天体望遠鏡の\n種類 ', href: '#select' },
        { naviItem: '月や惑星の\n見え方', href: '#magnification' },
        { naviItem: 'おすすめの\n天体望遠鏡 ', href: '#pickup' },
        { naviItem: '天体望遠鏡\n価格一覧 ', href: '#list' },
        { naviItem: '関連おすすめ\n特集一覧', href: '#tokusyu' }
      ],
      installmentPaymentExampleList: [
        { productPrice: '109,962', numberOfDivisions: '30', monthlyPayment: '3,666' },
        { productPrice: '33,174', numberOfDivisions: '10', monthlyPayment: '3,318' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/hosizora',
          img: 'https://shopimg.kitamura.jp/images/banner/5032.png',
          alt: '星空総合ページ'
        },
        {
          href: 'https://www.vixen.co.jp/app/nebulabook/',
          img: 'https://shopimg.kitamura.jp/images/banner/3439.gif',
          alt: '星雲・星団・銀河等の天体撮影を支援するアプリ「Nebula Book」'
        },
        {
          href: 'https://www.vixen.co.jp/app/planet-book/',
          img: '/ec/images2/special/camera/astronomical/telescope/bnr_planetbook350-130.jpg',
          alt: '惑星の方角を調べるアプリ「Planet Book」'
        }
      ],
      visible: false,
      index: 0, // default: 0
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '天体観測スケジュール',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/astronomical',
          disabled: false
        },
        {
          path: 'おすすめ天体望遠鏡の選び方',
          disabled: true
        }
      ],
      // 取得するJancode
      productJanCodeList: ['4955295327539'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      handleHide,
      showImg
    };
  }
});
