
























































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'om5',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！OM SYSTEM OM-5 ご予約受付中！| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'OM-5価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/10/26 14:59',
      fanValidTo: '2022/10/26 14:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/10/26 15:00',
      reserveValidTo: '2022/12/09 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/12/10 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'オリンパス OM-D E-M5 MarkIII ボディ',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9%20OM-D%20E-M5%20MarkIII%20%E3%83%9C%E3%83%87%E3%82%A3&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/f0d/8a4/46c/c2e/e85/5d5/393/323/aa1/ffb/46e/vkh/eyr/e/L.jpg',
        txt: 'OM-5',
        tradeInImg: '/ec/images2/special/camera/feature/omsystem/om5/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'オリンパス OM-D E-M5 MarkIII ボディ',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9%20OM-D%20E-M5%20MarkIII%20%E3%83%9C%E3%83%87%E3%82%A3&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword'
          },
          {
            name: 'オリンパス OM-D E-M5 MarkII ボディ',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9%20OM-D%20E-M5%20MarkII%20%E3%83%9C%E3%83%87%E3%82%A3&s4=%E3%83%9C%E3%83%87%E3%82%A3&n1c=%EF%BD%9E2000%E4%B8%87%E7%94%BB%E7%B4%A0&sort=keyword&limit=40'
          },
          { name: 'オリンパス OM-D E-M1 MarkIII ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350053000' }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '小型軽量ボディーに5軸手ぶれ補正を備え、カメラシステムとしての高い機動性を実現',
          contents: [
            '約366ｇの小型軽量ボディーに強力な5軸手ぶれ補正を搭載し「5軸シンクロ手ぶれ補正」により最大7.5段の補正効果を実現。三脚が使えない場所でスローシャッターでの撮影を可能にし、旅行先で出会った大切なシャッターチャンスにもスムーズに対応します',
            '有効画素数2037万画素 Live MOSセンサーと高性能の画像処理エンジン TruePic IX（トゥルーピック ナイン）の搭載により自然な階調表現と高感度時であってもノイズの少ない高画質が得られます',
            '121点オールクロス像面位相差AFセンサーを搭載し高いフレーミングの自由度とピント合わせの高精度化を両立させています',
            '「星空AF」を備えており、今までは難しいとされた天体撮影時のピント合わせの高精度オートフォーカスが可能になり、より手軽に撮影が行えます'
          ]
        },
        {
          subtitle: 'さまざまな撮影シーンで安心して使える防塵・防滴保護等級IP53、-10℃耐低温性能',
          contents: [
            '小型軽量ボディーながら、各部にシーリングを施し、フラッグシップモデルである「OM SYSTEM OM-1」と同等の防塵・防滴保護等級IP53、-10℃の耐低温という高い耐候性能を備えています',
            '風景撮影などでの突然の降雨、降雪や、滝などの水しぶきがかかるといった過酷な条件下においても、機材の心配をすることなくシャッターチャンスに集中できます',
            'レンズ交換時のイメージセンサー部へのゴミ・ホコリの付着によるトラブルを大幅に軽減するSSWF（スーパーソニックウェーブフィルター）を搭載し、屋外でも安心してレンズ交換を行え、アクティブに撮影の幅が広げられます',
            'バッテリーにはコンパクトな「BLS-50」を採用し、付属のUSB-ACアダプターを使うことで本体内充電が行えます'
          ]
        },
        {
          subtitle: '創作意欲を刺激する多彩なコンピュテーショナル フォトグラフィ機能',
          contents: [
            '「ハイレゾショット」「ライブND」など、従来は特殊な機材を使用したりパソコンによる画像合成などで実現していた写真表現をカメラ内で手軽に行えるコンピュテーショナル フォトグラフィ機能を備えています',
            '「ライブND」：NDフィルターを使用したようなスローシャッター効果を使った表現が可能で、効果の段数はND16（4段分）まで対応可能。スローシャッター効果を撮影前にファインダーや液晶モニターで確認できます',
            '「プロキャプチャー」：シャッターボタン半押しで記録を開始、シャッターボタン全押しの瞬間からさかのぼって記録することで、人の反応タイムラグ、カメラの動作タイムラグが原因で撮り逃がした瞬間もとらえることが可能となります',
            '「深度合成」：ピント位置の異なる8枚の写真を撮影し、自動的に1枚に合成することで過度に絞り込むことなく背景ボケを生かしながら、被写体に合わせて被写界深度をコントロールして撮影できます',
            '「ライブコンポジット」：明るく変化した部分のみを追加合成し、長時間撮影で発生しがちな画像が全体的に明るくなってしまう現象を防止。街と星空を一緒に撮影するといった露出制御の難しいシーンでも、ライブビュー画面で状況を確認しながら、メリハリのある美しい写真を簡単に撮影できます',
            'その他、「デジタルシフト」「ライブバルブ」「インターバル撮影/タイムラプス動画」「フォーカスブラケット」「HDR」「カラークリエーター」「アートフィルター」「フィッシュアイ補正」などが搭載されています'
          ]
        },
        {
          subtitle: '手持ち撮影で高画質が楽しめる4K動画、スマートフォンでの再生に便利な縦位置動画に対応',
          contents: [
            '強力なボディー内5軸手ぶれ補正は動画撮影にも対応。動画専用の電子手ぶれ補正を組み合わせることで、手持ちでも安定した4K動画が時間制限なく撮影できます',
            '像面位相差 AF センサーの情報を活用した動画用のフォーカシング制御も行っており、動画の特性に合ったスムーズなピント合わせを実現します',
            '暗部からハイライトまで白飛び、黒つぶれなく収録でき、カラーグレーディングにより自由度の高い編集作業が可能な「OM-Log」に対応しています',
            '新たに縦位置動画にも対応し、カメラを縦位置にして撮影した場合、縦位置の動画ファイルとして保存が可能となり、SNSではおなじみとなった縦位置動画も編集ソフトを介さず手軽に作成できます',
            'ハイレゾ対応のリニアPCMレコーダー「LS-P5」を接続することで、動画撮影時の音声を高音質で記録でき、スレートトーン発信機能によって動画編集時の音声データ同期も容易に行えます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ご予約受付中！OM SYSTEM OM-5',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350053949', '4545350053932', '4545350053963', '4545350053956', '4545350053987', '4545350053970'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4545350053949', '4545350052768', '4549292189711', '4547410440348'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
