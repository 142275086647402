













































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'lens-mzuiko',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'レンズ交換でワンランクアップ！担当者おすすめのオリンパスレンズ for マイクロフォーサーズ用レンズのご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'マイクロフォーサーズ用のオリンパスレンズ特集です。担当者おすすめ交換レンズの特徴と作例、撮影のコツをご紹介します。レンズを変えると何がどう違うの？など素朴な疑問にお答えしています！ミラーレス一眼カメラ初心者の方も楽しく写真を撮りながらワンランクアップしましょう♪'
      );
    const state = reactive({
      lensList: [
        {
          id: 'mzuiko45mm',
          titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/title_45.gif',
          subtitle: 'オリンパス M.ZUIKO DIGITAL 45mm F1.8',
          href: '/ec/pd/4545350037093',
          selectedIndex: 0,
          mainImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_45a.jpg',
          mainImageList: [
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_45a.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45a_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45a_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_45b.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45b_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45b_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_45c.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45c_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_45c_off.gif'
            }
          ]
        },
        {
          id: 'mzuiko60mm',
          titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/title_60.gif',
          subtitle: 'オリンパス M.ZUIKO DIGITAL ED 60mm F2.8 Macro',
          href: '/ec/pd/4545350041236',
          selectedIndex: 0,
          mainImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_60a.jpg',
          mainImageList: [
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_60a.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60a_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60a_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_60b.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60b_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60b_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_60c.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60c_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_60c_off.gif'
            }
          ]
        },
        {
          id: 'mzuiko14-150mm',
          titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/title_14150.gif',
          subtitle: 'オリンパス M.ZUIKO DIGITAL ED 14-150mm F4.0-5.6',
          href: '/ec/pd/4545350047795',
          selectedIndex: 0,
          mainImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_14150a.jpg',
          mainImageList: [
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_14150a.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150a_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150a_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_14150b.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150b_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150b_off.gif'
            },
            {
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/img_14150c.jpg',
              onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150c_on.gif',
              offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_14150c_off.gif'
            }
          ]
        }
      ],
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'オリンパスレンズ for マイクロフォーサーズ',
          disabled: true
        }
      ],
      naviList: [
        { href: '#mzuiko45mm', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_link_a.jpg' },
        { href: '#mzuiko60mm', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_link_b.jpg' },
        { href: '#mzuiko14-150mm', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/mzuiko/btn_link_c.jpg' }
      ]
    });

    const showImg = (target: number, item: number) => {
      state.lensList[target].selectedIndex = item;
      state.lensList[target].mainImage = state.lensList[target].mainImageList[item as number].src;
    };

    const isActive = (index: number) => {
      for (let i = 0; i < state.naviList.length; i++) {
        if (i === index) {
          state.naviList[index].src = state.naviList[index].src.replace('.jpg', '_on.jpg');
        } else {
          state.naviList[i].src = state.naviList[i].src.replace('_on.jpg', '.jpg');
        }
      }
    };
    return {
      ...toRefs(state),
      noimage,
      showImg,
      isActive
    };
  }
});
