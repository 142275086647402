

































import Vue from 'vue';

export default Vue.extend({
  name: 'back-numbers',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
});
