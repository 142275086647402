


































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
//import SubText from '@/components/common/special/subtext.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '344',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    //subText: SubText,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '光のアートを楽しもう！キラキラ写真が簡単に撮れるおすすめのクロスフィルター特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '夜景やイルミネーション撮影に最適★光の輝きを自在にコントロールできるクロスフィルターであなただけのオリジナルフォトを楽しみましょう！おすすめのクロスフィルターをカメラのキタムラがご紹介します'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'レンズフィルター特集',
          linkUrl: '/ec/special/camera/lensfilter',
          disabled: false
        },
        {
          path: 'クロスフィルター',
          disabled: true
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
