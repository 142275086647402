



























































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'backnumber',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '話題の新製品特集バックナンバー | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '話題の新製品特集 バックナンバー一覧');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: '話題の新製品特集バックナンバー',
          disabled: true
        }
      ],
      category: 'all',
      narrowingList: [
        { src: '/ec/images2/special/camera/feature/backnumber/btn-new-w.png', value: 'new' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-canon-w.png', value: 'canon' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-nikon-w.png', value: 'nikon' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-olympus-w.png', value: 'olympus' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-sony-w.png', value: 'sony' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-fujifilm-w.png', value: 'fujifilm' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-panasonic-w.png', value: 'panasonic' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-general-w.png', value: 'general' },
        { src: '/ec/images2/special/camera/feature/backnumber/btn-all.png', value: 'all' }
      ],
      campaignlist: [
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2024.8.30',
          href: '/ec/special/camera/feature/canon/eosr5mk2',
          src: 'https://shopimg.kitamura.jp/images/banner/9643.jpg',
          productName: 'キヤノン EOS R5 MARK II'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売予定 2024.11.29',
          href: '/ec/special/camera/feature/canon/eosr1',
          src: 'https://shopimg.kitamura.jp/images/banner/9641.jpg',
          productName: 'キヤノン EOS R1'
        },
        {
          category: ['all', 'sony', 'new'],
          releaseDate: '発売日 2024.8.2',
          href: '/ec/special/camera/feature/sony/zv-e10m2',
          src: 'https://shopimg.kitamura.jp/images/banner/9637.jpg',
          productName: 'ソニー VLOGCAM ZV-E10 II'
        },
        {
          category: ['all', 'nikon', 'new'],
          releaseDate: '発売日 2024.7.12',
          href: '/ec/special/camera/feature/nikon/z6III',
          src: 'https://shopimg.kitamura.jp/images/banner/9610.jpg',
          productName: 'ニコン Z6III'
        },
        {
          category: ['all', 'sony', 'new'],
          releaseDate: '発売日 2024.5.17',
          href: '/ec/special/camera/feature/sony/fe16-25mmf28g',
          src: 'https://shopimg.kitamura.jp/images/banner/9570.jpg',
          productName: 'ソニー FE 16-25mm F2.8 G [SEL1625G]'
        },
        {
          category: ['all', 'sony', 'new'],
          releaseDate: '発売日 2024.4.19',
          href: '/ec/special/camera/feature/sony/fe24mm50mmf28g',
          src: 'https://shopimg.kitamura.jp/images/banner/9500.jpg',
          productName: 'ソニー FE 24-50mm F2.8 G [SEL2450G]'
        },
        {
          category: ['all', 'olympus', 'new'],
          releaseDate: '発売日 2024.2.23',
          href: '/ec/special/camera/feature/omsystem/om1m2',
          src: 'https://shopimg.kitamura.jp/images/banner/9460.jpg',
          productName: 'OM SYSTEM OM-1 MARK II'
        },
        {
          category: ['all', 'olympus', 'new'],
          releaseDate: '発売日 2024.3.15',
          href: '/ec/special/camera/feature/omsystem/ed150-600mm',
          src: 'https://shopimg.kitamura.jp/images/banner/9462.jpg',
          productName: 'OM SYSTEM M.ZUIKO DIGITAL ED 150-600mm F5.0-6.3 IS'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2023.12.8',
          href: '/ec/special/camera/feature/canon/rf200800mm-rf24105mm',
          src: 'https://shopimg.kitamura.jp/images/banner/9382.jpg',
          productName: 'キヤノン RF200-800mm F6.3-9 IS USM/RF24-105mm F2.8 L IS USM Z'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2023.10.27',
          href: '/ec/special/camera/feature/canon/rf10-20mmf4l',
          src: 'https://shopimg.kitamura.jp/images/banner/9359.jpg',
          productName: 'キヤノン RF10-20mm F4 L IS STM'
        },
        {
          category: ['all', 'nikon', 'new'],
          releaseDate: '発売日 2023.10.27',
          href: '/ec/special/camera/feature/nikon/z600f63vrs',
          src: 'https://shopimg.kitamura.jp/images/banner/9361.jpg',
          productName: 'ニコン NIKKOR Z 600mm f/6.3 VR S'
        },
        {
          category: ['all', 'panasonic', 'new'],
          releaseDate: '発売日 2023.10.27',
          href: '/ec/special/camera/feature/nikon/z_f',
          src: 'https://shopimg.kitamura.jp/images/banner/9349.jpg',
          productName: 'ニコン Z f'
        },
        {
          category: ['all', 'panasonic', 'new'],
          releaseDate: '発売日 2023.10.27',
          href: '/ec/special/camera/feature/panasonic/dcg9m2',
          src: 'https://shopimg.kitamura.jp/images/banner/9340.jpg',
          productName: 'パナソニック LUMIX G9PROII'
        },
        {
          category: ['all', 'sony', 'new'],
          releaseDate: '発売日 2023.10.13',
          href: '/ec/special/camera/feature/sony/a7c2-a7cr',
          src: 'https://shopimg.kitamura.jp/images/banner/9325.jpg',
          productName: 'ソニー α7C II / α7CR'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2023.9.22',
          href: '/ec/special/camera/feature/sony/fe16-35mmf28gm2',
          src: 'https://shopimg.kitamura.jp/images/banner/9327.jpg',
          productName: 'ソニー FE 16-35mm F2.8 GM II [SEL1635GM2]'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2023.7.28',
          href: '/ec/special/camera/feature/sony/a6700',
          src: 'https://shopimg.kitamura.jp/images/banner/9288.jpg',
          productName: 'ソニー α6700'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2023.7.28',
          href: '/ec/special/camera/feature/sony/fe70-200mmf4goss2',
          src: 'https://shopimg.kitamura.jp/images/banner/9286.jpg',
          productName: 'ソニー FE 70-200mm F4 Macro G OSS II [SEL70200G2]'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2023.8.31',
          href: '/ec/special/camera/feature/nikon/z180-600mmf56-63vr',
          src: 'https://shopimg.kitamura.jp/images/banner/9261.jpg',
          productName: 'ニコン NIKKOR Z 180mm-600mm f/5.6-6.3 VR'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2023.6.29',
          href: '/ec/special/camera/feature/fujifilm/xs20',
          src: 'https://shopimg.kitamura.jp/images/banner/9238.jpg',
          productName: 'フジフイルム X-S20 '
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2023.5.26',
          href: '/ec/special/camera/feature/nikon/z_8',
          src: 'https://shopimg.kitamura.jp/images/banner/9221.jpg',
          productName: 'ニコン Z 8'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2023.5.26',
          href: '/ec/special/camera/feature/canon/rf100-300-f28l',
          src: 'https://shopimg.kitamura.jp/images/banner/9208.jpg',
          productName: 'キヤノン RF100-300mm F2.8 L IS USM'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2023.4.21',
          href: '/ec/special/camera/feature/sony/vlogcam_zv_e1',
          src: 'https://shopimg.kitamura.jp/images/banner/9194.jpg',
          productName: 'ソニー VLOGCAM ZV-E1'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2023.4.14',
          href: '/ec/special/camera/feature/canon/eosr8',
          src: 'https://shopimg.kitamura.jp/images/banner/9164.jpg',
          productName: 'キヤノン EOS R8'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2023.3.3/発売日 2021.7.23',
          href: '/ec/special/camera/feature/nikon/zfc',
          src: 'https://shopimg.kitamura.jp/images/banner/8494.jpg',
          productName: 'ニコン Z fc ボディ ブラック / ニコン Z fc '
        },
        {
          category: ['all', 'olympus'],
          releaseDate: '発売日 2023.2.24',
          href: '/ec/special/camera/feature/omsystem/ed90mmf35macro',
          src: 'https://shopimg.kitamura.jp/images/banner/9168.jpg',
          productName: 'OM SYSTEM M.ZUIKO DIGITAL ED 90mm F3.5 Macro IS PRO'
        },
        {
          category: ['all', 'olympus'],
          releaseDate: '発売日 2023.2.24/発売日 2022.3.18',
          href: '/ec/special/camera/feature/omsystem/om1',
          src: 'https://shopimg.kitamura.jp/images/banner/8766.jpg',
          productName: 'OMシステム OM-1'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2023.2.10',
          href: '/ec/special/camera/feature/sony/fe20-70mmf4g',
          src: 'https://shopimg.kitamura.jp/images/banner/9123.jpg',
          productName: 'ソニー FE 20-70mm F4 G [SEL2070G]'
        },
        {
          category: ['all', 'panasonic'],
          releaseDate: '発売日 2023.2.16',
          href: '/ec/special/camera/feature/panasonic/dcs5m2',
          src: 'https://shopimg.kitamura.jp/images/banner/9113.jpg',
          productName: 'パナソニック LUMIX DC-S5M2/DC-S5M2X'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2022.11.25',
          href: '/ec/special/camera/feature/fujifilm/xt5',
          src: 'https://shopimg.kitamura.jp/images/banner/9061.jpg',
          productName: 'フジフイルム X-T5'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2022.12.15',
          href: '/ec/special/camera/feature/canon/eosr6mk2',
          src: 'https://shopimg.kitamura.jp/images/banner/9066.jpg',
          productName: 'キヤノン EOS R6 MarkII'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2022.11.25',
          href: '/ec/special/camera/feature/sony/a7rv',
          src: 'https://shopimg.kitamura.jp/images/banner/9005.jpg',
          productName: 'ソニー α7R V'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2022.11.25',
          href: '/ec/special/camera/feature/nikon/z600mmf4',
          src: 'https://shopimg.kitamura.jp/images/banner/9068.jpg',
          productName: 'ニコン NIKKOR Z 800mm f/6.3 VR S'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2023.1.26',
          href: '/ec/special/camera/feature/canon/rf135',
          src: 'https://shopimg.kitamura.jp/images/banner/9063.jpg',
          productName: 'キヤノン RF135mm F1.8 L IS USM'
        },
        {
          category: ['all', 'olympus'],
          releaseDate: '発売日 2022.12.10',
          href: '/ec/special/camera/feature/omsystem/om5',
          src: 'https://shopimg.kitamura.jp/images/banner/9001.jpg',
          productName: 'OM SYSTEM OM-5'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2022.10.14',
          href: '/ec/special/camera/feature/sony/fx30',
          src: 'https://shopimg.kitamura.jp/images/banner/8981.jpg',
          productName: 'ソニー Cinema Line カメラ FX30'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2022.9.29',
          href: '/ec/special/camera/feature/fujifilm/xh2',
          src: 'https://shopimg.kitamura.jp/images/banner/8956.jpg',
          productName: 'フジフイルム X-H2'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2022.9.29',
          href: '/ec/special/camera/feature/fujifilm/xf18-200_xf56',
          src: 'https://shopimg.kitamura.jp/images/banner/8954.jpg',
          productName: 'フジフイルム XF18-120mm F4 LM PZ WR / XF56mm F1.2 R WR'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2022.8.26',
          href: '/ec/special/camera/feature/canon/rf24f18_rf15-30f45-63',
          src: 'https://shopimg.kitamura.jp/images/banner/8921.jpg',
          productName: 'キヤノン RF24mm F1.8 マクロ IS STM / RF15-30mm F4.5-6.3 IS STM'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2022.8.5',
          href: '/ec/special/camera/feature/nikon/z30',
          src: 'https://shopimg.kitamura.jp/images/banner/8905.jpg',
          productName: 'ニコン Z 30'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2022.7.15',
          href: '/ec/special/camera/feature/nikon/z400mmf45',
          src: 'https://shopimg.kitamura.jp/images/banner/8903.jpg',
          productName: 'ニコン NIKKOR Z 400mm f/4.5 VR S'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2022.7.14',
          href: '/ec/special/camera/feature/fujifilm/xf150-600',
          src: 'https://shopimg.kitamura.jp/images/banner/8883.jpg',
          productName: 'フジフイルム XF150-600mm F5.6-8 R LM OIS WR'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2022.7.14',
          href: '/ec/special/camera/feature/fujifilm/xh2s',
          src: 'https://shopimg.kitamura.jp/images/banner/8885.jpg',
          productName: 'フジフイルム X-H2S'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2022.6.23',
          href: '/ec/special/camera/feature/canon/eosr7',
          src: 'https://shopimg.kitamura.jp/images/banner/8855.jpg',
          productName: 'キヤノン EOS R7'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2022.6.10',
          href: '/ec/special/camera/feature/sony/sel2470gm2',
          src: 'https://shopimg.kitamura.jp/images/banner/8853.jpg',
          productName: 'ソニー FE 24-70mm F2.8 GM II [SEL2470GM2] '
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2022.5.7',
          href: '/ec/special/camera/feature/sony/selp1635g',
          src: 'https://shopimg.kitamura.jp/images/banner/8808.png',
          productName: 'ソニー FE PZ 16-35mm F4 G'
        },
        {
          category: ['all', 'panasonic'],
          releaseDate: '発売日 2022.3.25',
          href: '/ec/special/camera/feature/panasonic/gh6',
          src: 'https://shopimg.kitamura.jp/images/banner/8787.jpg',
          productName: 'パナソニック LUMIX DC-GH6'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.12.17',
          href: '/ec/special/camera/feature/sony/a7iv',
          src: 'https://shopimg.kitamura.jp/images/banner/8724.jpg',
          productName: 'ソニー α7 IV'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2021.12.24',
          href: '/ec/special/camera/feature/nikon/z_9',
          src: 'https://shopimg.kitamura.jp/images/banner/8685.jpg',
          productName: 'ニコン Z 9'
        },
        {
          category: ['all', 'nikon', 'new'],
          releaseDate: '発売日 2022.2.4',
          href: '/ec/special/camera/feature/nikon/z100-400mmf45-56vrs',
          src: 'https://shopimg.kitamura.jp/images/banner/8687.jpg',
          productName: 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.11.26',
          href: '/ec/special/camera/feature/sony/sel70200gm2',
          src: 'https://shopimg.kitamura.jp/images/banner/8657.jpg',
          productName: 'ソニー FE 70-200mm F2.8 GM OSS II'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2021.11.27',
          href: '/ec/special/camera/feature/canon/eosr3',
          src: 'https://shopimg.kitamura.jp/images/banner/8629.jpg',
          productName: 'キヤノン EOS R3'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2021.10.28',
          href: '/ec/special/camera/feature/canon/rf100-400_rf16',
          src: 'https://shopimg.kitamura.jp/images/banner/8631.jpg',
          productName: 'キヤノン RF100-400mm F5.6-8 IS USM / RF16mm F2.8 STM'
        },
        {
          category: ['all', 'general'],
          releaseDate: '発売日 2021.10.1',
          href: '/ec/special/camera/feature/ricoh/gr3x',
          src: 'https://shopimg.kitamura.jp/images/banner/8625.jpg',
          productName: 'リコー GR IIIx'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.9.29',
          href: '/ec/special/camera/feature/fujifilm/xf33f14',
          src: 'https://shopimg.kitamura.jp/images/banner/8627.jpg',
          productName: 'フジフイルム XF33mm F1.4 R LM WR'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.9.29',
          href: '/ec/special/camera/feature/fujifilm/gfx50sII',
          src: 'https://shopimg.kitamura.jp/images/banner/8603.jpg',
          productName: 'フジフイルム GFX 50S II'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.9.17',
          href: '/ec/special/camera/feature/sony/vlogcam_zv_e10',
          src: 'https://shopimg.kitamura.jp/images/banner/8551.jpg',
          productName: 'ソニー VLOGCAM ZV-E10'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2021.9.30',
          href: '/ec/special/camera/feature/canon/rf1435mmf4lisusm',
          src: 'https://shopimg.kitamura.jp/images/banner/8510.jpg',
          productName: 'キヤノン RF14-35mm F4 L IS USM'
        },
        {
          category: ['all', 'olympus'],
          releaseDate: '発売日 2021.6.25',
          href: '/ec/special/camera/feature/olympus/penEP7',
          src: 'https://shopimg.kitamura.jp/images/banner/8466.jpg',
          productName: 'オリンパス PEN E-P7'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2021.6.25',
          href: '/ec/special/camera/feature/nikon/z_mc_105mm_f28_vr_s',
          src: 'https://shopimg.kitamura.jp/images/banner/8458.jpg',
          productName: 'ニコン NIKKOR Z MC 105mm f/2.8 VR S'
        },
        {
          category: ['all', 'panasonic'],
          releaseDate: '発売日 2021.6.25',
          href: '/ec/special/camera/feature/panasonic/gh5m2',
          src: 'https://shopimg.kitamura.jp/images/banner/8439.jpg',
          productName: 'パナソニック LUMIX DC-GH5M2'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.6.4',
          href: '/ec/special/camera/feature/sony/7rm4a7rm3a',
          src: 'https://shopimg.kitamura.jp/images/banner/8456.jpg',
          productName: 'ソニー α7R IV A & α7R III A'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.5.28',
          href: '/ec/special/camera/feature/sony/fe14f18gm',
          src: 'https://shopimg.kitamura.jp/images/banner/8405.png',
          productName: 'ソニー FE 14mm F1.8 GM [SEL14F18GM]'
        },
        {
          category: ['all', 'general'],
          releaseDate: '発売日 2021.6.24',
          href: '/ec/special/camera/feature/tamron/150500mmf567',
          src: 'https://shopimg.kitamura.jp/images/banner/8403.png',
          productName: 'タムロン 150-500mm F/5-6.7 Di III VC VXD、タムロン 11-20mm F/2.8 Di III-A RXD'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.5.27',
          href: '/ec/special/camera/feature/fujifilm/xf18mmf14rlmwr',
          src: 'https://shopimg.kitamura.jp/images/banner/8398.png',
          productName: 'フジフイルム XF18mm F1.4 R LM WR'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2021.7.15',
          href: '/ec/special/camera/feature/canon/rf100f28l',
          src: 'https://shopimg.kitamura.jp/images/banner/8394.png',
          productName: 'キヤノン RF100mm F2.8 L MACRO IS USM'
        },
        {
          category: ['all', 'canon', 'new'],
          releaseDate: '発売日 2021.7.15',
          href: '/ec/special/camera/feature/canon/rf400f28l',
          src: 'https://shopimg.kitamura.jp/images/banner/8396.png',
          productName: 'キヤノン RF400mm F2.8 L IS USM / RF600mm F4 L IS USM'
        },
        {
          category: ['all', 'general'],
          releaseDate: '発売日 2021.4.23',
          href: '/ec/special/camera/feature/pentax/k3mk3',
          src: 'https://shopimg.kitamura.jp/images/banner/8376.jpg',
          productName: 'ペンタックス K-3 Mark III'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.4.23',
          href: '/ec/special/camera/feature/sony/sel24f28g',
          src: 'https://shopimg.kitamura.jp/images/banner/8380.jpg',
          productName: 'ソニー FE 24mm F2.8 G / FE 40mm F2.5 G / FE 50mm F2.5 G'
        },
        {
          category: ['all', 'general'],
          releaseDate: '発売日 2021.4.16',
          href: '/ec/special/camera/feature/sigma/fpl',
          src: 'https://shopimg.kitamura.jp/images/banner/8368.jpg',
          productName: 'シグマ fp L'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.4.23',
          href: '/ec/special/camera/feature/sony/sel50f12gm',
          src: 'https://shopimg.kitamura.jp/images/banner/8354.jpg',
          productName: 'ソニー FE 50mm F1.2 GM'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.3.19',
          href: '/ec/special/camera/feature/sony/a1',
          src: 'https://shopimg.kitamura.jp/images/banner/8198.jpg',
          productName: 'ソニー a1'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.2.25',
          href: '/ec/special/camera/feature/fujifilm/gfx100s',
          src: 'https://shopimg.kitamura.jp/images/banner/8232.jpg',
          productName: 'フジフイルム GFX100S'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.2.25',
          href: '/ec/special/camera/feature/fujifilm/xe4',
          src: 'https://shopimg.kitamura.jp/images/banner/8209.jpg',
          productName: 'フジフイルム X-E4'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2021.3.18',
          href: '/ec/special/camera/feature/fujifilm/xf70300mmf456rlmoiswr',
          src: 'https://shopimg.kitamura.jp/images/banner/8236.jpg',
          productName: 'フジフイルム XF70-300mmF4-5.6 R LM OIS WR / XF27mmF2.8 R WR'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2021.2.12',
          href: '/ec/special/camera/feature/sony/sel35f14gm',
          src: 'https://shopimg.kitamura.jp/images/banner/8186.jpg',
          productName: 'ソニー FE 35mm F1.4 GM'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.12.24',
          href: '/ec/special/camera/feature/canon/rf70-200-f4l',
          src: 'https://shopimg.kitamura.jp/images/banner/8084.jpg',
          productName: 'キヤノン RF70-200mm F4 L IS USM'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2020.11.19',
          href: '/ec/special/camera/feature/fujifilm/xs10',
          src: 'https://shopimg.kitamura.jp/images/banner/8038.jpg',
          productName: 'フジフイルム X-S10'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.12.11',
          href: '/ec/special/camera/feature/nikon/z6IIz7II',
          src: 'https://shopimg.kitamura.jp/images/banner/8030.jpg',
          productName: 'ニコン Z6II Z7II'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.11.27',
          href: '/ec/special/camera/feature/canon/eoskissm2',
          src: 'https://shopimg.kitamura.jp/images/banner/8022.jpg',
          productName: 'キヤノン EOS Kiss M2'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.12.11',
          href: '/ec/special/camera/feature/nikon/z50mm_z14-24mm',
          src: 'https://shopimg.kitamura.jp/images/banner/7946.jpg',
          productName: 'ニコン NIKKOR Z 50mm f/1.2 S、NIKKOR Z 14-24mm f/2.8 S'
        },
        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2020.10.23',
          href: '/ec/special/camera/feature/sony/7c',
          src: 'https://shopimg.kitamura.jp/images/banner/7942.jpg',
          productName: 'ソニー α7C'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2020.9.24',
          href: '/ec/special/camera/feature/fujifilm/xf50mm-f1rwr',
          src: 'https://shopimg.kitamura.jp/images/banner/7898.jpg',
          productName: 'フジフイルムXF50mm F1.0 R WR'
        },

        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2020.10.9',
          href: '/ec/special/camera/feature/sony/7sm3',
          src: 'https://shopimg.kitamura.jp/images/banner/7770.jpg',
          productName: 'ソニー α7S III'
        },

        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.8.28',
          href: '/ec/special/camera/feature/nikon/z5',
          src: 'https://shopimg.kitamura.jp/images/banner/7758.jpg',
          productName: 'ニコン Z 5'
        },

        {
          category: ['all', 'panasonic'],
          releaseDate: '発売日 2020.8.20',
          href: '/ec/special/camera/feature/panasonic/g100',
          src: 'https://shopimg.kitamura.jp/images/banner/7728.jpg',
          productName: 'パナソニックLUMIX G100'
        },

        {
          category: ['all', 'canon'],
          releaseDate: '発売日 R5 2020.7.30,R6 2020.8.27',
          href: '/ec/special/camera/feature/canon/eosr5r6',
          src: 'https://shopimg.kitamura.jp/images/banner/7725.jpg',
          productName: 'キヤノン EOS R5 / EOS R6'
        },

        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.8.27',
          href: '/ec/special/camera/feature/canon/rf100-500mm_f45_71l_is_usm',
          src: 'https://shopimg.kitamura.jp/images/banner/7712.jpg',
          productName: 'キヤノン RF100-500mm F4.5-7.1 L IS USM'
        },

        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.7.30',
          href: '/ec/special/camera/feature/canon/rf600mm-rf800mm_f11_is_stm',
          src: 'https://shopimg.kitamura.jp/images/banner/7737.jpg',
          productName: 'キヤノン RF600mm F11 IS STM／キヤノン RF800mm F11 IS STM'
        },

        {
          category: ['all', 'sony'],
          releaseDate: '発売日 2020.6.19',
          href: '/ec/special/camera/feature/sony/vlogcamzv1zv1g',
          src: 'https://shopimg.kitamura.jp/images/banner/7632.jpg',
          productName: 'ソニーZV-1'
        },

        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 シルバー2020.4.28 ブラック5.21',
          href: '/ec/special/camera/feature/fujifilm/xt4',
          src: 'https://shopimg.kitamura.jp/images/banner/7422.jpg',
          productName: 'フジフイルムX-T4'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.6.5',
          href: '/ec/special/camera/feature/nikon/d6',
          src: 'https://shopimg.kitamura.jp/images/banner/7374.jpg',
          productName: 'ニコンD6'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.6.25',
          href: '/ec/special/camera/feature/canon/eoskissx10i',
          src: 'https://shopimg.kitamura.jp/images/banner/7373.jpg',
          productName: 'キヤノン EOS KISS X10i'
        },
        {
          category: ['all', 'olympus'],
          releaseDate: '発売日 2020.2.28',
          href: '/ec/special/camera/feature/olympus/em1m3',
          src: 'https://shopimg.kitamura.jp/images/banner/7342.jpg',
          productName: ' オリンパス OM-D E-M1 Mark III '
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 シルバー2020.2.27ブラック3.12',
          href: '/ec/special/camera/feature/fujifilm/x100v',
          src: 'https://shopimg.kitamura.jp/images/banner/7275.jpg',
          productName: 'フジフイルムX100V'
        },
        {
          category: ['all', 'fujifilm'],
          releaseDate: '発売日 2020.2.27',
          href: '/ec/special/camera/feature/fujifilm/x-t200',
          src: 'https://shopimg.kitamura.jp/images/banner/7252.jpg',
          productName: 'フジフイルムX-T200'
        },
        {
          category: ['all', 'canon'],
          releaseDate: '発売日 2020.2.14',
          href: '/ec/special/camera/feature/canon/1dxmk3',
          src: 'https://shopimg.kitamura.jp/images/banner/7163.jpg',
          productName: 'キヤノン EOS-1D X Mark III'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.1.24',
          href: '/ec/special/camera/feature/nikon/D780',
          src: 'https://shopimg.kitamura.jp/images/banner/7171.png',
          productName: 'ニコン D780'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.2.14',
          href: '/ec/special/camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s',
          src: 'https://shopimg.kitamura.jp/images/banner/7165.jpg',
          productName: 'ニコン NIKKOR Z 70-200mm f/2.8 VR S'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.2.29',
          href: '/ec/special/camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr',
          src: 'https://shopimg.kitamura.jp/images/banner/7180.jpg',
          productName: 'ニコン AF-S NIKKOR 120-300mm f/2.8E FL ED SR VR'
        },
        {
          category: ['all', 'nikon'],
          releaseDate: '発売日 2020.2.7',
          href: '/ec/special/camera/feature/nikon/p950',
          src: 'https://shopimg.kitamura.jp/images/banner/7186.jpg',
          productName: 'ニコン COOLPIX P950'
        }
      ]
    });

    const filterByCategory = computed(() => {
      const result = state.campaignlist.filter((value) => {
        if (value.category.indexOf(state.category) !== -1) {
          return value;
        }
      });
      return result;
    });

    const searchByCategory = (value: string) => {
      state.category = value;
    };

    return {
      ...toRefs(state),
      filterByCategory,
      searchByCategory
    };
  }
});
