

















































































import Vue from 'vue';
import { reactive, toRefs, PropType, ref, watch } from '@vue/composition-api';
import ValidationService from '@/logic/validation.service';

export type ReviewContents = {
  title: string;
  pros: string; // 良い点
  cons: string; // 悪い点
};

export default Vue.extend({
  name: 'review-contents-form',
  props: {
    nickname: {
      type: String
    },
    value: {
      type: Object as PropType<ReviewContents>,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: (props: any, context) => {
    const state = reactive({
      reviewForm: ref<HTMLFormElement>(),
      title: props.value.title || '',
      pros: props.value.pros || '',
      cons: props.value.cons || '',
      rules: {
        titleRule: ref<(Function | boolean | string)[]>([]),
        prosRule: ref<(Function | boolean | string)[]>([]),
        consRule: ref<(Function | boolean | string)[]>([])
      }
    });

    // バリデーションをリセット
    state.reviewForm?.resetValidation();

    /**
     * バリデーションを有効化する
     */
    const enableValidation = () => {
      state.rules = {
        titleRule: [ValidationService.required('タイトル'), ValidationService.max('タイトル', 40)],
        prosRule: [ValidationService.required('良い点'), ValidationService.max('良い点', 1200)],
        consRule: [ValidationService.max('悪い点', 1200)]
      };
    };

    /**
     * バリデーションを行う
     */
    const validate = (): boolean => {
      const valid = state.reviewForm?.validate();
      return valid;
    };

    /**
     * 評価内容が変更したら親コンポーネントに伝える
     */
    const onChange = () => {
      const reviewContents = {
        title: state.title,
        pros: state.pros,
        cons: state.cons
      };
      context.emit('change', reviewContents);
    };

    watch(
      () => props.value,
      (value) => {
        state.title = value.title || '';
        state.pros = value.pros || '';
        state.cons = value.cons || '';
      }
    );

    return {
      ...toRefs(state),
      onChange,
      enableValidation,
      validate
    };
  }
});
