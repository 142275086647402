
























import Vue from 'vue';
import { formatPrice } from '@/logic/utils';
import { reactive, toRefs } from '@vue/composition-api';
export default Vue.extend({
  name: 'nopriceAndPopular',
  props: {
    productDetailList: {
      type: Array,
      required: true
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      formatPrice,
      ...toRefs(state)
    };
  }
});
