import { render, staticRenderFns } from "./rakutenpay.vue?vue&type=template&id=7a0f9358&scoped=true&"
import script from "./rakutenpay.vue?vue&type=script&lang=ts&"
export * from "./rakutenpay.vue?vue&type=script&lang=ts&"
import style0 from "./rakutenpay.vue?vue&type=style&index=0&id=7a0f9358&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0f9358",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VSheet})
