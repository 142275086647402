







































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';

export default Vue.extend({
  name: 'iphone7',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    facebookAndTwitter: FacebookAndTwitter
  },
  setup: () => {
    document.title = 'iPhone7のカメラ性能を徹底レビュー | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'iPhone7になってカメラ性能はどう進化したのか? まずはカタログスペックから比較してみました！')

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'キタムラ家電',
          linkUrl: '/ec/special/kaden',
          disabled: false
        },
        {
          path: 'iPhone7のカメラ性能を徹底レビュー',
          disabled: true
        }
      ],
    });

    const linkToOtherWindow = (url: string | undefined) => {
      window.open(url, '_blank');
    };
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      linkToOtherWindow
    };
  }
});
