/** 購入時の不正アクセス防止用 */
export const fraudbuster = {
  inserted: (el: Element) => {
    const input = document.createElement('input');
    input.id = 'fraudbuster';
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'fraudbuster');
    el.appendChild(input);

    const script = document.createElement('script');
    script.src = '//fraud-buster.appspot.com/js/fraudbuster.js';
    el.appendChild(script);
  }
};
