






























import Vue from 'vue';
import { onMounted, reactive, ref, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';

export default Vue.extend({
  name: 'panasonic-dishwasher',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    recommendedFeatures: RecommendedFeatures
  },
  setup: () => {
    document.title = 'パナソニック 食器洗い乾燥機（食洗機） スマホで設置シミュレーション| カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'パナソニックの食洗器の設置イメージをご確認いただけるサイトです。スマートフォンを使って、食洗器を実際にキッチンに置いたイメージを表示します。気になるサイズ感や対象物との距離感などを確認できます。 ');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '食洗機AR設置シミレーション',
          disabled: true
        }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shop.kitamura.jp/images/banner/1921.gif',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: '/ec/special/kaden/bento',
          img: 'https://shop.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当生活におすすめ商品'
        },
        {
          href: '/ec/special/kaden/toaster-oven',
          img: 'https://shop.kitamura.jp/images/banner/6374.jpg',
          alt: 'おすすめ高級トースター特集'
        },
        {
          href: '/ec/special/kaden/home-party',
          img: 'https://shop.kitamura.jp/images/banner/3770.gif',
          alt: 'キッチン家電特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      arRef: ref<HTMLElement>()
    });

    onMounted(() => {
      // ↓メーカー提供
      window.addEventListener(
        'message',
        function (e) {
          const eventName = e.data[0];
          const data = e.data[1];
          if (state.arRef) {
            state.arRef.style.width = '100%';
            switch (eventName) {
              case 'setHeight':
                state.arRef.style.height = data + 'px';
                break;
            }
          }
        },
        false
      );
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
