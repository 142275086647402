






































import Vue from 'vue';
import { reactive, toRefs, onMounted, ref } from '@vue/composition-api';
import MyPageLayout from '../common/my-page-layout.vue';
import Product from '@/components/common/product.vue';
import BackButton from '@/components/common/back-button.vue';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';

export default Vue.extend({
  name: 'review-post-completed',
  components: {
    'my-page-layout': MyPageLayout,
    product: Product,
    'back-button': BackButton
  },
  setup: (_, context) => {
    const state = reactive({
      // 画面タイトル
      title: '投稿完了',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: '投稿したレビュー', linkUrl: '/ec/mypage/review/list' },
        { path: '投稿完了', linkUrl: '' }
      ],
      // JANコード
      janCode: '',
      // レビューID
      reviewId: ref<number>(),
      // 投稿レビュー商品情報
      reviewProduct: {} as ProductDetail
    });

    /**
     * 商品詳細を取得する
     * @param janCode 商品コード
     */
    const fetchProductDetail = async (janCode: string): Promise<void> => {
      try {
        const result = await ProductService.fetchProducts([janCode], true);
        state.reviewProduct = result.items[0];
      } catch (error) {
        console.error(error);
        state.reviewProduct = {} as ProductDetail;
      }
    };

    onMounted(() => {
      const params = context.root.$route.params;
      const reviewId = context.root.$route.query?.review_id;
      // janCodeが無い場合は、NotFoundPageに遷移させる
      if (params.janCode) {
        state.janCode = params.janCode;
        fetchProductDetail(state.janCode);
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    });

    return {
      ...toRefs(state)
    };
  }
});
