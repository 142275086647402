




import Vue from 'vue';
import { ref } from '@vue/composition-api';
import IncludeFileService from '@/logic/include-file.service';

export default Vue.extend({
  name: 'banner',
  setup: () => {
    const includeHtml = ref<string>('');

    // インクルードファイルを取得
    IncludeFileService.fetchIncludeFile('header/banner.html').then((response) => (includeHtml.value = response));
    return {
      includeHtml
    };
  }
});
