






























































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Notfound from '@/components/common/not-found.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'wh-1000xm5',
  components: {
    breadcrumbs: Breadcrumbs,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    priceAndPopular: PriceAndPopular,
    watchInVideo: WatchInVideo,
    characteristic: Characteristic,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー ノイズキャンセリング機能搭載 ワイヤレスヘッドホン WH-1000XM5 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー ノイズキャンセリング機能搭載 ワイヤレスヘッドホン WH-1000XM5 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/05/13 09:00',
      fanValidTo: '2022/05/13 11:59',
      // ↓ ---- 予約レビュー掲載期間 ----
      reserveValidFrom: '2022/05/13 12:00',
      reserveValidTo: '2022/05/26 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/05/27 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー ノイズキャンセリング機能搭載 ワイヤレスヘッドホン WH-1000XM5',
          disabled: true
        }
      ],
      commonCharacteristicList: [
        {
          subtitle: '更に進化した業界最高クラスのノイズキャンセリング性能',
          contents: [
            '従来機で搭載していた「高音質ノイズキャンセリングプロセッサー QN1」に加えて、新たに「統合プロセッサーV1」を搭載',
            'ヘッドホンの外側と内側に左右4つずつ配置したセンサーで効率的にノイズを収音する「マルチノイズセンサーテクノロジー」',
            'ヘッドホンの装着状況や環境に合わせて常に自動でノイズキャンセリングの特性を最適化する「オートＮＣオプティマイザー」を新搭載'
          ]
        },
        {
          subtitle: '高音質を追求',
          contents: [
            '専用設計30mmドライバーユニットを搭載し、低音域から高音域までの再現性を高め、より自然で伸びのある音質を実現',
            'ウォークマンで培った高音質技術をヘッドホンに最適化。微細な音の再現や広がり、定位感も向上',
            '高音質コーデックLDAC™にも対応。ワイヤレスでもハイレゾ音質を楽しめます'
          ]
        },
        {
          subtitle: 'オンライン会議や授業にも最適な業界最高クラスの通話性能',
          contents: [
            'ビームフォーミング技術により口元への指向性を高めた左右2つずつのマイクを搭載',
            'ユーザーの声とそれ以外の環境ノイズを分離して発話した声をクリアに抽出',
            '風ノイズを低減するために内部マイク周辺の構造を新規設計し、風が強いなかでもクリアな通話が可能',
            'マルチポイント機能に対応するので、手動で接続機器を切り替える手間がなく、快適に使うことができます'
          ]
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736132566', '4548736132573'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品 かり '4548736140264',
      mainProductJanCode: ['4548736132566', '4548736132573'],
      mainProductList: [] as Array<ProductDetail>
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
          document.title = '掲載準備中 | カメラのキタムラネットショップ';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
