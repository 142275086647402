















































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'a1',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー α1 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー α1 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: 'a1 外観', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '圧倒的な解像力に加え、次世代を見据えた動画性能・AF性能',
          contents: [
            '新開発有効約5010万画素メモリー内蔵フルサイズ積層型CMOSイメージセンサーと新画像処理エンジン「BIONZ XR（ビオンズ エックスアール）」を採用。高解像と圧倒的なスピードを両立',
            '新開発フルサイズイメージセンサーの高速読み出しと大容量データのリアルタイム処理により、αシリーズ初、4:2:0 10bitでの8K 30pの動画記録が可能',
            'ブラックアウトフリーでの最高約30コマ/秒のAF/AE追随高速連写を実現。視野を遮られることなく被写体を肉眼で追い続けるように撮影することが可能',
            '撮像エリアの約92％をカバーする高密度かつ広範囲に配置された759点の像面位相差AFセンサーを搭載。高速・高精度な被写体追随を実現',
            '動体歪みを極限まで抑える最高1/32000秒のアンチディストーションシャッター。人物、動物に加え新たに「鳥」にも対応したリアルタイム瞳AF。世界最速フラッシュ同調速度1/400秒のデュアル駆動式シャッター等、新時代の性能を実現'
          ]
        },
        {
          subtitle: 'プロの撮影現場を支える信頼性、操作性',
          contents: [
            'クラス最高解像度の約944万ドットの広視野・高精細OLED電子ファインダー採用。世界初240fpsのリフレッシュレートの実現で、速く動く被写体も快適に撮影可能',
            'CFexpress Type AメモリーカードとSDXCメモリーカードに対応した両対応デュアルスロットを採用。静止画・動画の膨大なデータ処理においてもゆとりある撮影を実現',
            '外付けのWi-Fiアダプターが不要な本体内蔵Wi-Fi 802.11ac 2×2 MIMOに対応。業界トップクラスの転送速度を実現',
            'トップカバーやフロントカバー、内部フレーム、リアカバーなどに軽量かつ剛性の高いマグネシウム合金を使用。強化したセンサークリーニング機能と防塵防滴に配慮した設計',
            'PCリモート撮影や画像バックアップが可能なUSB 3.2対応Type-C端子と耐久性や汎用性の高いHDMI Type-A端子を搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α1',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736130678'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736130678', '4548736108288', '4549292146851', '4960759903402'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736130678',
        '4548736064522',
        '4548736105546',
        '4548736064539',
        '4548736064621',
        '4548736055704',
        '4548736109520',
        '4548736121249',
        '4548736083998',
        '4548736123151',
        '4548736099876',
        '4548736019515',
        '4548736038400',
        '4548736058415',
        '4548736099852',
        '4548736074125',
        '4548736089037',
        '4548736089044',
        '4548736067714',
        '4548736067738'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
