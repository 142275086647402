











































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import SubText from '@/components/common/special/subtext.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import SplitContent from '@/components/common/special/split-content.vue';
import Notfound from '@/components/common/not-found.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'eosr7',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    exampleImages: ExampleImages,
    expensiveTradeIn: ExpensiveTradeIn,
    urlcopy: Urlcopy,
    shasha: ShaSha,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPurchase: PriceAndPurchase,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    watchInVideo: WatchInVideo,
    notfound: Notfound,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'キヤノン EOS R7 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'キヤノン EOS R7 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      isShow: false,
      // ↓ ---- 予約掲載期間 ----
      fanValidFrom: '2022/5/24 13:20',
      fanValidTo: '2022/5/25 09:59',
      // ↓ ---- 発売掲載期間 ----
      reserveValidFrom: '2022/5/26 10:00',
      reserveValidTo: '2022/6/22 23:59',
      // ↓ ---- キャンペーン掲載期間 ----
      releaseValidFrom: '2022/6/23 00:00',
      releaseValidTo: '2099/5/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R7',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '高価下取り', href: '#price2' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      // ↓ ---- 高値下取り ----
      expensiveTradeInList: {
        name: 'キヤノン EOS 7D Mark II',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AD%E3%83%A4%E3%83%8E%E3%83%B3%20EOS%207D%20MarkII&category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&sort=add_date&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/a42/907/10e/abd/f32/39e/ded/ce2/57c/6b8/4dl/9u4/aqu/d/L.jpg',
        txt: 'キヤノン EOS R7',
        tradeInProducts: [
          {
            name: 'キヤノン EOS 7D Mark II',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AD%E3%83%A4%E3%83%8E%E3%83%B3%20EOS%207D%20MarkII&category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&sort=add_date&limit=40'
          },
          {
            name: 'キヤノン EOS 80D',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AD%E3%83%A4%E3%83%8E%E3%83%B3%20EOS%2080D&category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&sort=add_date&limit=40'
          },
          {
            name: 'キヤノン EOS 90D',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AD%E3%83%A4%E3%83%8E%E3%83%B3%20EOS%2090D&category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&sort=add_date&limit=40'
          }
        ],
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/canon/eosr7/bnr_shitadori_350-130.jpg'
      },
      characteristicList: [
        {
          subtitle: '動体撮影など幅広いシーンに対応する高速連写・高性能AFを搭載。狙った瞬間を逃さない！',
          contents: [
            'メカシャッター／電子先幕による撮影時は、EOS Rシリーズ最速となるAF／AE追従で最高約15コマ/秒の高速連写。電子シャッターによる撮影時は、AF／AE追従で最高約30コマ/秒の高速連写を実現',
            '「EOS R3」のAF被写体検出技術を継承し、人物の瞳・顔・頭部・胴体、動物（犬・猫・鳥）の瞳・顔・全身、乗り物（モータースポーツにおける車・バイク）の全体・スポット検出に対応',
            'ディープラーニング技術を活用したアルゴリズム「EOS iTR AF X」により、優れた検出性能と被写体追尾性能を実現',
            'クロップなしで最高約30コマ/秒の連続撮影を可能にする、電子シャッターによるRAW画像の連続撮影機能「RAWバーストモード」（AF／AE追従）を搭載',
            'シャッターボタン半押し状態で撮影を開始し、シャッターボタン全押しの最大約0.5 秒前から記録できる「プリ撮影」が可能'
          ]
        },
        {
          subtitle: '約3250万画素のEOS Rシリーズ初のAPS - CサイズCMOSセンサー',
          contents: [
            '新開発の有効画素数最大約3250万画素 APS - CサイズCMOSセンサーと、映像エンジン「DIGIC X」の組み合わせにより、優れた高感度性能を達成。APS - CサイズCMOSセンサー搭載のEOS史上最高解像性能を実現',
            '静止画撮影時に常用で最高ISO 32000の高感度撮影を実現。高感度撮影時のノイズを低減し、暗い場所でも自由度の高い撮影表現が可能',
            'レンズの収差などを補正し、解像感を向上する「デジタルレンズオプティマイザ」に対応。高速連続撮影時でも高い補正効果が得られる「強め」の設定が可能',
            '「HDR PQ」方式での静止画／動画記録に対応。また、高速でブラケット撮影を行い、カメラ内で3枚の写真を1枚に合成する「HDRモード」を搭載。階調性豊かな映像表現が可能',
            '自動的にピント位置を変えながら連続撮影を行うフォーカスブラケット撮影において、EOSシリーズで初めて、カメラ内深度合成処理を実現。深度合成した画像をその場で確認が可能'
          ]
        },
        {
          subtitle: '協調制御による最高8.0段の手ブレ補正を実現',
          contents: [
            'ボディー内5軸手ブレ補正機構を搭載し、手ブレの発生しやすい環境でも快適な撮影を実現。さらに、レンズ内ISを搭載したRFレンズ装着時は、カメラとレンズそれぞれの手ブレ補正機構が協調することで、最高8.0段の手ブレ補正が可能',
            'ボディー内手ブレ補正機構のロール補正機能を利用し、CMOSセンサーが動くことで撮影画面が水平になるよう自動補正。カメラの水平出し時間を短縮し、撮影の効率が向上',
            '動画撮影時には、ボディー内とレンズ内の手ブレ補正機構の協調制御に加え、動画電子ISの併用も可能。歩きながらの撮影など、多様化する動画撮影のニーズに対応'
          ]
        },
        {
          subtitle: 'ハイアマチュアユーザーの快適な撮影を支える操作性と信頼性',
          contents: [
            'ファインダーをのぞいたまま、設定から撮影が可能な新しいサブ電子ダイヤルを搭載。ホールド時に自然に指が届くため、指を大きく動かすことなくさまざまな操作が可能',
            '小型・軽量なボディーでありながら、UHS - II対応のSDカードスロットを2基搭載',
            'バリアングル液晶モニターにより、自分撮り時に構図確認を行いながらタッチ操作でAFや撮影開始などの操作が可能',
            '「OVFビューアシスト」により、一眼レフカメラユーザーでも馴染みやすいファインダー表示を実現。一眼レフカメラの光学ファインダーのように黒つぶれや白とびの少ない自然な見え方を再現',
            '「EOS 90D」（2019年9月発売）と同等の防じん・防滴性能を備えており、幅広い環境で使用が可能'
          ]
        }
      ],
      mainProductJanCode: ['4549292185430', '4549292185546'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 試し品 4549292157345----
      comparisonJanCodeList: ['4549292185430', '4549292189711', '4545350053864', '4547410427875'],
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ notice比較 予約開始までお待ちください 以外のJAN ----
      noticecomparisonJanCodeList: ['4545350053864', '4547410427875'],
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4549292185430',
        '4549292185546',
        '4549292189711',
        '4549292189766',
        '4549292189803',
        '4549292188325',
        '4549292195798',
        '4549292209907',
        '4549292209914',
        '4523052024108',
        '4549292157222',
        '4960999627496',
        '4960999627540',
        '4523052024092',
        '4523052024719',
        '4523052022715',
        '4523052022722',
        '4523052022739',
        '4549292065596',
        '4957638817080'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
          document.title = '掲載準備中 | カメラのキタムラネットショップ';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
