






































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
export default Vue.extend({
  name: 'highly-recommended-2015-0605',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = '超薄型レンズフィルター「コッキン PL(偏光) / プロテクター(保護/UV)」 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '究極の薄さと軽さを追求した「コッキン アルモニ」を動画＆レビューで紹介。超広角レンズ装着時のケラレを防止！超薄型カメラレンズフィルター「コッキン アルモニ PL(偏光) / レンズプロテクター(保護/UV)」はカメラのキタムラへお任せください♪')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '超薄型レンズフィルター「コッキン PL(偏光) / プロテクター(保護/UV)」 | スタッフのイチオシ',
          disabled: true
        }
      ],
      samplePhotoList: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/kenko-neo-protector1.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/cokin-protector1.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/kenko-neo-C-PL1.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/cokin-C-PL1.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/kenko-neo-protector2.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/cokin-protector2.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/kenko-neo-C-PL2.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/cokin-C-PL2.jpg',
      ],
      backNumberCameraList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/mpc-2.3_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0918/?ref=ca-0925', name: 'MEYER 電子レンジ圧力鍋 MPC-2.3' },
        { src: 'http://shopimg.kitamura.jp/images/pd/891/9ad/cd5/c5d/bc9/b10/768/968/104/44b/05p/7jv/m23/e/L.jpg', href: '/ec/special/general/highly_recommended/2015/0821', name: 'ニコン 携帯型レーザー距離計 COOLSHOT 20' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/i-item-sarafit_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0807', name: 'TVでお馴染み焦げ付かないセラミックフライパン「セラフィット」' },
      ],
      backNumberSmartphoneList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/i-item-elecom_high-reso_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0814', name: '「意外にいい音」、エレコム ハイレゾイヤホンシリーズ EHP-CH1000 他' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/i-item-ztylus_500px.jpg', href: '/ec/special/general/highly_recommended/2015/0710', name: 'iPhone6用レンズアタッチメント ズタイラス RV-2 & ZIP-6LB' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0925/i-item-ct-wpip13_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0619', name: '防水、耐衝撃の強靭なiPhoneケース Catalyst Case for iPhone 6' },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
