import { render, staticRenderFns } from "./z600f63vrs.vue?vue&type=template&id=7981b420&scoped=true&"
import script from "./z600f63vrs.vue?vue&type=script&lang=ts&"
export * from "./z600f63vrs.vue?vue&type=script&lang=ts&"
import style0 from "./z600f63vrs.vue?vue&type=style&index=0&id=7981b420&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7981b420",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
