




















































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';

export default Vue.extend({
  name: 'air-purifier-daikin',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures
  },
  props: {},
  setup: () => {
    document.title = 'ダニ・カビ・湿気など 夏のお悩み、空気清浄機で解決！ | カメラのキタムラ ネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ダイキン空気清浄機のおすすめポイントを解説！最新モデルの空気清浄機で健康な室内環境を手に入れましょう♪ご自宅の空気を浄化し、快適な日々の暮らしを手に入れるために、ぜひお見逃しなく！'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        }
      ],
      airCleanerList: [
        {
          name: 'ダイキン ストリーマ 空気清浄機 コンパクトモデル MC55Z-W',
          productImage: 'https://shopimg.kitamura.jp/images/pd/899/d68/16b/b0c/c21/dd9/c19/c0b/dda/4b0/942/9x5/ggk/u/M.jpg',
          href: '/ec/pd/4573535233572',
          txt: '寝室・子供部屋・書斎におすすめのコンパクトモデル',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier/daikin/parts_05.png'
        },
        {
          name: 'ダイキン 加湿ストリーマ 空気清浄機 スリムクーセイ MCK50Y-W',
          productImage: 'https://shopimg.kitamura.jp/images/pd/08f/fda/057/619/cb4/0a5/62f/7e1/d79/78f/1d1/dbv/nik/e/M.jpg',
          href: '/ec/pd/4573535125426',
          txt: '寝室・子供部屋におすすめの加湿機能付きお買い得モデル',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier/daikin/parts_06.png'
        },
        {
          name: 'ダイキン 加湿ストリーマ 空気清浄機 スリムタワータイプ MCK55Y-C',
          productImage: 'https://shopimg.kitamura.jp/images/pd/2c6/9fd/201/b46/b75/adf/f2c/984/610/dc9/1bp/a0x/z2q/n/M.jpg',
          href: '/ec/pd/4573535125464',
          txt: '充実機能とデザインを両立させた加湿空気清浄機',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier/daikin/parts_06b.png'
        }
      ],
      daikinTypeList: [
        { href: '/ec/list?keyword=MCK70Y&narrow18=0', src: 'https://shopimg.kitamura.jp/images/pd/712/605/439/d44/8b5/c55/268/461/1cf/6c2/54d/jhz/5ps/g/M.jpg', txt: '加湿付大風量タイプ' },
        { href: '/ec/list?keyword=MCK55Y&narrow18=0', src: 'https://shopimg.kitamura.jp/images/pd/8bc/c27/2f7/f2b/ebf/494/d8e/993/351/d9e/7e4/vhu/k3q/y/M.jpg', txt: '加湿付コンパクトタイプ' },
        { href: '/ec/pd/4573535233619', src: 'https://shopimg.kitamura.jp/images/pd/ad2/2d1/bd7/5d0/f50/b93/790/af4/9ba/ed5/0fd/3hb/wxv/w/M.jpg', txt: '除加湿タイプ' },
        { href: '/ec/pd/4573535233572', src: 'https://shopimg.kitamura.jp/images/pd/899/d68/16b/b0c/c21/dd9/c19/c0b/dda/4b0/942/9x5/ggk/u/M.jpg', txt: '加湿なしタイプ' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/fan-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/6597.jpg',
          alt: 'おすすめ扇風機特集'
        },
        {
          href: '/ec/special/kaden/heyabosi',
          img: 'https://shopimg.kitamura.jp/images/banner/4005.jpg',
          alt: 'おすすめ除湿機特集'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ],

      // ↓ ---- メイン商品 ----
      // 取得するJancode 4549292157345 仮
      mainProductJanCode: ['4573535233572', '4573535125426', '4573535125464'],
      // 結果格納用
      mainProductList: [] as Array<ProductDetail>
      // ↑ ---- メイン商品 ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      fetchProduct();
    });
    return {
      formatPrice,
      ...toRefs(state),
      noimage
    };
  }
});
