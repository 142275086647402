import { render, staticRenderFns } from "./other.vue?vue&type=template&id=5b025a81&scoped=true&"
import script from "./other.vue?vue&type=script&lang=ts&"
export * from "./other.vue?vue&type=script&lang=ts&"
import style0 from "./other.vue?vue&type=style&index=0&id=5b025a81&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b025a81",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
