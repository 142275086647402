






















































































import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import { ORDER_DETAIL_TYPES } from '@/constants/mypage-order-detail-types';
import { DELIVERY_COMPANY } from '@/constants/mypage-order-detail-delivery';
import { OrderBuyDetail } from '@/types/order-buy-detail';

export default Vue.extend({
  name: 'order-info',
  props: {
    type: {
      type: Number,
      required: true
    },
    info: {
      type: Object,
      required: true
    }
  },
  setup: (props) => {
    const state = reactive({});

    /**
     * 配送伝票番号URL
     */
    const deliveryTrackingUrl = computed(() => {
      let url = '';
      const info = props.info as OrderBuyDetail;

      if (
        info.delivTrackingId &&
        info.deliveryCompanyId &&
        info.rcvMethodId &&
        (info.rcvMethodId === 2 || info.rcvMethodId === 3) &&
        (info.delivTrackingId.length === 11 || info.delivTrackingId.length === 12)
      ) {
        switch (info.deliveryCompanyId) {
          case DELIVERY_COMPANY.YAMATO:
            url = `http://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${info.delivTrackingId}`;
            break;
          case DELIVERY_COMPANY.JAPAN_POST:
            url = `https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S002&reqCodeNo1=${info.delivTrackingId}`;
            break;
          case DELIVERY_COMPANY.SAGAWA:
            url = `https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${info.delivTrackingId}`;
            break;
          default:
            break;
        }
      }
      return url;
    });

    return {
      ...toRefs(state),
      ORDER_DETAIL_TYPES,
      deliveryTrackingUrl
    };
  }
});
