
































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ExpensivePurchasesAndShoppingCreditImage from '@/components/common/special/expensivePurchasesAndShoppingCreditImage.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'd850',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    exampleImages: ExampleImages,
    expensivePurchasesAndShoppingCreditImage: ExpensivePurchasesAndShoppingCreditImage,
    watchInVideo: WatchInVideo,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン フルサイズデジタル一眼レフ 新製品 D850 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン フルサイズデジタル一眼レフ 新製品「D850」特集。前機種からの進化点、上位機種との比較などの情報や価格・スペック・お得な情報をチェック！新製品ならカメラ専門店カメラのキタムラのショッピングサイトにおまかせください。'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d850/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d850/sp_img_top.jpg',
      exampleImagesPath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d850/',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d850',
      watchInVideos: [
        'https://www.youtube.com/embed/_0xYonoBywI?rel=0',
        'https://www.youtube.com/embed/zlsQPIt39TY?rel=0',
        'https://www.youtube.com/embed/IZcpFMj4SvQ?rel=0',
        'https://www.youtube.com/embed/XAw95xMTbPk?rel=0',
        'https://www.youtube.com/embed/6Z2tlswQXcc?rel=0',
        'https://www.youtube.com/embed/XSO982RyFRw?rel=0'
      ],
      naviList: [
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'ニコンデジタル一眼レフカメラ史上最高有効画素数4575万画素をベースに、さらに磨きをかけた描写力',
          contents: [
            'ニコンのデジタル一眼レフカメラで初めて裏面照射型CMOSセンサーを搭載',
            '有効画素数4575万画素と高画素ながら、ISO 64～25600の常用感度域を達成',
            '画像処理エンジン「EXPEED 5」と連携して、高感度でも鮮鋭感を保ちながら効果的にノイズを抑制'
          ]
        },
        {
          subtitle: '高画素モデルの限界を打ち破ったかつてない柔軟な対応力',
          contents: [
            '高画素モデルでありながら、ボディー単体で最高約7コマ/秒、マルチパワーバッテリーパック MB-D18使用で約9コマ/秒（※）の高速連続撮影が可能\n※ MB-D18を装着し、EN-EL18b使用時',
            'D5と同じ153点AFシステムがサポートし、動く被写体もより確実に捕捉',
            '14ビットロスレス圧縮RAWで約51コマ、12ビットロスレス圧縮RAW（L）なら170コマまで連続して撮影可能'
          ]
        },
        {
          subtitle: 'さらなる進化を遂げた動画性能',
          contents: [
            'FX フォーマットベース フルフレームの4K UHD（3840 ×2160）動画対応',
            'NIKKORレンズの、広角レンズや魚眼レンズのワイドな画角や独特の表現力を最大限に活用した、ドラマチックな映像表現が可能',
            'フルHD動画の表現力を拡げる「スローモーション動画」機能を新搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: ' ニコン D850',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759149336'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759149336', '4960759143532', '4549292075748'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759149336', '4960759149312', '4960759149329', '4960759900005', '4960759900012', '4960759900029'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
