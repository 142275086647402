











































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import SubText from '@/components/common/special/subtext.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'filmcamera-890',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    subText: SubText,
    recommendedFeatures: RecommendedFeatures,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'チェキ アルバム・台紙 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '結婚式の二次会や歓迎会、送別会などのイベントに活躍するチェキ！アルバムやフィルム価格をチェック★チェキと一緒に、チェキアルバムやチェキフィルムのご購入もおすすめ！商品をご紹介！カメラのキタムラにおまかせください！'
      );
    const state = reactive({
      naviList: [
        { naviItem: 'チェキ\n商品一覧', href: '/ec/special/camera/filmcamera/889' },
        { naviItem: 'チェキアルバム\n商品一覧', href: '/ec/special/camera/filmcamera/890' },
        { naviItem: 'チェキフィルム\n商品一覧', href: '/ec/special/camera/filmcamera/891' },
        { naviItem: 'アクセサリー\n電池一覧', href: '/ec/special/camera/filmcamera/892' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント注文',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura-print.com/special/mother/dispose/',
          img: 'https://shopimg.kitamura.jp/images/banner/1632.jpg',
          alt: '写真の整理術',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'チェキ・チェキフィルム・チェキアルバムおすすめ商品',
          linkUrl: '/ec/special/camera/filmcamera',
          disabled: false
        },
        {
          path: 'チェキ アルバム・台紙',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960999635170'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
