


























































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ProductListByJanCode from '@/components/special/common/product-list-by-jan-code.vue';
import { noimage } from '@/logic/utils';

export default Vue.extend({
  name: 'sample-component2.vue',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    'product-list-by-jan-code': ProductListByJanCode
  },
  setup: (props, context) => {
    const state = reactive({
      msg: 'sample-component2',
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'サンプル２',
          linkUrl: '',
          disabled: true
        }
      ]
    });

    onMounted(() => {
      console.log('onMounted');
      console.log(state.msg);
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
