











































import Vue from 'vue';
import { computed, reactive, toRefs, watch } from '@vue/composition-api';
import { ORDER_DETAIL_TYPES } from '@/constants//mypage-order-detail-types';
import {
  BUY_DELIVERY_ORDER_STEP,
  BUY_SHOP_ORDER_STEP,
  SELL_ORDER_STEP,
  SELL_TOKUTOKU_ORDER_NEED_KIT_STEP,
  SELL_TOKUTOKU_ORDER_NO_NEED_KIT_STEP
} from '@/constants/mypage-order-step';
import UserService from '@/logic/user.service';

export default Vue.extend({
  name: 'order-step',
  props: {
    type: {
      type: Number,
      required: true
    },
    val: {
      type: [String, Number],
      required: true,
      default: ''
    },
    // 「注文」「下取」といった種類の名称
    kindName: {
      type: String,
      default: '注文'
    }
  },
  setup: (props) => {
    const state = reactive({
      stepList: [] as Array<{ no: number; codeList: Array<string>; text: string }>,
      currentStep: {} as { no: number; text: string }
    });

    watch(
      () => [props.type, props.val],
      () => {
        switch (props.type) {
          case ORDER_DETAIL_TYPES.BUY_DELIVERY:
            state.stepList = BUY_DELIVERY_ORDER_STEP;
            break;
          case ORDER_DETAIL_TYPES.BUY_SHOP:
            state.stepList = BUY_SHOP_ORDER_STEP;
            break;
          case ORDER_DETAIL_TYPES.SELL:
            state.stepList = SELL_ORDER_STEP;
            break;
          case ORDER_DETAIL_TYPES.TOKUTOKU_NEED_KIT:
            state.stepList = SELL_TOKUTOKU_ORDER_NEED_KIT_STEP;
            break;
          case ORDER_DETAIL_TYPES.TOKUTOKU_NO_NEED_KIT:
            state.stepList = SELL_TOKUTOKU_ORDER_NO_NEED_KIT_STEP;
            break;
          default:
            break;
        }
        const value = `${props.val}`;
        state.currentStep = UserService.convertStepStatus(state.stepList, value);
      },
      { immediate: true }
    );

    // 表示されるSTEPリスト
    const displayedStepList = computed(() => state.stepList.filter((step) => step.no !== 0));

    return {
      ORDER_DETAIL_TYPES,
      ...toRefs(state),
      displayedStepList
    };
  }
});
