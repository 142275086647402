


























































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Userguide from '@/components/common/guide/user-guide.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
export default Vue.extend({
  name: 'used-navi',
  components: {
    breadcrumbs: Breadcrumbs,
    userguide: Userguide,
  },
  setup: (props, context) => {
    document.title = '中古ナビ | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'キタムラの中古が選ばれる理由や、中古品購入のコツをご紹介いたします');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'ようこそネットショップへ！はじめての方へ',
          linkUrl: '/ec/guide/firstguide',
          disabled: false
        },
        {
          path: '中古ナビ',
          disabled: true
        }
      ],
    });
    function linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }
    return {
      ...toRefs(state),
      linkToOtherWindow
    };
  }
});
