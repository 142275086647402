import { render, staticRenderFns } from "./dslr.vue?vue&type=template&id=54268dfa&scoped=true&"
import script from "./dslr.vue?vue&type=script&lang=ts&"
export * from "./dslr.vue?vue&type=script&lang=ts&"
import style0 from "./dslr.vue?vue&type=style&index=0&id=54268dfa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54268dfa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
