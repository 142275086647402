


































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SubText from '@/components/common/special/subtext.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';

export default Vue.extend({
  name: 'highly-recommended-2015-0501',
  components: {
    breadcrumbs: Breadcrumbs,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    kitamuraService: KitamuraService,
    subText: SubText,
    titleImage: TitleImage,
    content2015: Content2015
  },
  setup: () => {
    document.title = '速写ストラップをつけたまま、三脚を使う裏技! ブラックラピッド × JOBY | スタッフのイチオシ | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '速写ストラップをつけたまま、三脚を使う裏技! ブラックラピッド × JOBY | カメラストラップはカメラのキタムラへお任せください♪')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '速写ストラップをつけたまま、三脚を使う裏技! ブラックラピッド × JOBY | スタッフのイチオシ',
          disabled: true
        }
      ],
      mainProductJanCode: ['2239530002262',],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
