

































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputedいる
import { noimage } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'hosizora',
  components: {
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '星空総合ページ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '星空を撮影したい人におすすめ♪星空撮影・天体撮影の特集をまとめた、カメラのキタムラ 星空総合ページです。');

    const state = reactive({
      starrySkyList: [
        {
          title: '『星空を撮影したい!!』という人におすすめ♪ 星空撮影 入門ページ',
          itemList: [
            {
              subtitle: 'カメラで星空を撮ろう!',
              txt: '広角レンズで撮る「星景写真編」',
              href: '/ec/special/camera/polarie',
              src: 'https://shopimg.kitamura.jp/images/banner/1488.gif'
            },
            {
              subtitle: 'ポラリエと望遠レンズで星空を撮ろう!',
              txt: 'ポラリエと中・望遠レンズで撮る「星空・天体写真編」',
              href: '/ec/special/camera/polarie/acc',
              src: 'https://shopimg.kitamura.jp/images/banner/3588.gif'
            },
            {
              subtitle: '天体望遠鏡で星空を撮ろう!',
              txt: '天体望遠鏡・超望遠で撮る「天体写真編」',
              href: '/ec/special/camera/astronomical/telescope-feature',
              src: 'https://shopimg.kitamura.jp/images/banner/2381.jpg'
            }
          ]
        },
        {
          title: '『星空を見たい!楽しみたい!!』という人におすすめ♪ 星空観察ページ',
          itemList: [
            {
              subtitle: '天体イベントスケジュール',
              txt: '今年の天体ショーは何が起こるの！？まずは天文現象をチェック!!',
              href: '/ec/special/camera/astronomical',
              src: 'https://shopimg.kitamura.jp/images/banner/1327.gif'
            },
            {
              subtitle: '天体望遠鏡の選び方',
              txt: '天体望遠鏡で星空を楽しもう! 初心者さんはこちらをチェック',
              href: '/ec/special/camera/astronomical/telescope',
              src: 'https://shopimg.kitamura.jp/images/banner/1698.jpg'
            },
            {
              subtitle: '双眼鏡・天体望遠鏡で月を見よう',
              txt: '双眼鏡・天体望遠鏡で、Let’s 天体観測! 天体観測入門',
              href: '/ec/special/camera/astronomical/moonlook',
              src: 'https://shopimg.kitamura.jp/images/banner/1306.jpg'
            }
          ]
        },
        {
          title: '星空観察ページ 番外編',
          itemList: [
            {
              subtitle: '夏休み自由研究特集',
              txt: '自由研究におすすめ☆月の写真を撮ってみよう!',
              href: '/ec/special/general/summerhomework/moon',
              src: 'https://shopimg.kitamura.jp/images/banner/1309.gif'
            },
            {
              subtitle: '双眼鏡の選び方特集',
              txt: 'ライブやコンサート、スポーツ観戦を、双眼鏡でもっと楽しく!',
              href: '/ec/special/camera/operaglass',
              src: 'https://shopimg.kitamura.jp/images/banner/2462.gif'
            }
          ]
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
