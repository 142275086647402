






































































































































































































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
//import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'rf14-35mmf4lisusm',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    topTitleImg: TopTitleImg,
    priceAndPurchase: PriceAndPurchase,
    //expensiveTradeIn: ExpensiveTradeIn,
    naviBtn: NaviBtn,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'キヤノン RF14-35mm F4 L IS USM | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン RF14-35mm F4 L IS USM 予約受付中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン RF14-35mm F4 L IS USM',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292186758'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292186758', '4549292152272', '4549292009903', '0085126205546'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '作例画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '超広角14mmから35mmの幅広いズーム域',
          contents: [
            'EF16-35mm F4L IS USMに比べて広角2mmのワイド化を実現。従来以上に広がりのある表現が可能',
            'Lレンズにふさわしい高画質を実現。ズーム全域で画面中心から周辺まで解像感の高いクリアな画像が撮影可能'
          ]
        },
        {
          subtitle: 'ズーム全域での高画質と小型・軽量を両立',
          contents: [
            'RFマウントの大口径・ショートバックフォーカスを生かした小型・軽量設計',
            '高性能レンズを含む12群16枚の光学設計と特殊コーティングSWC/ASC採用で高画質を実現'
          ]
        },
        {
          subtitle: 'レンズ単体で5.5段分、協調補正で7段の強力な手ブレ補正機構',
          contents: [
            '14mmの超広角域をカバーするレンズとして初めてCIPA基準約5.5段の光学手ブレ補正機構を搭載',
            'EOS R5との組み合わせでは、ボディ・レンズISの協調制御により約7段の手ブレ補正効果'
          ]
        }
      ],
      shashaData: [
        {
          text:
            'キヤノンからフルサイズミラーレスカメラ用交換レンズの「RF14-35mm F4 L IS USM」を2021年9月下旬に発売するとアナウンスがありました。これでRFマウントの小三元が全て揃うことになります。\n本製品の魅力について、キヤノンマーケティングジャパン カメラ営業二部 営業第一課のご担当の方へインタビューしましたので...',
          href: 'https://shasha.kitamura.jp/article/482251290.html',
          src: '/ec/images2/special/camera/feature/canon/rf14-35mmf4lisusm/shashabnr.jpg'
        }
      ],
      expensiveTradeInList: {
        name: 'EF16-35mm F4L IS USM',
        href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4549292009903&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/2aa/33d/db1/b48/c71/a79/7db/feb/71d/d78/fat/j14/t87/s/L.jpg',
        txt: 'RF14-35mm F4 L IS USM',
        tradeInProducts: [
          { name: 'EF16-35mm F4L IS USM', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4549292009903&pattern=1' },
          { name: 'EF11-24mm F4L USM', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4549292010169&pattern=1' },
          { name: 'EF16-35mm F2.8L III USM', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4549292037722&pattern=1' },
          { name: 'EF17-40mm F4L USM', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960999189949&pattern=1' },
          { name: 'RF15-35mm F2.8 L IS USM', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4549292152272&pattern=1' }
        ],
        tradeInImg: '/ec/images2/special/camera/feature/canon/rf14-35mmf4lisusm/bnr_shitadori_350-130.png'
      },
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
