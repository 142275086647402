




































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';
import OrderBuyList from './order-buy-list.vue';
import OrderSellList from './order-sell-list.vue';
import MyPageLayout from '../common/my-page-layout.vue';
import BackButton from '@/components/common/back-button.vue';

export default Vue.extend({
  name: 'order-list',
  components: {
    'my-page-layout': MyPageLayout,
    'order-buy-list': OrderBuyList,
    'order-sell-list': OrderSellList,
    'back-button': BackButton
  },
  setup: (_, context) => {
    const state = reactive({
      // 画面タイトル
      title: 'ご利用履歴',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: 'ご利用履歴', linkUrl: '' }
      ],
      // タブメニュー
      tab: 0
    });

    // URLによってタブの初期表示を切り替える
    const tabType = context.root.$route.query.type;
    if (tabType === 'sell') {
      state.tab = 1;
    }

    watch(
      () => state.tab,
      (tabIndex) => {
        let url = '/ec/mypage/odr/list';
        if (tabIndex === 1) {
          // 買取・下取履歴タブが選択された場合、クエリパラメータを付与する
          url += '?type=sell';
        }
        history.replaceState(null, '', url);
      }
    );

    watch(
      () => context.root.$route.query.type,
      (tabType) => {
        if (tabType === 'sell') {
          state.tab = 1;
        } else {
          state.tab = 0;
        }
      }
    );

    return {
      ...toRefs(state)
    };
  }
});
