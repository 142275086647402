

































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import RecommendedTripod from '@/components/common/special/recommended-tripod.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'tripod',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    recommendedFeatures: RecommendedFeatures,
    recommendedTripod: RecommendedTripod,
    facebookAndTwitter: FacebookAndTwitter,
    shoppingCredit: ShoppingCredit,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '持ち運びが楽なミニサイズの三脚もおすすめ！用途別に三脚の使い方や選び方を担当者がご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '旅行や登山、花や鉄道・飛行機、夜景など、撮りたいシーンに合わせたおすすめ三脚や、ベルボン、マンフロット、スリックなどの人気メーカーのおすすめ三脚をピックアップ。コンパクトな小型ミニ三脚や軽量カーボン・アルミ三脚、ビデオカメラ用三脚、一脚、雲台の選び方のポイントもまとめています。'
      );
    const state = reactive({
      naviList: [
        { naviItem: '種類で選ぶ おすすめ三脚 ▼', href: '#v001' },
        { naviItem: '必要なパーツ類のご紹介 ▼', href: '#v002' },
        { naviItem: '撮影シーンで選ぶ おすすめ三脚 ▼', href: '#v003' }
      ],
      tripodTypeList: [
        { maintxt: '風景写真が好きで作品作りを考えてる方には最適。軽くて丈夫なので大口径レンズも対応！', btntxt: 'カーボン三脚を見る ≫' },
        { maintxt: '小さく畳める携帯性の高い三脚。\n使用時に標準的な高さになるので旅行におすすめ', btntxt: 'トラベラー三脚を見る ≫' },
        { maintxt: 'ピントあわせがシビアなマクロレンズと相性バッチリ！ローポジションで花に近づけます', btntxt: 'ローポジション三脚を見る ≫' },
        { maintxt: '家族の集合写真には三脚が必須。軽くて使いやすい三脚で大切な記録を残しましょう', btntxt: 'ファミリー三脚を見る ≫' },
        { maintxt: 'ミニサイズの三脚なら、持ち運びラクラク♪ 気軽にどこでも記念撮影', btntxt: 'ミニサイズ三脚を見る ≫' },
        { maintxt: 'お子様の大切な学校行事や誕生日会。成長の記録の共に', btntxt: 'ビデオ撮影用三脚を見る ≫' }
      ],
      tripodPartsList: [
        { maintxt: '風景の作品作りにはこれ。水平をしっかり確保し構図を決め、チャンスを逃さないようにしましょう', btntxt: '3ウェイ三脚を見る ≫' },
        { maintxt: '雲台が自由に動くので、スポーツやF1など動きのある被写体の撮影におすすめ。軽量なのもポイント！', btntxt: '自由雲台を見る ≫' },
        {
          maintxt: 'クイックシューで三脚へのセットが楽々♪とっさの手持ち撮影にも便利。またカメラのネジ山を傷めないためにもおすすめ！',
          btntxt: 'クイックシューを見る ≫'
        }
      ],
      shootingSceneNaviList: [
        { naviItem: '家族旅行', href: '#si01' },
        { naviItem: '花や昆虫', href: '#si02' },
        { naviItem: '夜景花火', href: '#si03' },
        { naviItem: '自然風景', href: '#si04' },
        { naviItem: '鉄道電車', href: '#si05' },
        { naviItem: '飛行機', href: '#si06' },
        { naviItem: '野鳥動物', href: '#si07' }
      ],
      visible: false,
      index: 0, // default: 0
      imgs: [
        '/ec/images2/special/camera/tripod/hikouki01.jpg',
        '/ec/images2/special/camera/tripod/hikouki02.jpg',
        '/ec/images2/special/camera/tripod/hikouki03.jpg'
      ],
      installmentPaymentExampleList: [
        { productPrice: '168,552', numberOfDivisions: '48', monthlyPayment: '3,512' },
        { productPrice: '35,730', numberOfDivisions: '10', monthlyPayment: '3,573' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/bag/trial',
          img: 'https://shop.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.jpg',
          alt: 'カメラレンズフィルター'
        },
        {
          href: '/ec/special/camera/lensfilter/344',
          img: 'https://shopimg.kitamura.jp/images/banner/2449.jpg',
          alt: 'クロスフィルター'
        },
        {
          href: '/ec/special/general/how-to/hanabi',
          img: 'https://shopimg.kitamura.jp/images/banner/3043.jpg',
          alt: '花火特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめ三脚の選び方',
          disabled: true
        }
      ],
      mainProductJanCode: [
        '8024221700084', //0
        '4907990412782', //1
        '4906752107126', //2
        '8024221700206', //3
        '8024221623178', //4
        '8024221623314', //5
        '4906752109410', //6
        '4907990448019', //7
        '4907990470423', //8
        '4906752209738', //9
        '8024221623291', //10
        '4906752109564', //11
        '4906752201688', //12
        '4907990500366', //13
        '8024221667189', //14
        '4906752204238', //15
        '4906752100448' //16
      ],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };

    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    const handleHide = () => {
      state.visible = false;
    };

    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg,
      handleHide
    };
  }
});
