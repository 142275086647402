var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isShow)?_c('div',{staticClass:"hatsuuri"},[(_vm.errorMsg)?_c('notfound',{attrs:{"errorMessage":_vm.errorMsg}}):(!_vm.errorMsg)?_c('div',{staticClass:"main-contents-wrap"},[_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),(_vm.isShowFan)?_c('div',[_c('p',{})]):_vm._e(),(_vm.isShowReserve)?_c('div',[_c('a',{attrs:{"href":"/ec/special/sale/hatsuuri"}},[_c('v-img',{staticClass:"pc-s",attrs:{"src":"https://shop.kitamura.jp/ec/images2/special/sale/hatsuuri/bn00.png","alt":"初売りセール 2023","max-width":"100%","max-height":"auto"}})],1),_c('a',{attrs:{"href":"/ec/special/sale/hatsuuri"}},[_c('v-img',{staticClass:"sp-s",attrs:{"src":"https://shop.kitamura.jp/ec/images2/special/sale/hatsuuri/bns00.png","alt":"初売りセール 2023","max-width":"100%","max-height":"auto"}})],1),_c('p',{staticClass:"mb-4 text-center text-body-1 red--text font-weight-bold"},[_vm._v("※一部宅配受取り限定商品もあり")]),_c('v-container',{staticClass:"ma-0 pa-0",staticStyle:{"display":"none"}},[_c('v-row',{staticClass:"oya",attrs:{"dense":""}},_vm._l((_vm.naviList),function(navi,index){return _c('v-col',{key:("navi-" + index),attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: navi.href }),expression:"{ el: navi.href }"}]},[_c('v-btn',{staticClass:"text-transform including-line-breaks",attrs:{"block":"","height":"50","elevation":hover ? 12 : 2,"color":"grey darken-4"}},[_c('span',{staticClass:"v-btn-text-color text-md-h6"},[_vm._v(_vm._s(navi.naviItem)+" "),_c('v-icon',[_vm._v("fa fa-angle-down")])],1)])],1)]}}],null,true)})],1)}),1)],1),_c('h2',{staticClass:"midsi mt-7",staticStyle:{"display":"none"},attrs:{"id":"no1"}},[_vm._v("新品から選ぶ")]),_c('v-container',{staticStyle:{"display":"none"}},[_c('v-row',{staticClass:"oya",attrs:{"dense":""}},_vm._l((_vm.sinpinList),function(sinpin,index){return _c('v-col',{key:index,attrs:{"cols":"6","sm":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":sinpin.href}},[_c('v-btn',{staticClass:"text-transform including-line-breaks",attrs:{"block":"","height":"54","elevation":hover ? 12 : 2,"color":"deep-orange darken-2"},on:{"click":function($event){return _vm.$vuetify.goTo(sinpin.href)}}},[_c('span',{staticClass:"v-btn-text-color text-body-1"},[_vm._v(_vm._s(sinpin.txt))])])],1)]}}],null,true)})],1)}),1)],1),_c('h2',{staticClass:"midsi mt-7",staticStyle:{"display":"none"},attrs:{"id":"no2"}},[_vm._v("価格から選ぶ")]),_c('v-container',[_c('v-row',{staticClass:"oya",attrs:{"dense":""}},_vm._l((_vm.kakakuList),function(kakaku,index){return _c('v-col',{key:index,attrs:{"cols":"6","sm":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":kakaku.href}},[_c('v-btn',{staticClass:"text-transform including-line-breaks",attrs:{"block":"","height":"54","elevation":hover ? 12 : 2,"color":"deep-orange darken-2"},on:{"click":function($event){return _vm.$vuetify.goTo(kakaku.href)}}},[_c('span',{staticClass:"v-btn-text-color text-body-1"},[_vm._v(_vm._s(kakaku.txt))])])],1)]}}],null,true)})],1)}),1)],1),_c('h2',{staticClass:"midsi2 mt-7",staticStyle:{"display":"none"},attrs:{"id":"no3"}},[_vm._v("中古から選ぶ")]),_c('v-container',{staticStyle:{"display":"none"}},[_c('v-row',{staticClass:"oya",attrs:{"dense":""}},_vm._l((_vm.tyukoList),function(tyuko,index){return _c('v-col',{key:index,attrs:{"cols":"6","sm":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":tyuko.href}},[_c('v-btn',{staticClass:"text-transform including-line-breaks",attrs:{"block":"","height":"54","elevation":hover ? 12 : 2,"color":"green darken-2"},on:{"click":function($event){return _vm.$vuetify.goTo(tyuko.href)}}},[_c('span',{staticClass:"v-btn-text-color text-body-1"},[_vm._v(_vm._s(tyuko.txt))])])],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"ma-0 mb-5"},[_c('subText',{attrs:{"textItem":'10万円以上 商品一覧',"id":"saleitm"}}),_c('product-list-by-special-id',{staticClass:"mb-5",attrs:{"specialId":5736}})],1)],1):_vm._e(),_c('v-container',{staticClass:"mb-7 pa-0"},[_c('v-row',{staticClass:"oya",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"/ec/special/sale/hatsuuri"}},[_c('v-btn',{staticClass:"text-transform including-line-breaks",attrs:{"block":"","height":"50","elevation":hover ? 12 : 2,"color":"grey darken-4"}},[_c('span',{staticClass:"v-btn-text-color text-body-1"},[_vm._v("初売りセールTOPへ戻る "),_c('v-icon',[_vm._v("fa fa-undo")])],1)])],1)]}}],null,false,2945823347)})],1)],1)],1),(_vm.isShowRelease)?_c('div',[_c('p',[_vm._v("初売りセール終了しました")])]):_vm._e(),_c('facebookAndTwitter')],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }