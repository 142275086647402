import { reactive } from '@vue/composition-api';
import { NewerCategory, SellCategory, UsedCategory } from '@/types/tsv-config';
import ProductListSysConfigsService from '@/logic/tsv/product-list-sys-configs.service';
import WebStorage from '@/logic/web-storage';

export default function categoryStore() {
  const NEWER_KEY = 'NewerCategory';
  const USED_KEY = 'UsedCategory';
  const SELL_KEY = 'SellCategory';

  const state = reactive({
    newerCategory: [] as Array<NewerCategory>,
    usedCategory: [] as Array<UsedCategory>,
    sellCategory: [] as Array<SellCategory>
  });

  return {
    get newerCategory(): Array<NewerCategory> {
      if (!state.newerCategory || !state.newerCategory.length) state.newerCategory = WebStorage.getSessionStorage(NEWER_KEY);
      return state.newerCategory ? state.newerCategory : [];
    },

    get newerCategoryOnlyParent(): Array<NewerCategory> {
      const parentCategory = state.newerCategory ? state.newerCategory.filter((cate) => cate.digit === '4') : [];
      return parentCategory.length ? parentCategory : [];
    },

    get usedCategory(): Array<UsedCategory> {
      if (!state.usedCategory || !state.usedCategory.length) state.usedCategory = WebStorage.getSessionStorage(USED_KEY);
      return state.usedCategory ? state.usedCategory : [];
    },

    get usedCategoryOnlyParent(): Array<UsedCategory> {
      const parentCategory = state.usedCategory ? state.usedCategory.filter((cate) => !cate.categoryName.includes(':')) : [];
      return parentCategory.length ? parentCategory : [];
    },

    get sellCategory(): Array<SellCategory> {
      if (!state.sellCategory || !state.sellCategory.length) state.sellCategory = WebStorage.getSessionStorage(SELL_KEY);
      return state.sellCategory ? state.sellCategory : [];
    },

    async fetchNewerCategories() {
      try {
        state.newerCategory = await ProductListSysConfigsService.fetchNewerCategories();
        WebStorage.setSessionStorage(NEWER_KEY, state.newerCategory);
      } catch (error) {
        console.error(error);
        state.newerCategory = [];
      }
    },

    async fetchUsedCategories() {
      try {
        state.usedCategory = await ProductListSysConfigsService.fetchUsedCategories();
        WebStorage.setSessionStorage(USED_KEY, state.usedCategory);
      } catch (error) {
        console.error(error);
        state.usedCategory = [];
      }
    },

    async fetchSellCategories() {
      try {
        state.sellCategory = await ProductListSysConfigsService.fetchSellCategories();
        WebStorage.setSessionStorage(SELL_KEY, state.sellCategory);
      } catch (error) {
        console.error(error);
        state.sellCategory = [];
      }
    },

    searchNewerCategoryByCode(code: string): NewerCategory | {} {
      return state.newerCategory.filter((item: NewerCategory) => item.code === code)[0]
        ? state.newerCategory.filter((item: NewerCategory) => item.code === code)[0]
        : {};
    },

    searchNewerCategoryByConcatenationName(name: string): NewerCategory | {} {
      return state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0]
        ? state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0]
        : {};
    },

    searchParentCategoryByCode(code: string): NewerCategory | {} {
      const parentCode = code.substr(0, 4);
      return this.searchNewerCategoryByCode(parentCode);
    },

    searchChildrenCategoryByCode(code: string): Array<NewerCategory> {
      let children = state.newerCategory ? state.newerCategory.filter((cate: NewerCategory) => cate.code.includes(code)) : [];
      // 自身は除外
      children.splice(0, 1);
      // 親カテゴリの指定の場合は、孫カテゴリを除外
      if (code.length === 4) {
        children = children.filter((c) => c.digit === '7');
      }
      return children ? children : [];
    },

    searchHighCategoryByConcatenationName(name: string): NewerCategory | {} {
      let highCategory = {} as NewerCategory | {};
      const category = state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0];

      switch (category.digit) {
        case '4':
          break;
        case '7': {
          const parentCode = category.code.substring(0, 4);
          highCategory = this.searchNewerCategoryByCode(parentCode);
          break;
        }
        case '10': {
          const childCode = category.code.substring(0, 7);
          highCategory = this.searchNewerCategoryByCode(childCode);
          break;
        }
        default:
          break;
      }

      return highCategory;
    },

    searchRelatedCategoryByConcatenationName(name: string, includeHighCategory: boolean): Array<NewerCategory> {
      let relatedCategory = [] as Array<NewerCategory>;
      const category = state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0];

      switch (category.digit) {
        case '4':
          relatedCategory = includeHighCategory
            ? this.newerCategoryOnlyParent
            : this.newerCategoryOnlyParent.filter((cate: NewerCategory) => cate.code !== category.code);
          break;
        case '7': {
          const parentCode = category.code.substring(0, 4);
          relatedCategory = includeHighCategory
            ? state.newerCategory.filter((cate: NewerCategory) => cate.code.includes(parentCode) && cate.digit === '7')
            : state.newerCategory.filter((cate: NewerCategory) => cate.code.includes(parentCode) && cate.code !== category.code && cate.digit === '7');
          break;
        }
        case '10': {
          const childCode = category.code.substring(0, 7);
          relatedCategory = includeHighCategory
            ? state.newerCategory.filter((cate: NewerCategory) => cate.code.includes(childCode) && cate.digit === '10')
            : state.newerCategory.filter((cate: NewerCategory) => cate.code.includes(childCode) && cate.code !== category.code && cate.digit === '10');
          break;
        }
        default:
          break;
      }

      return relatedCategory;
    },

    searchUsedCategoryByCode(code: string): UsedCategory | {} {
      return state.usedCategory.filter((item: UsedCategory) => item.newerCode === code)[0]
        ? state.usedCategory.filter((item: UsedCategory) => item.newerCode === code)[0]
        : {};
    },

    searchUsedCategoryByName(name: string): UsedCategory | {} {
      return state.usedCategory.filter((item: UsedCategory) => item.categoryName === name)[0]
        ? state.usedCategory.filter((item: UsedCategory) => item.categoryName === name)[0]
        : {};
    }
  };
}

export type CategoryStore = ReturnType<typeof categoryStore>;
