








































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch } from '@vue/composition-api';
import MyPageLayout from '../common/my-page-layout.vue';
import BackButton from '@/components/common/back-button.vue';
import OrderInfo from '@/components/my-page/order-detail/order-info.vue';
import OrderStep from '@/components/my-page/order-detail/order-step.vue';
import OrderProduct from '@/components/my-page/order-detail/order-product.vue';
import OrderTotalInfo from '@/components/my-page/order-detail/order-total-info.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { ORDER_DETAIL_TYPES } from '@/constants//mypage-order-detail-types';
import UserService from '@/logic/user.service';
import { OrderTokutokuDetail } from '@/types/order-tokutoku-detail';
import GaService from '@/logic/ga.service';

export default Vue.extend({
  name: 'order-tokutoku-detail',
  components: {
    'my-page-layout': MyPageLayout,
    'back-button': BackButton,
    'order-info': OrderInfo,
    'order-step': OrderStep,
    'order-product': OrderProduct,
    'order-total-info': OrderTotalInfo,
    'section-loading': SectionLoading
  },
  setup: (props, context) => {
    const { authorizer } = context.root.$store;
    const state = reactive({
      // 画面タイトル
      title: '下取申込み詳細',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: '買取・下取履歴', linkUrl: '/ec/mypage/odr/list?type=sell' },
        { path: 'トクトク交換詳細', linkUrl: '' }
      ],
      type: ORDER_DETAIL_TYPES.TOKUTOKU_NO_NEED_KIT,
      // トクトク交換番号
      orderId: '',
      // トクトク交換詳細
      orderDetail: {} as OrderTokutokuDetail,
      // 梱包キット申込みURL
      packingKitLink: process.env.VUE_APP_NET_CHUKO_URL + 'sell/direct/index.do',
      // ロード状態
      loaded: {
        detail: false
      },
      // 購入履歴URL
      linkUrl: ''
    });

    /**
     * GAクロスドメイン用にリンカーパラメータを設定する
     */
    const setLinkerParam = async () => {
      state.packingKitLink = await GaService.addLinkerParam(state.packingKitLink);
    };

    /**
     * 下取申込み詳細を取得
     * @param id 注文ID
     */
    const fetchOrderDetail = async (id: string) => {
      state.loaded.detail = false;
      try {
        const result = await UserService.fetchTokutokuOrderDetail(id);
        state.orderDetail = result;

        // 梱包キット申込希望あり/なしでタイプを切り替える
        state.type = state.orderDetail.isNeedPackKit ? ORDER_DETAIL_TYPES.TOKUTOKU_NEED_KIT : ORDER_DETAIL_TYPES.TOKUTOKU_NO_NEED_KIT;
      } catch (error) {
        console.error(error);
        state.orderDetail = {} as OrderTokutokuDetail;
      } finally {
        state.loaded.detail = true;
      }
    };

    onMounted(() => {
      const params = context.root.$route.params;
      // orderIdが無い場合は、NotFoundPageに遷移させる
      if (params.orderId) {
        state.orderId = `${params.orderId}`;
        if (authorizer.isLoggedIn) fetchOrderDetail(state.orderId);
        state.linkUrl = '/ec/mypage/odr/' + state.orderId;
        // リンカーパラメータを設定する
        setLinkerParam();
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    });

    // ログインがされたらAPIで取得する
    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchOrderDetail(state.orderId);
      }
    );

    /**
     * 梱包キットを申し込む押下処理
     */
    const offerPackagingKit = async () => {
      // TODO JSESSIONIDが切り替わってしまう問題が解決されるまでコメントアウトする
      // await UserService.offerPackagingKit();
      const boxFlgParam = state.orderDetail.isNeedPackKit === false ? '&boxFlg=1' : '';
      window.location.href = process.env.VUE_APP_NET_CHUKO_URL + `sell/direct/init.do?nsOrderNo=${state.orderDetail.formatedOdrNum}&serviceId=40${boxFlgParam}`;
    };

    return {
      ...toRefs(state),
      offerPackagingKit,
      state
    };
  }
});
