






















































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import ExampleImages from '@/components/common/special/example-image.vue';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Characteristic from '@/components/common/special/characteristic.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Notfound from '@/components/common/not-found.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'zv_e1',
  components: {
    breadcrumbs: Breadcrumbs,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    characteristic: Characteristic,
    shasha: ShaSha,
    splitContent: SplitContent,
    watchInVideo: WatchInVideo,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー VLOGCAM ZV-E1 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー VLOGCAM ZV-E1 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- ページ公開期間 ----
      fanValidFrom: '2023/03/30 10:00',
      fanValidTo: '2023/04/04 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/04/04 10:00',
      reserveValidTo: '2023/04/20 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/04/21 00:00',
      releaseValidTo: '2999/10/20 09:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      naviList: [
        { naviItem: '価格', href: '#spec' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー VLOGCAM ZV-E1',
          disabled: true
        }
      ],
      // ↓ ---- 高値下取り ----
      reserveExpensiveTradeInList: {
        name: 'ソニー VLOGCAM ZV-E10',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%BD%E3%83%8B%E3%83%BC%20VLOGCAM%20ZV-E10',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/d57/07a/e34/ee0/a5f/fdf/843/bce/7b6/606/48h/nmp/y9h/j/L.jpg',
        txt: 'ソニー  VLOGCAM ZV-E1',
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/zv_e1/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー VLOGCAM ZV-E10', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%BD%E3%83%8B%E3%83%BC%20VLOGCAM%20ZV-E10' },
          { name: 'ソニー VLOGCAM ZV-1', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736118584' },
          {
            name: 'ソニー α7C',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%BD%E3%83%8B%E3%83%BC%20%CE%B17C&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&sort=keyword&limit=40'
          }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '主役を引き立てる映像表現',
          contents: [
            '裏面照射型で有効約1210万画素の35mmフルサイズCMOSセンサーを搭載し、映像美溢れる表現を実現します。CMOSセンサーの高速読み出しと新画像処理エンジンにより、動体歪（ゆが）み（ローリングシャッター）も大幅に抑制',
            '画像処理エンジンBIONZ XRの圧倒的な処理能力により、高感度性能と高い階調表現、忠実な色再現性能や低ノイズ性能などの高画質化を実現',
            'すべての感度領域においてノイズを低減させ、15+ストップの幅広いラチチュードを実現。常用ISO感度は80 - 102400（静止画、動画共通）（静止画拡張時40 - 409600、動画拡張時80 - 409600相当）と、暗い環境でもノイズを抑えたクリアな映像が撮影可能',
            'フルサイズ領域で、画素加算のない全画素読み出しによる4K（QFHD）の本体内記録に対応。全画素情報を間引きや画素加算なく読み出して使用するため、モアレやジャギーを抑えた、より解像感の高い4K映像を実現'
          ]
        },
        {
          subtitle: 'こだわりの表現・色味を追求できる機能',
          contents: [
            '複雑なカメラ操作をすることなく、映画のワンシーンのような印象的な画（え）づくりを直感的に一括設定できる新機能「シネマティックVlog設定」を搭載。誰でもかんたんにシネマのような映像表現が可能',
            '肌の色を美しく見せるスキントーンを中心としたルック「S-Cinetone」を搭載。シネマのようなルックを手軽に再現でき、制作者のさらなるクリエイティブな表現要求に対応',
            '撮影する映像を思い通りの雰囲気に仕上げることができる「クリエイティブルック」を全10種類搭載。また、自分好みの設定をカスタムルックとして登録することも可能',
            '人の動きや街並みを実際より短い時間に短縮して、独特な映像表現を記録する「タイムラプス」がカメラ本体内で生成可能'
          ]
        },
        {
          subtitle: 'AIや最新技術による高品質なフォーカシング表現',
          contents: [
            '最新のAIプロセッシングユニットを搭載。従来認識が難しかった画面内で人物のサイズが小さいシーンでも確実に狙った人物被写体を捕捉。また、後ろ向きの状態や、マスクなどで顔が見えないシーンなどでも認識し続けます',
            '人物・動物に加え、新たな認識被写体として、鳥・昆虫・車/列車・飛行機に対応。また、動物に対する認識性能は約40%以上アップ。犬や猫のような動物の、頭や顔の認識が加わっただけでなく、一部の草食動物・小動物の瞳の認識も可能',
            '高速・高精度な像面位相差AFが被写体をすばやく捉え、粘り強く追随。小型・軽量ならではの機動性とファストハイブリッドAFの組み合わせにより、動きの速い被写体に高精度かつなめらかにフォーカスを合わせ続けます',
            '動画撮影中のフォーカス時に起こるフォーカスブリージング（フォーカスレンズ位置の変化に伴う画角変動）現象を最小化するブリージング補正機能に対応',
            'おすすめの商品を紹介するとき、カメラに向けた商品にピントが合わなくて困る場面では、「商品レビュー用設定ボタン」をひと押しすれば、顔から商品へ、商品から顔へのスムーズなピント合わせが可能',
            '複数人での自撮りや集合写真撮影時に、手前の人にだけフォーカスが合ってしまい、奥の人の顔がぼけてしまうような失敗を防ぐよう、顔認識した複数人にピントが合うように自動的にカメラがぼけ具合を調整'
          ]
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736144224', '4548736144262', '4548736144569', '4548736144705'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品 4548736140264 かり
      mainProductJanCode: ['4548736144224', '4548736144262', '4548736144569', '4548736144705'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736144224', '4548736133730', '4549980649909', '4549292200508'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
