





























































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'om1m2',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！OM SYSTEM OM-1 MARK II| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'OM SYSTEM OM-1 MARK II価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/1/30 14:59',
      fanValidTo: '2024/1/30 14:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/1/30 15:00',
      reserveValidTo: '2024/2/22 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/2/23 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'OM SYSTEM OM-1 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350053864',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/3f2/06b/188/37b/9b3/d89/4b5/9a7/7e7/171/den/mzs/uf6/0/L.jpg',
        txt: 'OM SYSTEM OM-1 MARK II',
        day: '2024年2月29日(木)',
        tradeInImg: '/ec/images2/special/camera/feature/omsystem/om1m2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'OM SYSTEM OM-1 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350053864'
          },
          {
            name: 'オリンパス OM-D E-M1 MarkIII ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350053000'
          },
          {
            name: 'オリンパス OM-D E-M1 MarkII ボディ ブラック',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350050849'
          },
          {
            name: 'オリンパス OM-D E-M1 MarkII ボディ シルバー',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350052706'
          },
          {
            name: 'オリンパス OM-D E-M1X ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350052621'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '裏面照射積層型Live MOSセンサーと画像処理エンジン「TruePic X」が生む高画質',
          contents: [
            '「有効画素数2037万画素裏面照射積層型Live MOS センサー」と、最新の画像処理エンジン「TruePic X」（トゥルーピック エックス）を搭載、定評ある高解像レンズ「M.ZUIKO DIGITAL」との組み合わせにより、センサーサイズの常識を覆すOM SYSTEMカメラ史上最高の高画質が得られます',
            'ミックス光などの難しい条件でもクリアな色再現が得られるようAWBのアルゴリズムを改善、さまざまな光源下で適切な色調の作品が得られます',
            '強力なボディー内5軸手ぶれ補正を備え、補正効果はボディー単体で世界最高の最大8.5段に向上「5軸シンクロ手ぶれ補正」でも最大8.5段。フットワークを活かしながらの手持ち撮影領域がさらに広がります'
          ]
        },
        {
          subtitle: 'ハーフNDフィルターの効果をGND段数、タイプを確認しながら操れる世界初「ライブGND」',
          contents: [
            '明暗部をコントロールして、印象的な作品に変えるハーフNDフィルターの撮影効果をコンピュテーショナル フォトグラフィにより再現した、世界初の「ライブGND（グラデーションND）」機能を搭載しました',
            'フィルター段数（G ND 2、G ND4、G ND 88）フィルタータイプ（Soft、Medium、HardHard）を、EVF もしくは背面モニターを見ながらリアルタイムに確認。効果がかかる位置や角度の調整も行え、意図に合った作画が行えます',
            'レンズへのフィルター装着が不要なため、超広角系などフィルターネジの切られていないレンズ、手元にフィルターがない場合でも、ハーフNDフィルターの効果を作画に活かせ、風景撮影などで表現の幅を大きく広げます',
            '好評の「ライブND」では段数の上限がND64 相当からND128 相当に拡大され、より大きな段数が必要なシーンにも対応可能となりました',
            '複数枚の画像を合成、ISO感度約2段分のノイズを低減しながらセンサーサイズを超えた高解像画像を生成する約5000万画素の「手持ちハイレゾショット」。同じく約8000万画素の「三脚ハイレゾショット」を備え、それぞれハイレゾショット時のRAWは14ビットでの記録が可能になります',
            'さらに明るく変化した部分のみを比較明合成する「ライブコンポジット」、被写界深度をコントロールできる「深度合成」といった便利なコンピュテーショナル フォトグラフィ機能を備え、さまざまな撮影シーンに対応します'
          ]
        },
        {
          subtitle: '「AI 被写体認識AF」がさらに進化。新たに人物認識に対応',
          contents: [
            'ディープラーニング技術を活用して開発した「AI 被写体認識AF」フォーミュラーカー・バイク、飛行機・ヘリコプター、鉄道、鳥、動物（犬・猫）に加え、新たに人物の認識も可能になりました',
            '定評ある顔優先／瞳優先AFを「AI 被写体認識AF」として統合・発展させたもので、人物が横向きや後ろ向き、目や口が隠れていてもオートフォーカスが働くよう認識能力が向上しています',
            '「AI 被写体認識AF」では、画像処理エンジン「TruePic X」の高速演算により、リアルタイムに被写体の移動を検出、画面内で移動する被写体を逃さず追尾、人の操作では追いきれないドライバーのヘルメット、鳥・動物の目を追い続けることができます',
            '人物、動物などでは最大8体まで検出。どのターゲットを追うかの選択も可能になり、作画上の自由度が増しています'
          ]
        },
        {
          subtitle: 'メモリー増設により連続撮影可能枚数の大幅アップ。高速撮影能力が向上',
          contents: [
            '1st レリーズで記録を開始。2nd レリーズの瞬間からさかのぼって記録することで、人の反応タイムラグが原因で撮り逃がした瞬間も確実にとらえるプロキャプチャーも搭載。AF/AE 固定で最高約120コマ/秒、AF/AE 追従でも約50コマ/秒の高速連写が行え、素早い動きの瞬間を確実にとらえられます',
            '「OM-1 Mark II」では搭載バッファメモリーを増設。プロキャプチャー時にさかのぼって撮影可能な枚数は約99枚（OM-1は70枚）に増えています。同様に連写撮影、約120コマ/秒時の連写枚数もJPEGで約219枚、RAWで約213枚（OM-1はそれぞれ約92 枚）にアップ。高速撮影能力が大幅に向上しています'
          ]
        },
        {
          subtitle: 'ハードな撮影条件に対応する高い防塵・防滴・耐低温設計。信頼のダストリダクションシステムも搭載',
          contents: [
            '堅牢で軽量なマグネシウム合金ボディーの適所にシーリング部材を効果的に配置することで、高い防塵・防滴設計（防塵・防水保護等級IP53）に対応。低温下においても確実に動作する耐低温－10℃も合わせて実現、過酷な環境下での撮影を可能としています',
            'ファインダーは576万ドット有機EL（OLED）、高い解像度と見やすいファインダー倍率 0.83倍、表示遅れ0.005秒。120コマ秒の高速表示で光学ファインダーのクリアさに迫りつつ、暗い場面でも被写体が判別可能。シャッターを切る前に撮影イメージが得られるといった電子ビューファインダーならではの利点を併せ持ちます。加えて雨中等の撮影でも曇りにくく、クリアな視界が得られる防曇処理も施されています',
            'レンズ交換時のゴミの付着、内部の機構部品から発生するイメージセンサー面へのゴミの付着を排除、ゴミの写り込みを激減させる定評あるダストリダクションシステムも搭載。カメラシステムの高い信頼性を支えます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'OM SYSTEM OM-1 MARK II',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350056056', '4545350056063', '4545350056599'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', '', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4545350056056', '4545350053864', '4549292185430', '4549980720875'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      //popup
      imgs: [
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_01.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_02.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_03.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_04.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_05.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_06.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_07.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/omsystem/om1m2/pic_08.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
