




























































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'strap',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = 'おしゃれなカメラストラップ特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'デジタル一眼レフやミラーレス用のおしゃれなカメラストラップ特集！カメラ女子にも人気のかわいいタイプや機能的なストラップまで、ぜひご覧ください。'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おしゃれなカメラストラップ特集',
          linkUrl: '/ec/special/camera/strap',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      // ↓ ---- ナビボタン ----
      naviList: [
        { naviItem: '人気デザイン', href: '#i01' },
        { naviItem: '機能的', href: '#i02' },
        { naviItem: 'その他', href: '#i03' },
        { naviItem: '一眼レフ\nミラーレス用', href: '#i04' },
        { naviItem: 'コンパクト\nデジカメ用', href: '#i05' },
        { naviItem: 'おしゃれな\nブランド', href: '#i06' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/bag',
          img: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          alt: '収納力で選べる！カメラバッグ特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/girlscamera',
          img: 'https://shopimg.kitamura.jp/images/banner/1390.jpg',
          alt: '女子がきゅんとくる ミラーレス一眼カメラ'
        },
        {
          href: '/ec/special/general/pet',
          img: 'https://shopimg.kitamura.jp/images/banner/1878.jpg',
          alt: 'ペット写真のステキな残し方'
        },
        {
          href: '/ec/special/camera/frame/shacolla',
          img: 'https://shopimg.kitamura.jp/images/banner/3099.gif',
          alt: 'フジフイルム シャコラで写真を壁に飾ろう！'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: 'カメラのキタムラネットプリント',
          target: 'blank'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759028921'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
