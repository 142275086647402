



































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import SubText from '@/components/common/special/subtext.vue';
//import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import SplitContent from '@/components/common/special/split-content.vue';
import Notfound from '@/components/common/not-found.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'dcg9m2',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    shasha: ShaSha,
    //priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    'product-list-by-special-id': ProductListBySpecialId,
    notfound: Notfound,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'パナソニック LUMIX G9PROII | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'パナソニック LUMIX G9PROII   価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isShow: false,
      // ↓ ---- 発表告知 ----
      fanValidFrom: '2023/9/13 9:59',
      fanValidTo: '2023/9/15 9:59',
      // ↓ ---- 予約開始 ----
      reserveValidFrom: '2023/9/15 10:00',
      reserveValidTo: '2023/10/26 23:59',
      // ↓ ---- 発売開始 ----
      releaseValidFrom: '2023/10/27 00:00',
      releaseValidTo: '2099/5/15 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: true
        },
        {
          path: 'パナソニック LUMIX G9PROII',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: 'お得情報', href: '#otoku' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'パナソニック LUMIX DC-G9-K ボディ ブラック',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980016695',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/44f/f1d/1a4/5f2/744/ddc/39e/8fb/84e/404/5bf/sf2/ino/5/L.jpg',
        txt: 'LUMIX DC-G9M2',
        day: '2023年10月31日',
        tradeInImg: '/ec/images2/special/camera/feature/panasonic/dcg9m2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'パナソニック LUMIX DC-G9-K ボディ ブラック', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980016695' },
          { name: 'パナソニック LUMIX DC-G99D-K ボディ ブラック', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980665602' },
          { name: 'パナソニック LUMIX DC-GX7MK3', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=DC-GX7MK3-%20%E3%83%9C%E3%83%87%E3%82%A3' }
        ]
      },
      characteristicList: [
        {
          subtitle: '優れた画質・描写性能',
          contents: [
            '新開発の有効画素数2521万画素 Live MOSセンサーは、位相差センサーによる高精度な像面位相差AFを実現しながら、広いダイナミックレンジと高速レスポンスの両立を可能にします',
            '高い解像性能を引き出す光学ローパスフィルターレス設計で、被写体の細部まで精緻に描写し、高画素化しながらも飽和性能が向上、なめらかで階調豊かな描写性能を実現します',
            '読み出し速度の高速化により、連写速度の高速化を可能にし、電子シャッターにおいて動く被写体を高速連写撮影する場合でもローリングシャッター歪み現象を抑制しながら、高い解像感で写し撮ることができます',
            '動画撮影においても5.7K 60pなど高解像な動画記録に対応。高速読み出し性能を活かして、Cinema4Kや4Kでの120pハイフレームレート撮影も可能です',
            'ライカとパナソニックが共同開発したL2  Technology（エルスクエア・テクノロジー）搭載の最新世代ヴィーナスエンジン。高画素センサーから出力される画像情報を高速処理しながら、高精細かつ自然な質感描写を実現します',
            'イメージセンサーの「低ISO回路」から生成する飽和優先の画像と、「高ISO回路」から生成する低ノイズ優先の画像の2つを1画素ごとに光量に応じた合成比で合成することにより、低ノイズと高飽和の特性を持った階調豊かでなめらかなHDR撮影を実現するダイナミックレンジブーストを搭載しました'
          ]
        },
        {
          subtitle: '圧倒的な静止画の表現力',
          contents: [
            '撮影前に、好みの⾊味や画質（コントラスト・彩度など）に調整ができるフォトスタイルを搭載。[L.クラシックネオ]、[L.モノクロームS]、[フラット]などを⽤意しています。[モノクローム]や[L.モノクローム]、[L.モノクロームD]、 [L.モノクロームS]を選択時、⻩⾊・オレンジ・⾚・緑のフィルター効果を加えることも可能。特定の⾊をモノトーンで強調し、印象深い写真を撮影することができます',
            '従来のモノクロームやL.モノクローム、L.モノクロームD、L.モノクロームSに加えLEICAのモノクロームを踏襲した、深い黒と白のコントラストが特長の「LEICAモノクローム」を新搭載。LUMIXならではの豊富なモノクローム表現を楽しめます',
            'LUMIX独自のフォトスタイルに加え、カメラ本体でLUT（ルックアップテーブル）を適用して、自分好みの色表現を撮影データに反映できる「リアルタイムLUT」を搭載しました。これにより色設定の自由度が上がり、撮影後に色味の補正を行わなくても撮影データのまま編集へ移行できるので、ワークフローの効率化も図れます',
            'ボディ内手ブレ補正（B.I.S.）の機構を活かして、センサーをシフトさせながら8回連続で自動撮影を行い、カメラ内で自動合成処理を行うハイレゾモードを搭載しました。通常撮影時に比べて4倍の画素数にあたる画像サイズで、最大約1億画素相当の高解像写真を生成。細かなディテールまで美しく描写し、被写体の持つ精細感や臨場感を最大限に記録することができます。またRAW記録に加えJPEG記録が可能です'
          ]
        },
        {
          subtitle: '決定的瞬間を撮り逃さない高速性能と機動力',
          contents: [
            '新搭載の像面位相差AFは、像面位相差センサーにより、被写体までの距離を高速・高精度に算出し、779点の測距でダイナミックに動く被写体をとらえるだけでなく、自動認識AFとの組み合わせで、逆光や低照度撮影での被写体が判別しにくいシーンや、複数の人が交差するシーン、より小さな被写体においても、優れたAF追従性能を発揮します',
            'ディープラーニング技術を用いた認識AIを、新たに開発することで、従来からの「人物認識」「動物認識」においてもより小さな被写体を、高速かつ正確に認識できるようになりました。また従来の認識対象に加え「車認識」「バイク認識」「動物瞳認識」を新搭載。距離情報を組み合わせることで、静止画・動画撮影で高精度なフォーカス性能を実現します',
            '天候や撮影環境の変化、さまざまな被写体の撮影において、多彩なフォーカス機能が活躍。撮影シーンや被写体に合わせて最適なAF機能を選べるので、撮影に集中でき、こだわりの作品を残せます。自動認識AFは、さまざまなAFモードを組み合わせることで、自由度が高まり、撮影シーンに応じた最適な組み合わせの選択が可能です。さらに、縦・横・斜めの8方向操作に対応したジョイスティックで、スピーディなAF操作ができます',
            '連写撮影前から連写記録を行うことができる「SHプリ連写」を搭載。高速連写（SH75 PRE / SH60 PRE / SH20 PRE）に設定時、連写撮影を始める際に行われるシャッターボタン半押しの動作から、連写記録を事前に開始。シャッターボタン全押し（連写撮影開始）までの間をさかのぼって記録することができます。プリ記録時間は0.5秒/1.0秒/1.5秒から選択可能で、1.5秒設定でAFS/MF設定時は約113コマ、AFC設定時は約90コマのプリ連写撮影ができます'
          ]
        }
      ],

      mainProductJanCode: ['4549980720875', '4549980721025'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549980720875', '4549980016695', '4545350053864', '4549292185430'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode 4549980490693 4549980490709
      productJanCodeList: ['4549980490693', '4549980490709'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
      // ページ内スクロール
      setTimeout(() => {
        const hash = context.root.$route.hash;
        if (hash) context.root.$vuetify.goTo(hash);
      }, 100);
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
