










































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'rf100-400_rf16',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    priceAndPurchase: PriceAndPurchase,
    naviBtn: NaviBtn,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    characteristic: Characteristic,
    shasha: ShaSha,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'キヤノン RF100-400mm F5.6-8 IS USM/RF16mm F2.8 STM | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン RF100-400mm F5.6-8 IS USM/RF16mm F2.8 STM 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '価格・ご購入', href: '#kakaku' },
        { naviItem: '作例', href: '#images2' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '100mmから400mmのズーム全域で高画質と小型・軽量を両立',
          contents: [
            'RFマウントがもたらす自由度の高いレンズ設計により、高画質と小型・軽量を両立',
            '「UDレンズ」1枚、「非球面レンズ」1枚を含む9群12枚の光学設計により、望遠レンズに発生しやすい色収差をズーム全域で抑制',
            '望遠域を400mmとすることで、スポーツ撮影や動物の撮影など多様な撮影ニーズに対応'
          ]
        },
        {
          subtitle: '高速かつ滑らかなAFと快適な撮影をもたらす多彩な機能',
          contents: [
            '超音波モーター「ナノUSM」により静止画撮影時の高速・高精度AFを実現。動画時もなめらかなAFにより、快適な撮影が可能',
            '手持ち撮影をアシストする手ブレ補正機構により「EOS R5」装着時はボディー内の手ブレ補正機構と協調制御し、静止画撮影時6.0段の手ブレ補正を実現',
            'ボディー内手ブレ補正機構を搭載していない「EOS R」装着時でも、5.5段の手ブレ補正を実現',
            '最大撮影倍率0.41倍、最短撮影距離0.88mを実現。花などの被写体により接近した撮影も可能'
          ]
        }
      ],
      commonCharacteristicList2: [
        {
          subtitle: '「EOS Rシステム」初の焦点距離16mm超広角単焦点レンズ',
          contents: [
            '焦点距離16mmの超広角を生かし、複数人での自撮りや撮影スペースの限られた室内での撮影などに対応',
            '最短撮影距離0.13m、最大撮影倍率0.26倍を実現。被写体を大きく映しながら背景との遠近感も強調した迫力ある表現が可能',
            '開放F値2.8の大口径と最短撮影距離の短さにより、遠近感とボケ味を生かした写真表現が可能'
          ]
        },
        {
          subtitle: '約165gの小型・軽量でありながら高画質を実現',
          contents: [
            '撮像面近くに大口径レンズを配置する7群9枚の光学設計により、高画質と小型・軽量を両立',
            '開放F値2.8の明るさにより、室内・夜間といった暗いシーンでの撮影や動く被写体など速いシャッタースピードが必要な撮影にも対応',
            '気軽に持ち歩き、撮影を楽しめる最大径約69.2mm、長さ約40.2mm、質量約165gの小型・軽量設計'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01l',
          img: 'http://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン RF100-400mm F5.6-8 IS USM/RF16mm F2.8 STM',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292186765', '4549292186772'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292186765'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
