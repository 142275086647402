































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'tuyutaisaku',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '梅雨対策特集―梅雨の湿気対策におすすめのアイテムをご紹介― | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ジメジメする梅雨の時期を快適に過ごす為に、湿気やカビ対策におすすめのアイテムを厳選！カメラ・レンズをカビや湿気から守る防湿庫や防カビ剤などカメラ保管用品のほか、「部屋干しが続いて生乾きが気持ち悪い」「髪がまとまらない」といったお悩みにこたえる、除湿機・ふとん乾燥機などの家電製品もピックアップしました。'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '梅雨対策特集',
          disabled: true
        }
      ],
      rainySeasonMeasuresList: [
        {
          href:
            '/ec/ct/C010?category=%E9%98%B2%E6%B9%BF%E5%BA%AB&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          name: 'おすすめ防湿庫',
          txt: 'カメラ、レンズの湿気・カビ対策に！ おすすめの防湿庫やドライボックス、乾燥剤などをまとめました'
        },
        {
          href: '/ec/special/camera/storage/368',
          name: 'おすすめ雨の日 撮影用品',
          txt: '突然雨が降っても安心！ カメラをしっかりガードして撮影できる、おすすめの「雨よけアイテム」をピックアップ！'
        },
        {
          href: '/ec/list?narrow18=0&keyword=&category=空調家電%3A除湿機&n20c=完了商品は除く&sort=number20,Price,Score&path=空調家電%3A除湿機',
          name: 'おすすめ除湿機',
          txt: '除湿機で、お部屋も部屋干しの衣類もカラッと快適に。冬は結露防止にも役立つ除湿機は、年中使えるおすすめ家電です'
        },
        {
          href:
            '/ec/ct/E004/?category=%E7%94%9F%E6%B4%BB/%E5%AE%B6%E4%BA%8B%E5%AE%B6%E9%9B%BB:%E3%81%B5%E3%81%A8%E3%82%93%E4%B9%BE%E7%87%A5%E6%A9%9F%E3%83%BB%E8%A1%A3%E9%A1%9E%E4%B9%BE%E7%87%A5%E6%A9%9F&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          name: 'おすすめふとん乾燥機 衣類乾燥機',
          txt: 'なかなか布団や衣類を外に干せない梅雨時期には「ふとん乾燥機・衣類乾燥機」が便利。カビ対策やダニ対策にも効果的！'
        },
        { src: '/ec/images2/special/general/tuyutaisaku/bk03.png' },
        {
          href:
            '/ec/ct/E005?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E9%A3%9F%E5%99%A8%E6%B4%97%E3%81%84%E6%A9%9F/%E9%A3%9F%E5%99%A8%E4%B9%BE%E7%87%A5%E6%A9%9F&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          name: 'おすすめ食器乾燥機',
          txt: '洗った食器や布巾の水分が雑菌を増殖させる原因に！ 高温でしっかり乾燥して食の安全対策を'
        },
        {
          href: '/ec/list?narrow18=0&keyword=&category=理美容家電%3Aドライヤー%2Fヘアケア&path=理美容家電%3Aドライヤー%2Fヘアケア&n20c=完了商品は除く',
          name: 'おすすめヘアケア',
          txt: '湿気で髪がうねるのは、痛んで乾燥しているから。毎日のヘアケアで、梅雨でも広がらない、まとまりのある髪へ！'
        },
        { src: '/ec/images2/special/general/tuyutaisaku/bk04.png' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/waterproof',
          img: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          alt: '防水カメラ・ウェアラブルカメラ特集'
        },
        {
          href: '/ec/special/kaden/fan-fair',
          img: 'https://shop.kitamura.jp/images/banner/6597.jpg',
          alt: 'おすすめ扇風機特集'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage
    };
  }
});
