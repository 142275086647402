































































































import Vue from 'vue';
import { computed, onMounted, reactive, toRefs, watch } from '@vue/composition-api';
import MoreItemsButton from '@/components/common/more-items-button.vue';
import { BUY_ORDER_TYPES } from '@/constants/mypage-order-types';
import { BUY_ORDER_SEASONS } from '@/constants/mypage-order-seasons';
import { DELIVERY_ORDER_STATUS } from '@/constants/order';
import { noimage, sortByRowNo } from '@/logic/utils';
import UserService from '@/logic/user.service';
import { OrderInfo } from '@/types/order-list';
import SectionLoading from '@/components/common/section-loading.vue';
import dayjs from 'dayjs';

type OrderInfoScreen = OrderInfo & { deliveryOdrStatusName: string };

export default Vue.extend({
  name: 'order-buy-list',
  components: {
    'more-items-button': MoreItemsButton,
    'section-loading': SectionLoading
  },
  setup: (_, context) => {
    const { authorizer } = context.root.$store;

    // 過去10年分の注文時期選択肢を作成
    const thisYear = dayjs().year();
    const orderSeasonYears = [...Array(10).keys()].map((i) => thisYear - i);

    const state = reactive({
      // 種類
      orderTypes: BUY_ORDER_TYPES,
      selectedOrderType: BUY_ORDER_TYPES[0].value,
      // 注文時期
      orderSeasons: [...BUY_ORDER_SEASONS, ...orderSeasonYears],
      selectedOrderSeason: BUY_ORDER_SEASONS[0].value,
      // 履歴一覧
      orderList: [] as Array<OrderInfoScreen>,
      // 表示注文数
      showOrderLength: 10,
      maxOrderLength: 0,
      page: 1,
      loaded: {
        orderList: false
      }
    });

    // 注文状況を取得する
    const getDeliveryOrderStatus = (statusId: string): string => {
      return DELIVERY_ORDER_STATUS[statusId] || '-';
    };

    /**
     * 注文履歴を取得
     */
    const fetchOrderList = () => {
      state.loaded.orderList = false;

      const year = state.selectedOrderSeason !== 0 ? state.selectedOrderSeason : undefined;
      const goodsKind = state.selectedOrderType !== 0 ? state.selectedOrderType : undefined;
      UserService.fetchOrderList(state.page, year, goodsKind)
        .then((response) => {
          const odrInfoList = response.odrInfo;
          let orderList: Array<OrderInfoScreen> = odrInfoList.map((order) => ({
            ...order,
            deliveryOdrStatusName: getDeliveryOrderStatus(order.deliveryOdrStatusId)
          }));

          // 「rowNo」を昇順にソートする
          // ページ毎に「rowNo」が新規に割り振られているため、レスポンス内容の中でソートする
          orderList = sortByRowNo<OrderInfoScreen>(orderList);

          // 2ページ目以降は配列に追加していく
          if (1 < state.page) {
            state.orderList.push(...orderList);
          } else {
            state.orderList = orderList;
          }
          state.maxOrderLength = response.totalPageCount;
        })
        .catch((error) => console.error(error))
        .finally(() => (state.loaded.orderList = true));
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        fetchOrderList();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchOrderList();
      }
    );

    // 表示する注文履歴を取得する
    const getOrderList = computed((): Array<OrderInfoScreen> => state.orderList.slice(0, state.showOrderLength));

    // 表示条件が変更された場合、指定された条件で一覧を取得する
    const onChangeFilter = () => {
      state.page = 1;
      fetchOrderList();
    };

    // 次の一覧を取得する
    const fetchNextList = () => {
      state.page += 1;
      fetchOrderList();
      state.showOrderLength += 10;
    };

    // 表示する画像を取得する
    const getOrderImage = (order: OrderInfoScreen): string => {
      return order.imagePath || require('@/assets/no-image-M.gif');
    };

    return {
      ...toRefs(state),
      noimage,
      getOrderList,
      onChangeFilter,
      fetchNextList,
      getOrderImage
    };
  }
});
