
































































import Vue from 'vue';
import { reactive, toRefs, ref, watch } from '@vue/composition-api';
import NavDrawerSubMenu from './nav-drawer-sub-menu.vue';
import { APPLIANCES_CATEGORY_LIST, CAMERA_CATEGORY_LIST } from '@/constants/categories';
import AuthService from '@/logic/auth.service';

const CAMERA_CATEGORY_MIN_SIZE = 4;
const APPLIANCES_CATEGORY_MIN_SIZE = 3;

export default Vue.extend({
  name: 'nav-drawer-menu',
  components: {
    'nav-drawer-sub-menu': NavDrawerSubMenu
  },
  setup: (_, context) => {
    const state = reactive({
      selectSubMenu: '',
      buy: [
        {
          plus: true,
          items: [
            { text: '買いたい', type: 'title', linkType: 'internal', url: '/' },
            { text: 'カメラ', type: 'main' }
          ],
          itemsPlus: ref<{ [key: string]: string }[]>([])
        },
        {
          plus: true,
          items: [{ text: '家電', type: 'main' }],
          itemsPlus: ref<{ [key: string]: string }[]>([])
        }
      ],
      sell: [
        {
          plus: false,
          items: [
            {
              text: '売りたい',
              type: 'title',
              linkType: 'external',
              url: process.env.VUE_APP_NET_CHUKO_URL
            },
            {
              text: '無料査定',
              type: 'main',
              linkType: 'external',
              url: process.env.VUE_APP_NET_CHUKO_URL + '#quick-assessment-link'
            },
            {
              text: '店舗で買取り',
              type: 'main',
              linkType: 'external',
              url: 'https://www.kitamura.jp/map/'
            },
            {
              text: '宅配で買取り',
              type: 'main',
              linkType: 'external',
              url: process.env.VUE_APP_NET_CHUKO_URL + 'sell/direct/about.do#a06'
            },
            {
              text: '出張で買取り',
              type: 'main',
              linkType: 'external',
              url: process.env.VUE_APP_NET_CHUKO_URL + 'static/contents/page/purchase-syutyou.html'
            }
          ]
        }
      ],
      service: [
        {
          plus: false,
          items: [
            { text: 'サービスメニュー', type: 'title' },
            {
              text: '買取り・下取り',
              type: 'main',
              linkType: 'external',
              url: '/special/sale-fair/page/sell/'
            },
            {
              text: 'なんでも下取り',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_shitadori_index.html'
            },
            {
              text: 'トクトク交換',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/ec/special/general/tokutokukoukan'
            },
            {
              text: 'お支払い方法',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_shiharai_index.html'
            },
            {
              text: '5年間保証',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_hoshou_index.html'
            },
            {
              text: '修理のご案内',
              type: 'main',
              linkType: 'external',
              url: '/files/syuuri/index.html'
            },
            {
              text: '送料・手数料',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_souryou_index.html'
            },
            {
              text: '返品・交換',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_henpin_index.html'
            },
            {
              text: '商品お届け・納期',
              type: 'main',
              linkType: 'external',
              url: '/sitemap/riyou_otodoke_index.html'
            }
          ]
        }
      ],
      group: [
        {
          plus: false,
          items: [
            { text: 'グループサイト', type: 'title' },
            {
              text: 'プリントサービス',
              type: 'main',
              linkType: 'external',
              url: 'https://www.kitamura-print.com'
            },
            {
              text: 'フォトブック',
              type: 'main',
              linkType: 'external',
              url: 'https://photobook.kitamura.jp/'
            },
            {
              text: 'アップル製品修理サービス',
              type: 'main',
              linkType: 'external',
              url: 'https://www.kitamura.jp/service/apple/'
            },
            {
              text: 'スタジオマリオ',
              type: 'main',
              linkType: 'external',
              url: 'https://www.studio-mario.jp'
            },
            {
              text: '時計買取',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/watch/sell/'
            },
            {
              text: 'スマホ買取',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/smartphone/sell/'
            },
            {
              text: '店舗検索',
              type: 'main',
              linkType: 'external',
              url: 'https://sss.kitamura.jp'
            }
          ]
        }
      ],
      isLoggedIn: false,
      userName: 'ようこそ（ゲスト）',
      logoutUrl: ref<string>()
    });

    // ログアウトURL
    const currentUrl = location.origin + context.root.$route.fullPath;
    const urlParams = new URLSearchParams({
      s: process.env.VUE_APP_SITE_ID,
      u: currentUrl
    });
    const logoutUrl = process.env.VUE_APP_API_MEMBER_BASE_URL + 'sso/logout.html?' + urlParams.toString();
    state.logoutUrl = logoutUrl;

    // 買いたい＞カメラのサブ項目を設定
    const cameraSubItems = CAMERA_CATEGORY_LIST.map((category) => ({
      text: category.text,
      url: category.url,
      type: 'sub',
      linkType: category.linkType || 'internal'
    }));
    state.buy[0].items.push(...cameraSubItems.slice(0, CAMERA_CATEGORY_MIN_SIZE));
    state.buy[0].itemsPlus = cameraSubItems.slice(CAMERA_CATEGORY_MIN_SIZE);

    // 買いたい＞家電のサブ項目を設定
    const appliancesSubItems = APPLIANCES_CATEGORY_LIST.map((category) => ({
      text: category.text,
      url: category.url,
      type: 'sub',
      linkType: 'internal'
    }));
    state.buy[1].items.push(...appliancesSubItems.slice(0, APPLIANCES_CATEGORY_MIN_SIZE));
    state.buy[1].itemsPlus = appliancesSubItems.slice(APPLIANCES_CATEGORY_MIN_SIZE);

    /**
     * ログイン状態確認
     */
    const { authorizer } = context.root.$store;
    watch(
      () => [authorizer.isLoggedIn, authorizer.user],
      ([isLoggedIn, user]) => {
        state.isLoggedIn = isLoggedIn;
        if (user?.lastName && user?.firstName) {
          state.userName = user.lastName + ' ' + user.firstName;
        } else {
          state.userName = 'ようこそ（ゲスト）';
        }
      }
    );

    /**
     * ログインダイアログを表示する際に、すでにログイン状態であるか確認する
     * この実行は、リダイレクトが発生する
     */
    const checkLoginStatus = (): void => {
      AuthService.checkLoginStatus().then((isLoggined) => {
        if (!isLoggined) {
          authorizer.openLoginMenu();
        }
        context.emit('on-close-menu');
      });
    };

    return {
      ...toRefs(state),
      checkLoginStatus
    };
  }
});
