







































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'D780',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン D780 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン D780 価格・スペック・ニコン D750との比較・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'キタムラ特典', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'AF性能を強化し、幅広い撮影シーンに対応。狙った被写体をより高精度に捕捉',
          contents: [
            '強化された51点AFシステムによりファインダー撮影時のAF性能が大きく進化。被写体検出性能の向上で狙った被写体を高精度に捕捉',
            'RGBセンサーを用いたアドバンストシーン認識システムが進化。「3D-トラッキング」の動体追尾性能が向上',
            'ライブビュー撮影時、「瞳AF」に対応。像面位相差AF搭載の273点ハイブリッドAFシステム'
          ]
        },
        {
          subtitle: '多彩な映像表現をサポートする性能・機能',
          contents: [
            'ファインダー撮影時、ライブビュー撮影時ともに最高約7コマ/秒での快適な高速連続撮影が可能',
            '14ビットロスレス圧縮RAWでも「D750」の約4倍となる約68コマの連続撮影を実現',
            'シャッタースピードは1/8000秒～最長900秒までの幅広い露光時間の設定が可能。動きの速い被写体から天体撮影まで幅広く対応'
          ]
        },
        {
          subtitle: '高い信頼性と操作性。優れた高感度・低ノイズ性能でより幅広い撮影シーンで活躍',
          contents: [
            'カメラ上面と背面カバーにマグネシウム合金を採用したモノコック構造で、高い剛性と耐久性を達成しながらも軽量ボディーを実現',
            '低消費電力設計で1回の充電で約2260コマ（CIPA規格準拠）の撮影に対応し、長時間の撮影にも安心して使用可能',
            '常用ISO感度100～51200の広い常用感度域を実現。高感度でも解像感を保ちながらノイズを効果的に低減'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'ニコン D750 ボディ',
        href:
          'https://www.net-chuko.com/sell/item-list.do?kind=&maker=&goodsname=4960759143846&_ga=2.61767404.1712661879.1629071621-279164427.1627285160&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/009/b51/5b1/d9b/6cf/2b3/e79/7b4/a00/b9f/61y/sz7/qnw/n/L.jpg',
        txt: 'ニコン D780',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/D780/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン D780',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759904058'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759904058', '4549292157253', '4960759149336', '4960759901484'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759904058', '4960759126566', '4960759904348', '4960759904355', '4960759146335', '4960759146670'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
