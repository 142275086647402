










































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'pentax-k-1',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    naviBtn: NaviBtn,
    subText: SubText,
    characteristic: Characteristic,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'PENTAX K-1 ペンタックス フルサイズデジタル一眼レフカメラ 新製品 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ペンタックス デジタル一眼レフ新製品｢PENTAX K-1｣特集。優れた機動性と高い信頼性を備えた、ペンタックス初のフルサイズデジタル一眼レフカメラ。「K-3 II」と「キヤノン EOS 5D MarkIII」、「ニコン D810」との比較や価格・スペック・お得な情報をチェック！新製品ならカメラ専門店カメラのキタムラのショッピングサイトにお任せください'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/k-1/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/k-1/sp_img_top.jpg',
      appearanceImageList: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/k-1/img_a.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/k-1/img_b.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/k-1/img_c.jpg'
      ],
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '階調豊かな有効約3640万画素の高精細画像',
          contents: [
            '35.9mm×24.0mmの大型・高性能CMOSイメージセンサーを採用。超高精細な約3640万画素の有効画素数と独自の画像処理技術などにより、階調再現性や高感度性能にも優れた極めて高画質な画像を実現'
          ]
        },
        {
          subtitle: '5軸対応の新手ぶれ補正機構“SR II”',
          contents: [
            '新たに5軸方向に対応し、5段の手ぶれ補正効果やピクセル単位の動作制御で超高解像撮影が可能な新手ぶれ補正機構の搭載、人工知能技術を応用した的確な露出制御機能など、最新のテクノロジーを採用'
          ]
        },
        {
          subtitle: '撮影スタイルが広がるフレキシブルチルト式液晶モニター',
          contents: [
            '背面の画像モニターには、レンズの光軸上から位置をずらすことなくワンアクションで上下左右や斜めに向きを変えることができる新開発のフレキシブルチルト式液晶モニターを採用'
          ]
        }
      ],
      commonCharacteristicList2: [
        {
          subtitle:
            '35ミリ判フィルムと同等サイズの大型CMOSイメージセンサーを搭載し、写真愛好家の作品づくりやプロフェッショナル用途にも対応するKシリーズ最高級デジタル一眼レフカメラ',
          contents: [
            '35ミリフルサイズの新型CMOSイメージセンサーを採用。階調豊かな有効約3640万画素の高精細画像を実現',
            '全てのペンタックスレンズで利用できる本体内5軸対応手ぶれ補正機構“SR II（Shake Reduction II）”を搭載',
            'ワンアクションで上下左右や斜めに向きを変えることができる新開発のフレキシブルチルト式液晶モニターを採用',
            '新開発の33点測距、フルサイズ対応のAFエリアを実現した“SAFOX 12”を搭載',
            '人工知能技術の応用でさらに進化した“ペンタックス リアルタイムシーン解析システム”搭載',
            '暗所でのレンズ交換や各種設定に便利な“操作部アシストライト”機能や小型設計ながら約100%の視野率を実現した光学ファインダー搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/dslr',
          img: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          alt: 'ミラーレス一眼特集'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/sitemap/s_credit_01.html',
          img: 'http://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/feature/',
          disabled: false
        },
        {
          path: 'PENTAX K-1 ペンタックス フルサイズ一眼レフカメラ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549212302336', '4549212302565', '4549212303043'],
      mainProductList: [] as Array<ProductDetail>,
      productJanCodeList: ['4549212295003', '4549212294594', '4549212296468', '4549212214042', '4549212294686', '4549212294648'],
      productDetailList: [] as Array<ProductDetail>,
      comparisonJanCodeList: ['4549212302336', '4549212299896', '4960759146441', '4549292138313'],
      comparisonDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;

        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 比較
        state.comparisonDetailList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
