





























































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'xt4',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '富士フイルム X-T4 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '富士フイルム X-T4 好評発売中！比較や、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'ボディ内手ブレ補正と高速AF・高速連写で快適な撮影を実現',
          contents: [
            '「X-T シリーズ」初。5軸・最大6.5段のボディ内に手ぶれ補正機構搭載し快適な手持ち撮影を実現',
            '新開発フォーカルプレーンシャッターの採用と、アルゴリズムのさらなる進化で、最速15 コマ/秒の高速連写と最短約0.02 秒のAFを実現',
            '顔・瞳AF 性能が向上。優れた動体追従AF を備えカメラに向かって来る被写体だけでなく、離れていく被写体も正確に捕捉可能'
          ]
        },
        {
          subtitle: '独自の色再現技術などにより卓越した写真画質を実現',
          contents: [
            '80年以上にわたり培った色再現技術と、2610万画素「X-TransCMOS4」センサー＆画像処理エンジン「X-Processor4」の組み合わせで、記憶に残る鮮やかな色を再現',
            '「オートホワイトバランス」に、「ホワイト優先」と「雰囲気優先」を追加。難しい光源でもイメージに近い色味を再現可能',
            '「フィルムシミュレーション」に、「ETERNAブリーチバイパス」モードを新搭載。低彩度・高コントラストで重厚感のある写真・映像の撮影が可能'
          ]
        },
        {
          subtitle: 'より撮影に集中できるように進化したバッテリー＆ファインダー',
          contents: [
            '新開発の大容量バッテリー「NP-W235」を採用。ノーマルモードで約500枚、エコノミーモードで約600枚の連続撮影が可能',
            '撮影シーンや被写体に応じて3つのブーストモードを選択できる369万ドット高精細有機ELファインダー採用で、難しいシーンでも撮影しやすいように進化',
            '3.0インチ・約162万ドット・タッチパネル式の大型LCDモニターは新たにバリアングル式を採用。多彩なアングルでの撮影が可能'
          ]
        },
        {
          subtitle: '充実の動画撮影機能を搭載',
          contents: [
            'SDカード記録が可能な4K/60Pの動画撮影に対応。ボディ天面に静止画/動画切換ダイヤルを新搭載し、静止画と動画の設定を独立して行うことが可能',
            '電子式手ブレ補正機能を新搭載。手ブレに合わせてフレーミングを微調整することで、手持ちによる動画撮影で生じるブレを徹底的に補正',
            'フルHD・240Pのハイスピード動画撮影が可能。素早く動く被写体の一瞬のアクションも、最大10倍のスローモーション映像で再生可能'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'フジフイルム X-T3 ボディ',
        href:
          'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4547410378030&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&_ga=2.171423328.1712661879.1629071621-279164427.1627285160&pattern=1',
        nameImg: 'https://shop.kitamura.jp/images/pd/f71/5e1/a86/183/6d7/70e/436/dc8/03f/090/b0x/wwt/61t/a/L.jpg',
        txt: '富士フイルム X-T4',
        tradeInImg: '/ec/images2/special/camera/feature/fujifilm/xt4/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'フジフイルム X-T3 ボディ',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4547410378030&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&_ga=2.197098348.1712661879.1629071621-279164427.1627285160&pattern=1'
          },
          {
            name: 'フジフイルム X-T2 ボディ',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4547410325287&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&_ga=2.197098348.1712661879.1629071621-279164427.1627285160&pattern=1'
          },
          {
            name: 'フジフイルム X-Pro2 ボディ',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4547410311914&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&_ga=2.95714012.1712661879.1629071621-279164427.1627285160&pattern=1'
          }
        ]
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shop.kitamura.jp/images/banner/8422.png',
          alt: 'カメラのメンテナンスサービス'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: '富士フイルム X-T4',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410427875'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410427875', '4547410378030', '4547410365801', '4548736108417'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4547410427875',
        '4547410427936',
        '4547410428032',
        '4547410428056',
        '4547410428131',
        '4547410428070',
        '4547410428155',
        '4547410378122',
        '4547410428124',
        '4547410428179'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
