


















































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import Urlcopy from '@/components/common/special/url-copy.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'moonlook',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    watchInVideo: WatchInVideo,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    orangeBtn: OrangeBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '天体観測 入門編 月を見よう!おすすめ天体望遠鏡・双眼鏡の選び方 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '天体観測入門編！初心者さんにもおすすめな月の観測方法や、天体望遠鏡・双眼鏡の選び方、スマホで撮影できる天体望遠鏡などご紹介♪天体望遠鏡・双眼鏡はカメラのキタムラへおまかせください'
      );
    const state = reactive({
      naviList: [
        { item: '月の観測ではじめにすること', href: '#first' },
        { item: '天体望遠鏡の選び方-天体望遠鏡で月の観測', href: '#astronomical-telescope' },
        { item: '天体望遠鏡とスマホで月を撮影', href: '#smartphone' },
        { item: 'あると便利！天体観測用アイテム', href: '#benri' },
        { item: '双眼鏡の選び方-双眼鏡で月の観測', href: '#field-glasses' },
        { item: '月の観測・撮影におすすめの商品一覧', href: '#list' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/hosizora',
          img: 'https://shopimg.kitamura.jp/images/banner/5032.png',
          alt: '星空総合ページ'
        },
        {
          href: 'https://www.vixen.co.jp/app/moon-book/',
          img: '/ec/images2/special/camera/astronomical/moonlook/bnr_vixenmoonbook.jpg',
          alt: '月食の方角を調べる「Moonbook」'
        },
        {
          href: 'https://www.vixen.co.jp/app/nebulabook/',
          img: 'https://shopimg.kitamura.jp/images/banner/3439.gif',
          alt: '星雲・星団・銀河等の天体撮影を支援するアプリ「Nebula Book」'
        }
      ],
      recommendedBinoculars: [
        {
          name: 'ケンコー 7倍双眼鏡 Artos 7×50',
          href: '/ec/pd/4961607971703',
          img: 'https://shopimg.kitamura.jp/images/pd/518/4c3/323/ecd/b75/13f/9b9/119/14c/bb4/8dd/qvc/eqg/z/TN.jpg'
        },
        {
          name: 'ビクセン 6倍双眼鏡 アトレックライトII BR6×30WP',
          href: 'https://shop.kitamura.jp/ec/pd/4955295147076',
          img: 'https://shopimg.kitamura.jp/images/pd/235/804/860/53d/c12/964/f5b/2c0/9f8/227/4f3/9ea/6sw/b/TN.jpg'
        },
        {
          name: 'ひとみ径 6mm ビクセン双眼鏡',
          href: '/ec/pd/4955295156504',
          img: 'https://shopimg.kitamura.jp/images/pd/c10/482/306/edb/293/d2a/287/25a/4c9/6af/4bs/u9n/0ru/i/TN.jpg'
        },
        {
          name: 'ひとみ径 7mm ビクセン双眼鏡',
          href: '/ec/pd/4955295156207',
          img: 'https://shopimg.kitamura.jp/images/pd/5fe/1c3/92b/048/45f/213/ff1/e94/583/752/88f/p9d/hch/l/TN.jpg'
        }
      ],
      astronomicalTelescopes: [
        {
          name: 'ビクセン A70Lf モバイルポルタ 天体望遠鏡',
          href: '/ec/pd/4955295399055',
          img: '/ec/images2/special/camera/astronomical/moonlook/4955295399055.jpg'
        },
        {
          name: 'ビクセン 天体望遠鏡 ポルタII R130Sf',
          href: '/ec/pd/4955295399543',
          img: 'https://shopimg.kitamura.jp/images/pd/6d5/b01/433/c0f/e5e/e8a/450/d37/9da/611/ddg/4r2/g2m/9/M.jpg'
        },
        {
          name: 'ビクセン ポルタII A80Mf 天体望遠鏡',
          href: '/ec/pd/4955295399529',
          img: '/ec/images2/special/camera/astronomical/moonlook/4955295399529.jpg'
        },
        {
          name: 'ビクセン 天体望遠鏡 スペースアイ600',
          href: '/ec/pd/4955295327539',
          img: '/ec/images2/special/camera/astronomical/moonlook/4955295327539.jpg'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '天体観測スケジュール',
          linkUrl: '/ec/special/camera/astronomical',
          disabled: false
        },
        {
          path: '天体観測 入門編 月を見よう!おすすめ天体望遠鏡・双眼鏡の選び方',
          disabled: true
        }
      ],
      productJanCodeList: [
        '4955295399529',
        '4955295327539',
        '4955295392049',
        '4955295391998',
        '4955295156207',
        '4955295430239',
        '4955295880003',
        '4955295399697',
        '4955295840908',
        '4955295372256',
        '4955295710898'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
