

























































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'fujifilm-xf100-400',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    priceAndPopular: PriceAndPopular,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'フジフイルム XF100-400mm F4.5-5.6 R LM OIS WR | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'フジフイルム XF100-400mm F4.5-5.6 R LM OIS WRの特集ページ。軽くて高画質な超望遠レンズのおすすめポイントをまとめました。価格・おすすめポイント・お得な買取情報もチェック！カメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      relatedProductLinkList: [
        {
          href:
            '/ec/list?narrow18=0&keyword=&category=ミラーレス一眼%3Aメーカーで選ぶ%3Aフジフイルム&sort=number20,Number17,Score&fmt=json&s19%5B%5D=1&style=0&path=ミラーレス一眼%3Aメーカーで選ぶ%3Aフジフイルム&s4%5B%5D=ボディ&limit=20',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn1.png',
          alt: 'ミラーレスボディ一覧'
        },
        {
          href: '/ec/ct/C004/015/002/',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn2.png',
          alt: '望遠ズームレンズ一覧'
        },
        {
          href: '/ec/ct/C004/015/001/',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn3.png',
          alt: '標準レンズ一覧'
        },
        {
          href: '/ec/ct/C004/015/003/',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn4.png',
          alt: '広角レンズ一覧'
        },
        {
          href: '/ec/ct/C004/015/004/',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn5.png',
          alt: '標準・中望遠ズームレンズ一覧'
        },
        {
          href: '/ec/ct/C004/015/007/',
          img: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/fujifilm/xf100-400/link/btn6.png',
          alt: 'マクロレンズ一覧'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '富士フイルム FUJINONレンズ特集「XマウントXFレンズ」',
          linkUrl: '/ec/special/camera/lens/fujifilm',
          disabled: false
        },
        {
          path: 'フジフイルム XF100-400mm F4.5-5.6 R LM OIS WR',
          disabled: true
        }
      ],
      productJanCodeList: ['4547410316193', '4547410268225', '4547410310429'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state)
    };
  }
});
