




















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import ProductService from '@/logic/product.service';
import productSliderItemVue from './product-slider-item.vue';
import { SliderProduct } from '@/types/product';

export default Vue.extend({
  name: 'product-slider',
  components: {
    'product-slider-item': productSliderItemVue
  },
  props: {
    recommend: {
      required: false
    },
    // 中古商品に関連する商品かどうか
    isUsed: {
      required: true,
      type: Boolean
    },
    items: {
      required: true
    },
    isRelatedProducts: {
      type: Boolean,
      required: true
    },
    // カートボタンテキスト
    cartButtonText: {
      type: String,
      default: 'あわせて買う'
    }
  },
  setup: (props, context) => {
    const state = reactive({
      model: null,
      usedProductsSummary: [] as Array<{
        janCode: string;
        itemCount: number;
        minPrice: number;
      }>
    });

    const janCodeList = (props.items as Array<SliderProduct>).map((item) => item.janCode) as string[];

    // 新品用中古情報
    if (janCodeList.length) {
      ProductService.searchUsedProductsSummary(janCodeList)
        .then((result) => {
          state.usedProductsSummary = result.itemInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // 指定したJANコードの新品用中古情報を取得する関数
    function fetchUsedProductSummary(janCode: string) {
      const findItem = state.usedProductsSummary.find((item) => item.janCode === janCode);
      if (findItem) {
        return findItem;
      } else {
        return {};
      }
    }

    function addCart() {
      context.emit('addCart');
    }

    return {
      ...toRefs(state),
      fetchUsedProductSummary,
      addCart
    };
  }
});
