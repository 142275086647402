
















































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';

export default Vue.extend({
  name: 'xs_xsmax_xr',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'iPhoneXs/iPhoneXs Max/iPhoneXRの性能を徹底レビュー | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'iPhone Xs、iPhone Xs Max、iPhone XRの性能や新機能を、iPhone Xと比較してみました。');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'iPhoneXs/iPhoneXs Max/iPhoneXRの性能を徹底レビュー',
          disabled: true
        }
      ],
      iphoneImages: [
        '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/iphonexs_size.jpg',
        '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/iphone_max_size.jpg',
        '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/iphone8plus_size.jpg'
      ],
      dualCameraSystemList: [
        {
          subtitle: 'スマートHDR',
          txt: '新しいセカンダリーフレーム、より高速なセンサー、パワフルなA12 Bionicチップが、あなたの写真の明部と暗部により精細なディテールをもたらします。'
        },
        {
          subtitle: 'まったく新しい深度コントロール',
          txt: '撮影後でもポートレート写真の被写界深度を調整できるようになりました。'
        },
        {
          subtitle: '強化されたボケ効果',
          txt: '精巧な背景のぼかしによって、ポートレートモードで撮影した写真がプロフェッショナルな作品に一段と近づきます。'
        },
        {
          subtitle: 'より大きく、より深いピクセル',
          txt: '新しいセンサーにより、画像はより忠実に、色はより正確に再現され、十分な明るさがない場所でのノイズが軽減されます。'
        }
      ],
      printServiceList: [
        {
          src: '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/imageing01.jpg',
          href: 'https://www.kitamura-print.com/print/wonder_shuffle/',
          hreftxt: 'シャッフルプリント',
          txt: 'たくさん撮影された写真を1枚のプリントにギュッ！\n思い出を一枚の写真にできるから旅行やお子さまの写真にピッタリ！',
          btnLink: 'https://www.kitamura-print.com/print/wonder_shuffle/?_ga=2.133011022.1712661879.1629071621-279164427.1627285160',
          appStoreLink:
            'https://apps.apple.com/jp/app/%E3%82%B7%E3%83%A3%E3%83%83%E3%83%95%E3%83%AB%E3%83%97%E3%83%AA%E3%83%B3%E3%83%88-%E3%82%AB%E3%83%A1%E3%83%A9%E3%81%AE%E3%82%AD%E3%82%BF%E3%83%A0%E3%83%A9/id1009274709',
          googlePlayLink: 'https://play.google.com/store/apps/details?id=jp.kitamura.shuffle'
        },
        {
          src: '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/imageing02.jpg',
          href: 'https://www.kitamura-print.com/print/smartphone_print/?_ga=2.61717100.1712661879.1629071621-279164427.1627285160',
          hreftxt: '高品質スマホプリント',
          txt:
            'スマホで撮った写真を高品質プリント！\n豊富なプリントサイズをお選びいただけます。\n専用アプリなら注文が簡単。\n最短10分のスピード仕上げ！ご注文時に「お店受取」を選ぶとご来店時にすぐお渡しできます。',
          btnLink: 'https://www.kitamura-print.com/print/smartphone_print/?_ga=2.98334175.1712661879.1629071621-279164427.1627285160',
          appStoreLink: 'https://apps.apple.com/jp/app/purinto-zhi-xing-bian-for/id451410131',
          googlePlayLink: 'https://play.google.com/store/apps/details?id=com.photo'
        },
        {
          src: '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/imageing03.jpg',
          href: 'https://photobook.kitamura.jp/yearalbum/apri.html',
          hreftxt: 'イヤーアルバム',
          txt:
            '期間を指定するだけで、写真の中から写りのいいものを選択・レイアウトまでソフトが自動でおこないます！\nイメージ作成までは料金がかかりませんので、ぜひ一度このお手軽さを体験してみてください。',
          btnLink: 'https://photobook.kitamura.jp/yearalbum/apri.html',
          appStoreLink:
            'https://apps.apple.com/jp/app/%E3%83%95%E3%82%A9%E3%83%88%E3%83%96%E3%83%83%E3%82%AF%E4%BD%9C%E6%88%90%E3%82%A2%E3%83%97%E3%83%AA-%E3%82%A4%E3%83%A4%E3%83%BC%E3%82%A2%E3%83%AB%E3%83%90%E3%83%A0/id934282943',
          googlePlayLink: 'https://play.google.com/store/apps/details?id=jp.co.fujifilm.yearalbum_android.kitamura'
        },
        {
          src: '/ec/images2/special/kaden/smart_phone/iphone/xs_xsmax_xr/pocketbook.jpg',
          href: 'https://photobook.kitamura.jp/pocketbook/',
          hreftxt: 'ポケットブック',
          txt: '小さいかわいい、お手軽なフォトブック。はじめてのフォトブックやアルバム作りにぴった♪\n気軽に作れる、おしゃれなフォトブック',
          btnLink: 'https://photobook.kitamura.jp/pocketbook/',
          appStoreLink: 'https://apps.apple.com/JP/app/id1502495032?mt=8',
          googlePlayLink: 'https://play.google.com/store/apps/details?id=jp.kitamura.pocketbook'
        }
      ]
    });

    function linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      linkToOtherWindow
    };
  }
});
