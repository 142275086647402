



































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'zv-e10m2',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！ソニー VLOGCAM ZV-E10 II | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー VLOGCAM ZV-E10 II 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/7/11 10:00',
      fanValidTo: '2024/7/17 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/7/17 10:00',
      reserveValidTo: '2024/8/1 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/8/2 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ソニー VLOGCAM ZV-E10',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=VLOGCAM%20ZV-E10',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/6e9/8ee/bad/410/9c1/040/89e/125/eae/ca7/5cq/201/04o/1/L.jpg',
        txt: 'ソニー ZV-E10M2 ',
        day: '2024年8月31日(土)',
        tradeInImg: '/ec/images2/special/camera/feature/sony/zv-e10m2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ソニー VLOGCAM ZV-E10',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=VLOGCAM%20ZV-E10'
          },
          {
            name: 'ソニー α6400[ILCE-6400]',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-6400'
          },
          {
            name: 'ソニー α6500[ILCE-6500]',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-6500'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '撮りたい瞬間を逃さない高速AF',
          contents: [
            '写真も動画も、追いかける瞳AFでかんたん・キレイ。新画像処理エンジンBIONZ XRがパワフルに処理することで、高精度・高速・高追随のAFを実現しました',
            'カメラまかせで被写体を自動で追い続ける「リアルタイムトラッキング」を搭載。顔検出精度も向上しています',
            '最高約11コマ/秒の連写性能を備え、ベストショットを逃しません'
          ]
        },
        {
          subtitle: '声も動きもくっきり鮮やかな動画撮影を実現',
          contents: [
            '動画撮影時に手ブレ補正効果を向上させる、電子式手ブレ補正機能「アクティブモード」に対応し、歩きながらの手持ち撮影でも安定した動画を撮影できます',
            '高性能な内蔵マイクと、デジタルオーディオインターフェースに対応した、マルチインターフェースシューを搭載。同梱のウインドスクリーンや、別売のマイクなどを使用すれば、よりクリアな音声記録が可能となるなど、高音質な音声記録をサポートする拡張性も充実しています'
          ]
        },
        {
          subtitle: 'らくらく外へ持ち出せるコンパクトボディ',
          contents: [
            '長時間撮影できる大容量Zバッテリーを搭載しながら、約377gの小型・軽量設計を実現。手持ち撮影でも疲れにくく、どこへでも気軽に持ち運んでたっぷり撮影できます',
            'モニターは「バリアングル液晶モニター」を搭載し、自撮りやさまざまなアングルでの撮影に対応。画面をタッチして操作することもできるので、自由なスタイルで動画撮影を楽しめます',
            '操作しやすいボタンの配置や握りやすいグリップにもこだわり、写真も動画も快適に撮影するためのデザインと機能を採用しています'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー VLOGCAM ZV-E10 II ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736160675', '4548736160736', '4548736161061', '4548736161078', '4548736160866', '4548736160828'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', '', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736160675', '4548736128354', '4960759906311', '4549292189711'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      //popup
      imgs: [
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_01.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_02.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_03.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_04.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_05.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_06.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_07.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_08.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_09.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_010.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_011.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_012.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_013.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_014.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_015.jpg',
        '/ec/images2/special/camera/feature/sony/zv-e10m2/pic_016.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
