
















































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'lens',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (props, context) => {
    document.title = ' 2022年カメラレンズの人気ランキング：メーカーマウント別にトップ5をご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '人気カメラレンズランキング！デジタル一眼やミラーレスの交換レンズを、キヤノン・ニコン・ソニー・フジフイルムなどメーカー別にまとめました。望遠ズームや単焦点レンズなど、最適なレンズをお探しください♪キタムラネットショップならご自宅で受取りも出来ますし、お近くのキタムラ店舗で受取ることも出来ます。'
      );
    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラレンズ人気ランキング',
          linkUrl: '/ec/special/camera/lens',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      selectedItem: 0,
      itemsank: [
        { text: 'キヤノン用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#canon' },
        { text: 'ニコン用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#nikon' },
        { text: 'ソニー用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#sony' },
        { text: 'マイクロフォーサーズ用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#maicro' },
        { text: 'フジフイルム用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#fuji' },
        { text: 'ペンタックス用 マウント レンズランキング', icon: 'fa-chevron-circle-right', link: '#penta' }
      ],
      // ↓ ---- レンズランキング情報 ----
      productList: [
        {
          id: 'canon',
          type: 'キヤノン',
          JanCodeList: ['4549292186765', '4549292037692', '4549292115611', '4549292186772', '4549292168037'],
          featureList: [
            ['RFマウント', 'フルサイズ対応', '望遠ズーム', '手ブレ補正機構搭載'],
            ['RFマウント', 'フルサイズ対応', '標準単焦点'],
            ['RFマウント', 'フルサイズ対応', '標準ズーム', '手ブレ補正機構搭載', '防塵防滴構造'],
            ['RFマウント', 'フルサイズ対応', '広角単焦点'],
            ['EFマウント', 'フルサイズ対応', '望遠ズーム', '手ブレ補正機構搭載', '防塵防滴構造']
          ],
          btntxt: 'キヤノン用マウント\n人気カメラレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itemcanon',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        },
        {
          id: 'nikon',
          type: 'ニコン',
          JanCodeList: ['4960759906274', '4960759906557', '4960759909091', '4960759904928', '4960759902191'],
          featureList: [
            ['Zマウント', 'フルサイズ対応', '標準ズーム', '防塵防滴に配慮した設計'],
            ['Zマウント', 'フルサイズ対応', '望遠ズーム', '手ブレ補正機構搭載', '防塵防滴に配慮した設計'],
            ['Zマウント', 'フルサイズ対応', '望遠単焦点', '手ブレ補正機構搭載', '防塵防滴に配慮した設計'],
            ['Zマウント', 'フルサイズ対応', '標準単焦点'],
            ['Zマウント', 'フルサイズ対応', '望遠ズーム', '手ブレ補正機構搭載', '防塵防滴に配慮した設計']
          ],
          btntxt: 'ニコン用マウント\n人気カメラレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itemnikon',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        },
        {
          id: 'sony',
          type: 'ソニー',
          JanCodeList: ['4548736134119', '4960371006796', '4960371006789', '4960371006703', '4548736134133'],
          featureList: [
            ['Eマウント', 'フルサイズ対応', '望遠ズーム', '手ブレ補正機構搭載', '防塵防滴に配慮した設計'],
            ['Eマウント', 'フルサイズ対応', '標準ズーム', '簡易防滴構造'],
            ['Eマウント', 'フルサイズ対応', '標準ズーム', '簡易防滴構造'],
            ['Eマウント', 'フルサイズ対応', '高倍率ズーム', '簡易防滴構造'],
            ['Eマウント', 'フルサイズ対応', '標準ズーム', '防塵防滴に配慮した設計']
          ],
          btntxt: 'ソニー用マウント\n人気カメラレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itemsony',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        },
        {
          id: 'maicro',
          type: 'マイクロフォーサーズ',
          JanCodeList: ['4545350043742', '4545350053055', '4545350053925', '4545350029654', '4545350052546'],
          featureList: [
            ['望遠ズーム'],
            ['望遠ズーム', '手ブレ補正機構搭載', '防塵防滴構造'],
            ['マクロレンズ', '手ブレ補正機構搭載', '防塵防滴構造'],
            ['広角ズーム'],
            ['高倍率ズーム', '防塵防滴構造']
          ],
          btntxt: 'マイクロフォーサーズ用\nおすすめレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itemmicroft',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        },
        {
          id: 'fuji',
          type: 'フジフイルム',
          JanCodeList: ['4547410437980', '4547410206159', '0085126585754', '4960371006772', '4547410440386'],
          featureList: [
            ['Xマウント', '望遠ズーム', '手ブレ補正機構搭載', '防塵防滴仕様'],
            ['Xマウント', '標準単焦点'],
            ['Xマウント', '標準ズーム', '簡易防滴構造'],
            ['Xマウント', '高倍率ズーム', '手ブレ補正機構搭載', '簡易防滴構造'],
            ['Xマウント', '標準単焦点', '防塵防滴構造']
          ],
          btntxt: 'フジフイルム用マウント\n人気カメラレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itemfuji',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        },
        {
          id: 'penta',
          type: 'ペンタックス',
          JanCodeList: ['4549212296642', '4549212299735', '4549212302954', '4549212217609', '4549212304859'],
          featureList: [
            ['Kマウント', 'フルサイズ', '望遠ズーム', '防滴構造'],
            ['Kマウント', 'フルサイズ', '広角ズーム', '防塵防滴構造'],
            ['Kマウント', 'フルサイズ', '標準ズーム', '防塵防滴構造'],
            ['Kマウント', 'フルサイズ', '標準単焦点'],
            ['Kマウント', 'フルサイズ', 'マクロレンズ', '防塵防滴構造']
          ],
          btntxt: 'ペンタックス用マウント\n人気カメラレンズ 一覧を見る',
          btnhref: '/ec/special/camera/lens/itempentax',
          resultproductList: [] as Array<ProductDetail> //結果格納用
        }
      ],
      // ↓ ランキング取得するJancode
      productJanCodeList2: ['4549292200508', '4548736145573', '4960759911421', '4545350053864', '4548736128521', '4549292205053'],
      productDetailList2: [] as Array<ProductDetail> // 結果格納用
      // ↑ 取得するJancode
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        for (let i = 0; i < state.productList.length; i++) {
          const mainResult = await ProductService.fetchProducts(state.productList[i].JanCodeList, true);
          state.productList[i].resultproductList = mainResult.items;
        }
        // ランキング商品
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
      } catch (error) {
        console.log(error);
        state.productDetailList2 = [] as Array<ProductDetail>; // ランキング商品2
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
