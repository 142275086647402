export const BOT_USER_AGENTS = [
  'Googlebot',
  'Mediapartners-Google',
  'AdsBot-Google',
  'Google-Site-Verification/1.0',
  'GoogleImageProxy',
  'All About Japan Link Patrol',
  'Ask Jeeves',
  'BaiDuSpider',
  'Bookmark Renewal Check Agent',
  'Faxobot',
  'FreshGet',
  'Gigabot',
  'Googlebot',
  'ia_archiver',
  'indexpert',
  'InfoNaviRobot',
  'MJ12bot',
  'msnbot',
  'Openfind data gatherer, Openbot',
  'proodleBot',
  'psbot',
  'ScSpider',
  'TutorGigBot',
  'websitepulse checker',
  'WWWC',
  'Yahoo! Slurp',
  'YottaShopping_Bot',
  'bgcbot',
  'facebookexternalhit',
  'crawler.vrg.jp',
  'crawler.ocenyc.jp',
  'Y!J-MBS/1.0',
  'Yahoo! DE Slurp',
  'Googlebot-Mobile/',
  'adsence-Google',
  'bingbot/',
  'bingbot',
  'http://www.bing.com/bingbot.htm',
  'Hatena',
  'MicroAd/',
  'Baidu',
  'Yeti/',
  'NaverBot',
  'BecomeBot',
  'heritr',
  'ichiro',
  'archive.org_bot',
  'ICC-Crawler',
  'Googlebot-Image/',
  'Feedfetcher-Google',
  'GoogleProducer',
  'Y!J-VSC/ViSe',
  'Y!J-SRD',
  'Y!J-BRI',
  'Y!J-BRE',
  'Y!J SearchMonkey',
  'Y!J-BRO/YFSJ',
  'Y!J-BRP/YFSBJ',
  'Y!J-BRT',
  'Y!J-BRU/VSIDX',
  'Y!J-IER',
  'Y!J-BRW',
  'Y!J-RIE',
  'Y!J-APP',
  'YahooSeeker',
  'Y!J-BRH',
  'Y!J-BSC',
  'Y!J-BRS',
  'Y!J-BRD/YSML',
  'Y!J-BUZZ',
  'Y!J-BRJ/YATS',
  'Y!J-BRL/YATSS',
  'Y!J-BRM/YATSD',
  'Y!J-BRN/YATSA',
  'Y!J-BRY/YATSH',
  'Y!J-BRZ/YATSHA',
  'Y!J-DLC',
  'Y!J-BRX/DLCK',
  'Y!J-BRQ/DLCK',
  'Y!J-PSC',
  'Y!J-DSC',
  'Y!J-PUF/1.0',
  'https://developers.google.com/+/web/snippet/'
];
