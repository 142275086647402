





























































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '7sm3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー α7S III | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー α7S III 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ソニー α7S II ボディ [ILCE-7SM2]',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&goodsname=4548736018808&_ga=2.175077090.1712661879.1629071621-279164427.1627285160&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/9a9/607/8a2/ded/cb8/e5c/703/dd1/ab1/9bd/45q/gw4/6nb/r/M.jpg',
        txt: 'ソニー α7S III',
        tradeInImg: '/ec/images2/special/camera/feature/sony/7sm3/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      characteristicList: [
        {
          subtitle: 'プロの映像クリエイターが求める多彩な映像表現を実現',
          contents: [
            '最高ISO409600の高感度・階調性能を生かした最高120fps(4K)のハイフレームレート撮影など、多彩な動画フォーマットへの対応',
            '新開発フルサイズイメージセンサーと新画像処理エンジン「BIONZ XR」の搭載で、画素加算のないフルサイズ領域での4K全画素読み出し等、高い映像品質を実現',
            '業務用ラージフォーマットカメラ「FX9」譲りのAF性能を搭載。動画撮影時も像面位相差AF有効等、さまざまな撮影条件下で安定したAF性能と多彩なフォーカスワークを提供',
            '新開発の放熱構造でイメージセンサーや画像処理エンジンの駆動に伴う熱を効果的に分散し放熱。動画撮影中のカメラ本体内の温度上昇を抑制し、長時間の4K連続録画をサポート'
          ]
        },
        {
          subtitle: '新開発CMOSセンサーと刷新されたシステムで圧倒的な静止画、動画撮影性能を実現',
          contents: [
            '高感度・低ノイズ・広ダイナミックレンジを継承し、像面位相差AFにも対応した、裏面照射型の新開発有効約1210万画素35mmフルサイズCMOSセンサー搭載',
            '静止画撮影では、新たにファストハイブリッドAFや非圧縮RAWフォーマットでの1000枚以上の連写スピード性能を実現',
            '動画撮影では、4:2:2 10bitの4K/120p記録に対応。4K解像度で最大5倍のなめらかなスローモーションで、印象的な映像表現が可能',
            '従来比最大約8倍の高速処理が可能な新画像処理エンジン「BIONZ XR」の採用でリアルタイム処理の負荷の大きさに関わらず、快適な操作レスポンスを実現'
          ]
        },
        {
          subtitle: 'プロに応える操作性&信頼性をコンパクトな一眼スタイルに凝縮',
          contents: [
            'α Eマウントボディ初「バリアングル液晶モニター」を採用。手持ちの動画撮影時、ジンバルに搭載時、状況記録用の自撮りや地面すれすれのローポジションでの撮影にも対応',
            '「クラス最高解像度約944万ドット高精細電子ファインダー」搭載。ファインダー内で被写体の動きがよりなめらかに表示され、動体撮影時に被写体をよりスムーズに追尾が可能',
            '高速書き込み可能な次世代メモリーカード「CFexpress Type Aメモリーカード」と、従来の「SDXCメモリーカード」の両方に対応したデュアルスロットを採用'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α7S III',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736121331'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736121331', '4548736079625', '4548736128477', '4549077845511'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736121331',
        '4548736064522',
        '4548736064539',
        '4548736055704',
        '4548736109520',
        '4905524929379',
        '4548736120549',
        '4548736105546',
        '4548736064621',
        '4548736100688',
        '4548736100664',
        '4548736089037',
        '4548736089044',
        '4548736067714',
        '4548736067714'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
