










































































import Vue from 'vue';
import { reactive, toRefs, PropType, watch, computed } from '@vue/composition-api';
import Product from '@/components/product-list/product.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { SPECIAL_ITEM_SORT_LIST } from '@/constants/sort-list';
import { SPECIAL_DISPLAY_CONUT_LIST } from '@/constants/display-conut-list';
import SpecialProductService from '@/logic/special-product.service';
import { ProductItem } from '@/types/product-list';

export default Vue.extend({
  name: 'product-list-by-jan-code',
  components: {
    product: Product,
    'section-loading': SectionLoading
  },
  props: {
    janCodes: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    sortList: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => []
    },
    countList: {
      type: Array as PropType<Array<number>>,
      required: false,
      default: () => []
    }
  },
  setup: (props, context) => {
    const state = reactive({
      // 並び替え
      currentSort: SPECIAL_ITEM_SORT_LIST[0].value,
      sort: SPECIAL_ITEM_SORT_LIST,
      // 表示件数
      currentDisplayCount: SPECIAL_DISPLAY_CONUT_LIST[0].value,
      displayCountList: SPECIAL_DISPLAY_CONUT_LIST,
      // 商品件数
      totalCount: -1,
      // 表示ページ
      page: 1,
      // スマホ用の表示制御
      sortDrawer: false,
      // 価格の表示制御
      displayProductPriceType: '0',
      // 商品リスト
      currentResult: [] as Array<ProductItem>, // 現在の表示
      results: [] as Array<ProductItem>, // 全量
      // ロード状態
      loaded: {
        results: false
      }
    });

    /**
     * 商品リストの表示件数とソート処理
     */
    const setDispalySpecialproduct = (count = state.currentDisplayCount) => {
      // 一旦、「人気・おすすめ順」を設定
      const copyList = state.results.concat();
      if (state.currentSort === 'price_asc') {
        copyList.sort((firstItem, secondItem) => +firstItem.price - +secondItem.price);
      } else if (state.currentSort === 'price_desc') {
        copyList.sort((firstItem, secondItem) => +secondItem.price - +firstItem.price);
      }
      state.currentResult = SpecialProductService.isAllCount(count) ? copyList : copyList.slice(0, count);
    };

    /**
     * 特集商品の取得
     */
    const getSpecialProduct = async (janCodes: Array<string>) => {
      state.loaded.results = false;
      try {
        state.results = await SpecialProductService.getNewsSpecialProduct(janCodes);
        state.totalCount = state.results.length;
        setDispalySpecialproduct();
      } catch (error) {
        console.error(error);
        state.results = [] as Array<ProductItem>;
      } finally {
        state.loaded.results = true;
      }
    };

    /** -------------------------------------------------------------
     * 監視対象
     ------------------------------------------------------------- */

    // バナーIDに基づく表示
    watch(
      () => [props.janCodes],
      () => {
        getSpecialProduct(props.janCodes as Array<string>);
      },
      { immediate: true }
    );

    // 検索条件情に基づく表示
    watch(
      () => [state.currentSort, state.currentDisplayCount],
      () => {
        state.page = 1;
        setDispalySpecialproduct();
      }
    );

    // ソートリスト制御
    watch(
      () => [props.sortList],
      () => {
        state.sort = SpecialProductService.filterSortList(props.sortList as Array<string>);
        state.currentSort = state.sort[0].value;
      },
      { immediate: true, deep: true }
    );

    // 表示件数リスト制御
    watch(
      () => [props.countList],
      () => {
        state.displayCountList = SpecialProductService.filterDisplayCountList(props.countList as Array<number>);
        state.currentDisplayCount = state.displayCountList[0].value;
      },
      { immediate: true, deep: true }
    );

    /**
     * 表示している件数の表示制御
     */
    const displayMaxCount = computed(() => {
      if (SpecialProductService.isAllCount(state.currentDisplayCount)) {
        return state.totalCount;
      } else if (state.totalCount > state.currentDisplayCount * state.page) {
        return state.currentDisplayCount * state.page;
      } else {
        return state.totalCount;
      }
    });

    /** -------------------------------------------------------------
     * イベント
     ------------------------------------------------------------- */

    /**
     * もっと見る押下時の検索
     */
    const addSearch = () => {
      state.page += 1;
      setDispalySpecialproduct(state.page * state.currentDisplayCount);
    };

    /**
     * SP用：ソート選択
     * @param val 削除対象
     */
    const selectedSort = (val: string) => {
      state.currentSort = val;
      state.sortDrawer = !state.sortDrawer;
    };

    return {
      ...toRefs(state),
      displayMaxCount,
      addSearch,
      selectedSort
    };
  }
});
