// 商品一覧の表示件数
export const DISPLAY_COUNT_LIST = [
  {
    text: '20件',
    value: 20
  },
  {
    text: '40件',
    value: 40
  },
  {
    text: '100件',
    value: 100
  }
];

// 特集ページの表示件数
export const SPECIAL_DISPLAY_CONUT_LIST = [
  {
    text: '20件',
    value: 20
  },
  {
    text: '40件',
    value: 40
  },
  {
    text: '60件',
    value: 60
  },
  {
    text: '80件',
    value: 80
  },
  {
    text: '100件',
    value: 100
  },
  {
    text: 'すべて',
    value: 0
  }
];
