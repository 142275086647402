





















































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'trial',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    orangeBtn: OrangeBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'カメラバッグトライアルサービス | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラのキタムラ カメラバッグトライアルサービス 開催中！お店でカメラバッグをお試しいただけます♪合わなければキャンセルOK！カメラバッグ・カメラケースは、カメラのキタムラにおまかせください！'
      );
    const state = reactive({
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/bag',
          img: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          alt: '収納力で選べる！カメラバッグ特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/bag/feature/trekking',
          img: 'https://shopimg.kitamura.jp/images/banner/2932.gif',
          alt: 'フォトトレッキング特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラバッグ・カメラケース特集',
          linkUrl: '/ec/special/camera/bag',
          disabled: false
        },
        {
          path: 'カメラバッグトライアルサービス',
          disabled: true
        }
      ]
    });
    return {
      ...toRefs(state)
    };
  }
});
