



















































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'em1m3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'オリンパス E-M1 MarkIII | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'オリンパス OM-D E-M1 Mark III 発売開始！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！ OM-D E-M1 Mark IIIをご購入時、OM-D E-M1 MarkII ボディ ブラック高値下取り実施中'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#point' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観画像', href: '#images2' }
      ],
      characteristicList: [
        {
          subtitle: '最高7.5段手ぶれ補正、新エンジン「TruePic IX」が生む高画質を小型・軽量ボディーで実現',
          contents: [
            '対応レンズのレンズ内手ぶれ補正機構とボディー内5軸手ぶれ補正機構をシンクロさせることで、世界最高7.5段の手ぶれ補正を実現',
            'ボディー内に搭載された手ぶれ補正機構単独で最高7段分の補正効果。装着したすべてのレンズで最高レベルの手振れ補正を実現',
            '新画像処理エンジン「TruePic IX」を搭載。高感度時でもノイズが少なく、周辺まで歪みの少ない一眼トップクラスの高画質を実現'
          ]
        },
        {
          subtitle: '防塵・防滴、耐低温設計など、過酷な環境下でも安心して使える高い信頼性',
          contents: [
            '各部にシーリングを施し高い防塵・防滴、耐低温性を備えた堅牢なマグネシウム合金のボディーを採用。防塵・防滴仕様の「M.ZUIKO DIGITAL」レンズと組み合わせることで、過酷な環境下であっても撮影が可能',
            'USB経由での本体内充電に対応。給電しながらの長時間撮影を実現し、寒冷地での天体撮影等でも安心して撮影が可能',
            '従来機に比べゴミやホコリが写真に映り込む可能性を1/10に低減した、新コーティング採用の進化したダストリダクションシステム搭載'
          ]
        },
        {
          subtitle: '新開発の「星空AF」や進化した「顔優先／瞳優先AF」など、高速・高精度なAF システム',
          contents: [
            '動く被写体も高精度に捉える121点オールクロス像面位相差AF センサー搭載。「顔優先／瞳優先AF」も進化し狙った被写体を逃さない',
            'マニュアルによるピント合わせが常識だった天体写真の世界に革新をもたらす新開発「星空AF」で、暗闇に光る微小な星にも確実に捕捉',
            'ファインダーを覗きながら親指の操作で素早くAFターゲットを移動可能な「マルチセレクター」を搭載。動きの激しい被写体でも確実に捕捉可能'
          ]
        },
        {
          subtitle: '「プロキャプチャーモード」や手持ち4K 動画など、表現の幅を広げる多彩な撮影機能',
          contents: [
            '人の反応タイムラグやカメラの動作タイムラグが原因で撮り逃していた瞬間を確実に記録できる「プロキャプチャーモード」を搭載',
            'ボディー内5軸手ぶれ補正に電子手ぶれ補正を組み合わせることで高い手ぶれ補正を実現する、動画撮影専用の手ぶれ補正モードM-IS1 を搭載',
            'ハイレゾ録音に対応したリニアPCMレコーダー「LS-P4」を外部マイク／レコーダーとして組み合わせることで、高音質な動画音声記録が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラメンテナンスサービス'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'オリンパス OM-D E-M1 Mark III',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350053000', '4545350053017'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4545350053000', '4545350050849', '4545350052621', '4545350052768'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4545350053000', '4545350053017', '4545350050894', '4545350051020', '4545350052553', '4545350050924', '4545350050917'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
