

































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'highly-recommended-2015-1127',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: () => {
    document.title = '12月5日は「アルバムの日」人気の写真アルバム大集合！ | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '12月5日は「アルバムの日」。今年一年の思い出写真を整理にするのに、贈るのにおすすめな写真アルバムを集めました！写真アルバムはカメラのキタムラへおまかせください♪')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '12月5日は「アルバムの日」人気の写真アルバム大集合！',
          disabled: true
        }
      ],
      howToUseList: ['速']
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
