

































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'watch-omega',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'オメガ OMEGA 時計 ｜人気モデル・レディース | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'Ωオメガの時計特集。人気のスピードマスター・シーマスターやレディースモデルの価格や特徴をご紹介。カメラのキタムラなら分割金利手数料が無料♪安心の電話注文が出来ます！'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'オメガ(OMEGA) 時計特集',
          disabled: true
        }
      ],
      naviList: [
        { name: 'オメガ時計 人気モデル', href: '#series' },
        { name: 'オメガ時計 レディースモデル', href: '#ladies' },
        { name: 'オメガ時計の歴史', href: '#history' }
      ],
      popularModelList: [
        {
          category: 'シーマスター',
          txt: '1948年に誕生したモデル\n150m防水の「アクアテラ」、600m防水の「プラネット・オーシャン」など、高い防水性能を備えています',
          linktxt: 'オメガ シーマスター 人気商品一覧',
          href:
            '/ec/list?narrow18=0&keyword=オメガ%28OMEGA%29%20シーマスター&category=&r=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=オメガ%28OMEGA%29%20シーマスター&x=0&y=0',
          src: 'https://shop.kitamura.jp/ts_rsc/514/images/btn_seamaster.jpg'
        },
        {
          category: 'スピードマスター',
          txt: '1957年に誕生したモデル\n1965年、アメリカ航空宇宙局の公式腕時計として採用され、精度、視認性、信頼性の面で高い評価を得ています',
          linktxt: 'オメガ スピードマスター 人気商品一覧',
          href:
            '/ec/list?narrow18=0&keyword=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%82%B9%E3%83%94%E3%83%BC%E3%83%89%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&category=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%82%B9%E3%83%94%E3%83%BC%E3%83%89%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC&x=0&y=0',
          src: 'https://shop.kitamura.jp/ts_rsc/514/images/btn_speedmaster.jpg'
        },
        {
          category: 'コンステレーション',
          txt: '1952年に誕生したモデル\n英語で「星座」の意味を持ち、文字盤の6時位置にある星のマークと、裏蓋に描かれた天文台のデザインが特徴的です',
          linktxt: 'オメガ コンステレーション 人気商品一覧',
          href:
            'https://shop.kitamura.jp/ec/list?narrow18=0&keyword=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%82%B3%E3%83%B3%E3%82%B9%E3%83%86%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3&category=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%82%B3%E3%83%B3%E3%82%B9%E3%83%86%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3&x=0&y=0',
          src: 'https://shop.kitamura.jp/ts_rsc/514/images/btn_constellation.jpg'
        },
        {
          category: 'デ・ビル',
          txt: '1967年に誕生したモデル\nフランス語で「都会・街」の意味を持ち、高機能なドレスウォッチとして、多数のバリエーションが存在します',
          linktxt: 'オメガ デ・ビル 人気商品一覧',
          href:
            'https://shop.kitamura.jp/ec/list?narrow18=0&keyword=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%83%87%E3%83%BB%E3%83%93%E3%83%AB&category=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=%E3%82%AA%E3%83%A1%E3%82%AC%28OMEGA%29%20%E3%83%87%E3%83%BB%E3%83%93%E3%83%AB&x=0&y=0',
          src: 'https://shop.kitamura.jp/ts_rsc/514/images/btn_deville.jpg'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage
    };
  }
});
