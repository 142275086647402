
































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'eosr1',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！キヤノン EOS R1| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'キヤノン EOS R1価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/7/18 10:00',
      fanValidTo: '2024/7/23 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/7/23 10:00',
      reserveValidTo: '2024/11/28 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/11/29 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '関連アクセサリー', href: '#goods' },
        { naviItem: '作例', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '関連アクセサリー', href: '#goods' },
        { naviItem: '作例', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'キヤノン EOS R3',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292181661',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/1ae/f1e/096/736/ce6/aa0/afd/3a8/eb4/192/f21/5l3/4uk/v/L.jpg',
        txt: 'ソニー EOSR1 ',
        day: '2024年11月30日(土)',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr1/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'キヤノン EOS R3',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292181661'
          },
          {
            name: 'キヤノン EOS R5',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157345'
          },
          {
            name: 'キヤノン EOS R6',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157253'
          },
          {
            name: 'キヤノン EOS R6 MarkII',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292200508'
          },
          {
            name: 'キヤノン EOS-1D X Mark II',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292060485'
          },
          {
            name: 'キヤノン EOS-1D X MarkIII',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292146851'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '狙った被写体を素早く捉え粘り強く追尾し続ける高精度AF',
          contents: [
            'トラッキング中最大約100％の測距可能エリアにおいてデュアルピクセルCMOS AF初となるクロスAFに対応。被写体の模様や障害物の影響を抑えて、正確に被写体を捉え続けることが可能',
            '高速解析・高速撮影を実現する新エンジンシステム「Accelerated Capture」とディープラーニング技術の融合による新たなAFシステム「Dual Pixel Intelligent AF」により、優れたトラッキング性能を実現',
            '被写体が交錯する場面が多いチームスポーツ撮影などでも選手を粘り強く撮影し続けるため、トラッキング性能を強化。人物が小さい場合でも検知可能な「上半身検知」や、障害物が被写体の前を横切るシーンなどでも被写体の頭部とその前に重なる障害物を見分ける「頭部領域推定による障害物回避」により精度の高いトラッキングが可能',
            'サッカーのシュートなど特定の動作の被写体をカメラが自動で認識し、素早くAFフレームを合わせる「アクション優先」機能を搭載',
            'あらかじめ狙いたい被写体を登録し、優先的に追尾し続ける「登録人物優先」機能では、「EOS R3」と比較し、顔が斜めに向いた際の検出性能が向上'
          ]
        },
        {
          subtitle: '決定的瞬間を逃さない高速連写や快適な撮影を実現する新開発の電子ビューファインダー',
          contents: [
            '電子シャッター時、AF／AE追従しながら最高約40コマ/秒を実現し、30コマ/秒、20コマ/秒などの中間のコマ速の設定が可能',
            '電子シャッター時のローリングシャッター歪みを、「EOS-1D X Mark III」のメカシャッター同等レベルに低減',
            'EOSシリーズ最多となる約944万ドット、ファインダー倍率約0.9倍、EOSシリーズ最高輝度を達成した新開発の電子ビューファインダーを搭載',
            'ファインダーをのぞく瞳の動きでAF操作が行うことができる視線入力AFでは、検出フレームレートを「EOS R3」比約2倍とすることでレスポンスを向上し、光学系も新規設計とすることで目の位置がずれた場合や眼鏡装着時の検出安定性も向上',
            'シャッターボタンを押したタイミングより最大約20コマ分遡って撮影することができる「プリ連続撮影」機能を搭載し、JPEG／RAW／HEIFにおいて切り出し作業なしで記録可能'
          ]
        },
        {
          subtitle: '新開発のフルサイズ裏面照射積層CMOSセンサーや「カメラ内アップスケーリング」機能などによる高画質の実現',
          contents: [
            '高速性能と高感度性能を両立した新開発の有効画素数最大約2420万画素フルサイズ裏面照射積層CMOSセンサーを採用し、常用ISO感度100～102400（静止画撮影時）をカバーすることで幅広いシーンで高画質を実現',
            'ボディー内５軸手ブレ補正機構を搭載。また、レンズ内光学式手ブレ補正機構を搭載したRFレンズでは協調制御にも対応し、中心最大8.5段・周辺最大7.5段の手ブレ補正効果を発揮。さらに、広角特有の画面周辺でのブレを抑制する周辺協調制御にも対応',
            '「カメラ内アップスケーリング」機能では、アプリケーションなどを介することなく、撮影した画像（JPEG/HEIF）から最大約9600万画素の画像をカメラ内で生成し、トリミングをしたい場合などに有効'
          ]
        },
        {
          subtitle: 'プロの映像撮影を支える操作性と通信機能',
          contents: [
            '「EOS-1D X」シリーズと同等の防じん・防滴性能※1を備え、マグネシウム合金を外装に採用することで高い堅牢性を実現',
            '「EOS-1D X Mark III」および「EOS R3」と共通の大容量リチウムイオンバッテリー「LP-E19」を採用し、静止画撮影可能枚数約1,330枚を達成',
            '「EOS-1D X Mark III」比約78％となる約1,115g（CIPA基準）の軽量設計と操作性を両立',
            'EOSシリーズではじめてIEEE802.11ax規格相当に対応し、高速な無線通信が可能。更に6GHz（インフラストラクチャーのみ）に対応することで広い周波数帯が使用可能となり、快適な通信性能を実現',
            'EOSシリーズではじめて高速な有線LAN規格である2.5GBase-Tに対応し、安定した接続環境で大容量の静止画や動画を送信することが可能',
            '新アプリケーション「Content Transfer Professional（有償）※4」では、静止画・動画を5G／LTE対応のスマートフォン経由で場所を選ばずカメラ本体からファイルを受信し、FTPサーバーへ送信することや、スマートフォンでのタグ付けが可能'
          ]
        },
        {
          subtitle: '映像制作機器「Cinema EOS SYSTEM」で培った多彩な撮影性能や親和性の高い画作りを実現',
          contents: [
            'フルサイズ画角での6K／60Pの RAW動画や、4K／120PのMP4動画記録をカメラ内部記録で実現',
            '動画撮影中の静止画記録にも対応し、動画撮影を停止せずに最高約10コマ/秒の連続撮影を可能とすることで、機材の簡略化など業務効率の向上に寄与',
            '動画撮影時常用ISO32000を達成し、暗いシーンでの撮影にも対応。ノイズリダクションのアルゴリズムの改善により「EOS R3」よりもさらに高感度撮影時のノイズを低減',
            '4K／60PのMP4動画をSlot1へ、ファイルサイズの軽いProxy動画をSlot2へ同時に記録することが可能。軽量なProxy動画を使用することで、PCへの送付や編集を効率化',
            '「Cinema EOS SYSTEM」と共通の仕様として、用途に応じて画質調整が可能な「カスタムピクチャー」や「Canon Log 3」と比較し、中間から暗部の階調性が高い「Canon Log 2」を搭載し撮影用途に応じた最適な設定を選択することが可能',
            'デジタルシネマカメラ「EOS C400」（2024年9月発売予定）と共通のファイルシステムXF-HEVC S／XF-AVC Sを採用することで、ワークフローを共通化し、編集作業を効率化',
            'カメラから離れた位置でも動画の記録状況を把握することができるタリーランプを搭載',
            '動画撮影ボタンを押す３秒前もしくは５秒前から映像を記録することが可能な「プレ記録」機能を搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R1',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292230116'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', '', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['', '', '', ''],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      //popup
      imgs: [
        '/ec/images2/special/camera/feature/canon/eosr1/pic_01.jpg',
        '/ec/images2/special/camera/feature/canon/eosr1/pic_02.jpg',
        '/ec/images2/special/camera/feature/canon/eosr1/pic_03.jpg',
        '/ec/images2/special/camera/feature/canon/eosr1/pic_04.jpg',
        '/ec/images2/special/camera/feature/canon/eosr1/pic_05.jpg',
        '/ec/images2/special/camera/feature/canon/eosr1/pic_06.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
