
































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '7r4',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニーα7R IV | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニーα7R IV 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ソニー α7R III',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=%CE%B17RIII&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/d1c/dc4/57f/edf/af1/9fc/d49/8bb/a0a/949/057/c63/gt4/s/L.jpg',
        txt: 'ソニー α7R III',
        tradeInImg: '/ec/images2/special/camera/feature/sony/7r4/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },

      characteristicList: [
        {
          subtitle: '被写体を圧倒的な臨場感で写し出す高解像・高画質',
          contents: [
            '世界初(*１)有効約6100万画素の新開発35mmフルサイズ裏面照射型センサーExmor Rを搭載。α7R III比で約1.5倍の高画素を実現',
            '最新の画像処理エンジンBIONZ Xにより高感度・低ノイズ性能を維持したまま、高い解像度と豊かな階調性を、小型軽量ボディに凝縮',
            '手ブレ補正アルゴリズムを強化し最高5.5段のボディ内5軸手ブレ補正効果を実現。微細な振動にも敏感な高解像撮影をサポート'
          ]
        },
        {
          subtitle: '最高10コマ/秒の高速連写(*2)と、リアルタイムトラッキング、リアルタイム瞳AFなどの高いAF性能',
          contents: [
            'α7R III比で約1.5倍の高画素にもかかわらず、AF/AE追従 最高約10コマ/秒※2の高速連写を実現',
            '色や模様、被写体との距離などの空間情報を高速に検出し、複雑な動きやスピードに緩急のある動体を高精度に認識し追尾し続けるリアルタイムトラッキング搭載',
            '動画にも対応した高い検出精度・速度をもつリアルタイム瞳AF搭載。正面からうつむき顔になった場合や、振り向いた瞬間、逆光で顔が暗いシーンなど、ピント合わせが難しい状況でも、瞬時に瞳を検出し追従します'
          ]
        },
        {
          subtitle: 'さらに進化した操作性・堅牢性ですべてのカメラマンをサポート',
          contents: [
            'ピントの山をつかみやすい高精細な約576万ドット電子ファインダー「UXGA OLED Tru-Finder」で、微細なピント合わせを強力にサポートします',
            '重量レンズ装着時や長時間の使用でも、撮影者の負担になりにくいグリップ形状を実現。新開発の縦位置グリップ『VG-C4EM』にも対応',
            'α7R III同等のボディサイズで、フィールドでの使用に向けさらに強化した防塵防滴に配慮した設計を採用'
          ]
        },
        {
          subtitle: '高解像4K動画とプロフェッショナルの映像制作に対応する多彩な動画関連機能',
          contents: [
            '高解像度4K動画の本体内記録に対応。業務用映像制作に使用されているXAVC®を民生用に拡張したXAVC S®を採用。最大100Mbpsの高ビットレートで高画質記録が可能です',
            '動画撮影時のAF性能が大幅に進化し、動画撮影時に求められるスムーズかつ安定したAFが可能になりました',
            '業務用カメラで採用の高音質な音声伝送を実現するデジタルオーディオインターフェースを追加したMIシューを搭載'
          ]
        }
      ],
      topcharacteristicList: [
        {
          subtitle: '世界初（＊）有効約6100万画素、最高約10コマ/秒高速連写、高速・高精度AFを小型ボディに凝縮',
          contents: [
            '35mmフルサイズ初の有効約6100万画素裏面照射型CMOSセンサーを新開発。圧倒的な描写力をフルサイズミラーレスで実現',
            '有効約6100万画素の高解像で、AF/AE追随。最高約10コマ/秒の高速連写。人物にも動物にも動画にも対応する、進化した「リアルタイム瞳AF」搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニーα7R IV',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736108264'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736108264', '4548736075139', '4548736063945', '4960759901088'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736108264',
        '4548736064522',
        '4548736064539',
        '4548736105546',
        '4548736100688',
        '4548736100671',
        '4905524929379',
        '4548736100664',
        '4548736038431',
        '4548736019515',
        '4548736038400',
        '4905524935493'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
