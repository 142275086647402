import { render, staticRenderFns } from "./z180-600mmf56-63vr.vue?vue&type=template&id=2fa6f5ec&scoped=true&"
import script from "./z180-600mmf56-63vr.vue?vue&type=script&lang=ts&"
export * from "./z180-600mmf56-63vr.vue?vue&type=script&lang=ts&"
import style0 from "./z180-600mmf56-63vr.vue?vue&type=style&index=0&id=2fa6f5ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa6f5ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
