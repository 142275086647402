

















































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'xt3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'フジフイルム 新製品 X-T3 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '「Xシリーズ」が第4世代へと絶え間なく進化！フジフイルム X-T3！比較や、価格・スペック・お得な情報をチェック！最大60回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴スペック', href: '#point' },
        { naviItem: '外観画像', href: '#images2' }
      ],
      characteristicList: [
        {
          subtitle: '新開発の裏面照射型「X-TransTM CMOS 4」センサーと高速画像処理エンジン「X-Processor 4」を搭載',
          contents: [
            '新開発「X-Trans CMOS 4」センサーを小型軽量ボディに搭載。「Xシリーズ」史上最高画質と多彩な色彩表現を実現',
            '新開発「X-Processor 4」には4つのCPUを搭載。現行比約3倍の処理速度を実現',
            '｢FUJIFILM GFX 50S｣のみに搭載していた「カラークローム・エフェクト」機能を初搭載',
            'フィルムシミュレーションに「温黒調・冷黒調」が調整できる「モノクロ調整」機能を追加'
          ]
        },
        {
          subtitle: '像面位相差AFエリアを画面全域(約100%)に拡大',
          contents: [
            '暗いシーンでの撮影や動体撮影におけるAF性能の大幅な向上を実現',
            '位相差AFの低照度限界を従来の-1EVから-3EVへ拡張し、光量の少ない光源下や、夜間でも像面位相差AFでの撮影が可能',
            '動く人物への顔検出性能｢瞳AF｣機能を向上。動きのあるポートレート撮影においても正確に被写体を追従'
          ]
        },
        {
          subtitle: 'メカニカルシャッター約11コマ/秒、電子シャッター最速30コマ/秒の連写',
          contents: [
            '電子ビューファインダー(EVF)の性能を進化させ、動体追従性能を向上',
            'メカニカルシャッター約11コマ/秒の連写では、縦位置バッテリーグリップを不要とし、ボディ単体で可能',
            '電子シャッターでは、1660万画素(1.25倍クロップ)相当のフレーム内を最速30コマ/秒のブラックアウトフリー高速連写が可能'
          ]
        },
        {
          subtitle: 'プロの映像制作ニーズに応える充実の動画性能を実現',
          contents: [
            'ミラーレスデジタルカメラで世界初となる4K/60P 4:2:0 10bitのカメラ内SDカード記録に対応',
            'Anker製モバイルバッテリーを接続することで撮影枚数と動画記録時間を大幅に増やすことが可能',
            '動画撮影時の顔検出/瞳AFにも対応。動画での人物撮影もよりスムーズに行えます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラメンテナンスサービス'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-T3',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410378030'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410378030', '4547410325287', '4548736079625'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4547410423457',
        '4547410423471',
        '4547410378030',
        '4547410378153',
        '4547410378054',
        '4547410378177',
        '4547410378139',
        '4547410378122'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
