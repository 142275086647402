




































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';
import BackNumbers from '@/components/common/special/highly-recommended/back-numbers.vue';

export default Vue.extend({
  name: 'highly-recommended-2015-1015',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015,
    backnumbers: BackNumbers
  },
  setup: () => {
    document.title = 'ほったらかしでOK！ 珪藻土バスマット | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'お風呂上りに便利！超吸水力をそなえた新感覚バスマット「ヒロ・コーポレーション 珪藻土バスマット」')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'ほったらかしでOK！ 珪藻土バスマット |  スタッフのイチオシ',
          disabled: true
        }
      ],
      backnumberList: [
        {
          src: 'https://shop.kitamura.jp/ts_rsc/671/images/img_cokin-350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0925',
          productName: '超薄型カメラレンズフィルター「コッキン アルモニ …',
          txt: '究極の薄さと軽さを追求した「コッキン アルモニ」。超広角レンズ装着時のケラレを防止！種類は2種類。「偏光フィルター ア …【続き】'
        },
        {
          src: 'https://shop.kitamura.jp/ts_rsc/676/images/flower-power_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/1009',
          productName: '植物の声が聞こえる？Parrot FLOWER  …',
          txt: '植物に必要な要素を計測し、スマートフォンに送信してくれるセンサー。光度、温度、肥料、湿度を分析し、植物たちの声をあなた …【続き】'
        },
      ],
      mainProductJanCode: ['4547410437980', '4547410440386'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
