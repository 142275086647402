








import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'title-image',
  setup: () => {
    const state = reactive({
      onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0327/rec_gotop_on.gif',
      offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0327/rec_gotop_off.gif'
    });

    return {
      ...toRefs(state)
    };
  }
});
