/** 注文状況 */
export const DELIVERY_ORDER_STATUS: { [key: string]: string } = {
  '00': '注文受付',
  '02': '商品手配中',
  '03': '発送準備中',
  '05': '発送済',
  '10': 'お渡し準備完了',
  '11': 'お渡し済',
  '99': '注文取消し',
  '80': 'バッチキャンセル'
};
