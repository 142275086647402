






























































































































































































































































































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'z_9',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    naviBtn: NaviBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ニコン Z 9 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Z 9  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      advertisementValidFrom: '2021/10/29 11:30',
      advertisementValidTo: '2021/11/2 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2021/11/2 10:00',
      reserveValidTo: '2021/12/23 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2021/12/24 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowAdvertisement: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ニコン D850 ',
        href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759149336&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/a51/43f/e0d/aea/a76/36c/722/54d/48c/405/51o/1li/rbg/k/M.jpg',
        txt: 'ニコン Z 9 ',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z_9/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ニコン D850 ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759149336&pattern=1' },
          { name: 'ニコン D5 ボディ（CF-Type）', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759146212&pattern=1' },
          { name: 'ニコン D5 ボディ（XQD-Type）', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736058415&pattern=1' },
          { name: 'ニコン D6 ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759903402&pattern=1' },
          { name: 'ニコン Z 6 ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759901484&pattern=1' },
          { name: 'ニコン Z 7 ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759901088&pattern=1' },
          { name: 'ニコン Z 6II ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759905901&pattern=1' },
          { name: 'ニコン Z 7II ボディ', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759905697&pattern=1' }
        ]
      },
      releaseExpensiveTradeInList: {
        name: 'ニコン D850 ',
        href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4960759149336&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/a51/43f/e0d/aea/a76/36c/722/54d/48c/405/51o/1li/rbg/k/M.jpg',
        txt: 'ニコン Z 9 ',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z_9/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      commonCharacteristicList: [
        {
          subtitle: 'プロフェッショナルの厳しいニーズに応える進化したAF',
          contents: [
            'ニコン初となる120回/秒の高速AF演算やディープラーニング、Zマウントならではの高速AF情報通信により、高速連続撮影中でもランダムに動く被写体を正確に捉える高次元のAF追従性能を実現',
            '静止画でも動画でも世界最多19種類の被写体検出を実現。人物、犬、猫、鳥、車、バイク、自転車、列車、飛行機の検出が可能',
            'ニコンの一眼レフカメラで追尾性能が好評だった３D-トラッキングをZシリーズで初めて搭載。粘り強い追尾で最高の瞬間に集中できます'
          ]
        },
        {
          subtitle: '「決定的瞬間を捉えるための独自技術を搭載',
          contents: [
            '従来の電子ビューファインダーでは見られなかった瞬間や、一眼レフカメラのミラーアップによる像消失で見ることができなかった瞬間まで、そのすべてを表示するReal-LiveViewfinderを世界で初めて搭載',
            'ローリングシャッターによるひずみを極限まで抑制、積層型ニコンFXフォーマットCMOSセンサーはひずみを極限まで抑制する世界最速のスキャンレートを達成。メカシャッターの要らない構造を実現',
            'Z 7II比で約10倍の高速処理を実現する新画像処理エンジンEXPEED7を搭載。高性能なAF、約120コマ/秒のハイスピードフレームキャプチャ＋、8K UHD/30p内部記録、4K UHD/120pなど、先進的な機能を数多く提供',
            '明るい状況でも被写体をクリアーに確認できる世界最高輝度3000cd/m2対応のQuad-VGAパネル搭載',
            '画像モニターに4軸のチルト機構を採用。横位置では画面を上90度、下43度、縦位置では上90度、下23度傾けることができるため、多彩なアングルから撮影可能'
          ]
        },
        {
          subtitle: '進化した動画性能で静止画だけでなく動画でも史上最高機能・性能を実現',
          contents: [
            '効率的な放熱技術により世界最長2時間を超える内部記録で決定的瞬間を撮り逃さない最も実用的な8K UHD/30p動画撮影を実現',
            '4K UHD動画は、120pでも最大125分までの内部記録に対応し、［FXベースの動画フォーマット］フルフレームで撮影可能'
          ]
        }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'ニコン Z 9',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759906168'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759906168', '4548736130678', '4549292181661', '4960759903402'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736134119'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const advertisementValidFrom = dayjs(state.advertisementValidFrom);
      console.log(advertisementValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        console.log(formatToday);

        if (formatToday.isBefore(advertisementValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.advertisementValidFrom, state.advertisementValidTo)) {
          state.isShowAdvertisement = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
