





















































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'gr3x',
  components: {
    breadcrumbs: Breadcrumbs,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    characteristic: Characteristic,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    topTitleImg: TopTitleImg,
    watchInVideo: WatchInVideo,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'リコー GR IIIx | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'リコー GR IIIx ご予約受付中、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');
    const state = reactive({
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '新開発の高解像GR LENS',
          contents: [
            '高性能化と薄型化を両立した新開発標準画角GRレンズを搭載',
            '35ミリ判換算で40mm相当の標準画角の為、自然な遠近感の新たな視野でのスナップ撮影に対応',
            '非球面レンズ2枚を最適配置した新開発の光学系によりディストーションや色収差を極限に近いレベルまで抑制、高い解像力を実現',
            '最短12cmでのマクロ撮影では、標準画角の焦点距離と相まって背景のボケ味を生かした自然な遠近感での撮影が可能'
          ]
        },
        {
          subtitle: '解像感と階調再現性に優れた高画質を実現',
          contents: [
            '高彩度かつ柔らかい階調表現を特長とする「ノスタルジックネガ」を含む全19種類の「フィルムシミュレーション」を搭載。被写体やシーンに合わせて、写真フィルムを選ぶ感覚で多彩な表現が可能',
            '有効画素数は約2424万画素と超高精細で、解像感と階調再現性に優れた高画質を実現',
            'センサーからの画像情報を最適化する独自のアクセラレーターユニットを搭載、最高ISO感度102400の優れた高感度性能を実現'
          ]
        },
        {
          subtitle: '高速高精度ハイブリッドAF',
          contents: [
            'オートフォーカスには、コントラストAFと像面位相差AFによるハイブリッド方式を採用',
            'AF時に人物の顔および瞳検出をおこない、ピントを合わせた位置にAF枠を表示することが可能',
            '複数の人物の顔を検出した場合は、自動でメインの被写体とサブの被写体を判別して枠を表示、タッチパネルでの簡単操作でメイン被写体の切り替えも可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'http://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラメンテナンスサービス'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'リコー GR IIIx',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549212303739'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549212303739', '4549212300202', '4547410423433'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
