









































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'story-after-taking',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '｢写真撮ったあと物語｣_写真整理・贈り方・飾り方に困っている3人の物語 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '写真を撮ったあと｢写真整理ができないA男さん｣｢友達にどうやって贈るか悩むB子さん｣｢写真をいろいろ活用できないか考えるC氏｣の悩みを、物語風にご紹介。'
      );
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '｢写真撮ったあと物語｣_写真整理・贈り方・飾り方に困っている3人の物語',
          disabled: true
        }
      ],
      naviBtnList: [
        { btntxt: '写真を撮ったあと､整理ができないA男\nさんの物語', href: '#a01' },
        { btntxt: '写真を撮ったあと､ 友達にどうやって贈\nるか悩むB子さんの物語', href: '#a02' },
        { btntxt: '写真を撮ったあと､ 写真をいろいろ活用\nできないか考えるC氏の物語', href: '#a03' }
      ],
      photoBookList: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/story_after_taking/2-2.jpg',
          alt: 'フォト本 レイアウト',
          txt: '思い出写真が大きく残せます'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/story_after_taking/2-3.jpg',
          alt: 'フォト本 文字入れ',
          txt: '文字も入ります'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/story_after_taking/2-4.jpg',
          alt: 'フォト本 梱包',
          txt: 'そのまま郵送できる梱包です'
        }
      ],
      boxList: [
        {
          txt1: '写真プリントも、カメラのキタムラなら\n高品質な仕上がり',
          txt2: '専門店ならではの、早くて高品質な写真プリント。\n店舗なら、最短10分仕上げ。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/story_after_taking/2-5.jpg',
          alt: '高品質な仕上がり',
          href: 'https://www.kitamura-print.com/digicameprint/',
          btntxt: '写真プリントの詳細はコチラ'
        },
        {
          txt1: '写真を入れる袋によっても､ 印象が変わります。',
          txt2: 'レトロな雰囲気のデザイン写真袋や、和紙を使ったもの、かわいい写真袋など、種類が豊富。\n写真を受け取る印象も変わります。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/story_after_taking/2-6.jpg',
          alt: '写真袋',
          href:
            'https://shop.kitamura.jp/ec/list?narrow18=0&keyword=%E3%83%8F%E3%82%AF%E3%83%90%20%E5%86%99%E7%9C%9F%E8%A2%8B&category=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=%E3%83%8F%E3%82%AF%E3%83%90%20%E5%86%99%E7%9C%9F%E8%A2%8B&x=0&y=0',
          btntxt: 'ハクバ 写真袋はコチラ'
        }
      ]
    });

    return {
      ...toRefs(state)
    };
  }
});
