/**
 * Windowのinterfaceにチャットボットのプロパティを追加する
 */
declare global {
  interface Window {
    ul_widget: any;
  }
}

// ChatBot関連サービス
const ChatBotService = {
  /**
   * チャットボットを表示する
   */
  show(): void {
    if (window.ul_widget) {
      window.ul_widget('show');
    }
  },
  /**
   * チャットボットを非表示する
   */
  hide(): void {
    if (window.ul_widget) {
      window.ul_widget('hide');
    }
  }
};

export default ChatBotService;
