

















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'expensivePurchasesAndShoppingCreditImage',
  components: {},
  props: {
    expensivePurchaseHref: {
      type: String,
      required: true
    },
    expensivePurchaseSrc: {
      type: String,
      required: true
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state)
    };
  }
});
