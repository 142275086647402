





























































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'highly-recommended-2015-0327',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015
  },
  setup: () => {
    document.title = '三脚専門メーカーが作る『本格派自撮り棒』ベルボン×コールマンCVSS-6 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '三脚専門メーカーが作る『本格派自撮り棒』ベルボン×コールマンCVSS-6')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '三脚専門メーカーが作る『本格派自撮り棒』ベルボン×コールマンCVSS-6 | スタッフのイチオシ',
          disabled: true
        }
      ],
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
