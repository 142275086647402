
























































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';

export default Vue.extend({
  name: 'iphone',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter
  },
  setup: (props, context) => {
    document.title = 'iPhone 14/ iPhone 14 Plus iPhone 14 Pro / iPhone 14 Pro Max専用アクセサリー紹介| カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'iPhone 14/ iPhone 14 Plus iPhone 14 Pro / iPhone 14 Pro Max専用アクセサリー。')

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'iPhone 14/ iPhone 14 Plus iPhone 14 Pro / iPhone 14 Pro Max専用アクセサリー紹介',
          disabled: true
        }
      ],
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
