


















































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: '5dmk4',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    kitamuraService: KitamuraService,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    priceAndPurchase: PriceAndPurchase,
    appearanceImage: AppearanceImage,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'Canon EOS 5D Mark IV キヤノン フルサイズ一眼レフカメラ| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'Canon EOS 5D Mark IV キヤノン フルサイズ一眼レフカメラ！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '高画素・高速連写・高感度などの基本性能がバランス良く向上',
          contents: [
            '有効画素数約3040万画素35mmフルサイズCMOSセンサーおよび映像エンジン「DIGIC 6+」の搭載により、常用ISO感度ISO100～32000を実現',
            '新開発ミラー振動制御システムの採用により、最高約7コマ／秒の高速連写を実現',
            '「デュアルピクセルCMOS AF」の搭載により、ライブビュー撮影時にも高速で追従性に優れたAFを実現'
          ]
        },
        {
          subtitle: 'フラッグシップ機と同等の優れた機能を搭載',
          contents: [
            '「EOS-1D X Mark II」と同じ、「61点高密度レティクルAF II」を搭載。左右の測距エリアでは縦方向に約24％、中央の測距エリアでは縦方向に約8.6％測距エリアを拡大',
            '61点全点がF8光束に対応しており、エクステンダーを使用した超望遠撮影時に、レンズの開放絞り数値がF8までの明るさとなる場合でも、全測距点でAFが可能',
            '中央測距点ではEV-3の低輝度限界を達成し、暗い環境下でも高精度なAF合焦を実現'
          ]
        },
        {
          subtitle: '4K（4096×2160）動画を中心とした動画撮影機能の強化',
          contents: [
            '4Kでの動画撮影が可能。またデジタルシネマの標準規格の動画撮影も可能',
            '動画撮影時にもスムーズで滑らかなAFを可能とする「デュアルピクセルCMOS AF」とタッチパネル対応の液晶モニターにより、AFフレームを素早く選択',
            '4Kで記録した動画の1フレームを約880万画素の静止画として切り出し、JPEG画像として保存する「4Kフレームキャプチャー」機能を搭載',
            '「タイムラプス動画撮影」機能を搭載しており、一定間隔で撮影した静止画を自動でつなぎ合わせて動画を作成することが可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/general/highly_recommended/2020/4722/',
          img: 'https://shopimg.kitamura.jp/images/banner/7619.png',
          alt: 'スタッフイチオシ！キヤノン RF70-200mm F2.8 L IS USM'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'Canon EOS 5D Mark IV キヤノン フルサイズ一眼レフカメラ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292075748'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292075748', '4960999810089', '4960759143532'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4549292075748',
        '4549292075854',
        '4549292075816',
        '4957792125335',
        '4549292037722',
        '4549292063615',
        '4549292008296',
        '4549292075946'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
