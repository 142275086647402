




















































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
export default Vue.extend({
  name: 'deliver',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (props, context) => {
    document.title = '商品のお届けまでの流れ | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '商品のお届けまでの流れ | 全国900店舗のカメラ専門店カメラのキタムラのショッピングサイトにお任せください。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '商品のお届けまでの流れ',
          disabled: true
        }
      ],
      naviBtn: [
        { src: '/ec/images2/special/page/deliver/pc/btn01_off.png', href: '#a01' },
        { src: '/ec/images2/special/page/deliver/pc/btn02_off.png', href: '#a02' },
        { src: '/ec/images2/special/page/deliver/pc/btn03_off.png', href: '#a03' }
      ]
    });

    //mouseover時の処理
    const changeImg = (target: number) => {
      state.naviBtn[target].src = `/ec/images2/special/page/deliver/pc/btn0${target + 1}_on.png`;
    };
    //mouseleave時の処理
    const returnImg = (target: number) => {
      state.naviBtn[target].src = `/ec/images2/special/page/deliver/pc/btn0${target + 1}_off.png`;
    };

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      changeImg,
      returnImg
    };
  }
});
