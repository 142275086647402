// 中古在庫の商品：状態
export const USED_STATUS = [
  {
    text: 'C',
    value: '1'
  },
  {
    text: 'B',
    value: '2'
  },
  {
    text: 'AB',
    value: '3'
  },
  {
    text: 'A',
    value: '4'
  },
  {
    text: 'AA',
    value: '5'
  }
];
