















































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '7c',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    kitamuraService: KitamuraService,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー α7C | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー α7C 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ソニー α6500 ボディ [ILCE-6500]',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&goodsname=4548736021662&pattern=1&_ga=2.108369346.1712661879.1629071621-279164427.1627285160',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/b77/97e/fb1/943/797/42b/d6f/e99/57a/4e3/5cj/f5f/d4d/n/L.jpg',
        txt: 'ソニー α7C ',
        tradeInImg: '',
        tradeInProducts: []
      },
      characteristicList: [
        {
          subtitle: '「α7」の高画質撮影性能を手軽に持ち運べる世界最小・最軽量ボディに凝縮',
          contents: [
            '光学式5軸ボディ内手ブレ補正機構やシャッターユニットを新開発。APS-Cセンサー搭載モデル「α6600」とほぼ同等のサイズを実現',
            '有効約2420万画素35mmフルサイズ裏面照射型CMOSイメージセンサー「Exmor R」と、高速処理能力を備えた画像処理エンジン「BIONZ X」を搭載。ノイズの少ない高感度撮影も可能',
            '安心して長時間撮影時が可能な業界最大のスタミナ性能を実現。ビューファインダー使用時約680枚、LCDモニター使用時約740枚の撮影が可能'
          ]
        },
        {
          subtitle: '瞬間を逃さず撮影できる「AI」を活用した高性能AFと最高約10コマ/秒のAF/AE追従高速連写',
          contents: [
            '「AI」を活用した物体認識アルゴリズムにより、色や模様、被写体との距離などの空間情報を高速処理して動きのある被写体をカメラ任せで高精度に認識',
            '人物や動物の瞳にフォーカスを合わせ続けられる、進化したリアルタイム瞳AFにより、動きのある被写体でも、高速・高精度に瞳を捕捉',
            '撮像エリアの約93%をカバーする範囲に693点の像面位相差検出AFセンサーを配置したほか、コントラスト検出AF枠を425点に多分割化し、高い検出精度を実現'
          ]
        },
        {
          subtitle: '高解像4K動画記録が可能な充実の動画撮影機能',
          contents: [
            'フルサイズ領域で画素加算のない全画素読み出しにより、4K映像に必要な画素数の約2.4倍（6K相当）の情報量を活用して4K映像を出力。より解像感の高い映像表現が可能',
            'AFトランジション速度を7段階に、AF乗り移り感度をそれぞれ5段階に設定可能。撮影シーンや撮影者の表現・意図に合わせた細かなフォーカス表現を実現',
            '横方向に開くバリアングル液晶モニターの搭載やMOVIEボタンのボディ天面への配置など、動画に撮影時にも快適に撮影できる様進化'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α7C',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736121614', '4548736121621', '4548736121676', '4548736121683'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736121614', '4960759904485', '4548736079625', '4548736108417'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736121614',
        '4548736121621',
        '4548736121676',
        '4548736121683',
        '4548736064522',
        '4548736064539',
        '4548736109520',
        '4548736121249',
        '4548736100664',
        '4548736064621',
        '4548736120624',
        '4548736067714'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
