
































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'wearable',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ウェアラブル特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ウェアラブル特集！人気のウェアラブルカメラや腕時計型スマートウォッチをまとめました。キタムラネットショップなら全国で受け取り可能です'
      );

    const state = reactive({
      smartWatchImgList: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/wearable/img_b.gif',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/wearable/img_wa.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/wearable/img_wb.jpg'
      ],
      smartWatchImg2List: [
        {
          name: 'ガーミン ≫',
          href: '/ec/ct/E019/028/039/',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/wearable/gamin.jpg',
          alt: 'ガーミン'
        },
        {
          name: 'フィットビット ≫',
          href: '/ec/ct/E019/028/043/',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/wearable/fitbit.jpg',
          alt: 'フィットビット'
        }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/waterproof',
          img: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          alt: '防水カメラ・ウェアラブルカメラ特集'
        },
        {
          href: '/ec/special/camera/feature',
          img: 'https://shop.kitamura.jp/images/banner/1341.jpg',
          alt: '話題の新製品'
        },
        {
          href: '/ec/special/camera/bag',
          img: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          alt: 'カメラバッグ・カメラケース特集'
        },
        {
          href: '/ec/special/general/highly_recommended/2015/0821',
          img: 'https://shop.kitamura.jp/images/banner/3576.png',
          alt: 'COOLSHOT特集'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント',
          target: 'blank'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'ウェアラブル特集 カメラ・スマートウォッチ',
          disabled: true
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
