/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import {
  ProductDetail,
  UsedProductDetail,
  RecommendProducts,
  UsedProductsSummary,
  MemberProduct,
  SimpleProduct,
  PostReview,
  ProductCartOptionParamGroup
} from '@/types/product';
import ApiService from './api.service';
import { generateImagePath } from './utils';
import { CART_ERROR_LIST } from '@/constants/cart-error';
import { ReviewList } from '@/types/review-list';
import AuthService from './auth.service';
import GtmService from './gtm.service';

/**
 * Windowのinterfaceにレコメンド関連のプロパティを追加する
 */
declare global {
  interface Window {
    // ac-recommend
    sendAcTag: any;
    newAcRecommend: any;
  }
}

/** 最大リトライ回数 */
const RECOMMEND_MAX_RETRY_COUNT = 10;

/** 待機時間（ms） */
const RECOMMEND_WAITING_TIME = 1000;

const ProductService = {
  // 待機処理のID
  _intervalId: undefined as number | undefined,

  // リトライ回数
  _retryCount: 0,

  /**
   * レコメンドモジュールの読み込みが完了しているかをチェックする。
   *
   * @returns レコメンドモジュールの読み込みが完了している場合はtrue
   */
  isRecommendModuleLoaded(): boolean {
    return !!window.sendAcTag;
  },

  /**
   * レコメンドモジュールが読み込まれているかをチェックする。
   *
   * @param resolve Promiseの成功処理
   */
  _isLoadedRecommendForPromise(resolve: (value: boolean) => void): void {
    if (this.isRecommendModuleLoaded() || !this._intervalId) {
      clearInterval(this._intervalId);
      resolve(true);
    } else if (this._retryCount > RECOMMEND_MAX_RETRY_COUNT) {
      // リトライ回数を超えたら待機状態から開放する
      resolve(false);
    }

    this._retryCount++;
  },

  /**
   * レコメンドモジュールが読み込まれるまで待機する。
   *
   * @returns レコメンドモジュールが読み込まれたら返却する
   */
  waitForRecommendInitialized(): Promise<boolean> {
    this._retryCount = 0;
    clearInterval(this._intervalId);
    return new Promise<boolean>((resolve) => {
      this._intervalId = setInterval(this._isLoadedRecommendForPromise.bind(this), RECOMMEND_WAITING_TIME, resolve);
    });
  },

  /**
   * レコメンドの商品詳細一覧を取得する（clickイベント発火のため。acRecommendごとレスポンス）
   * - レコメンドAPIで商品一覧を取得
   * - 商品一覧からJANコードのみ一覧で取得する
   * - JANコードをもとに商品詳細APIで商品詳細取得
   * @param customerId 顧客ID
   * @param recommendId レコメンドID
   * @param itemId 商品ID
   * @param cateCode カートコード
   */
  async searchRecommendProducts(param: {
    recommendId: string;
    itemId?: string;
    cateCode?: {
      cat01?: string;
      cat02?: string;
      cat03?: string;
    };
  }): Promise<{
    instance: any;
    prodDetailList: {
      itemCount: number;
      cartMaxCount: number;
      items: Array<SimpleProduct>;
    };
  }> {
    let prodDetailList: {
      itemCount: number;
      cartMaxCount: number;
      items: Array<SimpleProduct>;
    } = {
      itemCount: 0,
      cartMaxCount: 0,
      items: []
    };
    let recResult: RecommendProducts = {
      rid: '0'
    };

    // レコメンドAPIで扱う設定
    const recommendProperties = {
      measure_account_id: 35007,
      rid: param.recommendId,
      customer_id: '35007',
      item_id: param.itemId ?? '',
      obj_name: `acRecommend_${param.recommendId}`
    };
    const sendAcTag = window.sendAcTag;
    const recommend = new window.newAcRecommend(sendAcTag, recommendProperties);

    const recommendParam = {
      num: 20,
      // フィルタ情報
      filter: {
        cat01: param.cateCode?.cat01 ?? '',
        cat02: param.cateCode?.cat02 ?? '',
        cat03: param.cateCode?.cat03 ?? ''
      }
    };
    const drawFunc = (_objName: any, itemRes: RecommendProducts) => {
      recResult = itemRes;
      return;
    };
    // レコメンドAPIで商品一覧を取得
    await recommend.fetchItems(recommendParam, '', drawFunc, '');

    //  商品一覧からJANコードのみ一覧で取得する
    if (recResult.item_list && recResult.item_list.length > 0) {
      const janList = recResult.item_list.map((item: { item_id: string }) => item.item_id);

      // JANコードをもとに商品詳細APIで商品詳細取得
      if (janList.length > 0) {
        prodDetailList = await this.fetchProducts(janList, true);

        // 画像パス生成
        if (prodDetailList.items) {
          prodDetailList.items.forEach((product) => {
            product.images.forEach((image) => {
              image.imagePath = generateImagePath(image.imagePath);
            });
          });
        }
      }
    }

    // 設定したinstanceで._clickを叩かないと計測できないそうなので、レスポンスに含める
    return {
      instance: recommend,
      prodDetailList
    };
  },

  /**
   * JANコード配列から、商品詳細を取得する
   * @param janCodes 取得したい商品のJANコード配列
   * @param isSimple 簡易取得かどうか
   * @param categoryCode カテゴリコード
   * @param isMemberDisplay 会員向けかどうか
   * @param secretId 限定販売ID
   */
  async fetchProducts(
    janCodes: Array<string>,
    isSimple: boolean,
    categoryCode?: string,
    isMemberDisplay?: boolean,
    secretId?: string
  ): Promise<{
    itemCount: number;
    cartMaxCount: number;
    items: Array<ProductDetail>;
  }> {
    const isLoggedIn = Vue.prototype.$store.authorizer.isLoggedIn;
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + `product?login=${isLoggedIn}`;
    let response = {} as {
      itemCount: number;
      cartMaxCount: number;
      items: Array<ProductDetail>;
    };
    response = await ApiService.get(url, {
      params: { janCodes, categoryCode, isMemberDisplay, secretId, isSimple }
    });

    // 画像パス変換
    response.items.forEach((product) => {
      product.images.forEach((image) => {
        image.imagePath = generateImagePath(image.imagePath);
      });
      // 簡易取得でないとき
      if (!isSimple) {
        product.sampleImages.forEach((image) => {
          image.sampleImageOrgPath = generateImagePath(image.sampleImageOrgPath);
          image.sampleImagePath = generateImagePath(image.sampleImagePath);
        });
        product.relatedItems.forEach((item) => {
          item.imagePath = generateImagePath(item.imagePath);
        });
      }
    });

    return response;
  },

  /**
   * 中古商品コードから、中古の商品詳細を取得する
   * @param itemCode 中古商品コード
   */
  async fetchUsedProducts(itemCode: string): Promise<UsedProductDetail> {
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + 'used_product';
    let response = {} as UsedProductDetail;
    response = await ApiService.get(url, {
      params: { itemCode }
    });

    // 画像パス変換
    response.imageUrl1 = generateImagePath(response.imageUrl1);
    if (response.imageUrl2) response.imageUrl2 = generateImagePath(response.imageUrl2);
    if (response.imageUrl3) response.imageUrl3 = generateImagePath(response.imageUrl3);

    return response;
  },

  /**
   * 新品用中古情報を取得する関数
   * 新品商品の中古情報を取得
   * @param janCodes 中古情報を取得したい新品商品のJANコード配列
   */
  async searchUsedProductsSummary(janCodes: Array<string>): Promise<{ itemInfo: Array<UsedProductsSummary> }> {
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + 'used_summary';
    const response = await ApiService.get(url, {
      params: { janCodes }
    });

    return response;
  },

  /**
   * 店舗情報を取得する関数
   * @param shopCode 店舗コード配列
   */
  async searchShop(shopCode: string): Promise<any> {
    const url = process.env.VUE_APP_API_SHOP_BASE_URL + 'shop/shopsByCodes';
    const response = await ApiService.get(
      url,
      {
        params: { shopCode }
      },
      false
    );
    return response;
  },

  /**
   * カート投入
   * @param janCode 商品コード
   * @param isChuko 中古かどうか
   * @param option オプション
   * @param displayPrice 表示価格
   * @param secretId 限定販売ID
   * @param count 数量
   */
  async addCart(
    janCode: string,
    isChuko: boolean,
    option: ProductCartOptionParamGroup | null,
    displayPrice: number,
    count = 1,
    secretId?: number
  ): Promise<any> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'cart';

    const config = {
      janCode,
      isChuko,
      ...(option || {}),
      displayPrice,
      secretId,
      count
    };

    try {
      const response = await ApiService.post(url, config);

      // ヘッダーに表示するカート内商品数を更新するため、セッション情報を取り直す。
      AuthService.checkLoginStatus();

      // GTMイベントを発火する
      const dataLayer = {
        jan_cd: [janCode] // eslint-disable-line @typescript-eslint/camelcase
      };
      GtmService.trackEvent('cart-add', dataLayer);

      return response;
    } catch (error) {
      console.error((error as any).message);

      // タイムアウトエラー
      if ((error as any).code === 'ECONNABORTED') {
        Vue.prototype.$store.errorStore.errorMessage = '一部のシステムがご利用いただけない可能性があります。しばらく待ってからご利用ください（cart）';
      } else {
        // 5xxエラー
        const errorDetails = (error as any).response.data?.details || [];
        const errorCode = errorDetails.length > 0 ? errorDetails[0].errorCode : '';
        const errorCodeMessage = errorCode ? '：' + errorCode : '';
        Vue.prototype.$store.errorStore.errorMessage =
          CART_ERROR_LIST.find((item) => item.code === errorCode)?.message ||
          '一部のシステムがご利用いただけない可能性があります。しばらく待ってからご利用ください（cart' + errorCodeMessage + '）';
      }

      throw error;
    }
  },

  /**
   * 会員商品の取得
   * @param janCode JANコード
   * @returns 会員商品
   */
  async fetchMemberProduct(janCode: string): Promise<MemberProduct> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'member_product';
    const configs = { params: { janCode } };
    const response = await ApiService.get(url, configs);

    return response;
  },

  /**
   * お気に入り商品登録
   * @param janCode 商品コード
   * @param noticePrice 安くなった通知
   * @param noticeUsed 同型商品の通知
   * @param isDelete 削除区分
   */
  async registerFavorite(janCode: string, noticePrice: boolean, noticeUsed: boolean, isDelete: boolean): Promise<any> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'add_favorite';
    const body = {
      janCode,
      noticePrice,
      noticeUsed,
      isDelete
    };
    const response = await ApiService.post(url, body, {}, true, { apiName: 'favorite' });

    return response;
  },

  /**
   * 持っている商品登録
   * @param janCode 持っている商品のJANコード
   */
  async registerHaving(janCode: string, isDelete?: boolean): Promise<any> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'add_owned';
    const response = await ApiService.post(url, { janCode, isDelete }, {}, true, { apiName: 'have' });

    return response;
  },

  /**
   * フラグを含んでいるか確認する
   * @param productFlagList 商品の各種フラグリスト
   * @param flagNum 含んでいるかチェックしたいフラグ
   * @returns true：含んでいる false：含んでいない
   */
  includeFlag(productFlagList: Array<number>, flagNum: number): boolean {
    if (productFlagList && productFlagList.some((flag: number) => flag === flagNum)) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 「##」「$$」で囲まれている該当箇所をreplace
   * @param isUsed 中古か否か
   * @param text 対象文字列
   * @returns 該当箇所を削除した文字列
   */
  trimText(isUsed: boolean, text: string | undefined): string {
    // APIレスポンスが無いことがあるので制御
    if (typeof text !== 'string') return '';

    if (isUsed) {
      return text.replace(/##.*?\$\$/g, '');
    } else {
      return text.replace(/##(.*?)\$\$/g, '$1');
    }
  },

  /**
   * 一般レビュー一覧の取得
   * @param janCode 商品コード
   * @param pageNo ページ数
   * @param sort ソート １：投稿の早い順, ２：遅い順
   */
  async searchReviews(janCode: string, pageNo?: number, sort = 2): Promise<ReviewList> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'review';
    const response = await ApiService.get(url, {
      params: {
        isMyPage: false,
        janCode,
        pageNo,
        sort
      }
    });

    return response;
  },

  /**
   * レビューの取得
   * @param reviewId レビューID
   */
  async fetchReview(reviewId: number): Promise<ReviewList> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'review';
    const response = await ApiService.get(url, {
      params: {
        reviewId
      }
    });

    return response;
  },

  /**
   * レビューを投稿
   * @param janCode 商品コード
   * @param review レビュー内容
   */
  async postReview(janCode: string, review: PostReview = {} as PostReview): Promise<{ message?: string }> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'review';
    const body = {
      mode: '1',
      janCode,
      ...review
    };
    const response = await ApiService.post(url, body, {}, true, { apiName: 'review' });

    return response;
  },

  /**
   * レビューを更新
   * @param reviewId レビューID
   * @param janCode 商品コード
   * @param review レビュー内容
   */
  async updateReview(reviewId: number, janCode: string, review: PostReview = {} as PostReview): Promise<{ message?: string }> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'review';
    const body = {
      mode: '2',
      reviewId,
      janCode,
      ...review
    };
    const response = await ApiService.post(url, body, {}, true, { apiName: 'review' });

    return response;
  },

  /**
   * レビューを削除
   * @param reviewId レビューID
   * @param janCode 商品コード
   */
  async deleteReview(reviewId: number, janCode: string): Promise<{ message?: string }> {
    const url = process.env.VUE_APP_API_COMMON_BASE_URL + 'review';
    const body = {
      mode: '3',
      reviewId,
      janCode
    };
    const response = await ApiService.post(url, body, {}, true, { apiName: 'review' });

    return response;
  }
};

export default ProductService;
