





























import Vue from 'vue';

export default Vue.extend({
  name: 'content',
  props: {
    // サブタイトル
    subtitle: {
      type: String,
      required: true
    },
    // good icon を表示するかどうか
    isGoodIcon: {
      type: Boolean,
      default: true
    },
    // テキスト
    txt: {
      type: String,
      default: ''
    },
    // テキスト２
    txt2: {
      type: String,
      default: ''
    },
    // スモールテキスト
    smalltxt: {
      type: String,
      default: ''
    },
    // 画像を一つだけ渡したい時
    image: {
      type: String,
      default: null
    },
    // 画像を複数渡したい時
    images: {
      type: Array,
      default: null
    },
    // 画像のmax width
    maxWidth: {
      type: Number,
      default: 840
    },
    // youtube テキスト
    youtubetxt: {
      type: String,
      default: ''
    },
    // youtube link
    youtubeLink: {
      type: String,
      default: ''
    },
    sm: {
      type: Number,
      default: 6
    },
    order: {
      type: Boolean,
      default: true
    }
  },
});
