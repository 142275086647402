




































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'price-purchase',
  components: {},
  props: {
    // 配列で表示したい時
    mainProductList: {
      type: Array,
      required: false
    },
    // 配列の中のどれかを表示したい時
    mainProduct: {
      type: Object,
      required: false
    },
    // ボタンの色を指定
    btnColor: {
      type: String,
      default: 'rgba(255, 132, 0, 1)'
    },
    // 価格の上にテキストを表示したい時
    txt: {
      type: String,
      default: ''
    },
    // ボタンのテキストを指定
    btntxt: {
      type: String,
      default: 'ご購入はこちら'
    },
    // 配列なのかオブジェクトなのかの指定（デフォルトは配列)
    type: {
      type: Number,
      default: 1
    }
  },
  setup: () => {
    const state = reactive({
    });

    return {
      formatPrice,
      ...toRefs(state)
    };
  }
});
