










































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'k-3mk3',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    naviBtn: NaviBtn,
    subText: SubText,
    characteristic: Characteristic,
    splitContent: SplitContent,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    topTitleImg: TopTitleImg,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    shasha: ShaSha,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ペンタックス K-3 Mark III | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ペンタックス K-3 Mark III 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '視野率約100％・ファインダー倍率約1.05倍の光学ファインダー搭載一眼レフ',
          contents: [
            '「PENTAX K-3II」と比較して約10%明るさがアップした新開発の高屈折率ガラスペンタプリズムを採用。アイレリーフを確保しつつ、フルサイズ機に匹敵する大きな視野を実現',
            'フォーカシングスクリーンには、マニュアルフォーカス時のピント合わせのしやすさで定評あるナチュラルブライトマットIIIを採用。ボケ味の再現性にも優れた高品位な見え味を実現',
            'さまざまな撮影情報を画面上に表示させることが可能な透過型ファインダー内表示を採用し、さまざまな撮影情報がファインダーで確認可能'
          ]
        },
        {
          subtitle: '有効約2573万画素の高精細画像と最高ISO感度160万の超高感度性能を実現',
          contents: [
            'ローパスフィルターレス仕様の裏面照射型CMOSイメージセンサーを新採用。APS-Cサイズ相当で有効約2573万画素の高精細な画像を実現',
            '新開発の高性能画像処理エンジン「PRIMEV」「アクセラレーターユニットII」を搭載。低感度域から超高感度域まで高い解像感を維持しながら、徹底的にノイズを抑えた高画質画像を実現',
            '高感度ノイズの低減処理性能を飛躍的に高めることで、最高ISO感度160万の超高感度性能を実現し、撮影可能領域を大幅に拡大'
          ]
        },
        {
          subtitle: '5軸・5.5段の高性能手ぶれ補正機構「SR II」採用。装着可能な全てのレンズで手ぶれを効果的に補正',
          contents: [
            '5軸・5.5段の高性能手ぶれ補正機構「SR II」採用。装着可能な全てのレンズで手ぶれを効果的に補正',
            '真の解像力を引き出した超高精細な画像が得られる、イメージセンサーユニットを1画素ずつ微細にずらしながら4回撮影した画像を1枚の画像に合成する超解像技術「リアル・レゾリューション・システム」を搭載',
            '光学ローパスフィルターと同様のモアレ軽減効果が得られる「ローパスセレクター」機能には効果の「ON/OFF」や「強弱」の選択が可能。偽色・モアレの発生を大幅に軽減可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/feature/',
          disabled: false
        },
        {
          path: 'ペンタックス K-3 Mark III',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549212302336', '4549212302565', '4549212303043'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4549212302336',
        '4549212302565',
        '4549212303043',
        '4549212302664',
        '4549212302527',
        '4549212214042',
        '4549212213694',
        '4961333057955',
        '4549212276804',
        '4549212301254',
        '4549212296642',
        '4549212273728',
        '4549212273742',
        '4549212274756',
        '4549212274749',
        '4523052022722'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      // ↓ ---- 比較 ----
      // 取得するJancode
      comparisonJanCodeList: ['4549212302336', '4549212299896', '4960759146441', '4549292138313'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;

        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 比較
        state.comparisonDetailList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
