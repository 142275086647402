
























































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';
import BackNumbers from '@/components/common/special/highly-recommended/back-numbers.vue';

export default Vue.extend({
  name: 'highly-recommended-2015-0529',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015,
    backnumbers: BackNumbers
  },
  setup: () => {
    document.title = 'LEDライト搭載 見せる防湿庫 東洋リビングLD-120 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'ドア連動型の18連LEDライトを搭載。カメラを守るだけでなく、みせる防湿庫が登場。眺めて楽しく、出し入れしやすいプレミアムモデル。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'LEDライト搭載 見せる防湿庫 東洋リビングLD-120 | スタッフのイチオシ',
          disabled: true
        }
      ],
      contentList: [
        {
          title: '「収納庫」から「ショーケース」へみせる防湿庫への発想の転換',
          txt: 'カメラ愛好家にとっては、一家に一台の必需品、防湿庫。カメラ・レンズを、最大の敵、カビと湿気から守る重要なアイテムです。\n\n防湿庫といえば、あくまで収納庫、地味で目立たず、外観のデザインにはあまりこだわらないものがほとんどでした。 そこに近年、ミラーレス一眼のブームなどを受け、インテリアに馴染む、ホワイトやカラフルなモデルが発売され、賑わいをみせてきました。\n\n隠すものから見せるものへ、防湿庫の進化をさらに推し進めたのがLD-120です。',
        },
        {
          title: '照明技術の進化で実現したドア連動型 18連LEDライト',
          txt: 'なんとなく、冷蔵庫を思わせる防湿庫。\n「ドアを開けてもライトはつかないの？」というのはよくあるご質問です。\n\n従来の蛍光灯や白熱電球の場合、熱や紫外線を発し、機材に悪影響を及ぼす可能性がありました。\n発熱、紫外線照射の少ないLED電球が普及したことで、庫内を明るく照らすことができるようになりました。\n\nLD-120は自慢のコレクションを美しく照らす『常時点灯モード』と出し入れの利便性を高める『ドア開閉連動モード』とをスイッチひとつで簡単に切り替え可能。',
        },
        {
          title: 'デジカメ時代の新機能。庫内充電が可能なコンセントを搭載',
          txt: '充電器をはじめとした、デジカメの関連アクセサリーも、すっきりまとめて収納したいものです。\n最近では、マイクロUSB端子を介して、バッテリーを取り出さずに充電できるデジカメも増えてきました。\n\nせっかくカメラを守るために防湿庫を購入したのに、充電のためにカメラを一晩出しっぱなし、なんてこともあります。 LD-120は庫内にコンセントを搭載しているので、防湿庫に収納した状態でカメラの充電が行えます。\n\nフィルムカメラが中心の頃にはなかった、デジカメ時代に即した新機能です。',
        },
      ],
      backnumberList: [
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/i-item_fujin_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0508',
          productName: 'レンズ型カメラ掃除機 Fujin「風塵（ふうじん）」 EF …',
          txt: 'カメラメンテナンス・クリーニングの決定版！ 1分間でイメージセンサーについたホコリを掃除できるレンズ型カメラ掃除機。運動会後や撮影前後のケ…【続き】'
        },
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/i-item-blackrapid-joby_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0501',
          productName: 'ブラックラピッド クロスショット × JOBY ウルトラハ …',
          txt: '速写ストラップをつけたまま、三脚を使う裏技！JOBYウルトラプレートを使えば、速写ストラップをつけたまま、三脚が使える。一眼ユーザーの要望 …【続き】'
        },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
