










































































































































































































































































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'z_f',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    naviBtn: NaviBtn,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ニコン Z f | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Z f  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2023/09/19 10:00',
      fanValidTo: '2023/09/22 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/09/22 10:00',
      reserveValidTo: '2023/10/26 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/10/27 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: 'お得情報', href: '#otoku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ニコン Z 7 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901088',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/3cc/55d/93d/b00/397/e36/d23/88a/35c/3a4/064/q0v/wu9/1/L.jpg',
        txt: 'ニコン Z f ',
        day: '2023年10月31日',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z_f/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ニコン Z 7 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901088' },
          { name: 'ニコン Z 6 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901484' },
          { name: 'ニコン Z 7II ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905697' },
          { name: 'ニコン Z 6II ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905901' },
          { name: 'ニコン Df ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%83%8B%E3%82%B3%E3%83%B3Df%E3%83%9C%E3%83%87%E3%82%A3' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: 'ニコンの歴史的カメラ「FM2」にインスパイアされたヘリテージデザイン',
          contents: [
            'FM2の信念を受け継ぎ、インスピレーションを得て洗練された外観デザインとダイヤル操作により、自分の思いが作品へと生まれ変わるのを実感できる Z f。ペンタプリズム部に採用した1970年代から80年代のカメラと同じNikon ロゴ、シャッターボタンや操作ダイヤルの感触など、細部にまでこだわり抜いたこの1台が、目にした瞬間、触れた瞬間から、表現への探求心を掻き立てます',
            'シャッターボタンや電源スイッチをはじめ、シャッタースピード、露出補正、ISO感度の各ダイヤルには、質感の高い真鍮を採用。各ダイヤルのクリック音と感触は、試作と調整を重ね、使いやすさと心地良さを追求しています',
            '撮影をサポートしながらもデザイン性に優れたグリップや、触り心地と質感を向上した人工皮革、押し心地を重視したシャッターボタンも特長のひとつ。細部へのこだわりが、上質な操作感を実現します'
          ]
        },
        {
          subtitle: '表現の可能性を拡げる機能・性能',
          contents: [
            'シャープでダイナミックな高コントラストの「モノクローム」に加え、影から光へのグラデーションが滑らかで、中間調が豊かで温かく柔らかい印象の「フラットモノクローム」、中間調は暗めながらシャドー部の黒つぶれを抑えてダークトーンを再現する「ディープトーンモノクローム」を搭載しました',
            '自分のイメージに合わせて色みや表現を変更できるピクチャーコントロールやCreative Picture Controlは、簡単に設定できるだけでなく、適用した効果を画像モニターですぐに確認可能。その時々で違う感情、雰囲気、温度感やトーンを自分らしく伝える、美しい静止画や動画を実現します',
            '白とびを抑えながら豊かな階調で人物の肌を表現する「リッチトーンポートレート」を搭載。人物の肌を明るく滑らかにする従来の「ポートレート」に比べ、より肌のディテールを残せるため、レタッチして仕上げる際のベース画像にも適しています',
            'ピクセルシフト撮影により、モアレや偽色、ノイズを抑えた新次元の解像度と忠実度で、様々なシーンを撮影できます。4コマ、8コマ、16コマ、32コマと撮像素子の位置を微妙にずらしながら撮影することで、より正確な色情報を得ることが可能。16コマ、32コマでは約9600万画素という圧倒的な高解像度が得られます'
          ]
        },
        {
          subtitle: 'どんなシーンも自由に、確実に撮影できるフォーカスモード',
          contents: [
            'Z 9 や Z 8 にも搭載の画像処理エンジン EXPEED 7により、被写体検出時に高い追従性を発揮。静止画撮影ではAFエリアモードを、被写体の不規則な動きをシャープに捉え続ける3D-トラッキングに、動画撮影ではターゲット追尾AFを設定すれば、動く被写体をスムーズに追い続けることが可能です',
            '人物（目、顔、頭部、上半身）、犬、猫、鳥、車、バイク、自転車、列車、飛行機の9種類の被写体を検知し、自動的に捕捉・追尾。検知したい対象を設定する必要がありません。Z 9 や Z 8 と同じく、人物検出では世界最小サイズ（撮像範囲の長辺の約3％）の小さな顔まで検出可能。雄大な自然の中で人物の全身を撮影する場合や、遠くから歩いてくる人物を動画で捉える場合などに有効です',
            '画面の縦約89％、横約96％の範囲をカバーする273点のAFポイントが、小さな被写体や動きのある被写体を遠くからでも画面の端から端まで追尾できるので、意図した構図で狙った瞬間を、より確実に捉えます',
            'マニュアルフォーカスレンズで個性的な表現を追求したい時にも、被写体へのピント合わせが簡単です。画像処理エンジン EXPEED 7によって、ディープラーニング技術を活用した被写体検出がさらに進化。従来はAF時だけであった被写体検出を、マニュアルでのピント合わせでも行えるようになりました'
          ]
        },
        {
          subtitle: '自由度が高く、情感豊かな動画表現',
          contents: [
            '通常の4K UHD動画を超えるシャープな解像力を実現しています。有効画素数2450万画素のフルサイズセンサーから6Kの画像データを読み出しオーバーサンプリングで4K UHDに変換するため、被写体をよりシャープに描写することが可能。撮影シーンの臨場感が、さらに高まります',
            '動きのある被写体を美しく、風になびく髪の毛などの細部を描きたい時に、4K UHD 60pはその動きをきれいに捉えます。24pで出力すると2.5倍のスローモーションになるため、瞬間をドラマチックに演出することも可能。長時間にわたるシーンでは、どの瞬間も逃すことがないよう、4K UHD 60pで最長125分まで記録できます',
            '明るさが目まぐるしく変わるようなシーンでは、シャッタースピードを固定するのがおすすめ。そんな時に活躍するのがシャッター優先オート（S）モードです。ISO感度と絞りで露出制御することで、蛍光灯下でのフリッカーを抑えることができ、暗い場所から明るい場所へ移動するシーンでも、シャッタースピードの変化によって被写体の動きが不自然にならず、自然な動画を撮影できます',
            '5軸補正のボディー内センサーシフト方式VRにより、手持ち撮影時でも滑らかで鮮明な映像を実現します。歩きながら撮影する場合には、ボディー内VRと電子手ブレ補正を組み合わせて撮影するとより効果的。Z 9 同等の高い補正効果で、広角レンズでの撮影時に発生しやすい画像の歪みも軽減します'
          ]
        },
        {
          subtitle: 'その他の特徴',
          contents: [
            'APS-Cサイズ/DXフォーマットのカメラに比べ、同じ焦点距離のレンズでもより広い画角で撮影できるので、風景をそのままダイナミックに撮ることも可能。大きいのはセンサーサイズだけではありません。表現の可能性もさらに大きく拡げてくれます',
            '優れた常用最高感度ISO 64000で実現します。裏面照射型ニコンFXフォーマットCMOSセンサーと画像処理エンジン EXPEED 7との相乗効果で、繊細なディテールや質感もよりシャープに、より高い彩度で描けます。低光量下でノイズが出やすい平坦部の中間調も、よりきれいに表現が可能です',
            'AI画像解析に基づくアルゴリズムの進化により、Z シリーズで最も優れた8段分の手ブレ補正効果を発揮します。光量の少ない場所やスローシャッターでの創造的な表現など、手持ち撮影の可能性が拡がります',
            '世界初のフォーカスポイントVRにより、被写体が画面内のどこにあっても、フォーカスポイント付近のブレを抑えることが可能。そのため、画面の隅に被写体を配置するような場合でも、思い通りの構図でシャープに撮影できるため、手持ち撮影の可能性が大きく拡がります',
            'ディープラーニング技術を活用し、状況の違いを認識して、絞りやシャッタースピード、 ISO感度などを被写体や状況に応じてカメラが自動で調整します。美しいボケを活かしたポートレートも、全員の顔にピントが合ったグループ写真も、最適なカメラ設定できれいに撮れます'
          ]
        }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取'
        },
        {
          href: '/ec/special/reuse/watch',
          img: 'https://shopimg.kitamura.jp/images/banner/9216.jpg',
          alt: '中古時計販売'
        },
        {
          href: '/ec/special/reuse/smartphone',
          img: 'https://shopimg.kitamura.jp/images/banner/9217.jpg',
          alt: '中古スマホ販売'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'ニコン Z f',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759913784', '4960759913708'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759913784', '4960759905901', '4548736154292', '4549292200508'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736134119'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
