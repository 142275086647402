


















































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'a7iv',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    exampleImages: ExampleImages,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    shasha: ShaSha,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！ソニー α7 IV | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー α7 IV 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2021/11/2 10:00',
      fanValidTo: '2021/12/7 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2021/12/7 10:00',
      reserveValidTo: '2021/12/16 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2021/12/17 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ソニー α7III',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-7M3&sort=add_date&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/913/1b2/df1/d52/2b3/d6d/b42/c68/1b7/ba6/b03/n84/0hk/x/L.jpg',
        txt: 'ソニー α7 IV ',
        tradeInImg: '/ec/images2/special/camera/feature/sony/a7iv/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー α7III', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-7M3&sort=add_date&limit=40' },
          { name: 'ソニー α7II', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-7M2&sort=add_date&limit=40' },
          { name: 'ソニー α7C', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-7C&sort=add_date&limit=40' },
          { name: 'ソニー α7R II', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736017689' },
          { name: 'ソニー α6600', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-6600&sort=add_date&limit=40' }
        ]
      },
      releaseExpensiveTradeInList: {
        name: 'ソニー α7III ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=ILCE-7M3&sort=add_date&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/913/1b2/df1/d52/2b3/d6d/b42/c68/1b7/ba6/b03/n84/0hk/x/L.jpg',
        txt: 'ソニー α7 IV ',
        tradeInImg: '/ec/images2/special/camera/feature/sony/a7iv/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      commonCharacteristicList: [
        {
          subtitle: '新開発イメージセンサーと最新世代の画像処理エンジンを採用。フラッグシップから継承した高画質性能を実現',
          contents: [
            '新開発裏面照射型 有効約3300万画素の35mmフルサイズCMOSセンサーを搭載。すべての感度域で適切にノイズを低減させ、前モデルのα7 IIIよりも高い解像力を実現',
            'フラッグシップモデルのα1、α7S IIIと同じ最新画像処理エンジン BIONZ XRを搭載。従来比最大約8倍の高速処理が可能となり、さらに快適な操作レスポンスを実現',
            '静止画、動画共通で常用ISO感度100-51200の広い感度領域を実現。加えて、15ストップの広いダイナミックレンジ（静止画時）を達成',
            '裏面照射型構造と伝送速度が速いCu（銅）配線の採用、包含するAD変換処理の高速化により、動画クリエイターの要求にも応える最大4:2:2 10bit Super 35mmでの4K 60p動画記録などを実現',
            'フラッグシップモデルα1やα7S IIIに搭載した最新の画像処理エンジンの採用で、高画素化と低ノイズを高次元のバランスで実現。色再現や質感描写の向上に加え多彩な新機能を追加'
          ]
        },
        {
          subtitle: 'オートフォーカスとAF・AE追随高速撮影性能がさらに進化',
          contents: [
            'α1同等となる759点の選択可能な位相差測距点を高密度に配置。背景にピントが行きがちな小さな被写体や夜景と人物などの難しい構図での測距精度が向上',
            '被写体を追尾するリアルタイムトラッキングは狙いたい被写体を指定し、シャッターボタンを半押しするだけ。高い被写体認識精度を実現しているのでカメラまかせで高精度な自動追尾が可能',
            'αシリーズで初めて動画撮影時の鳥のリアルタイム瞳AFにも対応。様々な動く被写体の瞳にピントを合わせることが可能',
            '最大約10コマ/秒のAF/AE追随高速連写を実現。特に動きの激しい被写体の撮影でも決定的瞬間を捕捉可能',
            'AF検出輝度範囲の下限値はEV-4まで対応。また、低照度環境下や絞りこんでの撮影に有用な［フォーカス優先］モードも搭載。暗いシーンでも快適な撮影が可能'
          ]
        },
        {
          subtitle: '動画・静止画撮影を問わないハイブリッド機としての優れた操作性・信頼性',
          contents: [
            '1台で動画・静止画を問わない様々なスタイルでの撮影を実現する為、バリアングル液晶モニターを採用。自由度の高い撮影を実現',
            '静止画・動画のどちらも快適に撮影できるよう操作ダイヤルの仕様を大幅に改善。静止画撮影時と動画撮影時のそれぞれに、別々の機能をアサインできるカスタマイズ機能を搭載',
            '約368万ドット高精細 Quad-VGA OLED 電子ファインダーを搭載。α7 IIIに比べ、よりピントの確認もしやすく、撮影に集中することが可能',
            '動画撮影中の温度上昇を抑制し、長時間の4K連続録画をサポートする効率的な放熱構造を実現。1時間以上の4K 60p 4:2:2 10bit動画記録が可能',
            '従来機α7 IIIよりも、さらに防塵・防滴に配慮した設計ながらも、従来機から大きく寸法を変えることなく信頼性を確保。厳しい撮影環境でも安心して撮影が可能',
            'カメラの電源を切ったときにシャッターを閉じる機能が追加。レンズ交換時にゴミやほこりがイメージセンサーに付着しにくい構造に進化'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α7 IV',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736133730'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4548736079625', '4549292157253', '4960759905901'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736133730', '4548736079625', '4549292157253', '4960759905901'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
