



































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SubText from '@/components/common/special/subtext.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'actioncam',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures,
    watchInVideo: WatchInVideo,
    subText: SubText,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '圧倒的にブレない!?ソニー アクションカムX3000/AS300新登場！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー アクションカム新製品特集！圧倒的にブレない!?ソニー アクションカムX3000/AS300新登場！デジタル4KビデオカメラFDR-X3000やオールマイティモデルHDR-AS300！アクションカメラ・ビデオカメラはカメラ専門店カメラのキタムラのショッピングサイトにおまかせください。'
      );

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '圧倒的にブレない!?ソニー アクションカムX3000/AS300新登場！',
          disabled: true
        }
      ],
      newProductList: [
        { src: 'https://shopimg.kitamura.jp/images/banner/554.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8355.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/sel50f12gm' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8331.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/cinemaline-fx3' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8199.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/a1' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8233.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/gfx100s' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8210.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/x-e4' },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/8237.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/xf70-300mmf4-56rlm-ois-wr'
        },
        { src: 'https://shopimg.kitamura.jp/images/banner/8187.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/sel35f14gm' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8081.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/rf50-f18' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8083.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/rf70-200-f4l' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8037.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/x-s10' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8026.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/z6II_z7II' },
        { src: 'https://shopimg.kitamura.jp/images/banner/8023.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/eoskiss_m2' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7945.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/z50mm_z14-24mm' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7941.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/7c' },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7899.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/xf50mmf1.0rwr'
        },
        { src: 'https://shopimg.kitamura.jp/images/banner/7771.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/7sm3' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7757.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/z_5' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7727.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/panasonic/g100' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7719.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/eosr5_r6' },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7717.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/rf100-500mm_f4.5_7.1l_is_usm'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7730.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/rf600mm-rf800mm_f11_is_stm'
        },
        { src: 'https://shopimg.kitamura.jp/images/banner/7860.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/sony/vlogcam_zv1_zv1g' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7404.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/xt4' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7401.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/d6' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7350.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/eoskiss10I' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7331.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/olympus/em1m3' },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7334.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/olympus/ed_12-45mm_f4.0pro'
        },
        { src: 'https://shopimg.kitamura.jp/images/banner/7274.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/x100v' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7244.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/fujifilm/x-t200' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7162.jpg', href: 'https://shop.kitamura.jp/ec/special/camera/feature/canon/1dxmk3' },
        { src: 'https://shopimg.kitamura.jp/images/banner/7177.png', href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/D780' },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7164.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/7185.jpg',
          href: 'https://shop.kitamura.jp/ec/special/camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr'
        }
      ],
      reasonList: [
        {
          titleImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_07.png',
          txt:
            'アクティブな撮影も、暗いシーンも、美しく鮮やかに撮影。\nFDR-X3000/HDR-AS300は、画素加算なしの全画素読み出しに対応し、HDR-AS50よりもさらに高画質に記録できます。',
          partImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_08.png',
          partImg2: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_09.png'
        },
        {
          titleImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_10.png',
          txt:
            '解像度・コントラスト・色再現性に優れ、さらには画面周辺部の歪みを軽減した新開発レンズです。レンズ表面には多層膜コーティングを施し、光の乱反射によるゴーストやフレアを効果的に抑制します。',
          partImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_11.png',
          partImg2: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_12.png'
        },
        {
          titleImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_13.png',
          txt:
            '高感度なセンサーからの膨大な情報を高速処理し、低ノイズ・高精細に再現します。アクティブなシーンの躍動感や風景の美しさなどを、より自然で鮮やかな印象深い高精細映像で残せます。',
          partImg: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_14.png',
          partImg2: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_15.png'
        }
      ],
      movieList: [
        'https://www.youtube.com/embed/VWn9oEeMWqw?rel=0&amp;showinfo=0',
        'https://www.youtube.com/embed/pR2C2yZ4OiE?rel=0&amp;showinfo=0',
        'https://www.youtube.com/embed/qGrbcNUX7GA?rel=0&amp;showinfo=0'
      ],
      activeItemList: [
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_22.png', href: '/ec/pd/4548736009950/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_25.png', href: '/ec/pd/4548736029699/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_28.png', href: '/ec/pd/4548736005174/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_23.png', href: '/ec/pd/4905524952469/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_26.png', href: '/ec/pd/4905524991055/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_29.png', href: '/ec/pd/4905524938814/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_24.png', href: '/ec/pd/4548736015227/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_27.png', href: '/ec/pd/4548736015234/' },
        { src: '/ec/images2/special/camera/feature/sony/actioncam/pc/actioncam_30.png', href: '/ec/pd/4905524961478/' }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/dslr',
          img: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: 'https://shop.kitamura.jp/special/sale',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
