


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'photocon-learn',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'フォトコンテスト受賞作品から学ぶ 【レンズ・フィルター・星空撮影】 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'レンズの種類で撮影できる写真の違いや、レンズフィルターの役割り、星空撮影に便利なアイテムをご紹介！【カメラのキタムラ】');
    const state = reactive({
      ankerBotan: 0,
      anker: [
        { text: 'レンズ編', icon: 'fa-chevron-circle-right', link: '#renzu' },
        { text: 'レンズフィルター編', icon: 'fa-chevron-circle-right', link: '#filt' },
        { text: '星空写真編', icon: 'fa-chevron-circle-right', link: '#hosi' }
      ],
      ankerBotan2: 0,
      anker2: [{ text: '最高級クリスタルプリント', icon: 'fa-chevron-circle-right', link: '#pr' }],
      ankerBotan3: 0,
      anker3: [{ text: 'フォトコンテストのご案内', icon: 'fa-chevron-circle-right', link: '#p-con' }],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/tripod',
          img: 'https://shop.kitamura.jp/images/banner/3253.gif',
          alt: 'おすすめ三脚の選び方'
        },
        {
          href: '/ec/special/camera/bag/trial',
          img: 'https://shop.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/general/how-to/hanabi',
          img: 'https://shop.kitamura.jp/images/banner/3043.jpg',
          alt: '花火撮影特集'
        },
        {
          href: '/ec/special/general/pet',
          img: 'https://shopimg.kitamura.jp/images/banner/1878.jpg',
          alt: 'ペット写真のステキな残し方'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント',
          target: 'blank'
        }
      ],
      visible: false,
      isReinforcedParts: false,
      visibleGallery: false,
      index: 0,
      galleryList: [
        {
          src: '/ec/images2/special/general/photocon-learn/g-p02.jpg',
          title: '「屋根の上からこんにちは」',
          txt: '■使用カメラ／オリンパスE-5\n■使用レンズ／8mm F3.5 FISHEYE\n■絞り／F11\n■シャッタースピード／1 / 250秒'
        },
        {
          src: '/ec/images2/special/general/photocon-learn/g-p01.jpg',
          title: '「青空の子」',
          txt: '■使用カメラ／ニコン D7000\n■使用レンズ／AF DX 10.5mm（フィッシュアイ）\n■絞り／F6.3\n■シャッタースピード／1/800秒'
        }
      ],
      visibleGallery2: false,
      index2: 0,
      galleryList2: [
        {
          src: '/ec/images2/special/general/photocon-learn/m-p01.jpg',
          title: '「ダンス」',
          txt: '■使用カメラ／キヤノン EOS 7\n■使用レンズ／90mm マクロ\n■絞り／F6'
        },
        {
          src: '/ec/images2/special/general/photocon-learn/m-p02.jpg',
          title: '「色彩美」',
          txt: '■使用カメラ／ソニーα99\n■使用レンズ／マクロ180mm\n■絞り／F3.5\n■シャッタースピード／1/125秒\n■三脚使用'
        }
      ],
      visibleGallery3: false,
      index3: 0,
      galleryList3: [
        {
          src: '/ec/images2/special/general/photocon-learn/pl-p01.jpg',
          title: '「秋の彩り」',
          txt: '■使用カメラ／ニコンD600\n■使用レンズ／AF VR24-120mm\n■絞り／F11\n■シャッタースピード／1/50秒\n■フィルター／C-PL'
        }
      ],
      visibleGallery4: false,
      index4: 0,
      galleryList4: [
        {
          src: '/ec/images2/special/general/photocon-learn/nd-p01.jpg',
          title: '「秋宝」',
          txt: '■使用カメラ／ニコン D7000\n■使用レンズ／AF-S DX 16-85mm f/3.5-5.6G ED VR\n■絞り／F9\n■シャッタースピード／2秒\n■フィルター／ND8 +PL\n■三脚使用'
        }
      ],
      visibleGallery5: false,
      index5: 0,
      galleryList5: [
        {
          src: '/ec/images2/special/general/photocon-learn/hosi-p01.jpg',
          title: '「森の妖精」',
          txt: '■使用カメラ／キヤノンEOS 5D MarK II\n■使用レンズ／EF17-40mm F4L USM\n■絞り／F10\n■シャッタースピード／bulb'
        },
        {
          src: '/ec/images2/special/general/photocon-learn/hosi-p02.jpg',
          title: '「夜空を望む」',
          txt: '■使用カメラ／キヤノンEOS 5D MarkII\n■使用レンズ／Carl Zeiss Distagon T*2.8/21\n■絞り／F2.8\n■シャッタースピード／30秒'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'フォトコンテスト受賞作品から学ぶ 【レンズ・フィルター・星空撮影】',
          disabled: true
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4047865800686'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    const handleHide = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery = false;
    };
    const showGalleryImg = (index: number) => {
      state.index = index;
      state.visibleGallery = true;
    };
    const handleHide2 = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery2 = false;
    };
    const showGalleryImg2 = (index2: number) => {
      state.index2 = index2;
      state.visibleGallery2 = true;
    };
    const handleHide3 = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery3 = false;
    };
    const showGalleryImg3 = (index3: number) => {
      state.index3 = index3;
      state.visibleGallery3 = true;
    };
    const handleHide4 = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery4 = false;
    };
    const showGalleryImg4 = (index4: number) => {
      state.index4 = index4;
      state.visibleGallery4 = true;
    };
    const handleHide5 = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery5 = false;
    };
    const showGalleryImg5 = (index5: number) => {
      state.index5 = index5;
      state.visibleGallery5 = true;
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      handleHide,
      showGalleryImg,
      handleHide2,
      showGalleryImg2,
      handleHide3,
      showGalleryImg3,
      handleHide4,
      showGalleryImg4,
      handleHide5,
      showGalleryImg5,
      formatPrice
    };
  }
});
