























































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import ProductAppeal from '@/components/common/special/productAppeal.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SubText from '@/components/common/special/subtext.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'hanabi',
  components: {
    breadcrumbs: Breadcrumbs,
    productAppeal: ProductAppeal,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    kitamuraService: KitamuraService,
    subText: SubText,
    urlcopy: Urlcopy,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '花火撮影特集 打ち上げ花火の撮影のコツ＆撮影に便利な商品のご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '夏の風物詩、花火大会が近づいてきました。夜空に咲く一瞬の輝きを、カメラに美しく収めてみませんか？打ち上げ花火をキレイに撮影するコツは、カメラを固定することとシャッタースピード！ この特集ページでは、花火撮影におすすめのカメラやレンズ・三脚などの機材を厳選してご紹介するとともに、花火撮影時のカメラ設定やコツ・撮影後の写真の残し方などを掲載しております。'
      );

    const state = reactive({
      naviList: [
        { naviItem: '花火撮影の２つの心得', href: '#kokoroe' },
        { naviItem: '花火撮影のコツ', href: '#kotu' },
        { naviItem: '花火写真をプリントしよう', href: '#print' }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '花火撮影特集 打ち上げ花火の撮影方法  ',
          disabled: true
        }
      ],
      processList: [
        {
          id: 'step1',
          txt: '三脚からはずしたクイックシューをカメラに設置します。ブレの原因になるので、ねじ部分はコインを使ってしっかりしめましょう',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img04.jpg'
        },
        {
          id: 'step2',
          txt: '三脚にカメラをセットします',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img05.jpg'
        },
        {
          id: 'step3',
          txt:
            '高さと角度を調整して完成。簡単に設置できました！低い位置にセットできるコンパクトな三脚がオススメです★ 傾斜があるところでは、三脚の脚の長さを同じにして立てると、重心がぶれて倒れてしまいます。なので、傾斜の低いほうは三脚の脚を長く、傾斜の高いほうは三脚の脚を短くセットすると重心がぶれないので◎',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img06.jpg'
        },
        {
          id: 'step4',
          txt: '撮影前にレンズのホコリを払うこともキレイな写真を撮るコツです',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img07.jpg'
        }
      ],
      preparation2characteristicList: [
        {
          txt: 'レリーズを取り付けたら、きちんとシャッターが切れるかテストしましょう',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img12.jpg'
        },
        {
          txt: 'ロック機能があるものは、レリーズが外れないようにロックの確認も忘れずにチェックしましょう',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/hanabi/img13.jpg'
        }
      ],
      preparation3characteristicList: [
        {
          txt: 'ボディのモードダイヤルをAFに切り替えます。遠くにあるものにピントを合わせましょう。今回は“雲”に合わせてみました'
        },
        {
          txt: 'ボディのモードダイヤルをMに切り替える。ピントリングを回さない限り、ピントがずれることはありません'
        }
      ],
      // ↓ ---- Top商品価格 ----
      topProductJanCode: ['4977187208528'],
      topProductList: [] as Array<ProductDetail>,
      // ↓ ---- second商品価格 ----
      secondProductJanCode: ['4957638190145', '4957638140102', '4961607820841', '4977187329711'],
      secondProductList: [] as Array<ProductDetail>,
      // ↓ ---- third商品価格 ----
      thirdProductJanCode: ['8024221668315'],
      thirdProductList: [] as Array<ProductDetail>,

      // ↓ ---- tripod商品価格 ----
      tripodProductJanCode: ['4907990437006', '4907990500571', '4948271734520', '8024221700084'],
      tripodProductList: [] as Array<ProductDetail>,
      backNumberList: [
        {
          href: '/ec/special/general/how-to/hanabi',
          img: 'https://shop.kitamura.jp/images/banner/5093.jpg',
          alt: 'vol.5 Panasonic LUMIX G9 PRO'
        },
        {
          href: '/ec/special/general/how-to/a7r3-a9',
          img: 'https://shop.kitamura.jp/images/banner/4998.jpg',
          alt: 'vol.4 SONY α7RⅢ| α7RⅡやα9と比較しながらプロカメラマンが実写レビューレポート'
        },
        {
          href: '/ec/special/general/how-to/omd-em1',
          img: 'https://shop.kitamura.jp/images/banner/4841.jpg',
          alt: 'vol.3 OLYMPUS OM-D E-M1 MarkⅡ| 最強の手ぶれ補正性能！手持ちでも本格的な夜景撮影を可能に'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/list?category=&narrow1=&narrow2=&type=u&r=',
          img: 'https://shopimg.kitamura.jp/images/banner/4637.gif',
          alt: '新着中古'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // トップ商品
        const topResult = await ProductService.fetchProducts(state.topProductJanCode, true);
        state.topProductList = topResult.items;
        // second商品
        const secondResult = await ProductService.fetchProducts(state.secondProductJanCode, true);
        state.secondProductList = secondResult.items;
        // third商品
        const thirdResult = await ProductService.fetchProducts(state.thirdProductJanCode, true);
        state.thirdProductList = thirdResult.items;
        // tripod商品
        const tripodResult = await ProductService.fetchProducts(state.tripodProductJanCode, true);
        state.tripodProductList = tripodResult.items;
      } catch (error) {
        // トップ商品
        state.topProductList = [] as Array<ProductDetail>;
        // second商品
        state.secondProductList = [] as Array<ProductDetail>;
        // third商品
        state.thirdProductList = [] as Array<ProductDetail>;
        // micro商品
        state.tripodProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
