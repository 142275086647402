


































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';

export default Vue.extend({
  name: 'highly_recommended-2015-1224',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = 'ペリカン スーベレーンＭ600を購入しました | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'カメラのキタムラで新たに取扱を開始したブランド筆記具から、ペリカンの定番モデル｢スーベレーンM600｣を購入しました。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'ペリカン スーベレーンＭ600を購入しました | スタッフのイチオシ',
          disabled: true
        }
      ],
      backNumberCameraList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/img_item-350px.gif', href: '/ec/special/general/highly_recommended/2015/1218/?ref=ca-1224', name: 'データを無線で高速転送。 東芝 TransferJet SD-TJA016G' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/img_zetasslimpx.jpg', href: '/ec/special/general/highly_recommended/2015/1211/?ref=ca-1224', name: 'COTTON CARRIER コットンキャリア ストラップショットEV1' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/eh-csa96_350px.jpg', href: '/ec/special/general/highly_recommended/2015/1204/?ref=ca-1224', name: '冬も肌の潤いキープします！パナソニック スチーマーナノケア EH-CSA96-P（限定モデル）' },
      ],
      backNumberSmartphoneList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/flower-power_350px.jpg', href: '/ec/special/general/highly_recommended/2015/1009', name: '植物の声が聞こえる？Parrot FLOWER POWER' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/i-item-elecom_high-reso_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0814', name: '「意外にいい音」、エレコム ハイレゾイヤホンシリーズ EHP-CH1000 他' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/1224/i-item-ztylus_500px.jpg', href: '/ec/special/general/highly_recommended/2015/0710', name: 'iPhone6用レンズアタッチメント ズタイラス RV-2 & ZIP-6LB' },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
