






































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'x-e4',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    watchInVideo: WatchInVideo,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'フジフイルム X-E4 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'フジフイルム X-E4 ミラーレス一眼 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '外観', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'クラシカルデザインのボディに最短約0.02秒の高速・高精度AF機能を搭載',
          contents: [
            '天面に高強度なマグネシウム合金を採用。往年のフィルムカメラを彷彿とさせるクラシカルで美しいデザインと、質量約364gのコンパクトボディを両立。気軽にいつでも持ち歩きたいカメラ',
            '「Xシリーズ」のフラッグシップモデル「FUJIFILM X-T4」などで採用している2610万画素「X-Trans CMOS 4」センサー・高速画像処理エンジン「X-Processor 4」を搭載。最短約0.02秒の高速AFで決定的瞬間も逃さない',
            '高精度な動体追従AF機能を搭載。カメラに向かってくる被写体だけでなく、カメラから離れていく被写体も正確に捕捉。加えて優れた「顔・瞳検出AF」により、ピントの合った写真が気軽に撮影可能'
          ]
        },
        {
          subtitle: '気軽に高画質な撮影を楽しめる独自のフィルムシミュレーションと優れた操作性',
          contents: [
            '80年以上にわたり培った色再現による多彩な表現が可能な独自のフィルムシミュレーションにより、被写体や撮影シーンにあわせて、写真フィルムを取り換える感覚で多彩な表現が可能',
            'フィルムシミュレーションは静止画だけでなく、動画撮影時にも効果を発揮。手軽にオリジナリティのある美しい4K動画の撮影も可能',
            '「自分撮り」も簡単に行うことが出来るチルト式背面液晶モニターを採用。ハイアングルからローアングルまで様々なスタイルで撮影を楽しめます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-E4',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410442632', '4547410442625', '4547410457629', '4547410457636'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410442632', '4547410357363', '4548736092044', '4549292136401'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4547410442632',
        '4547410442625',
        '4547410457629',
        '4547410457636',
        '4547410334128',
        '4547410449327',
        '4547410449334',
        '4547410449310',
        '4547410449303',
        '4523052022715',
        '4523052022722',
        '4523052022739',
        '4523052016615',
        '4523052016639',
        '4547410440386',
        '4547410206142',
        '4547410206159',
        '4547410310412',
        '4547410310405',
        '4547410397314'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
