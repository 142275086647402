



























































































import Vue from 'vue';
import { reactive, toRefs, PropType } from '@vue/composition-api';
import ProductService from '@/logic/product.service';
import { noimage, formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'product-slider-item',
  components: {},
  props: {
    recommend: {
      required: false
    },
    // 中古商品に関連する商品かどうか
    isUsed: {
      required: true,
      type: Boolean
    },
    // 商品詳細情報
    // 関連商品と、関連商品以外で型が違う。
    product: {
      required: true
    },
    // 中古商品サマリ（JANコード、中古商品数、最小値）
    usedProductSummary: {
      type: Object as PropType<{
        janCode: string;
        itemCount: number;
        minPrice: number;
      }>,
      required: true
    },
    // 関連商品かどうか
    isRelatedProducts: {
      type: Boolean,
      required: true
    },
    // カートボタンテキスト
    cartButtonText: {
      type: String,
      default: 'あわせて買う'
    }
  },
  setup: (props: any, context) => {
    const state = reactive({
      model: null,
      showAddingCart: false
    });

    /**
     * 遷移処理
     */
    const navigate = () => {
      const path = `/ec/pd/${props.product.janCode}`;
      const recommend = (props.recommend as { _click: any }) || undefined;
      if (recommend && Object.keys(recommend).length > 0) {
        recommend._click(path, props.product.janCode);
      } else {
        context.root.$router.push({ path });
      }
    };

    // カート投入
    function addCart() {
      ProductService.addCart(props.product.janCode, props.product.isChuko, null, props.product.price, 1).then(() => {
        state.showAddingCart = true;
        context.emit('addCart');
        setTimeout(() => {
          // 3秒後に結果を非表示にする
          state.showAddingCart = false;
        }, 3000);
      });
    }

    return {
      ...toRefs(state),
      formatPrice,
      noimage,
      navigate,
      addCart
    };
  }
});
