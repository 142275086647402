import { render, staticRenderFns } from "./486.vue?vue&type=template&id=c4f8e8ea&scoped=true&"
import script from "./486.vue?vue&type=script&lang=ts&"
export * from "./486.vue?vue&type=script&lang=ts&"
import style0 from "./486.vue?vue&type=style&index=0&id=c4f8e8ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4f8e8ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
