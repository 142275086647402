
























































































































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import PriceAndPurchase2 from '@/components/common/special/price-purchase2.vue';
import { ProductDetail } from '@/types/product';
import ExampleImages from '@/components/common/special/example-image.vue';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'sony-a7rv',
  components: {
    breadcrumbs: Breadcrumbs,
    exampleImages: ExampleImages,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    priceAndPurchase2: PriceAndPurchase2,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！ソニー α7R V | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー α7R V 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/10/27 10:00',
      fanValidTo: '2022/11/1 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/11/1 10:00',
      reserveValidTo: '2022/11/24 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/11/25 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ソニー α7R IV ボディ [ILCE-7RM4]',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736108264',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/c70/f4e/cb7/aa6/70d/6bb/fb3/e03/6f4/136/abw/t43/9jd/l/L.jpg',
        txt: 'ソニー α7R V',
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/a7rv/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー α7R IV ボディ [ILCE-7RM4]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736108264' },
          { name: 'ソニー α7R IV A ボディ [ILCE-7RM4A]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736128477' },
          { name: 'ソニー α7R III ボディ [ILCE-7RM3]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736075139' },
          { name: 'ソニー α7R III A ボディ [ILCE-7RM3A]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736128378' },
          { name: 'ソニー α7 IV ボディ [ILCE-7M4]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736133730' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: 'クラス最高有効約6100万画素の解像度とα史上最高8.0段の手ブレ補正で静止画も動画もより鮮明に表現',
          contents: [
            'クラス最高有効約6100万画素35mmフルサイズ裏面照射型CMOSセンサーと史上最高 補正効果8.0段のボディ内手ブレ補正機能によりα史上最高の解像性能を実現。静止画、動画を問わず被写体の持つ繊細なディテールを精緻に描き切ります',
            '裏面照射型イメージセンサーの高い感度特性とギャップレスオンチップ構造による高い集光性能などソニーの持つ高度なイメージセンサー技術を結集。低感度時のダイナミックレンジは約15ストップと広く、ハイライトからシャドウまで階調を再現。人物撮影における肌の色や、鮮やかな花などを自然な印象で描写します',
            '手ブレ補正効果はα史上最高の8.0段をボディ単体で実現。さらに、対応する手ブレ補正機構内蔵レンズとの組み合わせで、より効果的にボディと協調。従来よりも安定したフレーミングで撮影をサポートします',
            '極めて高い解像感を持つ画像を生成する「ピクセルシフトマルチ撮影」 がさらに進化。合計約9億6320万画素分の膨大な情報を、約2億4080万画素（19,008×12,672）で画像生成が可能。目で見る以上のディテールと臨場感あふれる画像を提供します',
            '撮影する静止画・動画を思い通りの雰囲気に仕上げることができるクリエイティブルックを搭載。静止画・動画の区別なく自分好みの表現で記録が可能です'
          ]
        },
        {
          subtitle: '新開発AIプロセッシングユニットを搭載で、被写体の認識性能が向上した新次元のAF性能',
          contents: [
            '画像処理エンジンとは別に、AI処理に特化した新開発のAIプロセッシングユニットを搭載。ディープラーニングを含むAI処理で、人物の骨格や姿勢などの詳細な情報に基づいた、より高精度な被写体認識が新たに可能になりました',
            '人物の瞳の認識精度がα7R IV比で60％向上。瞳だけではなく人間の胴体、頭部の位置をより高精度に認識するため、たとえば後ろ向きの人物を捉えたり、マスクなどで顔が見えないシーンでも人物の頭部を認識し続けます',
            '［人物］［動物］［鳥］に加え、新たな認識被写体として［昆虫］［車・列車］［飛行機］に対応。従来と比べ、動物に対する認識性能は約40%アップしています',
            'AIを活用した「リアルタイムトラッキング」を搭載。狙いたい被写体を指定してシャッターボタンを半押しするだけで、カメラまかせで自動追尾が可能。撮影者は構図に集中できます',
            '従来よりも広いイメージセンサーの撮像領域の約79%（静止画撮影時）のエリアに、最大693点の像面位相差AF点を高密度に配置。認識した被写体をピンポイントで精度高く捕捉します'
          ]
        },
        {
          subtitle: '新開発4軸マルチアングル液晶モニターをはじめとする高解像撮影をサポートする優れた操作性・信頼性',
          contents: [
            '従来のチルト液晶モニターと横開きバリアングル液晶モニターの利便性を兼ね備えたソニー独自の4軸マルチアングル液晶モニターを新開発。横位置でも縦位置でも見やすい位置に自在に角度を調整できるためより撮影に集中できます',
            '小型・軽量のボディに静止画・動画撮影を問わずプロが求める操作性を追求。放熱構造による長時間撮影、防塵・防滴に配慮した設計、マグネシウム合金の採用などフラグシップモデルα1と同等の仕様で過酷な現場での撮影をサポートします',
            'α7R IVに比べ約1.6倍の高解像度化を実現したクラス最高解像度約944万ドットの高精細OLEDを採用。0.90倍のファインダー倍率、撮影画像の対角視野角約41度と撮影者がより集中して撮影ができるファインダーとなっています',
            '動画撮影中の温度上昇を抑制し長時間の連続録画をサポートするため、設計を徹底的に見直し効率的な放熱構造を採用。従来比（α7R IV）約5倍の放熱効果を得ることに成功しました',
            'スロット1、スロット2ともに、CFexpress Type AメモリーカードとSDXC/ SDHCメモリーカード UHS - II / UHS - Iに対応したデュアルスロットを採用。利便性がさらに向上しました',
            'モードダイヤルの下部に、新たに「静止画 / 動画 / S＆Q切り換えダイヤル」を搭載。静止画 / 動画 / S＆Qモードごとの露出モード選択も、モードダイヤルを回すだけで簡単に設定できます'
          ]
        },
        {
          subtitle: '動画性能が大幅に向上。臨場感あふれる高精細動画の撮影が可能',
          contents: [
            '圧倒的な解像感の8K動画を、高画質かつ圧縮効率の高いHEVC / H.265コーデックの記録フォーマットXAVC HS方式で8K（7680x4320）24pで内部記録できます',
            '4K動画記録時は、表現や用途に合わせてフルサイズとSuper 35mmから選択が可能。フルサイズ選択時は60p / 30p / 24pの動画をカメラ内に記録できます',
            'さらに、Super 35mm（APS - Cサイズ相当 16: 9）の画面領域では4K（QFHD：3840x2160）動画に必要な画素数の約2.6倍の豊富な情報量から4K映像を出力。ディテールの再現性や解像感に優れています',
            'α7R IV比で動画撮影時の基本画質が向上。特に低感度時の解像感をチューニングし、よりクリアな映像を実現しています',
            '人物撮影時に、顔の小じわやシミ、肌のくすみなどを目立たなくし、肌をきれいに撮影できる美肌効果機能を搭載。静止画・動画を問わず活用可能です',
            '動画撮影時の手ブレ補正効果を向上させるアクティブモードに対応。ボディ単体での高い補正効果に加え、対応する手ブレ補正機構内蔵レンズと組み合わせれば、協調して制御することで大きなブレを抑制。より一層フレーミングが安定し快適な撮影をサポートします'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α7R V',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736145573'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください 新製品以外のJAN----
      noticecomparisonJanCodeList: ['4548736128477', '4549292157345', '4960759905697'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736145573', '4548736128477', '4549292157345', '4960759905697'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
