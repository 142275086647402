



















































import Vue from 'vue';
import { reactive, toRefs, PropType, watch } from '@vue/composition-api';

export type Rating = {
  totalRating: number;
  designRating: number;
  priceRating: number;
  operationRating: number;
  imageRating: number;
};

export default Vue.extend({
  name: 'review-rating-table',
  props: {
    value: {
      type: Object as PropType<Rating>,
      required: true,
      default: {}
    },
    readonly: {
      type: Boolean,
      default: true
    },
    // 他のテーブル要素を組み合わせるか
    combined: {
      type: Boolean,
      default: false
    }
  },
  setup: (props: any, context) => {
    const state = reactive({
      tableItems: [
        {
          title: 'デザイン',
          key: 'designRating'
        },
        {
          title: '価格',
          key: 'priceRating'
        },
        {
          title: '操作性',
          key: 'operationRating'
        },
        {
          title: '画質',
          key: 'imageRating'
        }
      ],
      rating: {
        totalRating: props.value.totalRating || 0,
        designRating: props.value.designRating || 0,
        priceRating: props.value.priceRating || 0,
        operationRating: props.value.operationRating || 0,
        imageRating: props.value.imageRating || 0
      },
      validationEnabled: false,
      valid: {
        totalRating: true
      }
    });

    /**
     * バリデーションを有効化する
     */
    const enableValidation = () => {
      state.validationEnabled = true;
    };

    /**
     * バリデーションを行う
     */
    const validate = (): boolean => {
      // 総合評価は選択必須
      state.valid.totalRating = +state.rating.totalRating > 0;

      const valid = Object.values(state.valid).every((val) => val);
      return valid;
    };

    /**
     * 評価が変化したらバリデーションチェックをし、親コンポーネントに伝える
     */
    const onChange = () => {
      if (state.validationEnabled) {
        validate();
      }

      context.emit('change', state.rating);
    };

    /**
     * 評価をクリアする
     */
    const ckearRating = () => {
      state.rating = {
        totalRating: 0,
        designRating: 0,
        priceRating: 0,
        operationRating: 0,
        imageRating: 0
      };
    };

    watch(
      () => props.value,
      (value) => {
        state.rating.totalRating = value.totalRating || 0;
        state.rating.designRating = value.designRating || 0;
        state.rating.priceRating = value.priceRating || 0;
        state.rating.operationRating = value.operationRating || 0;
        state.rating.imageRating = value.imageRating || 0;
      }
    );

    return {
      ...toRefs(state),
      onChange,
      enableValidation,
      validate,
      ckearRating
    };
  }
});
