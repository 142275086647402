var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component2"},[_c('div',{staticClass:"component2-info"},[_c('div',[_vm._v("テンプレートC")]),_c('div',[_vm._v("コンポーネント : "+_vm._s(_vm.msg))])]),_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('p',[_vm._v("中古特集")]),_c('product-list-by-special-id',{staticClass:"mb-5",attrs:{"specialId":908,"specialPageType":'used'}}),_c('product-list-by-special-id',{staticClass:"mb-5",attrs:{"specialId":603,"sortList":['recommended', 'price_asc'],"countList":[20, 40, 60]}}),_c('product-list-by-jan-code',{attrs:{"janCodes":[
      '4547410369137',
      '4547410379372',
      '4547410379389',
      '4974214167704',
      '4974214175174',
      '4902205338192',
      '4902408338685',
      '4901770448428',
      '4901881167966',
      '4901881291913',
      '4902205235248',
      '4901881168130',
      '4901881168123',
      '4901881168062',
      '4901881168055',
      '4901881168048',
      '4901881168116',
      '4901881168147',
      '4901881168031',
      '4901881168079',
      '4901881168086',
      '4901881168093'
    ],"sortList":['price_asc', 'price_desc'],"countList":[80, 100, 0]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }