





















































































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import ExampleImages from '@/components/common/special/example-image.vue';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'xt5',
  components: {
    breadcrumbs: Breadcrumbs,
    exampleImages: ExampleImages,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！フジフイルム X-T5 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'フジフイルム X-T5 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/11/03 10:00',
      fanValidTo: '2022/11/08 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/11/08 10:00',
      reserveValidTo: '2022/11/24 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/11/25 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'フジフイルム X-T4 ボディ',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%83%95%E3%82%B8%E3%83%95%E3%82%A4%E3%83%AB%E3%83%A0%20X-T4%20%E3%83%9C%E3%83%87%E3%82%A3&s4=%E3%83%9C%E3%83%87%E3%82%A3&n4c=%E6%9C%89&sort=keyword&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/1fe/9d4/237/6cb/623/b3c/d6e/f2c/6da/04f/23c/ov1/mzg/n/L.jpg',
        txt: 'フジフイルム X-T5',
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/fujifilm/xt5/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'フジフイルム X-T4 ボディ',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%83%95%E3%82%B8%E3%83%95%E3%82%A4%E3%83%AB%E3%83%A0%20X-T4%20%E3%83%9C%E3%83%87%E3%82%A3&s4=%E3%83%9C%E3%83%87%E3%82%A3&n4c=%E6%9C%89&sort=keyword&limit=40'
          },
          {
            name: 'フジフイルム X-T3 ボディ',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=X-T3&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&n4c=%E6%9C%89&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword&limit=40'
          },
          { name: 'フジフイルム X-T2 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410325287' },
          { name: 'フジフイルム X-T2 ボディ グラファイトシルバーエディション', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410327021' },
          { name: 'フジフイルム X-H1 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410365801' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '「X シリーズ」第五世代の「X-Trans CMOS 5 HR」センサーを搭載し高画質を実現',
          contents: [
            '「X シリーズ」第五世代の裏面照射型約4020万画素「X-Trans CMOS 5 HR」センサーを搭載。高画質・高機動性を実現しました',
            '肌のレタッチを自動で行う「スムーススキンエフェクト」を搭載。ポートレート写真などで行われるレタッチの手間を軽減することができ、より短時間で完成度の高い画像データを作成できます',
            'ディープラーニング技術を活用したオートホワイトバランスを採用。AIにより、オレンジがかった暖かみのある電球色を正確に識別し、より精度の高いホワイトバランスを実現します',
            '多彩な色調を可能とする「フィルムシミュレーション」に、高彩度かつ柔らかい階調表現を特長とする「ノスタルジックネガ」など全19種類のモードを搭載。フィルムを選ぶ感覚で多彩な色表現をお楽しみいただけます',
            '4倍の解像力と忠実な色再現による撮影が可能な「ピクセルシフトマルチショット」を搭載。約1.6億画素の画像を生成できるため、撮影可能な被写体の幅が広がります'
          ]
        },
        {
          subtitle: '快適な操作を実現するハードウェア・デザインにより撮影を強力にサポート',
          contents: [
            '5軸・最大7.0段のボディ内手ブレ補正機能を搭載。夜景などの暗い撮影シーンでも、快適な手持ち撮影を可能にします',
            'ボディ天面にISO感度・シャッタースピード・露出補正の3つのダイヤルを搭載。電源を入れる前にカメラの設定を確認または変更することができ、快適な操作性を実現します',
            '184万ドットの3方向チルト液晶モニターを新たに搭載。静止画撮影で多用される、縦位置やウエストレベルでの撮影を快適に行うことができます',
            'ファインダー倍率0.8倍・369万ドットの高倍率EVFを搭載。ファインダー使用時に瞳の位置ずれにより発生する像流れや歪みを抑制し、高い視認性を実現しています',
            'ボディ内手ブレ補正機構やEVFのパーツを軽量化。さらに、防塵・防滴・耐低温 -10℃の耐低温構造も備えているため、あらゆるシーンでご使用いただけます'
          ]
        },
        {
          subtitle: '6.2K / 30Pの映像記録など充実の動画撮影機能を搭載',
          contents: [
            '6.2K/30P 4:2:2 10bitでのカメラ内SDカード記録に対応。6.2Kの豊富なデータ量から高品質な4K映像を生成する「4K HQ」モードも搭載し、高画素センサーを生かした解像感の高い映像を実現します',
            'SDカード対応のデュアルスロットを採用。快適な動画撮影をサポートします',
            '階調豊かな映像記録が可能な「F-Log2」を搭載。ポストプロダクションでの自由度が大きく向上します',
            'HDMI経由で最大6.2K/30P 12bitのRAWデータ出力が可能。さらに、ATOMOS社製「NINJA V+」でApple ProRes RAW、Blackmagic Design社製の「Video Assist 12G」でBlackmagic RAWとして記録することができます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-T5',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410486421', '4547410486438', '4547410486742', '4547410486759'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4547410427875', '4549292185430', '4545350053864'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410486421', '4547410427875', '4549292185430', '4545350053864'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
