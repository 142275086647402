





































































































































import Vue from 'vue';
import { computed, onMounted, reactive, toRefs, watch } from '@vue/composition-api';
import MoreItemsButton from '@/components/common/more-items-button.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { SELL_ORDER_TYPES, SELL_KIND } from '@/constants/mypage-order-types';
import { SELL_ORDER_SEASONS } from '@/constants/mypage-order-seasons';
import { noimage, sortByRowNo } from '@/logic/utils';
import UserService from '@/logic/user.service';
import { PurchaseInfo } from '@/types/purchase-list';
import dayjs from 'dayjs';
import { SELL_ORDER_STEP } from '@/constants/mypage-order-step';

// 画面表示用注文内容
type OrderInfoScreen = PurchaseInfo & {
  statusText: string;
};

export default Vue.extend({
  name: 'order-sell-list',
  components: {
    'more-items-button': MoreItemsButton,
    'section-loading': SectionLoading
  },
  setup: (_, context) => {
    const { authorizer } = context.root.$store;

    // 過去10年分の注文時期選択肢を作成
    const thisYear = dayjs().year();
    const orderSeasonYears = [...Array(10).keys()].map((i) => thisYear - i);

    const state = reactive({
      // 種類
      orderTypes: SELL_ORDER_TYPES,
      selectedOrderType: SELL_ORDER_TYPES[0].value,
      // 注文時期
      orderSeasons: [...SELL_ORDER_SEASONS, ...orderSeasonYears],
      selectedOrderSeason: SELL_ORDER_SEASONS[0].value,
      // 履歴一覧
      orderList: [] as Array<OrderInfoScreen>,
      // 表示注文数
      showOrderLength: 10,
      maxOrderLength: 0,
      page: 1,
      loaded: {
        orderList: false
      }
    });

    // 買取・下取り履歴を取得
    const fetchPurchaseList = () => {
      state.loaded.orderList = false;

      const year = state.selectedOrderSeason !== 0 ? state.selectedOrderSeason : undefined;
      const kind = state.selectedOrderType !== 0 ? state.selectedOrderType : undefined;
      UserService.fetchPurchaseList(state.page, year, kind)
        .then((response) => {
          const odrInfoList = response.odrInfo;
          let orderList: Array<OrderInfoScreen> = odrInfoList.map((order) => {
            const statusText = UserService.convertStepStatus(SELL_ORDER_STEP, `${order.status}`).text;
            return {
              ...order,
              statusText
            };
          });

          // 「rowNo」を昇順にソートする
          // ページ毎に「rowNo」が新規に割り振られているため、レスポンス内容の中でソートする
          orderList = sortByRowNo<OrderInfoScreen>(orderList);

          // 2ページ目以降は配列に追加していく
          if (1 < state.page) {
            state.orderList.push(...orderList);
          } else {
            state.orderList = orderList;
          }
          state.maxOrderLength = response.totalPageCount;
        })
        .catch((error) => console.error(error))
        .finally(() => (state.loaded.orderList = true));
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        fetchPurchaseList();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchPurchaseList();
      }
    );

    // 表示する注文履歴を取得する
    const getOrderList = computed((): Array<OrderInfoScreen> => state.orderList.slice(0, state.showOrderLength));

    // 表示条件が変更された場合、指定された条件で一覧を取得する
    const onChangeFilter = () => {
      state.page = 1;
      fetchPurchaseList();
    };

    // 次の一覧を取得する
    const fetchNextList = () => {
      state.page += 1;
      fetchPurchaseList();
      state.showOrderLength += 10;
    };

    const getRouterLink = (order: OrderInfoScreen): string => {
      switch (order.kind) {
        case SELL_KIND.TOKUTOKU:
          return `/ec/mypage/odr/tokutoku/${order.odrId}`;
        case SELL_KIND.TRADE_IN:
          return `/ec/mypage/odr/trade/${order.odrId}`;
        default:
          return `/ec/mypage/odr/sell/${order.odrId}`;
      }
    };

    return {
      ...toRefs(state),
      noimage,
      getOrderList,
      onChangeFilter,
      fetchNextList,
      SELL_KIND,
      getRouterLink
    };
  }
});
