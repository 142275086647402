





































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'guarantee',
  components: {
  },
  setup: () => {
    document.title = '５年間保証/下取り 料金シミュレーション | カメラのキタムラネットショップ';
    const state = reactive({
      inputProductPrice: '',
      inputTradeinPrice: '',
      resultHoshouPrice: null as unknown as number,
      resultTotalPrice: null as unknown as number,
      error: ''
    });

    // 計算を行う
    const doCalc = () => {

      if (state.inputProductPrice === '') {
        state.error = '入力してください。'
      } else {

        state.error = ''
        if (state.inputTradeinPrice === '') state.inputTradeinPrice = '0'
        const a = (parseInt(state.inputProductPrice) - parseInt(state.inputTradeinPrice)) * 1.05;// 合計金額
        state.resultTotalPrice = Math.floor(a) // 小数点以下切り捨て
        const b = (parseInt(state.inputProductPrice) - parseInt(state.inputTradeinPrice)) * 0.05;// 保証金額
        state.resultHoshouPrice = Math.floor(b)// 小数点以下切り捨て
      }
    };

    // 初期化
    const initialize = () => {
      state.inputProductPrice = '',
        state.inputTradeinPrice = '',
        state.resultHoshouPrice = null as unknown as number,
        state.resultTotalPrice = null as unknown as number
    }

    return {
      ...toRefs(state),
      doCalc,
      initialize
    };
  }
});
