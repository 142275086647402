
























































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ExpensivePurchasesAndShoppingCreditImage from '@/components/common/special/expensivePurchasesAndShoppingCreditImage.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'canon-5ds',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    naviBtn: NaviBtn,
    expensivePurchasesAndShoppingCreditImage: ExpensivePurchasesAndShoppingCreditImage,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'canon EOS 5Ds/5Ds R 一眼レフ新製品 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン EOS 5Ds /5DsR 一眼レフ新製品特集！50.6Mの超高画質とそれを生み出す最新技術を集結した高解像モデル「EOS 5Ds」と人気の「5D markIII」、「1DX」を比較しました。価格・スペック・お得な情報をチェック！カメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/5ds/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/5ds/sp_img_top.jpg',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/5ds',
      naviList: [
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList1: [
        {
          subtitle: 'ユーザーの想像を超える圧倒的高解像度を実現。35mmフルサイズCMOSセンサー',
          contents: ['有効約5060万画素の高画素でありながらも低ノイズ・広ダイナミックレンジを実現しました']
        },
        {
          subtitle: '高画素を支える優れた基本性能',
          contents: ['被写体を高精度に測距する61点高密度レティクルAFや、優れた動体追従性を発揮するEOS iTR AFでその瞬間を高精度に切り出します']
        },
        {
          subtitle: '高解像度を優先するユーザー向けのさらなる高解像度モデル「EOS 5Ds R」',
          contents: ['さらに高い解像感とキレのよい描写を実現する、ローパスフィルター効果キャンセルモデル。偽色やモアレの抑制よりも解像度を優先するユーザー向け']
        }
      ],
      characteristicList2: [
        {
          subtitle:
            '視界の果ての光と色をも解像し、立体感と質感を生々しいまでに描き出す\n5Ds Rは、さらに高い解像感とキレのよい描写を実現するローパスフィルター効果キャンセルモデル',
          contents: [
            '約5060万画素35mmフルサイズCMOSセンサー搭載',
            '繊細かつ高度な画像処理とカメラ制御を可能にした「デュアルDIGIC 6」',
            '61点高密度レティクルAF搭載、高密度に配置することで被写体の動きにも素早く追従',
            '障害物などによる測距誤差を検知、露光する瞬間の被写体距離を高精度に予測「AIサーボAFIII」',
            '追尾性能を高めた新アルゴリズムを搭載しており、速度や方向の急変にも的確に対応「EOS iTR AF」',
            '測距エリア選択モード6種類、1点スポット～61点自動フォーカスまで被写体に応じて自由に選択'
          ]
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'canon EOS 5Ds / 5Ds R デジタル一眼レフ新製品',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292037760', '4549292037814'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
