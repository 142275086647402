

















































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail, UsedProductsSummary } from '@/types/product';
import { ConditionItem } from '@/types/conditions';
import { ProductItem } from '@/types/product-list';
import { NEWER_SORT_LIST } from '@/constants/sort-list';
import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
import { SEARCH_STATE } from '@/constants/search-state';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'feature',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  props: {},

  //★スライド
  template: '#app-template',
  data: () => ({
    slides: [
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9644.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr5mk2',
        name: 'キヤノン EOS R5 MARK II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9642.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr1',
        name: 'キヤノン EOS R1'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9638.jpg',
        linkUrl: '/ec/special/camera/feature/sony/zv-e10m2',
        name: 'ソニー VLOGCAM ZV-E10 II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9611.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z6III',
        name: 'ニコン Z6III'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9571.jpg',
        linkUrl: '/ec/special/camera/feature/sony/fe16-25mmf28g',
        name: 'ソニー FE 16-25mm F2.8 G [SEL1625G]'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9501.jpg',
        linkUrl: '/ec/special/camera/feature/sony/fe24mm50mmf28g',
        name: 'ソニー FE 24-50mm F2.8 G [SEL2450G]'
      },
      //{
      //  image: 'https://shopimg.kitamura.jp/images/banner/9499.jpg',
      //  linkUrl: '/ec/special/camera/feature/fujifilm/x-100vi',
      //  name: 'フジフイルム X100VI'
      //},
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9461.jpg',
        linkUrl: '/ec/special/camera/feature/omsystem/om1m2',
        name: 'OM SYSTEM OM-1 MARK II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9463.jpg',
        linkUrl: '/ec/special/camera/feature/omsystem/ed150-600mm',
        name: 'OM SYSTEM M.ZUIKO DIGITAL ED 150-600mm F5.0-6.3 IS'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9360.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf10-20mmf4l',
        name: 'キヤノン RF10-20mm F4 L IS STM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9362.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z600f63vrs',
        name: 'ニコン NIKKOR Z 600mm f/6.3 VR S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9350.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z_f',
        name: 'ニコン Z f'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9339.jpg',
        linkUrl: '/ec/special/camera/feature/panasonic/dcg9m2',
        name: 'パナソニック LUMIX G9PROII'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9326.jpg',
        linkUrl: '/ec/special/camera/feature/sony/a7c2-a7cr',
        name: 'ソニー α7C II / α7CR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9328.jpg',
        linkUrl: '/ec/special/camera/feature/sony/fe16-35mmf28gm2',
        name: 'ソニー FE 16-35mm F2.8 GM II [SEL1635GM2]'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9262.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z180-600mmf56-63vr',
        name: 'ニコン NIKKOR Z 180mm-600mm f/5.6-6.3 VR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9238.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xs20',
        name: 'フジフイルム X-S20'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9222.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z_8',
        name: 'ニコン Z 8'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9209.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf100-300-f28l',
        name: 'キヤノン RF100-300mm F2.8 L IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9195.jpg',
        linkUrl: '/ec/special/camera/feature/sony/vlogcam_zv_e1',
        name: 'ソニー VLOGCAM ZV-E1'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9165.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr8',
        name: 'キヤノン EOS R8'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9166.jpg',
        linkUrl: '/ec/special/camera/feature/omsystem/ed90mmf35macro',
        name: 'OM SYSTEM M.ZUIKO DIGITAL ED 90mm F3.5 Macro IS PRO'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9129.jpg',
        linkUrl: '/ec/special/camera/feature/sony/fe20-70mmf4g',
        name: 'ソニー FE 20-70mm F4 G [SEL2070G]'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9112.jpg',
        linkUrl: '/ec/special/camera/feature/panasonic/dcs5m2',
        name: 'パナソニック LUMIX DC-S5M2/DC-S5M2X'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9062.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xt5',
        name: 'フジフイルム X-T5'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9067.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr6mk2',
        name: 'キヤノン EOS R6 MarkII'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9006.jpg',
        linkUrl: '/ec/special/camera/feature/sony/a7rv',
        name: 'ソニー α7R V'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9069.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z600mmf4',
        name: 'ニコン NIKKOR Z 800mm f/6.3 VR S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/9063.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf135',
        name: 'キヤノン RF135mm F1.8 L IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8999.jpg',
        linkUrl: '/ec/special/camera/feature/omsystem/om5',
        name: 'OM SYSTEM OM-5'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8982.jpg',
        linkUrl: '/ec/special/camera/feature/sony/fx30',
        name: 'ソニー Cinema Line カメラ FX30'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8957.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xh2',
        name: 'フジフイルム X-H2'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8955.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xf18-200_xf56',
        name: 'フジフイルム XF18-120mm F4 LM PZ WR / XF56mm F1.2 R WR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8922.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf24f18_rf15-30f45-63',
        name: 'キヤノン RF24mm F1.8 マクロ IS STM / RF15-30mm F4.5-6.3 IS STM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8906.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z30',
        name: 'ニコン Z 30'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8904.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z400mmf45',
        name: 'ニコン NIKKOR Z 400mm f/4.5 VR S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8856.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr7',
        name: 'キヤノン EOS R7'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8854.jpg',
        linkUrl: '/ec/special/camera/feature/sony/sel2470gm2',
        name: 'ソニー FE 24-70mm F2.8 GM II [SEL2470GM2]'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8809.png',
        linkUrl: '/ec/special/camera/feature/sony/selp1635g',
        name: 'ソニー FE PZ 16-35mm F4 G'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8765.jpg',
        linkUrl: '/ec/special/camera/feature/omsystem/om1',
        name: 'OMシステム OM-1'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8786.jpg',
        linkUrl: '/ec/special/camera/feature/panasonic/gh6',
        name: 'パナソニック LUMIX DC-GH6'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8725.jpg',
        linkUrl: '/ec/special/camera/feature/sony/a7iv',
        name: 'ソニー α7 IV'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8686.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z_9',
        name: 'ニコン Z 9'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8688.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z100-400mmf45-56vrs',
        name: 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8658.jpg',
        linkUrl: '/ec/special/camera/feature/sony/sel70200gm2',
        name: 'ソニー FE 70-200mm F2.8 GM OSS II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8630.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr3',
        name: 'キヤノン EOS R3'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8632.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf100-400_rf16',
        name: 'キヤノン RF100-400mm F5.6-8'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8626.jpg',
        linkUrl: '/ec/special/camera/feature/ricoh/gr3x',
        name: 'リコー GR IIIx'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8628.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xf33f14',
        name: 'フジフイルム XF33mm F1.4 R LM WR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8604.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/gfx50sII',
        name: 'フジフイルム GFX 50S II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8551.jpg',
        linkUrl: '/ec/special/camera/feature/sony/vlogcam_zv_e10',
        name: 'ソニー VLOGCAM ZV-E10'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8509.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf1435mmf4lisusm',
        name: 'キヤノン RF14-35mm F4 L IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8495.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/zfc',
        name: 'ニコン Z fc'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8467.jpg',
        linkUrl: '/ec/special/camera/feature/olympus/penEP7',
        name: 'オリンパス PEN E-P7'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8459.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z_mc_105mm_f28_vr_s',
        name: 'ニコン NIKKOR Z MC 105mm f/2.8 VR S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8440.jpg',
        linkUrl: '/ec/special/camera/feature/panasonic/gh5m2',
        name: 'パナソニック LUMIX DC-GH5M2'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8457.jpg',
        linkUrl: '/ec/special/camera/feature/sony/7rm4a7rm3a',
        name: 'ソニー α7R IV A & α7R III A'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8406.png',
        linkUrl: '/ec/special/camera/feature/sony/fe14f18gm',
        name: 'ソニー FE 14mm F1.8 GM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8404.png',
        linkUrl: '/ec/special/camera/feature/tamron/150500mmf567',
        name: 'タムロン 150-500mm F/5-6.7 Di III VC VXD'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8399.png',
        linkUrl: '/ec/special/camera/feature/fujifilm/xf18mmf14rlmwr',
        name: 'フジフイルム XF18mm F1.4 R LM WR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8395.png',
        linkUrl: '/ec/special/camera/feature/canon/rf100f28l',
        name: 'キヤノン RF100mm F2.8 L MACRO IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8397.png',
        linkUrl: '/ec/special/camera/feature/canon/rf400f28l',
        name: 'キヤノン RF400mm F2.8 L IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8377.jpg',
        linkUrl: '/ec/special/camera/feature/pentax/k3mk3',
        name: 'ペンタックス K-3 Mark III'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8381.jpg',
        linkUrl: '/ec/special/camera/feature/sony/sel24f28g',
        name: 'ソニー FE 24mm F2.8 G'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8369.jpg',
        linkUrl: '/ec/special/camera/feature/sigma/fpl',
        name: 'シグマ fp L'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8355.jpg',
        linkUrl: '/ec/special/camera/feature/sony/sel50f12gm',
        name: 'ソニー FE 50mm F1.2 GM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8331.jpg',
        linkUrl: '/ec/special/camera/feature/sony/cinemalinefx3',
        name: 'ソニー Cinema Line FX3'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8199.jpg',
        linkUrl: '/ec/special/camera/feature/sony/a1',
        name: 'ソニー α1'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8233.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/gfx100s',
        name: 'フジフイルム GFX100S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8210.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xe4',
        name: 'フジフイルム X-E4'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8237.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xf70300mmf456rlmoiswr',
        name: 'フジフイルム XF70-300mmF4-5.6 R LM OIS WR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8187.jpg',
        linkUrl: '/ec/special/camera/feature/sony/sel35f14gm',
        name: 'ソニー FE 35mm F1.4 GM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8081.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf50f18',
        name: 'キヤノン RF50mm F1.8 STM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8083.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf70-200-f4l',
        name: 'キヤノン RF70-200mm F4 L IS USM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8037.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xs10',
        name: '富士フイルムX-S10'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8026.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z6IIz7II',
        name: 'ニコン Z 6II ・ Z 7II'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/8023.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eoskissm2',
        name: 'キヤノン EOS Kiss M2'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7945.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z50mm_z14-24mm',
        name: 'NIKKOR Z 50mm f/1.2 S・ NIKKOR Z 14-24mm f/2.8 S'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7941.jpg',
        linkUrl: '/ec/special/camera/feature/sony/7c',
        name: 'ソニーα７C'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7899.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xf50mm-f1rwr',
        name: 'XF50mm F1.0 R WR'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7771.jpg',
        linkUrl: '/ec/special/camera/feature/sony/7sm3',
        name: 'ソニー α7S III'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7757.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/z5',
        name: 'ニコン Z 5'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7727.jpg',
        linkUrl: '/ec/special/camera/feature/panasonic/g100',
        name: 'パナソニック LUMIX G100'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7719.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eosr5r6',
        name: 'キヤノン EOS R5 / EOS R6'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7717.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf100-500mm_f45_71l_is_usm',
        name: 'キヤノン RF100-500mm F4.5-7.1 L IS USM エクステンダー RF1.4X & RF2X'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7730.jpg',
        linkUrl: '/ec/special/camera/feature/canon/rf600mm-rf800mm_f11_is_stm',
        name: 'キヤノン RF600mm/RF800mm F11 IS STM'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7860.jpg',
        linkUrl: '/ec/special/camera/feature/sony/vlogcamzv1zv1g',
        name: 'ソニー VLOGCAM ZV-1/ZV-1G'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7404.jpg',
        linkUrl: '/ec/special/camera/feature/fujifilm/xt4',
        name: '富士フイルム X-T4'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7401.jpg',
        linkUrl: '/ec/special/camera/feature/nikon/d6',
        name: 'ニコン D6'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7350.jpg',
        linkUrl: '/ec/special/camera/feature/canon/eoskissx10i',
        name: 'キヤノン EOS KISS 10i'
      },
      {
        image: 'https://shopimg.kitamura.jp/images/banner/7331.jpg',
        linkUrl: '/ec/special/camera/feature/olympus/em1m3',
        name: 'オリンパス EM1mark3'
      }
    ],
    model: null
  }),
  //スライド

  setup: (props, context) => {
    document.title = 'デジタル一眼レフ/ミラーレス | 最新デジカメ！カメラ新製品';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '最新デジカメ！話題の新製品カメラが続々登場！デジカメ・デジタル一眼レフ・ミラーレス・ビデオカメラや交換レンズのスペックや価格をチェック！日本最大級のカメラ専門店カメラのキタムラのショッピングサイトにおまかせください！'
      );

    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----

      // ↓ ---- メイン商品 ----
      // 取得するJancode
      mainProductJanCode: '4548736130678',
      // 結果格納用
      mainProduct: {} as ProductDetail,
      // ↑ ---- メイン商品 ----

      // ↓ ---- 比較 ----
      // 取得するJancode
      comparisonJanCodeList: ['4548736130678'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736121249'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----

      // ↓ ---- サンプル：検索APIでの表示 ----
      // 検索条件（項目は、仕様を参照）
      searchCondition: [
        // カテゴリー（valueTextに値を設定）
        {
          paramCode: 'category',
          paramText: '',
          value: '',
          valueText: 'ミラーレス一眼'
        },
        // キーワード
        {
          paramCode: 'keyword',
          paramText: '',
          value: 'EOS',
          valueText: ''
        },
        // メーカー
        {
          paramCode: 's3',
          paramText: '',
          value: 'シルバー',
          valueText: ''
        }
      ] as Array<ConditionItem>,
      // 並び替え (設定値は、NEWER_SORT_LIST参照)
      sort: NEWER_SORT_LIST[0].value,
      // 表示件数 (設定値は、DISPLAY_COUNT_LIST参照)
      displayCount: DISPLAY_COUNT_LIST[0].value,
      // 表示ページ
      page: 1,
      // 価格の表示制御 (option 0:新品・中古、 1:新品、 2:中古)
      displayProductPriceType: SEARCH_STATE.option[1].value,
      // 結果格納用
      results: [] as Array<ProductItem>,
      // 中古価格情報
      usedProductsSummary: [] as Array<UsedProductsSummary>
      // ↑ ---- サンプル：検索APIでの表示 ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts([state.mainProductJanCode], true);
        state.mainProduct = mainResult.items[0];
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProduct = {} as ProductDetail;
        // 比較
        state.comparisonDetailList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    /**
     * 検索結果の取得
     */
    const searchNewerProductList = async () => {
      let searchResult;
      // 新品・中古の価格表示情報を検索パラメーターに落とし込み
      state.searchCondition.push({
        paramCode: 'narrow18',
        paramText: '',
        value: state.displayProductPriceType,
        valueText: ''
      });

      // 検索結果取得
      try {
        searchResult = await ProductListService.searchNewItem(state.searchCondition, state.sort, state.displayCount, state.page);
        state.results = searchResult.items;
      } catch (err) {
        searchResult = {
          items: [] as Array<ProductItem>,
          wordItems: [] as Array<{
            keyword: string;
            items: Array<ProductItem>;
          }>
        };
      }

      // 新品用中古情報の取得
      if (
        (state.displayProductPriceType === SEARCH_STATE.option[0].value || state.displayProductPriceType === SEARCH_STATE.option[2].value) &&
        searchResult.items.length
      ) {
        const itemIds = [] as Array<string>;
        searchResult.items.forEach((item) => {
          itemIds.push(item.itemid);
        });
        try {
          const usedProductsResult = await ProductService.searchUsedProductsSummary(itemIds);
          state.usedProductsSummary = usedProductsResult.itemInfo;
        } catch (err) {
          state.usedProductsSummary = [];
        }
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
      searchNewerProductList();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
