var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"my-page-review-post-completed"}},[_c('my-page-layout',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}},[_c('div',{staticClass:"review-post-completed-area"},[(_vm.reviewProduct)?_c('product',{attrs:{"product":{
          janCode: _vm.reviewProduct.janCode,
          makerName: _vm.reviewProduct.makerName,
          itemName: _vm.reviewProduct.itemName,
          imagePath: _vm.reviewProduct.images && _vm.reviewProduct.images.length ? _vm.reviewProduct.images[0].imagePath : ''
        },"isShowReview":false}}):_vm._e(),_c('div',{staticClass:"review-post-completed-message-area"},[_c('span',{staticClass:"d-inline-block"},[_vm._v("投稿が完了しました。")]),_c('span',{staticClass:"d-inline-block"},[_vm._v("ありがとうございました。")])])],1),_c('div',{staticClass:"review-post-completed-footer-area"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"footer-btn ec-link-btn",attrs:{"depressed":"","to":'/ec/pd/' + _vm.janCode}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-angle-left")]),_vm._v("元の商品に戻る ")],1):_vm._e()],1)]),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"to":'/ec/pd/' + _vm.janCode}},[_vm._v("元の商品に戻る")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }