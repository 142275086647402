







































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';

import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sony-5074',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ソニー Cyber-shot DSC-HX90V/WX500 | カメラのキタムラネットショップ';

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'ソニー Cyber-shot DSC-HX90V/WX500',
          disabled: true
        }
      ],
      accessoryList: [
        {
          titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_33.png',
          titleImageAlt: 'ファッションに合わせて選べる専用ジャケットケース',
          href:
            '/ec/list?narrow18=0&keyword=ソニー%20ジャケットケース%20LCJ-HWA&category=&r=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=ソニー%20ジャケットケース%20LCJ-HWA&x=0&y=0',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_34.png',
          leftImageAlt: 'ジャケットケース LCJ-HWA「NEW」「購入はこちら」カラー：（T）ブラウン、（TI）ライトブラウン、（B）ブラック',
          rightImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_35.png',
          rightImageAlt: ''
        },
        {
          titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_36.png',
          titleImageAlt: '思い出を撮り逃さないお得で便利なアクセサリーキット',
          href: '/ec/pd/4905524899054',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_37.png',
          leftImageAlt: 'バッテリー（NP-BX1）とUSB出力が可能なバッテリーチャージャー（BC-TRX） アクセサリーキット ACC-TRBX 「購入はこちら」',
          rightImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_38.png',
          rightImageAlt: 'こんなとき、予備バッテリーがあれば・・・'
        }
      ],
      accessoryList2: [
        {
          href: '/ec/pd/4905524948813',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_39.png',
          alt: 'モニターが見えにくくなる太陽光などの反射を低減 モニター保護シート PCK-LS30「購入はこちら」'
        },
        {
          href: '/ec/pd/4901780986446',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_40.png',
          alt: '旅行などに携帯しやすい軽量・コンパクト設計 三脚 VCT-R100「購入はこちら」'
        },
        {
          href:
            '/ec/list?narrow18=0&keyword=ソニー%20SDHCメモリーカード%20SF-&category=メモリーカード・記録メディア&r=&fmt=json&limit=20&path=メモリーカード・記録メディア&q=ソニー%20SDHCメモリーカード%20SF-&s1%5B%5D=ソニー&s19%5B%5D=1&sort=number20,rank,Score&style=0&index=all',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/hx90vwx500/cybershot_cut_41.png',
          alt: '撮影データの記録に適したSDXC/SDHC/SDメモリーカード（Class4） 記録メディア〈容量〉64GB/32GB/16GB/8GB/4GB「購入はこちら」'
        }
      ]
    });
    return {
      ...toRefs(state)
    };
  }
});
