



























































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';

import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'vlogcam_zv_e10',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    characteristic: Characteristic,
    shasha: ShaSha,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー VLOGCAM ZV-E10 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー VLOGCAM ZV-E10 好評発売中！比較や、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      characteristicList: [
        {
          subtitle: 'レンズ交換式ならではの多彩で印象的な映像表現と、印象的な表現を引き立たせる高画質な動画撮影機能を搭載',
          contents: [
            'ソニーのEマウントレンズ群64本（※2021年7月27日時点）からレンズを選択可能。動画でも静止画でも、Vlogger（ブイロガー）や幅広いユーザーの創作意欲に応えられる多彩な映像撮影が可能',
            'APS-Cサイズのイメージセンサーとレンズ交換により、ぼけ表現を生かした印象的な高画質4K動画や、印象的な映像表現のフルHDでの高画質スローモーション動画も簡単に撮影可能',
            '被写体が次々と変わるシーンでも被写体にピントを素早く移し、被写体を捉え続けることができるファストハイブリッドAFシステム搭載。カメラに任せのフォーカシングでも安心して撮影が可能',
            '動画撮影時に使用できる電子式手ブレ補正アクティブモードを搭載。手持ちによる歩き撮りなどさまざまな場面でブレを気にせず撮影が可能'
          ]
        },
        {
          subtitle: 'さまざまなVlog撮影スタイルに対応するこだわりのデザインと撮影をサポートする多彩な機能を搭載',
          contents: [
            '動画撮影のしやすさも考えた機能とデザインを採用。「操作しやすいボタンの配置」「握りやすいグリップ」「バリアングル液晶モニター」を搭載等、撮影に集中できるデザインを実現',
            '動画撮影時の「リアルタイム瞳AF（人物）」や液晶モニタータッチするだけで、ピント合わせや被写体追尾可能な「リアルタイムトラッキング」に対応。シーンを選ばず主役を際立たせることが可能',
            '高性能な内蔵マイクと、デジタルオーディオインターフェースに対応した、マルチインターフェースシューを搭載。高音質な音声記録のサポートも充実しています',
            'ワンタッチで瞬時に表現の切り換えができる「背景ぼけ切換ボタン」や、素早くピントの移動ができる「商品レビュー用設定」を搭載。初心者でも簡単にVlog撮影が可能',
            'αシリーズの4K撮影機能搭載カメラとして最軽量の質量343gと手軽に持ち運び可能。日常の撮影もストレスなく行えます'
          ]
        },
        {
          subtitle: '優れた静止画撮影機能と安心の基本性能',
          contents: [
            '動く人物や動物の瞳を自動的に捉え、高精度に追随する「リアルタイム瞳AF」、カメラまかせで被写体を自動で追い続ける「リアルタイムトラッキング」などの先進的なAF性能を搭載',
            'えて、世界最速0.02秒の高速AFと最高約11コマ/秒の連写性能も実現。撮りたい瞬間を逃さず撮影することが可能',
            '静止画モードと動画モードで別々の機能をFnボタンに割り当てることが可能な、自分好みにカスタマイズできる便利なカスタム性能搭載。お好みの機能をボタン1つで呼び出しが可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー VLOGCAM ZV-E10',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736128354', '4548736128361', '4548736128521', '4548736128552'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736128354', '4548736092044', '4549980632239', '4960759906311'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736128354'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
