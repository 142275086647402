import { render, staticRenderFns } from "./usedsaleweek.vue?vue&type=template&id=1097e977&scoped=true&"
import script from "./usedsaleweek.vue?vue&type=script&lang=ts&"
export * from "./usedsaleweek.vue?vue&type=script&lang=ts&"
import style0 from "./usedsaleweek.vue?vue&type=style&index=0&id=1097e977&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1097e977",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCol})
