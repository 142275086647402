
























































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, PropType, watch, computed } from '@vue/composition-api';
import ProductService from '@/logic/product.service';
import { ProductItem } from '@/types/product-list';
import { SEARCH_STATE } from '@/constants/search-state';
import { noimage, formatPrice, validationPeriod } from '@/logic/utils';
import productFavoriteDialog from '../common/product-favorite-dialog.vue';
import { MemberProduct } from '@/types/product';
import { SecretInfo } from '@/types/special-product';

// TODO: searchUsedProductsSummaryのリターンがanyではなくなったら、修正
type usedProductsSummary = {
  janCode: string;
  itemCount: number;
  minPrice: number;
};

type productPriceLabel = {
  label: string;
  price: string;
  cssClass: string;
};

export default Vue.extend({
  name: 'product',
  components: {
    'product-favorite-dialog': productFavoriteDialog
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    displayPriceType: {
      type: String,
      required: true
    },
    usedProductsSummary: {
      type: Object as PropType<usedProductsSummary>,
      required: false
    },
    isSpecial: {
      type: Boolean,
      default: false,
      required: false
    },
    isUsedSpecial: {
      type: Boolean,
      default: false,
      required: false
    },
    secretInfo: {
      type: Object as PropType<SecretInfo>,
      required: false
    },
    today: {
      type: String,
      default: '',
      required: false
    }
  },
  setup: (props, context) => {
    const { authorizer, comparison, product } = context.root.$store;

    const state = reactive({
      stateProduct: props.product as ProductItem,
      memberProduct: {} as MemberProduct,
      productMakerName: '',
      comparisonCheck: false,
      favoriteDialog: false,
      // 中古情報
      productUsedMinPrice: '0',
      productUsedItemCount: 0,
      // hover style制御用
      isHover: false
    });

    // 比較チェック処理
    watch(
      () => comparison.comparisonList,
      (list) => {
        state.comparisonCheck = list.includes(state.stateProduct.itemid);
      },
      { immediate: true }
    );
    watch(
      () => state.comparisonCheck,
      (newVal) => {
        newVal ? comparison.add(state.stateProduct.itemid) : comparison.remove(state.stateProduct.itemid);
      }
    );

    // 新品用中古情報チェック処理
    watch(
      () => props.usedProductsSummary,
      () => {
        // 新品中古情報の格納
        if (props.usedProductsSummary) {
          const usedProductsSummary = props.usedProductsSummary as usedProductsSummary;
          state.productUsedMinPrice = formatPrice(usedProductsSummary.minPrice);
          state.productUsedItemCount = usedProductsSummary.itemCount;
        }
      }
    );

    // お気に入り商品登録
    const clickFavorite = async () => {
      const isLoggedIn = authorizer.isLoggedIn as boolean;
      if (isLoggedIn) {
        state.favoriteDialog = true;
      } else {
        alert('お気に入り登録はログインが必要です。');
        const successCallback = () => {
          state.favoriteDialog = true;
        };
        authorizer.openLoginMenu(successCallback);
      }
    };

    // お気に入り登録ダイアログを閉じる
    function onFavoriteDialogClosed() {
      state.favoriteDialog = false;
    }

    // お気に入り登録時
    async function onFavoriteRegisterd() {
      try {
        const memberProduct = await ProductService.fetchMemberProduct(state.stateProduct.itemid);
        state.memberProduct = memberProduct;
      } catch (error) {
        console.error(error);
      }
      state.favoriteDialog = false;
    }

    // 特集の限定商品用の遷移処理
    const toSpecialDetail = (url: string) => {
      product.secretInfo = props.secretInfo;
      context.root.$router.push({ path: url });
    };

    // 商品価格, 表示ラベル
    const productPrice = computed(
      (): productPriceLabel => {
        const isLoggedIn = authorizer.isLoggedIn as boolean;
        const displayPrice = {} as productPriceLabel;

        if (props.isSpecial) {
          displayPrice.label = '価格';
          displayPrice.price = state.stateProduct.isSalesEnd ? '-' : formatPrice(parseInt(state.stateProduct.price));
          displayPrice.cssClass = '';
        } else if (
          state.stateProduct.data5 &&
          state.stateProduct.data6 &&
          validationPeriod(props.today as string, state.stateProduct.data5, state.stateProduct.data6)
        ) {
          if (state.stateProduct.number20 === '1') {
            displayPrice.label = '価格';
            displayPrice.price = '-';
          } else {
            if (isLoggedIn && state.stateProduct.data4 && +state.stateProduct.data4 >= 1) {
              displayPrice.label = '特別価格';
              displayPrice.price = formatPrice(parseInt(state.stateProduct.data4));
              displayPrice.cssClass = 'font-weight-bold';
            } else if (state.stateProduct.data3 && +state.stateProduct.data3 >= 1) {
              displayPrice.label = '特別価格';
              displayPrice.price = formatPrice(parseInt(state.stateProduct.data3));
              displayPrice.cssClass = 'font-weight-bold';
            } else if (isLoggedIn && state.stateProduct.data2 && +state.stateProduct.data2 >= 1) {
              displayPrice.label = '会員価格';
              displayPrice.price = formatPrice(parseInt(state.stateProduct.data2));
              displayPrice.cssClass = '';
            } else {
              displayPrice.label = '価格';
              displayPrice.price = formatPrice(parseInt(state.stateProduct.price));
              displayPrice.cssClass = '';
            }
          }
        } else {
          displayPrice.label = '価格';
          displayPrice.price = state.stateProduct.number20 === '1' ? '-' : formatPrice(parseInt(state.stateProduct.price));
          displayPrice.cssClass = '';
        }
        return displayPrice;
      }
    );

    return {
      ...toRefs(state),
      SEARCH_STATE,
      authorizer,
      comparison,
      clickFavorite,
      onFavoriteRegisterd,
      onFavoriteDialogClosed,
      noimage,
      productPrice,
      toSpecialDetail
    };
  }
});
