

















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';

export default Vue.extend({
  name: 'tripod-type-footer',
  components: {
    recommendedFeatures: RecommendedFeatures,
  },
  setup: () => {
    const state = reactive({
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest-nd',
          img: 'https://shop.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/camera/okamoto-yutaka',
          img: 'https://shopimg.kitamura.jp/images/banner/7678.jpg',
          alt: '航空写真家 岡本豊先生のレオフォト三脚講座'
        },
        {
          href: 'https://shop.kitamura.jp/special/sale-fair/general/how-to/hanabi/',
          img: 'https://shopimg.kitamura.jp/images/banner/3043.jpg',
          alt: '花火特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
    });

    return {
      ...toRefs(state)
    };
  }
});
