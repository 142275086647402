




































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'skillup-02',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '初心者でもできる！プロのような写真を撮るコツ | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '初心者でもできる！プロのような写真を撮るコツ');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '初心者でもできる！プロのような写真を撮るコツ',
          disabled: true
        }
      ],
      // ↓ ランキング取得するJancode
      productJanCodeList: ['4960759906335', '4548736079625', '4549292171433', '4547410440379', '4960759905901', '4960759906311'],
      productDetailList: [] as Array<ProductDetail>, // 結果格納用
      productJanCodeList2: ['4549292138313', '4960759146441', '4549292132670', '4549212297397', '4549292151251', '4549292075748'],
      productDetailList2: [] as Array<ProductDetail> // 結果格納用
      // ↑ 取得するJancode
    });
    /**↓ 商品詳細を取得する**/
    const fetchProduct = async () => {
      try {
        // ランキング商品
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
      } catch (error) {
        console.error(error);
        state.productDetailList = [] as Array<ProductDetail>; // ランキング商品1
        state.productDetailList2 = [] as Array<ProductDetail>; // ランキング商品2
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
