
















































































































































































































































































































































































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import ProductAppeal from '@/components/common/special/productAppeal.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'mirrorless-oldlens',
  components: {
    breadcrumbs: Breadcrumbs,
    productAppeal: ProductAppeal,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'レトロなのに新しい！ミラーレス一眼とオールドレンズで撮影しよう | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '「オールドレンズを使って撮影できるの?」とか「撮影が難しそう」と不安な方へ！ミラーレスとマウントアダプターを組み合わせる事で手軽に撮影を楽しむことができます'
      );
    const state = reactive({
      // ↓ ---- 商品価格 ----
      topProductJanCode: ['4548736133730'],
      topProductList: [] as Array<ProductDetail>,

      recommendedFeaturesList: [
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/1341.jpg',
          alt: '最新デジカメ新製品'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/dslr',
          img: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          alt: '一眼レフ&ミラーレス特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/girlscamera',
          img: 'https://shopimg.kitamura.jp/images/banner/1390.jpg',
          alt: '女子がきゅんとくるミラーレス一眼カメラ'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/filmcamera/utsurundesu',
          img: 'https://shopimg.kitamura.jp/images/banner/4115.gif',
          alt: '写ルンです 使い方・楽しみ方特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shop.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://shop.kitamura.jp/ec/list?type=u&limit=40&page=1&sort=update_date',
          img: 'https://shop.kitamura.jp/images/banner/4637.gif',
          alt: '新着中古'
        }
      ],
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'レトロなのに新しい！ミラーレス一眼とオールドレンズで撮影しよう',
          disabled: true
        }
      ]
      // ↑ ---- パンくず情報 ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 商品
        const topResult = await ProductService.fetchProducts(state.topProductJanCode, true);
        state.topProductList = topResult.items;
      } catch (error) {
        // 商品
        state.topProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
