import { render, staticRenderFns } from "./item-canon.vue?vue&type=template&id=12b8a2f0&scoped=true&"
import script from "./item-canon.vue?vue&type=script&lang=ts&"
export * from "./item-canon.vue?vue&type=script&lang=ts&"
import style0 from "./item-canon.vue?vue&type=style&index=0&id=12b8a2f0&lang=scss&scoped=true&"
import style1 from "./item-canon.vue?vue&type=style&index=1&id=12b8a2f0&lang=scss&scoped=true&"
import style2 from "./item-canon.vue?vue&type=style&index=2&id=12b8a2f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b8a2f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCol,VContainer,VRow})
