import { render, staticRenderFns } from "./filmcamera.vue?vue&type=template&id=00af831a&scoped=true&"
import script from "./filmcamera.vue?vue&type=script&lang=ts&"
export * from "./filmcamera.vue?vue&type=script&lang=ts&"
import style0 from "./filmcamera.vue?vue&type=style&index=0&id=00af831a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00af831a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCol,VContainer,VIcon,VRow})
