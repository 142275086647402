













































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';
import AppHeader from '@/components/header/app-header.vue';
import AppFooter from '@/components/footer/app-footer.vue';
import NavDrawerMenu from '@/components/header/nav-drawer-menu/nav-drawer-menu.vue';
import OverlayLoading from '@/components/common/overlay-loading.vue';
import ErrorDialog from './components/common/error-dialog.vue';
import ConfirmDialog from './components/common/confirm-dialog.vue';
import GtmService from '@/logic/gtm.service';
import ChatBotService from '@/logic/chatbot.service';

export default Vue.extend({
  name: 'app',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    'nav-drawer-menu': NavDrawerMenu,
    'overlay-loading': OverlayLoading,
    'error-dialog': ErrorDialog,
    'confirm-dialog': ConfirmDialog
  },
  setup: (_, context) => {
    const { searcher } = context.root.$store;
    const state = reactive({
      drawer: false,
      rendered: false,
      simpleHeader: false,
      visibleHeader: true,
      visibleFooter: true,
      fixedSearcher: false
    });

    // routerイベントをwatch
    watch(
      () => context.root.$store.loader.isRouteLoaded,
      (isRouteLoaded) => {
        state.rendered = isRouteLoaded;
      },
      { deep: true, immediate: true }
    );

    watch(
      () => context.root.$route.meta,
      () => {
        const meta = context.root.$route.meta;
        state.simpleHeader = meta?.simpleHeader != null ? meta.simpleHeader : false;
        state.visibleHeader = meta?.visibleHeader !== undefined ? meta.visibleHeader : true;
        state.visibleFooter = meta?.visibleFooter !== undefined ? meta.visibleFooter : true;
      },
      { immediate: true }
    );

    /**
     * 画面遷移時に実行
     */
    watch(
      () => context.root.$route,
      () => {
        // GTMイベントを発火する
        const dataLayer = {
          GA_mediaForgeMerchantId: context.root.$vuetify.breakpoint.mdAndUp ? 6764 : 6772 // eslint-disable-line @typescript-eslint/camelcase
        };
        GtmService.trackEvent('content-view-option', dataLayer);
        // チャットボットを非表示
        ChatBotService.hide();
      }
    );

    /**
     * 検索ボックスの固定状態監視
     */
    watch(
      () => searcher.fixed,
      () => {
        state.fixedSearcher = searcher.fixed;
      }
    );

    /**
     * スマホ版のナビゲーションメニューを閉じる
     */
    const closeMenu = () => (state.drawer = false);

    return {
      closeMenu,
      ...toRefs(state)
    };
  }
});
