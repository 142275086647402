



































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { noimage } from '@/logic/utils';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import IntenseRedBtn from '@/components/common/special/intenseRedBtn.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'graduation_entrance',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    topTitleImg: TopTitleImg,
    intenseRedBtn: IntenseRedBtn,
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    // ページタイトル
    document.title = '卒業・入学特集！担当者おすすめのミラーレス一眼やコンパクトデジタルカメラなど集めました | カメラのキタムラネットショップ';
    // description
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '入学式・卒業式を迎えられるご家族のみなさま、おめでとうございます。卒業/卒園式・入園/入学式でカメラやビデオ撮影をお考えのパパママ必見！カメラのキタムラおすすめ商品をまとめました！お子さまの晴れ姿を上手に残しましょう');

    const state = reactive({
      // パンくず情報をbreadcrumbsにセットします
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '卒業・入学特集！おすすめのカメラ大集合！',
          disabled: true
        }
      ],
      // おすすめの理由！キタムラおすすめビデオカメラ・一眼レフ・レンズ・デジタルカメラのリストをrecommendedCameraListにセットします
      recommendedCameraList: [
        {
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/camera_c.png',
          href: '/ec/special/general/graduation_entrance/942',
          title: '強力な手ブレ補正機能をもつ★ビデオカメラ',
          txt:
            'ベストポジションを探すうちに無理な体勢になり手ブレが気になってしまう…。そんなときには強力な手ブレ補正機能を持つ、このビデオカメラがおすすめ！走りながらの撮影にも耐えられるほどの強力な手ブレ補正で、大切な思い出をキレイに残せます♪\nさらに広角での撮影が可能ですので、室内でも全体を撮影でき、その場の雰囲気も無理なく撮影できるんです！',
          intenseRedBtntxt: 'ソニー デジタルHDビデオカメラ\nレコーダー HDR-CX680 ',
          orangeBtntxt: 'キタムラおすすめビデオカメラ もっと見る',
          orangeBtnHref: '/ec/special/general/graduation_entrance/1441'
        }
      ],
      // 便利な商品をご紹介の商品リストをconvenientProductListにセットします
      convenientProductList: [
        {
          href: '/ec/special/general/graduation_entrance/2044',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/cameray_c.png'
        },
        {
          href: '/ec/special/general/graduation_entrance/5887',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/videoy_b.png'
        },
        {
          href: '/ec/special/general/graduation_entrance/3278',
          src: '	https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/cameray_a.png'
        },
        {
          href: '/ec/special/general/graduation_entrance/2035',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/cheki.png'
        }
      ],
      // 入学の準備におすすめ！なまえラベル＆お弁当生活におすすめ商品リストをpreparationForAdmissionListにセットします
      preparationForAdmissionList: [
        {
          title: 'ぺたねーむEXPRESS',
          txt: 'LINE注文、ネット注文、店頭注文でお名前シール承ります！',
          href: 'https://www.kitamura-print.com/petaname/',
          src: 'https://shopimg.kitamura.jp/images/banner/9475.png'
        },
        {
          title: '「名前ラベル」を使って名前を貼ろう',
          txt: '持ち物に、ひとつひとつ名前を書くのは大変！ そんなときは、名前ラベルシールを使いましょう！',
          href: '/ec/special/general/graduation_entrance/2698',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/bnr_name.jpg'
        },
        {
          title: 'お弁当生活のスタートにおすすめの商品',
          txt: 'お弁当生活に便利でおすすめな商品を集めました！ 新生活のスタートに最適です！',
          href: '/ec/special/kaden/bento',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/bnr_sh350130.jpg'
        }
      ],
      // 卒園/卒業・入園/入学を撮影した写真の「 整理 」におすすめプリントサービスリストをprintServiceListにセットします
      printServiceList: [
        {
          title: 'フォトプラスブック',
          txt: '光沢表紙・マット表紙から選べます。\n中のページも厚みのある、丈夫な仕上がりのものがあります。',
          href: 'https://photobook.kitamura.jp/photoplus/',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/plus_logo.jpg',
          src2: '	https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/plus.png'
        },
        {
          title: 'イヤーアルバム',
          txt:
            '人物の顔や写真の明るさを解析して、おすすめ写真を自動レイアウト。\nおしゃれな表紙デザイン・中ページデザインが人気。傷や色あせなどの耐久性にも優れています。',
          href: 'https://photobook.kitamura.jp/yearalbum/',
          src: 'https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/year_logo.gif',
          src2: '	https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/year.png'
        }
      ],
      // 卒園/卒業・入園/入学撮影のコツや『 ビデオ・カメラ選び 』に関する疑問にお答えしますQ＆AデータをshootingTipsAndCameraSelectionListにセットします
      shootingTipsAndCameraSelectionList: [
        {
          title: '卒園式・入学式の撮影のコツを伝授します',
          txt: '卒業式を撮影するのは実はとっても難しい…。\n失敗したくないパパ・ママ必見です♪',
          href: 'https://www.kitamura-print.com/special/knack/graduation.html',
          src: '	https://shop.kitamura.jp/ec/images2/special/general/graduation_entrance/bot_kotu.gif',
          btntxt: '撮影のコツを詳しく見る'
        },
        {
          title: 'カメラ選びの疑問に関するQ＆A',
          txt: 'カメラのキタムラへ寄せられる『 ビデオ・カメラ選び 』に関する疑問にお答えします！',
          href: '/ec/special/general/graduation_entrance/faq',
          src: 'https://shopimg.kitamura.jp/images/banner/3645.gif',
          btntxt: '特集を詳しく見る '
        }
      ],
      // おすすめ特集・プリントサービスリストをrecommendedFeaturesListにセットします
      recommendedFeaturesList: [
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        },
        {
          href: '/ec/special/camera/album/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/3597.jpg',
          alt: '手作りアルバム特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/general/wirelessimageshare',
          img: 'https://shopimg.kitamura.jp/images/banner/2785.gif',
          alt: 'デジカメとスマホを無線でつないで写真を楽しもう'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/1341.jpg',
          alt: '話題の新製品'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736100503'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
