











































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'g9',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    appearanceImage: AppearanceImage,
    urlcopy: Urlcopy,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'パナソニック DC-G9 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'パナソニック LUMIX DC-G9 販売中♪前機種からの進化点、オリンパス E-M1 MarkIIとの比較や、価格・スペック・お得な情報をチェック！DC-G9の実写レビューもご紹介。新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'パナソニック LUMIX DC-G9',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549980016695'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549980016695', '4545350050849', '4549077809575'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549980016695'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: 'お得情報', href: '#price2' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '高精細20.3M Live MOSセンサー＆世界最高6.5段ボディ内手ブレ補正で、LUMIX史上最高画質を実現',
          contents: [
            'ローパスフィルターレス設計のセンサーと最新の画像処理エンジンにより、階調特性・色再現性などを向上',
            'センサーの表面にARコーティング処理を新たに施し、逆光の撮影時も効果的にフレアを抑制',
            '世界最高の6.5段分のボディ内手ブレ補正を実現。「Dual I.S. 2」で望遠域まで6.5段分補正効果',
            '約80M画素の高解像写真を生成する「ハイレゾモード」で、精細感や臨場感を最大限に記録'
          ]
        },
        {
          subtitle: '決定的瞬間を撮り逃さない機動力、世界最速AF約0.04秒と高速連写',
          contents: [
            'AF合焦速度は世界最速の約0.04秒を実現。手前に向かってくる被写体にもしっかりとフォーカス',
            '電子シャッター連写機能は、AF追従連写で約20コマ/秒、AF固定連写で約60コマ/秒の超高速連写',
            '約18Mの高画素で、秒間30コマ高速連写の「6Kフォト」機能を搭載。A1サイズまでプリント引き伸ばしが可能'
          ]
        },
        {
          subtitle: '直感的に操れ、撮影に集中できる優れた操作性＆デザイン',
          contents: [
            '倍率約0.83倍を備えた約368万ドット高精細OLEDファインダーを搭載。状況に応じて倍率の切換も可能',
            '本体天面にステータスLCDを新搭載。基本的な撮影設定などを素早く確認できよりスピーディな撮影をサポート',
            '「ファンクションレバー」をボディ正面に新搭載。モニターを確認することなく、設定の切換が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'http://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラ・レンズ メンテナンスサービス'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/special/camera/lens',
          img: 'http://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
