


















































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
export default Vue.extend({
  name: 'highly-recommended-2015-0828',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = 'フランス生まれのシンプルなスマートウォッチ「Withings Activite Pop」 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '美しいスマートウォッチ”として話題になった「Wthings Activite」の弟分、お求めやすい価格の「Withings Activite Pop（ウィジングズ アクティビテ ポップ）」が登場!フランス生まれのシンプルなスマートウォッチは、どんな場面、服装にも合わせやすいデザインで、あなたを虜にします。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'フランス生まれのスマートウォッチ Withings Activite Pop | スタッフのイチオシ',
          disabled: true
        }
      ],
      backNumberCameraList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-coolshot20_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0821', name: 'ゴルフで大活躍！ニコン携帯型レーザー距離計 COOLSHOT 20 ' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-sarafit_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0807', name: 'TVでお馴染み焦げ付かないセラミックフライパン「セラフィット」' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0731', name: '可変式ND(減光)フィルター「ケンコー バリアブルNDX」' },
      ],
      backNumberSmartphoneList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-elecom_high-reso_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0814', name: '「意外にいい音」、エレコム ハイレゾイヤホンシリーズ EHP-CH1000 他' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-ztylus_500px.jpg', href: '/ec/special/general/highly_recommended/2015/0710', name: 'iPhone6用レンズアタッチメント ズタイラス RV-2 & ZIP-6LB' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-ct-wpip13_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0619', name: '防水、耐衝撃の強靭なiPhoneケース Catalyst Case for iPhone 6' },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
