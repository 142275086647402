import { render, staticRenderFns } from "./bottle.vue?vue&type=template&id=3473c0c3&scoped=true&"
import script from "./bottle.vue?vue&type=script&lang=ts&"
export * from "./bottle.vue?vue&type=script&lang=ts&"
import style0 from "./bottle.vue?vue&type=style&index=0&id=3473c0c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3473c0c3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCol,VContainer,VHover,VIcon,VImg,VRow})
