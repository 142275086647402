var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"my-page-top"}},[_c('my-page-layout',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}},[_c('div',{attrs:{"id":"my-page-top-contents"}},[_c('div',{staticClass:"d-flex my-3"},[_c('v-btn',{staticClass:"flex-grow-1 mr-3 top-menu-btn focus",attrs:{"id":"usage-history-btn","outlined":"","depressed":"","x-large":"","to":"/ec/mypage/odr/list"}},[_c('i',{staticClass:"far fa-history mr-1"}),_vm._v(" ご利用履歴 (購入) ")])],1),_c('div',{staticClass:"d-flex my-3"},[_c('v-btn',{staticClass:"flex-grow-1 mr-3 top-menu-btn focus",attrs:{"id":"usage-history-btn","outlined":"","depressed":"","x-large":"","to":"/ec/mypage/odr/list?type=sell"}},[_c('i',{staticClass:"far fa-yen-sign mr-1"}),_vm._v(" ご利用履歴 (買取・下取) ")])],1),_c('div',{staticClass:"d-flex my-3"},[_c('v-btn',{staticClass:"flex-grow-1 top-menu-btn",attrs:{"id":"favorite-product-btn","outlined":"","depressed":"","x-large":"","to":"/ec/mypage/favorite/list"}},[_c('i',{staticClass:"far fa-heart mr-1"}),_vm._v(" お気に入り商品 ")])],1),_c('div',{staticClass:"my-news-area"},[_c('h2',{staticClass:"my-news-title"},[_vm._v(_vm._s(_vm.userName)+"さまへお知らせ")]),_c('div',{staticClass:"my-news-item-area"},[_c('news-list',{attrs:{"newsList":_vm.newsList}}),(!_vm.loaded.news)?_c('div',[_c('section-loading')],1):_vm._e(),_c('div',{staticClass:"d-flex justify-end secondary-link"},[_c('router-link',{staticClass:"my-news-link",attrs:{"to":"/ec/mypage/news"}},[_c('span',{staticClass:"secondary-link-text"},[_vm._v("お知らせ一覧を見る")]),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])],1)],1)])])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"my-page-layout-menu",attrs:{"id":"menu-list"}},[_c('my-page-menu')],1):_vm._e(),(!_vm.loaded.checkedProducts || (_vm.checkedProducts && _vm.checkedProducts.length > 0))?_c('div',{staticClass:"ec-back-color",class:{
      'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow full-width': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"checked-products"}},[_c('div',{class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('span',{staticClass:"content-title",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v(" 最近チェックした商品 ")]),(_vm.loaded.checkedProducts)?_c('product-slider',{attrs:{"recommend":_vm.checkedProductsRecommendInstance,"items":_vm.checkedProducts,"isUsed":false,"isRelatedProducts":false,"cartButtonText":"カートに入れる"}}):_c('div',[_c('section-loading')],1)],1)]):_vm._e(),(!_vm.loaded.recommendProducts || (_vm.recommendProducts && _vm.recommendProducts.length > 0))?_c('div',{staticClass:"ec-back-color",class:{
      'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow full-width': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"recoomend-products"}},[_c('div',{class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('span',{staticClass:"content-title",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v(" あなたにおすすめ ")]),(_vm.loaded.recommendProducts)?_c('product-slider',{attrs:{"recommend":_vm.recommendProductsRecommendInstance,"items":_vm.recommendProducts,"isUsed":false,"isRelatedProducts":false,"cartButtonText":"カートに入れる"}}):_c('div',[_c('section-loading')],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }