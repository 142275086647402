

















import Vue from 'vue';

export default Vue.extend({
  name: 'more-items-button',
  props: {
    text: {
      type: String,
      default: 'もっと見る'
    }
  }
});
