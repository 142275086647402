




















































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'feature',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '写真台紙特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '結婚式・七五三などの記念写真から、メッセージカードとしても使える写真台紙を多数取り揃えております。写真台紙はカメラのキタムラにおまかせ下さい'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '写真台紙特集',
          disabled: true
        }
      ],
      recommendedPhotoMountList: [
        { txt: '婚礼やお祝い写真を贈るのにピッタリ', href: '/ec/special/camera/photomount/feature/316' },
        { txt: 'メッセージと一緒に贈るのにピッタリ', href: '/ec/special/camera/photomount/feature/317' },
        { txt: '保管するのにもピッタリな普通台紙', href: '/ec/special/camera/photomount/feature/318' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/photomount',
          img: 'https://shop.kitamura.jp/images/banner/3611.gif',
          alt: '写真台紙 おすすめシリーズ紹介'
        },
        {
          href: '/ec/special/general/graduation_entrance',
          img: 'https://shop.kitamura.jp/images/banner/1929.gif',
          alt: '卒業・入学特集'
        },
        {
          href: 'ec/special/general/sportsday',
          img: 'https://shop.kitamura.jp/images/banner/2006.gif',
          alt: 'パパ・ママ応援！運動会特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
