






























































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'd6',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    kitamuraService: KitamuraService,
    priceAndPurchase: PriceAndPurchase,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    shasha: ShaSha,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '好評発売中！ニコン D6 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン D6 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！ ニコン D6をご購入時、ニコン D5 ボディ ブラック高値下取り実施中'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ニコン D5 ボディ',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=%E3%83%8B%E3%82%B3%E3%83%B3%20D5%20%E3%83%9C%E3%83%87%E3%82%A3%EF%BC%88&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
        nameImg: '/images/pd/840/032/7c3/1a3/b55/6b0/6b0/25a/eb7/713/a1j/rd5/7yf/3/L.jpg',
        txt: 'ニコンD６',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/d6/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ニコン D5 ボディ',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=%E3%83%8B%E3%82%B3%E3%83%B3%20D5%20%E3%83%9C%E3%83%87%E3%82%A3%EF%BC%88&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort='
          },
          {
            name: 'D850',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=D850&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort='
          },
          {
            name: 'D4s',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=D4s&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort='
          }
        ]
      },
      characteristicList: [
        {
          subtitle: 'ニコン史上最強。高密度配置の全点クロスタイプセンサー105点AFシステム',
          contents: [
            '105点のフォーカスポイントはすべてクロスセンサーで、全点が選択可能。AFエリアをD5比約1.6倍の高密度でカバー',
            '新開発のAF専用エンジンにより、約14コマ/秒の高速連続撮影時にも全点同時測距を実現',
            '瞳を優先したAFも可能にするアドバンストシーン認識システム搭載で被写体の平面方向への動きの追尾性能が向上'
          ]
        },
        {
          subtitle: '撮影後のワークフローを効率化する多彩な機能と通信機能を搭載',
          contents: [
            '最も競争力のある画像をフリック操作で優先して送信できる「送信指定」で最重要な画像をいち早く送信可能',
            'D5で高い評価を受けてきた有線LANによる画像転送速度はD6でさらに向上。一段と高い競争力を発揮',
            '目的に応じて、撮影画像を効率的かつ安全に記録できるようCFexpressカード（Type B）/XQDカード対応ダブルスロットを搭載'
          ]
        },
        {
          subtitle: '安心して撮影に集中できる高画質と高い信頼性',
          contents: [
            '画像処理エンジンは最新のEXPEED 6を搭載。撮影後のレタッチなしで高品質な画像を実現',
            'CMOSセンサーと画像処理エンジンの連携により高感度でも効果的にノイズを抑制。ISO 100からISO 102400までの幅広い感度域で高画質を実現',
            'カメラに実装した状態で40万回にもおよぶレリーズテストをクリアーした、プロフェッショナルの過酷な使用に応える高い耐久性を誇るシャッターユニット搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン D6',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759903402'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759903402', '4960759146205', '4549292146851', '4548736108288'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759903402', '4960759144652', '4960759900197', '4960759146335'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
