


















































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'xpro3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    watchInVideo: WatchInVideo,
    appearanceImage: AppearanceImage,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'フジフイルム X-Pro3 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'フジフイルム X-Pro3 比較や、価格・スペック・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/fujifilm/xpro3/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/fujifilm/xpro3/sp_img_top.jpg',
      exampleImagesPath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/fujifilm/xpro3/',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/fujifilm/xpro3',
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-Pro3',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '世界唯一、光学式・電子式の切り替えが可能な「アドバンスドハイブリッドビューファインダー」を搭載',
          contents: [
            '光学ファインダー（OVF）と電子ビューファインダー（EVF）を、レバー操作で瞬時に切り替え可能',
            '光学ファインダー（OVF）では、被写体をありのままに捉え、直感的に撮影が可能',
            '電子ビューファインダー（EVF）は、高解像約369万ドットの有機ELパネル採用。露出などの撮影条件を確認しながらの撮影が可能'
          ]
        },
        {
          subtitle: 'フィルムの発色に近い仕上がりを追求。人間の記憶に残る鮮やかな色「記憶色」を実現',
          contents: [
            '「Xシリーズ」第4世代のセンサー・画像処理エンジンを搭載。白飛びや黒つぶれの少ない、自然な色の写真を撮影可能',
            '多彩な色調を可能とする「フィルムシミュレーション」を17モード搭載。「クラシックネガ」モードも新たに追加',
            'フィルムで撮った写真が持つ独特の粒状感を再現する「グレイン・エフェクト」機能をさらに進化。グレインの濃さ・粗さを調整可能'
          ]
        },
        {
          subtitle: '優れた機動性・耐久性に加え、高級感＋快適な操作を実現',
          contents: [
            'マグネシウム合金をボディ内部のフレームに加え、ボディ外装に軽量・高強度で耐食性にも優れたチタンを採用',
            '高解像や広い視野角、高いコントラストを備えた 約162万ドット・チルト式タッチパネル液晶モニターを搭載で撮影をサポート',
            '電源のON/OFFに関わらず、撮影情報を確認することが可能なカラーメモリ液晶モニターをボディ背面に新たに採用'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'フジフイルム X-Pro2',
        href:
          '/ec/sell/item-list?category=ミラーレス一眼&keyword=フジフイルム%20X-Pro2&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=40&tokutoku=0&sort=',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/ca4/224/44a/785/1d6/17f/b8e/31c/0d0/613/c34/2cl/fc5/z/L.jpg',
        txt: 'FUJIFILM X-Pro3',
        tradeInImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/fujifilm/xpro3/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      mainProductJanCode: ['4547410421767', '4547410421774'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410421767', '4547410421774', '4547410311914', '4545350053000', '4548736108417'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4547410421767',
        '4547410421774',
        '4547410421781',
        '4547410426045',
        '4547410426038',
        '4547410334128',
        '4547410242539',
        '4547410378122',
        '4547410263923',
        '4547410289879',
        '4547410416572',
        '4547410397314',
        '4547410397307',
        '4547410206142',
        '4547410328820',
        '4547410328813',
        '4547410206159',
        '4547410341003',
        '4547410340990',
        '4547410266627'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
