



























































































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import ExampleImages from '@/components/common/special/example-image.vue';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Characteristic from '@/components/common/special/characteristic.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Notfound from '@/components/common/not-found.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'fx30',
  components: {
    breadcrumbs: Breadcrumbs,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    characteristic: Characteristic,
    shasha: ShaSha,
    splitContent: SplitContent,
    watchInVideo: WatchInVideo,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー Cinema Line カメラ FX30 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ソニー Cinema Line カメラ FX30 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- ページ公開期間 ----
      fanValidFrom: '2022/10/04 10:00',
      fanValidTo: '2999/10/20 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/10/04 10:00',
      reserveValidTo: '2022/10/13 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/10/14 00:00',
      releaseValidTo: '2999/10/20 09:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      naviList: [
        { naviItem: '価格', href: '#spec' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '外観', href: '#images' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー Cinema Line カメラ FX30',
          disabled: true
        }
      ],
      // ↓ ---- 高値下取り ----
      reserveExpensiveTradeInList: {
        name: 'ソニー Cinema Line カメラ FX3 ボディ [ILME-FX3]',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736123205',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/348/2ad/976/376/039/c66/824/fde/d86/881/499/k0d/zfb/6/L.jpg',
        txt: 'ソニー Cinema Line カメラ FX30 ',
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/fx30/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー Cinema Line カメラ FX3 ボディ [ILME-FX3]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736123205' },
          { name: 'ソニー α7S III ボディ [ILCE - 7SM3]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736121331' },
          { name: 'ソニー α7III ボディ [ILCE - 7M3]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736079625' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '最新のイメージセンサーと画像処理エンジン搭載でシネマのような映像表現を多くのクリエーターに提供',
          contents: [
            '新開発の有効約2010万画素APS-Cサイズ（Super 35mm）裏面照射型CMOSセンサーExmor R と最新の画像処理エンジンBIONZ XR の搭載で6K相当の豊富な情報量を凝縮。シネマの映像表現を多くのクリエーターに提供します',
            '肌の色を美しく見せるスキントーンを中心としたルック「S-Cinetone」を搭載。制作者のさらなるクリエイティブな表現要求に応えます',
            '最大120pのハイフレームレートで、4K QFHD（3840×2160）解像度によるカメラ内の動画記録に対応。フレーム単位で圧縮し高画質記録を行うIntra（All-I）方式も選択でき、被写体の動きを引き立たせる、なめらかなスローモーションをポストプロダクションによって実現可能です',
            '2つの基準ISO感度（ISO800とISO2500）に切り換えできるデュアル・ベースISOに対応。ISO800とISO2500のノイズレベルがほぼ同等で、撮影環境の明るさに合わせて基準ISO感度を使い分けることで、ノイズを抑えたクリアな映像撮影が可能です',
            'シャドウからミッドトーン（18%グレー）にかけての階調特性を重視した、S-Log3を搭載。S-Log3設定時は、14+ストップという再現域を確保しています'
          ]
        },
        {
          subtitle: '被写体をすばやく捉え、粘り強く追随するAFで動きの速い被写体にも対応',
          contents: [
            '最新の像面位相差検出AFとコントラスト検出AFを併用した、ファストハイブリッドAFシステムを搭載。高密度かつ広範囲（動画時最大495点、撮像エリアの水平方向 約93%、垂直方向 約97%をカバー）に像面位相差の測距点を配置。動きの速い被写体や浅い被写界深度の大口径レンズ使用時も、高精度かつなめらかに、ワイドなエリアでフォーカスを合わせ続けます',
            '液晶パネル上で狙った被写体をタッチし、フォーカスを自動追尾させる「タッチトラッキング」を搭載。ジンバル撮影時やワンオペレーション撮影時などのフォーカシングの負荷が軽減され、構図により集中できます',
            '4K 120p 記録においても使用可能な「リアルタイム瞳AF」は人物や動物、鳥の瞳検出に対応。より角度のある横顔や下向き、上向きの顔でもピント位置を気にせず構図に集中できます',
            'オートフォーカスでの撮影中においても、フォーカスリングによるマニュアル操作への切り換えをシームレスに行える「AFアシスト」機能を搭載。複数の人物の中から直感的に特定の人物にフォーカスを合わせたり、スムーズに被写体の選択とトランジションが可能です',
            '動画撮影中にピント位置が前後に移動すると発生しがちな画角変動（フォーカスブリージング現象）を最小化する「ブリージング補正」機能を搭載。フォーカシング中でも画角が一定になるよう自動調整することで、より画角の安定した動画を収録可能です',
            '多くのプロクリエイターからの要望を受けて、動画撮影時の被写界深度を可視化する「フォーカスマップ」機能を搭載。ライブビューの実映像にリアルタイムに重ね合わせることで、被写界深度を視覚的かつ直感的に画面上で表示します'
          ]
        },
        {
          subtitle: 'ワンオペレーションをサポートする機動力とプロに応える信頼性',
          contents: [
            'プロのクリエイターへのヒアリングを基に設計した約646gの小型・軽量で機動力を追求したボディと安定したホールドに配慮したグリップ形状を採用。長時間の撮影でも疲れにくくワンオペレーションをサポートしてくれます',
            '小型・軽量な純正レンズとの組み合わせで、システムとしても高い機動力を発揮。手持ち撮影だけでなく、ジンバルやドローンとの親和性が高く、フレキシブルな撮影スタイルを実現します',
            '光学式5軸ボディ内手ブレ補正ユニットと、カメラに内蔵した高精度なジャイロセンサー、最適化されたアルゴリズムで手ブレ補正効果を向上させる「アクティブモード」を搭載。4Kを含む各フォーマットで高い補正効果を得られ、機動性を損ないません',
            'XLR端子を2基備えたデジタルオーディオインターフェース対応XLRハンドルユニットを同梱したモデルなら別売のXLRマイクとの組み合わせで、音声をデジタル信号のままダイレクトにカメラに伝送し、劣化のない高音質録音を実現。本体側の設定により、デジタル4CHや24bitでの音声収録もサポート。ハンドル一体型、ケーブルフリー・バッテリーフリー仕様のため、撮影時の自由度やノイズ低減に寄与するだけでなく、設営・撤収時の負担も軽減します',
            '冷却ファンと放熱に配慮した構造を採用。アクティブ、パッシブ両面で効率的に放熱することで、動画撮影中の温度上昇を抑制し、4K 60pの長時間動画記録を実現。小型・軽量ボディと放熱性能を両立しています',
            'αで定評のあるZバッテリーを採用。カメラの使用状況に応じて細やかにパワーセーブを行う電源制御により、消費電力の削減を実現し、長時間撮影を可能にしました'
          ]
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736143173', '4548736141797'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品 4548736140264 かり 4549292157345
      mainProductJanCode: ['4548736143173', '4548736141797'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736143173', '4548736123205', '4548736133730', '4549980490693'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
