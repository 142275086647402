


























































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Carousels from '@/components/common/special/carousels.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'canon-f28lis',
  components: {
    breadcrumbs: Breadcrumbs,
    carousels: Carousels,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'キヤノン F2.8 L IS ズームレンズシリーズ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'RFレンズのラインナップに新たに加わった「F2.8 L ISズームレンズシリーズ」RF15-35mm F2.8 L IS USM/RF24-70mm F2.8 L IS USM');
    const state = reactive({
      visible: false,
      index: 0, // default: 0
      // パンくず情報
      breadcrumbs: [
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン F2.8 L IS ズームレンズシリーズ',
          disabled: true
        }
      ],
      carousels: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_01.jpg',
          txt: 'Canon EOS R RF15-35mm F2.8 L IS USM F11 1/250 ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_02.jpg',
          txt: 'Canon EOS R RF15-35mm F2.8 L IS USM F2.8 1/100 ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_03.jpg',
          txt: 'Canon EOS R RF15-35mm F2.8 L IS USM F11 8sec.ISO100'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_04.jpg',
          txt: 'EOS R RF24-70mm F2.8 1/200 ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_05.jpg',
          txt: 'EOS R RF24-70mm F5.6 1/5 ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/touit-slide_06.jpg',
          txt: 'EOS R RF24-70mm F2.8 L IS USM F5.6 1/5 ISO400'
        }
      ],
      productList: [
        {
          title: 'キヤノン RF15-35mm F2.8 L IS USM\n表現の幅を広げる大口径広角ズームレンズ',
          src: 'https://shop.kitamura.jp/ts_rsc/1230/images/4549292152272.jpg',
          href: '/ec/pd/4549292152272',
          txt:
            '焦点距離15mmから35mmのズーム全域で、開放F値2.8を実現。現行のEFレンズと比較して焦点距離が1mm広角化し、建築や風景などにおいて、被写体から遠く離れずに撮影することや遠近感を強調した写真表現が可能。'
        },
        {
          title: 'キヤノン RF24-70mm F2.8 L IS USM\n多様なシーンに対応する大口径標準ズームレンズ',
          src: 'https://shop.kitamura.jp/ts_rsc/1230/images/4549292148381.jpg',
          href: '/ec/pd/4549292148381',
          txt:
            '露出の設定や調整がしやすいズーム全域での開放F値2.8を実現。\n\n広角24mmから中望遠70mmの焦点距離を生かし、スナップやポートレート、風景など多様な撮影シーンに対応。'
        }
      ],
      imgs: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_1.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_2.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_3.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_4.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_5.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_6.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_7.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_8.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_9.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_10.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_11.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_12.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_13.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_14.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_15.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/f28lis/photo_16.jpg'
      ]
    });

    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    };
    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
