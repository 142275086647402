




































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';
import BackNumbers from '@/components/common/special/highly-recommended/back-numbers.vue';
export default Vue.extend({
  name: 'highly-recommended-2015-0605',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015,
    backnumbers: BackNumbers
  },
  setup: () => {
    document.title = '写真のバックアップに最適！キヤノンConnect Station CS100 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'スマホ・デジカメ・ビデオで撮った思い出の写真や動画をカンタンに整理することができるキヤノンCS100')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '写真のバックアップに最適！キヤノンConnect Station CS100 | スタッフのイチオシ',
          disabled: true
        }
      ],
      backnumberList: [
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/ld-120_350px.jpg.jpg',
          href: '/ec/special/general/highly_recommended/2015/0529',
          productName: 'LEDライト搭載 みせる防湿庫 東洋リビングLD-120',
          txt: 'ドア連動型の18連LEDライトを搭載。\nカメラを守るだけでなく、\nみせる防湿庫が登場。眺めて楽しく、\n出し入れしやすいプレミアムモデル。'
        },
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/i-item-blackrapid-joby_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0501',
          productName: 'ブラックラピッド クロスショット × JOBY ウルトラハ …',
          txt: '速写ストラップをつけたまま、三脚を使う裏技！JOBYウルトラプレートを使えば、速写ストラップをつけたまま、三脚が使える。一眼ユーザーの要望 …【続き】'
        },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
