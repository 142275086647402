














































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'polarie',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    tableOfContentsNavi: TableOfContentsNavi,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '星空を撮影しよう！ おすすめカメラ・レンズを紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '星空を撮影をする方に向けて、撮影方法やおすすめのカメラ・レンズを紹介しています。必要な道具やプリントしたい方に向けての解説もしているので、天体撮影をしたい方は参考にしてください。'
      );

    const state = reactive({
      naviList: [
        { naviItem: '星空撮影に最低限必要なもの ', href: '#no1' },
        { naviItem: 'ポラリエで星空撮影 ', href: '#no3' },
        { naviItem: '星空撮影おすすめカメラ ', href: '#no4' },
        { naviItem: '星空撮影おすすめレンズ ', href: '#no5' },
        { naviItem: '星空撮影おすすめカメラ用品 ', href: '#no6' },
        { naviItem: '星空写真ギャラリー ', href: '#no7' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/hosizora',
          img: 'https://shopimg.kitamura.jp/images/banner/5032.png',
          alt: '星空総合ページ'
        },
        {
          href: '/ec/special/camera/astronomical',
          img: 'https://shopimg.kitamura.jp/images/banner/1327.gif',
          alt: '天体イベントスケジュール'
        },
        {
          href: '/ec/special/camera/polarie/acc',
          img: 'https://shopimg.kitamura.jp/images/banner/3588.gif',
          alt: 'ポラリエとカメラ用望遠レンズで星空・天体撮影'
        },
        {
          href: 'https://www.vixen.co.jp/app/nebulabook/',
          img: 'https://shopimg.kitamura.jp/images/banner/3439.gif',
          alt: '星雲・星団・銀河等の天体撮影を支援するアプリ「Nebula Book」'
        },
        {
          href: '/ec/special/camera/astronomical/telescope',
          img: 'https://shopimg.kitamura.jp/images/banner/1698.jpg',
          alt: '初心者におすすめ 天体望遠鏡の選び方'
        },
        {
          href: '/ec/special/camera/astronomical/telescope-feature',
          img: 'https://shopimg.kitamura.jp/images/banner/2381.jpg',
          alt: '本格的な天体観測・天体撮影に 天体望遠鏡特集'
        },
        {
          href: '/ec/special/camera/astronomical/moonlook',
          img: 'https://shopimg.kitamura.jp/images/banner/1306.jpg',
          alt: '天体観測 入門！月を見よう'
        },
        {
          href: '/ec/special/general/summerhomework/moon',
          img: 'https://shopimg.kitamura.jp/images/banner/1309.gif',
          alt: '夏休み自由研究特集 【理科】天体観測 ~ 月の写真を撮ってみよう '
        },
        {
          href: '/ec/special/general/photocon-learn',
          img: 'https://shopimg.kitamura.jp/images/banner/2180.gif',
          alt: 'フォトコンテスト受賞作品から学ぶ'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '"超高画質プリント'
        }
      ],
      visible: false,
      index: 0, // default: 0
      imgs: [
        '/ec/images2/special/camera/polarie/bn-03-1.jpg',
        '/ec/images2/special/camera/polarie/bn-03-2.jpg',
        '/ec/images2/special/camera/polarie/reiimg02.jpg',
        '/ec/images2/special/camera/polarie/reiimg01-s.jpg'
      ],
      porarierList: [
        {
          itemName: 'ビクセン ポータブル赤道儀 星空雲台 ポラリエ',
          img: '/ec/images2/special/camera/polarie/posd.webp',
          text: '耐荷重：2kg\n ミラーレス一眼、コンパクトデジカメでの撮影におすすめ',
          href: '/ec/pd/4955295355051'
        },
        {
          itemName: 'ポラリエ＆ポーラメーターセット',
          img: 'https://shopimg.kitamura.jp/images/pd/c34/cb7/622/c36/9c4/396/e85/5c1/ea6/5e0/fdz/t9s/zfw/t/M.jpg',
          text: '耐荷重：2kg\n 星空撮影初心者さんには設定が簡単なポーラメーターセット',
          href: '/ec/pd/2239530000053'
        }
        // {
        //    itemName: 'ポラリエ フルサイズ一眼対応セット',
        //    img: 'https://shopimg.kitamura.jp/images/pd/97a/a45/585/916/f32/721/17a/50a/b76/f75/32m/9eb/am9/v/M.jpg',
        //    text: '耐荷重：6.5kg\n フルサイズ一眼レフカメラ、大口径レンズにおすすめ',
        //   href: '/ec/pd/2239530004822'
        //  },
        // {
        //  itemName: 'フルサイズ一眼対応セット ポーラメーター付き',
        // img: 'https://shopimg.kitamura.jp/images/pd/b8f/d0d/fa5/0ff/b29/e6d/df8/eb4/2d9/524/f5a/rjl/oeb/9/M.jpg',
        // text: '耐荷重：6.5kg\n ポーラメーター付き！ フルサイズ一眼レフ、大口径レンズにおすすめ',
        // href: '/ec/pd/2239530004914'
        // }
      ],
      recommendedCameraList: [
        {
          name: 'オリンパス\nコンパクトカメラ',
          img: 'https://shopimg.kitamura.jp/images/pd/168/80b/fba/c3d/4b0/c3a/4e2/d34/975/ece/99e/eqb/kym/m/TN.jpg',
          href:
            'https://shop.kitamura.jp/pd/list.html?path=%E3%82%B3%E3%83%B3%E3%83%91%E3%82%AF%E3%83%88%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%82%AB%E3%83%A1%E3%83%A9%3A%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%A7%E9%81%B8%E3%81%B6%3A%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          name: 'オリンパス\nミラーレス一眼',
          img: 'https://shopimg.kitamura.jp/images/pd/227/6c7/30d/011/e11/71e/e74/0ff/509/05d/d6s/9f6/3yz/m/TN.jpg',
          href:
            'https://shop.kitamura.jp/pd/list.html?path=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC%3A%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%A7%E9%81%B8%E3%81%B6%3A%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          name: 'ペンタックス\n一眼レフ',
          img: 'https://shopimg.kitamura.jp/images/pd/a6f/e12/d9b/c41/929/ab7/9c0/2b5/34c/a6b/29u/j8k/rdj/x/TN.jpg',
          href:
            'https://shop.kitamura.jp/pd/list.html?path=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95%3A%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%A7%E9%81%B8%E3%81%B6%3A%E3%83%9A%E3%83%B3%E3%82%BF%E3%83%83%E3%82%AF%E3%82%B9&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          name: 'ソニー アプリ\n対応デジカメ',
          img: 'https://shopimg.kitamura.jp/images/pd/639/964/8bd/3c8/69a/d84/149/47a/f71/50f/d33/e12/f2g/7/TN.jpg',
          href: 'https://shop.kitamura.jp/special/sale-fair/camera/polarie/startrail/'
        },
        {
          name: 'リコー GR III ',
          img: 'https://shopimg.kitamura.jp/images/pd/ed8/0f4/5f1/a63/11b/eea/2b5/46f/d9b/0c6/c60/mzr/7fl/q/TN.jpg',
          href: '/ec/pd/4549212300202'
        }
        // {
        //  name: 'リコー RICOH\nTHETA SC2',
        // img: 'https://shopimg.kitamura.jp/images/pd/115/f52/39c/aa6/5ea/b53/84d/85c/926/c42/a9v/gvs/a4t/7/TN.jpg',
        // href: '/ec/pd/4961311947308'
        // }
      ],
      starTrailImages: [
        '/ec/images2/special/camera/polarie/bn-02-1.jpg',
        '/ec/images2/special/camera/polarie/bn-02-3.jpg',
        '/ec/images2/special/camera/polarie/bn-02-2.jpg'
      ],
      // 星空撮影におすすめ
      starrySkyShootingList: [
        {
          itemName: '星景写真を撮るには広角レンズがおすすめ',
          img: 'https://shopimg.kitamura.jp/images/pd/8f2/7bb/a44/115/959/13d/cf8/962/54b/cdc/31m/kck/b5j/g/TN.jpg',
          btntxt: '広角レンズ',
          href:
            '/ec/ct/C004?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA&narrow18=0&s4=%E5%BA%83%E8%A7%92%E3%83%AC%E3%83%B3%E3%82%BA&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },
        {
          itemName: '広い星空を一気に撮れるフィッシュアイレンズ',
          img: 'https://shopimg.kitamura.jp/images/pd/a80/f3f/81c/d40/48c/298/187/3dd/7d1/349/8bq/vu1/6oo/j/TN.jpg',
          btntxt: 'フィッシュアイレンズ',
          href:
            '/ec/list?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA&keyword=Fish&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },

        {
          itemName: '星景も星座も撮れる便利な広角ズームレンズ',
          img: 'https://shopimg.kitamura.jp/images/pd/33b/bc5/b9d/575/36d/3f9/294/d0b/9d9/08c/94g/dvb/jdc/5/TN.jpg',
          btntxt: '広角ズームレンズ',
          href:
            '/ec/list?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA&narrow18=0&s4=%E5%BA%83%E8%A7%92%E3%82%BA%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%B3%E3%82%BA&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },

        {
          itemName: '星景も星座も撮れる便利な標準ズームレンズ',
          img: 'https://shopimg.kitamura.jp/images/pd/4aa/7c9/9a0/c1c/d7a/9e7/014/314/053/e32/641/xrk/94c/u/TN.jpg',
          btntxt: '標準ズームレンズ',
          href:
            '/ec/list?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA&narrow18=0&s4=%E6%A8%99%E6%BA%96%E3%82%BA%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%B3%E3%82%BA&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '星空総合ページ',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/hosizora',
          disabled: false
        },
        {
          path: '星空を撮影しよう！ 星空撮影入門特集',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/polarie',
          disabled: false
        }
      ],
      // 取得するJancode
      productJanCodeList: [
        '4955295355051',
        '2239530000053',
        '2239530004822',
        '2239530004822',
        '4955295355112',
        '4955295355341',
        '4955295355280',
        '4955295355198',
        '4955295265022',
        '4955295355235',
        '4955295355235',
        '4955295710898',
        '4955295354184',
        '4955295354313'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg,
      handleHide
    };
  }
});
