














































































































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
//import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import SplitContent from '@/components/common/special/split-content.vue';
import Notfound from '@/components/common/not-found.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'dcs5m2',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    shasha: ShaSha,
    //priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    'product-list-by-special-id': ProductListBySpecialId,
    notfound: Notfound,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'パナソニック LUMIX DC-dcs5m2 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'パナソニック LUMIX DC-dcs5m2   価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isShow: false,
      // ↓ ---- 予約掲載期間 ----
      fanValidFrom: '2023/1/10 9:59',
      fanValidTo: '2023/1/10 9:59',
      // ↓ ---- 発売掲載期間 ----
      reserveValidFrom: '2023/1/10 10:00',
      reserveValidTo: '2023/2/15 23:59',
      // ↓ ---- キャンペーン掲載期間 ----
      releaseValidFrom: '2023/2/16 00:00',
      releaseValidTo: '2099/5/15 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: true
        },
        {
          path: 'パナソニック LUMIX DC-S5M2/DC-S5M2X',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'パナソニック LUMIX DC-S1',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980233047',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/e23/c07/f3e/c2f/8dc/5fa/625/e8d/015/bf4/3aj/2lg/8qn/o/L.jpg',
        txt: 'LUMIX DC-S5M2/DC-S5M2X',
        tradeInImg: '/ec/images2/special/camera/feature/panasonic/dcs5m2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'パナソニック LUMIX DC-S1', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980233047' },
          { name: 'パナソニック LUMIX DC-S5', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980624180' },
          { name: 'パナソニック LUMIX DC-GH6', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549980490693' }
        ]
      },
      characteristicList: [
        {
          subtitle: '新開発フルサイズセンサーと新世代ヴィーナスエンジンを搭載',
          contents: [
            '有効画素数2,420万画素のフルサイズ CMOSセンサーは、像面位相差センサーによる高精度なAFを実現しながら、高い解像感と精緻な描写性、広いダイナミックレンジを実現します',
            '読み出し速度の高速化により、連写速度の高速化を可能にするとともに、電子シャッターや動画撮影時でのローリングシャッター現象を抑えることができる、高感度と解像性のバランスを高次元で達成したセンサーです',
            '高い解像感と立体描写を実現する新インテリジェントディテール処理やノイズリダクションにより静止画・動画撮影において、高画質・高感度・色再現に優れた処理性能を発揮し、6K 30p 10bit記録などの高解像な動画も高速に処理します',
            'AF性能においても、演算処理の高速化とAFアルゴリズムの改善により、被写体の検出性能が大幅に向上し、被写体の動きと特性を判別することで高精度なAFを実現します'
          ]
        },
        {
          subtitle: '高い描写性能とLUMIX初の像面位相差AFの両立を実現',
          contents: [
            '新搭載の像面位相差AFは、像面位相差センサーにより、被写体までの距離を高速・高精度に算出し、779点の測距でダイナミックに動く被写体をとらえるだけでなく、自動認識AFとの組み合わせで、逆光や低照度撮影での被写体が判別しにくいシーンや、複数の人が交差するシーン、より小さな被写体においても、優れたAF追従性能を発揮します',
            '撮影した画像そのものから、DFDテクノロジーにより被写体までの距離を、コントラストAFにより被写体のエッジ強度を同時に算出する為、従来のコントラストAFに比べて、高速性と高精度の両立を可能にしています',
            '「頭部認識」を加えたディープラーニングの進化と、認識処理の高速化により、顔・瞳認識や人体認識の追従性・検出速度も大幅に向上し、動く人物の顔・瞳を確実にとらえ続けます',
            '人物だけでなく動物にも確実にフォーカスを合わせることが可能。鳥、イヌ科（オオカミなどを含む）、ネコ科（ライオンなどを含む）の認識に対応しています'
          ]
        },
        {
          subtitle: '多彩なフォーカス機能で、さまざまな撮影環境をバックアップ',
          contents: [
            '天候や撮影環境の変化、さまざまな被写体の撮影において、多彩なフォーカス機能が活躍。撮影シーンや被写体に合わせて最適なAF機能を選べるので、撮影に集中でき、こだわりの作品を残せます',
            '自動認識AFは、さまざまなAFモードを組み合わせることで、自由度が高まり、撮影シーンに応じた最適な組み合わせの選択が可能です。さらに、縦・横・斜めの8方向操作に対応したジョイスティックで、スピーディなAF操作ができます'
          ]
        }
      ],
      characteristicList2: [
        {
          subtitle: '外付けSSDへの動画記録に対応',
          contents: [
            '市販の大容量外付けSSDに、USBケーブル（Type-Cなど）経由で写真や動画を記録・再生することができます',
            '動画記録においては、高品質・低圧縮のApple ProRes 422 HQや4:2:2 10bit All-Intraの高ビットレートでファイルサイズの大きい動画データを、最大2TBの外付けSSDへS5M2Xからダイレクトに記録することができます'
          ]
        },
        {
          subtitle: '目的や編集環境に合わせて選択できる圧縮方式',
          contents: [
            'S5ⅡXは、1フレーム単位でデコード/エンコードを行えるALL-Intra記録が可能。1フレーム単位で圧縮していくイントラフレーム方式の圧縮形式なので、クオリティが求められるシビアな画像合成などの高度なノンリニア編集に最適です'
          ]
        }
      ],
      mainProductJanCode: ['4549980649909', '4549980649923', '4549980684160', '4549980729533', '4549980729595', '4549980729601'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 試し4549292157345----
      comparisonJanCodeList: ['4549980649909', '4549980624180', '4549292200508', '4548736133730'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode 4549980490693 4549980490709
      productJanCodeList: ['4549980490693', '4549980490709'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
      // ページ内スクロール
      setTimeout(() => {
        const hash = context.root.$route.hash;
        if (hash) context.root.$vuetify.goTo(hash);
      }, 100);
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
