






























































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'kaden-heyabosi',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'おすすめ除湿機特集2017 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '除湿に加湿に空気清浄と1台で全てできる除加湿空気清浄機！おすすめの2017年最新モデルを比較しました。');
    const state = reactive({
      visible: false,
      index: 0,
      imgs: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_20-2.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_16-2.jpg'
      ],
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'キタムラ家電',
          linkUrl: '/ec/special/kaden',
          disabled: false
        },
        {
          path: 'おすすめ除湿機特集2017',
          disabled: true
        }
      ],
      daikinList: [
        {
          txt:
            'ダイキンのメニューは自動運転コースが追加で5種類。\n衣類乾燥用のモードが2つ「ランドリー乾燥」と「おやすみランドリー」。「ハウスキープ」は結露防止用の除湿機能です。「水de脱臭」は加湿でニオイ分子を浮き出させ、その後の除湿によってニオイ分子を除去するモードです。\n「るすばんターボ」は1時間だけ風量がターボになります。\nまた「しつどセレクト」を使って、湿度を40％・50％・60％から選択できるのも特徴です。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_20.jpg'
        },
        {
          txt:
            'シャープの「衣類乾燥モード」メニューは、ダイキンの「ランドリー乾燥」と一緒ですね。\n「結露抑制モード」はダイキンの「ハウスキープ」と一緒ですね。\n「パワフル吸じんモード」は最初の10分間は風量「強」、その後約1時間ホコリセンサーの感度を上げるモードです。\nシャープの風量「強」はダイキンの「ターボ」と同じ風量です。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_16.jpg'
        }
      ],
      tankList: [
        {
          txt:
            '両方共タンク容量は一緒で3Lずつ。加湿の給水タンクは手を入れて中を洗えます。\nシャープは持ち運びやすくする為の取っ手が付いています。タンクそのものにAg+イオンカートリッジ FZ-AG01K1を取り付けられる点もポイントです。',
          imgs: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_13.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_11.jpg'
          ]
        },
        {
          txt: '除湿の排水タンクはどちらも似ていますね。\n排水口が正面に付いているダイキンの方が排水はしやすかったです。',
          imgs: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_10.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/heyabosi/img_08.jpg'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/fan-fair',
          img: 'https://shop.kitamura.jp/images/banner/6597.jpg',
          alt: 'おすすめ扇風機特集'
        },
        {
          href: '/ec/special/kaden/air-purifier_fair',
          img: 'https://shopimg.kitamura.jp/images/banner/2393.gif',
          alt: '空気清浄機 | おすすめ新製品でウイルス・花粉対策'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    };
    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
