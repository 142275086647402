















































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'storage',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    tableOfContentsNavi: TableOfContentsNavi,
    urlcopy: Urlcopy,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'カメラやレンズの保管におすすめ！防湿庫のおすすめ12選｜選び方のポイントも解説 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '担当者おすすめの防湿庫の選び方や防湿用品をご紹介！防湿庫の重要性や容量を選ぶポイントを解説しており、湿気対策に必要なお手入れ用品もご紹介しております。雨の日・カビ対策にお悩みの方はぜひ参考にしてください。'
      );
    const state = reactive({
      ankList: [{ name: '防湿庫の収納容量比較', href: '#r03' }],
      naviList: [
        { naviItem: 'おすすめ\n防湿庫', href: '#r01' },
        { naviItem: '防湿庫の\n選び方', href: '#r02' },
        { naviItem: '防湿庫の\n収納目安', href: '#r03' },
        { naviItem: 'おすすめ\nお手入れ用品', href: '#r04' },
        { naviItem: '防湿庫の\n必要性', href: '#r05' }
      ],
      toyoMoistureProofList: [
        { text: '東洋リビング ED-40KITTY オートドライ防湿庫 ハローキティ', href: '/ec/pd/4945931019201' },
        { text: '東洋リビング ED-41CAT2(B) オートクリーンドライ防湿庫', href: '/ec/pd/4945931023024' },
        { text: '東洋リビング ED-80CATP3(B) オートクリーンドライ防湿庫', href: '/ec/pd/4945931023048' },
        { text: '東洋リビング ED-120CATP3(B) オートクリーンドライ防湿庫', href: '/ec/pd/4945931023055' }
      ],
      toriMoistureProofList: [
        { text: 'トーリ・ハン ドライ・キャビ PD-40', href: '/ec/pd/4930381440415' },
        { text: 'トーリ・ハン ドライ・キャビ PD-55', href: '/ec/pd/4930381440422' },
        { text: 'トーリ・ハン ドライ・キャビ PD-88', href: '/ec/pd/4930381440439' },
        { text: 'トーリ・ハン ドライ・キャビ PD-105', href: '/ec/pd/4930381440446' }
      ],
      hakubaMoistureProofList: [
        { text: 'ハクバ KED-25 電子制御式防湿庫 E-ドライボックス 25L', href: '/ec/pd/4977187403503' },
        { text: 'ハクバ KED-40 電子制御式防湿庫 E-ドライボックス 40L', href: '/ec/pd/4977187403510' },
        { text: 'ハクバ KED-60 電子制御式防湿庫 E-ドライボックス 60L', href: '/ec/pd/4977187403527' },
        { text: 'ハクバ KED-100 電子制御式防湿庫 E-ドライボックス 100L', href: '/ec/pd/4977187403534' }
      ],
      storageCapacityList: [
        { amount: '50L以下', type: '（小型）', criterion: '一眼カメラが4～5台、レンズ数本が目安。\nビギナーさん向け' },
        { amount: '55L～120L', type: '（中型）', criterion: '一眼カメラが10～20数台、比較的多くのレンズやアクセサリーをお持ちの人向け' },
        { amount: '140L以上', type: '（大型）', criterion: '一眼カメラが30～80台、横型防湿庫なら大口径のレンズも、らくらく収納可能' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/general/tuyutaisaku',
          img: 'https://shopimg.kitamura.jp/images/banner/5422.png',
          alt: '梅雨対策特集'
        },
        {
          href: '/ec/special/camera/bag/trial',
          img: 'https://shopimg.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/camera/bag',
          img: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          alt: 'カメラバッグ・カメラケース特集'
        },
        {
          href: '/ec/special/sale/lotbuying/',
          img: 'https://shopimg.kitamura.jp/images/banner/3773.gif',
          alt: 'まとめ買いコーナー'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント',
          target: 'blank'
        }
      ],
      visible: false,
      index: 0,
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '防湿庫の選び方・湿気対策特集',
          disabled: true
        }
      ],
      mainProductJanCode: [//東洋リビング 防湿庫
        '4945931019201',
        '4945931023024',
        '4945931023048',
        '4945931023055'
      ],
      mainProductList: [] as Array<ProductDetail>,
      mainProductJanCode2: [//トーリ・ハン 防湿庫
        '4930381440415',
        '4930381440422',
        '4930381440439',
        '4930381440446'
      ],
      mainProductList2: [] as Array<ProductDetail>,
      mainProductJanCode3: [//ハクバ 防湿庫
        '4977187403503',
        '4977187403510',
        '4977187403527',
        '4977187403534'
      ],
      mainProductList3: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        const mainResult2 = await ProductService.fetchProducts(state.mainProductJanCode2, true);
        state.mainProductList2 = mainResult2.items;
        const mainResult3 = await ProductService.fetchProducts(state.mainProductJanCode3, true);
        state.mainProductList3 = mainResult3.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        state.mainProductList2 = [] as Array<ProductDetail>;
        state.mainProductList3 = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
