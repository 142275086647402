














































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';

import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'nikon-df',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPopular: PriceAndPopular,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'Nikon ニコン Df｜Nikon ニコンFX フォーマットデジタル一眼レフカメラ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'Nikon Df ニコンデジタル一眼レフカメラ新製品の特集ページ。MF時代からのニコンユーザーによってはたまらないデザインのNikon FX フォーマットデジタル一眼レフカメラ新製品の画像と特徴をご紹介！デジタル一眼レフ・ミラーレスはもちろん、アクセサリーのご予約・ご購入はキタムラネットショップで！ネットショップで注文して全国1000店舗カメラのキタムラで受取り可能です。'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images' }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'Nikon ニコン Df デジタル一眼レフカメラ',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759141019',
        '4960759141002',
        '4960759141071',
        '4960759141064',
        '4960759028426',
        '4960759141750',
        '4960759141743',
        '4960759140890',
        '4960759027801',
        '4960759026071',
        '4960759024336',
        '4960759001689',
        '4960759126566',
        '4960759138415',
        '4961607252581'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
