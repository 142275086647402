








import Vue from 'vue';

export default Vue.extend({
  name: 'shootinginformation',
  components: {},
  props: {
    src: {
      type: String,
      required: true
    },
    photographicEquipment: {
      type: String,
      required: true
    },
    shootingInformation: {
      type: String,
      required: true
    },
  },

});
