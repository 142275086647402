
















































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { UsedCategory } from '@/types/tsv-config';
import ProductListService from '@/logic/product-list.service';

type ChildCategory = {
  type: 'category' | 'maker';
  dispalyedName: string;
  urlParam: string;
};
type Category = {
  parentCategoryName: string;
  children: Array<ChildCategory>;
};

const DISPLAY_MAKER_CATEGORY = ['デジタル一眼レフ', 'ミラーレス一眼', 'コンパクトデジタルカメラ'];

export default Vue.extend({
  name: 'used-category-link',
  setup: (_, context) => {
    const category = context.root.$store.category;
    const state = reactive({
      usedCategoryList: [] as Array<Category>
    });

    onMounted(async () => {
      if (!category.usedCategory.length) await category.fetchUsedCategories();

      for (const parentCate of category.usedCategoryOnlyParent) {
        const prefix = `${parentCate.categoryName}:`;
        let children: Array<ChildCategory> = [];
        if (DISPLAY_MAKER_CATEGORY.includes(parentCate.categoryName)) {
          // メーカーの件数取得
          const resluts = await ProductListService.searchUsedItemCount([
            {
              paramCode: 'category',
              paramText: 'カテゴリ',
              value: parentCate.categoryName,
              valueText: parentCate.categoryName
            }
          ]);
          const makerList = Object.entries(resluts.narrow1).map(([key, value]) => ({ name: key, count: value }));
          const displayMaker = makerList.length ? makerList.sort((a, b) => b.count - a.count).slice(0, 10) : [];
          children = displayMaker.map((maker) => ({
            type: 'maker',
            dispalyedName: maker.name,
            urlParam: maker.name
          }));
        } else {
          // 子カテゴリの設定
          children = category.usedCategory
            .filter((usedCate: UsedCategory) => usedCate.categoryName.includes(prefix))
            .map((childCate: UsedCategory) => {
              return {
                type: 'category',
                dispalyedName: childCate.categoryName.replace(prefix, ''),
                urlParam: `${childCate.categoryName}`
              };
            });
        }
        state.usedCategoryList.push({
          parentCategoryName: parentCate.categoryName,
          children: children
        });
      }
    });

    return {
      ...toRefs(state)
    };
  }
});
