
































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'pentax-645z',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    naviBtn: NaviBtn,
    subText: SubText,
    characteristic: Characteristic,
    facebookAndTwitter: FacebookAndTwitter,
    topTitleImg: TopTitleImg,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'PENTAX 645Z ペンタックス中判デジタル一眼レフカメラ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ペンタックス中判デジタル一眼レフカメラ新製品｢PENTAX 645Z｣特集。ユーザーの要望に応え、あらゆる面で進化を遂げた「PENTAX 645Z」。スペックや価格をチェック！カメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/645z/bg_topimg.gif',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/645z/sp_img_top.jpg',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/pentax/645z',
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' },
        { naviItem: '作例写真', href: '#images2' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '大型CMOSセンサーとローパスフィルターレス仕様を組み合わせた超高精細画像',
          contents: [
            '35mmフルサイズセンサーの約1.7倍の面積を誇る新型CMOSセンサーとローパスフィルターレス仕様の組み合わせで 有効約5140万画素の超高精細画像を実現。\nさらに画像処理エンジン“PRIME III”を搭載することにより徹底的にノイズを抑え最高ISO感度204800での撮影も可能。'
          ]
        },
        {
          subtitle: 'プロの要求に応える高速レスポンス',
          contents: [
            'ミラー・シャッター・絞りの独立した3モーターシステムを搭載し、3コマ/秒の高速連写・最大10コマの連続撮影を実現。\nさらに前モデルと比べ最大約5倍の処理速度を活かし、ライブビュー機能を搭載。'
          ]
        },
        {
          subtitle: '上下チルト式3.2型液晶搭載で新たな領域での撮影が可能に',
          contents: [
            '大型カメラでは撮影が困難なローアングルなどの撮影も可動式の液晶使用で容易に撮影可能。\n液晶の光の反射を効果的に抑制し視認性を大幅に高めることで屋外でも使いやすく。'
          ]
        },
        {
          subtitle: '大型センサーを活かした1920×1080：60iのフルHD動画撮影が可能に',
          contents: [
            '大型センサーならではの被写界深度が浅く、背景を効果的にぼかした動画を滑らかに撮影することが可能。\n内蔵のステレオマイクに加え、外部入力用のステレオマイク端子も装備しており、音声録音レベルの調整も可能。'
          ]
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '驚愕の解像力と豊かな階調『ペンタックス 645Z ボディキット',
          disabled: true
        }
      ],
      productJanCodeList: ['4549212275890', '4549212214042', '4549212276699', '4549212276484', '4549212276545'],
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
