
































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '6600',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    kitamuraService: KitamuraService,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー α6600 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー α6600 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ソニー α6500 ボディ [ILCE-6500]',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=%E3%82%BD%E3%83%8B%E3%83%BC%20%CE%B16500%20%E3%83%9C%E3%83%87%E3%82%A3%20%5BILCE-6500%5D&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
        nameImg: 'https://shop.kitamura.jp/images/pd/b77/97e/fb1/943/797/42b/d6f/e99/57a/4e3/5cj/f5f/d4d/n/L.jpg',
        txt: 'ソニー α6500 ',
        tradeInImg: '/ec/images2/special/camera/feature/sony/6600/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      characteristicList: [
        {
          subtitle: '「リアルタイムトラッキング」×「高速連写」で、最高の一瞬を描写',
          contents: [
            'フラッグシップモデル『α9』で採用されている、AIを活用した「リアルタイムトラッキング」を搭載',
            '最高約11コマ/秒のアフタービュー方式での高速連写に加え、最高約8コマ/秒のライブビュー方式での高速連写が可能',
            '表示タイムラグを限りなく低減させることで、光学ファインダーに近い感覚で使用できる電子ビューファインダー'
          ]
        },
        {
          subtitle: '光学式5軸ボディ内手ブレ補正をコンパクトボディに搭載',
          contents: [
            'フルサイズ機で採用されている光学式5軸ボディ内手ブレ補正機構を、APS-Cセンサー用手ブレ補正ユニットとして搭載',
            '角度ブレ（Pitch/Yaw）、シフトブレ（X/Y）、回転ブレ（Roll）などさまざまなブレを高精度に検出し補正',
            '高精度ジャイロセンサーも搭載し、5.0段分の手ブレ補正効果を実現'
          ]
        },
        {
          subtitle: 'Super 35mmフォーマットによる本格的な4K動画記録をサポート',
          contents: [
            '画素加算のない全画素読み出しにより、4K映像に必要な画素数の約2.4倍（6K相当）の豊富な情報量を凝縮して4K映像を出力',
            'S-Log3、S-Log2にも対応し、撮影後のグレーディングによる幅広い映像表現が可能',
            'マイク端子に加え、ヘッドホン端子を新たに搭載したことで、収録音の正確なモニタリングが可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α6600',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736108417'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736108417', '4548736021662', '4547410399875', '4549292078657'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736108417',
        '4548736108561',
        '4548736064522',
        '4548736055704',
        '4548736100664',
        '4905524929379',
        '4548736008427',
        '4905524989960',
        '4905524894875',
        '4548736099661',
        '4548736099814',
        '4548736099838'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
