













import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'momoBtn',
  props: {
    btntxt: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false,
      default: '55px'
    },
    type: {
      type: Number,
      default: 1
    },
  },
  setup: () => {
    const state = reactive({});

    const linkToOtherWindow = (url: string | undefined) => {
      window.open(url, '_blank');
    };

    return {
      ...toRefs(state),
      linkToOtherWindow
    };
  }
});
