















































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import ProductAppeal from '@/components/common/special/productAppeal.vue';
import ShootingInformation from '@/components/common/special/shootingInformation.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'macro-lens',
  components: {
    breadcrumbs: Breadcrumbs,
    productAppeal: ProductAppeal,
    shootingInformation: ShootingInformation,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'photo＋Mono特集 Vol.6 | 100mmマクロレンズ特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '話題の商品・気になる商品の使い心地を気鋭の現役プロカメラマンがレビューレポート。vol.6 100mmマクロレンズ特集  | 近所にも魅力的な被写体がいっぱい！「マクロレンズでお散歩撮影を楽しむ」'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '100mmマクロレンズ特集',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960999635170'],
      mainProductList: [] as Array<ProductDetail>,
      macroLensJanCode: ['4960999635170', '4549292090130', '4960759025159', '4960759025623', '4905524935493', '4548736043930', '4961333163038', '4549212273728', '4549077359964', '4545350041236', '4547410206166', '4960371006031', '4960371006048', '0085126258542', '0085126258559'],
      macroLensList: [] as Array<ProductDetail>,
      backNumberList: [
        {
          href: '/ec/special/general/photo_mono/g9pro',
          img: 'https://shop.kitamura.jp/images/banner/5093.jpg',
          alt: 'vol.5 Panasonic LUMIX G9 PRO'
        },
        {
          href: '/ec/special/general/photo_mono/a7r3-a9',
          img: 'https://shop.kitamura.jp/images/banner/4998.jpg',
          alt: 'vol.4 SONY α7RⅢ| α7RⅡやα9と比較しながらプロカメラマンが実写レビューレポート'
        },
        {
          href: '/ec/special/general/photo_mono/omd-em1',
          img: 'https://shop.kitamura.jp/images/banner/4841.jpg',
          alt: 'vol.3 OLYMPUS OM-D E-M1 MarkⅡ| 最強の手ぶれ補正性能！手持ちでも本格的な夜景撮影を可能に'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/list?category=&narrow1=&narrow2=&type=u&r=',
          img: 'https://shop.kitamura.jp/images/banner/4637.gif',
          alt: '新着中古'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // マクロレンズリスト
        const productListResult = await ProductService.fetchProducts(state.macroLensJanCode, true);
        state.macroLensList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // マクロレンズリスト
        state.macroLensList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
