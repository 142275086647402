






























import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'tokutokusueoki-fe-lens',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'トクトク据置対象 ソニー FEレンズ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '【トクトク据置対象 ソニー FEレンズ】月々のお支払いを圧倒的に安く抑えられる｢トクトク据置｣ 商品の返却時の買取り予定価格を残価額とし、残価額を差し引いた代金を24ヶ月の分割でお支払いできます'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'トクトク据置',
          linkUrl: '/ec/special/general/tokutokusueoki',
          disabled: false
        },
        {
          path: '対象商品',
          disabled: true
        }
      ],
      titleImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/tokutokusueoki/sueoki00.jpg',
      lensList: [
        {
          name: 'キヤノン EFレンズ',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/tokutokusueoki/sueoki-item02.jpg',
          alt: 'キヤノン EFレンズ | アイコン',
          href: '/ec/special/general/tokutokusueoki/ef-lens'
        },
        {
          name: 'キヤノン RFレンズ',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/tokutokusueoki/sueoki-item03.jpg',
          alt: 'キヤノン RFレンズ | アイコン',
          href: '/ec/special/general/tokutokusueoki/rf-lens'
        },
        {
          name: 'ニコン Zレンズ',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/tokutokusueoki/sueoki-item04.jpg',
          alt: 'ニコン Zレンズ | アイコン',
          href: '/ec/special/general/tokutokusueoki/z-lens'
        },
        {
          name: 'ソニー FEレンズ',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/tokutokusueoki/sueoki-item05.jpg',
          alt: 'ソニー FEレンズ | アイコン',
          href: '/ec/special/general/tokutokusueoki/fe-lens'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
