




























































































































































































































































































































































import Vue from 'vue';
import { CAMERA_CATEGORY_LIST, APPLIANCES_CATEGORY_LIST } from '@/constants/categories'; //★カテゴリ
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
const CAMERA_CATEGORY_MIN_SIZE = 10; //★カテゴリ
const APPLIANCES_CATEGORY_MIN_SIZE = 10; //★カテゴリ

export default Vue.extend({
  name: 'highly-recommended-2015-1120',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = '4Kビデオカメラでの撮影に適したSDカードをまとめました | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]')
      .setAttribute('content', '4K画質のビデオカメラでお使いいただけるSDカード・マイクロSDカードをまとめました。4K対応SDカード・マイクロSDカードはカメラのキタムラへお任せください♪');
    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '4Kビデオカメラでの撮影に適したSDカードをまとめました | スタッフのイチオシ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410437980', '4547410440386'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    /**
     * カメラカテゴリーリストをフィルタリングする(SP用) ★カテゴリ
     */
    const filterCameraCategories = computed(() =>
      state.isMoreCameraCategories ? CAMERA_CATEGORY_LIST : CAMERA_CATEGORY_LIST.slice(0, CAMERA_CATEGORY_MIN_SIZE)
    );
    /**
     * 家電カテゴリーリストをフィルタリングする(SP用) ★カテゴリ
     */
    const filterAppliancesCategories = computed(() =>
      state.isMoreAppliancesCategories ? APPLIANCES_CATEGORY_LIST : APPLIANCES_CATEGORY_LIST.slice(0, APPLIANCES_CATEGORY_MIN_SIZE)
    );
    /**
     * 画像読込 ★カテゴリ
     */
    function loadImg(path: string) {
      return require('@/assets/categories/' + path);
    }
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      loadImg, //★カテゴリ
      filterCameraCategories, //★カテゴリ
      filterAppliancesCategories, //★カテゴリ
      CAMERA_CATEGORY_LIST, //★カテゴリ
      APPLIANCES_CATEGORY_LIST //★カテゴリ
    };
  }
});
