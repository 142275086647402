import { reactive } from '@vue/composition-api';
import { Shop } from '@/types/product-list';
import ProductListService from '@/logic/product-list.service';
import WebStorage from '@/logic/web-storage';

export default function shopStore() {
  const KEY = 'shopList';

  const state = reactive({
    shopList: [] as Array<Shop>
  });

  return {
    get shopList(): Array<Shop> {
      if (!state.shopList || !state.shopList.length) state.shopList = WebStorage.getSessionStorage(KEY);
      return state.shopList ? state.shopList : [];
    },

    async fetchShop() {
      try {
        const shopResults = await ProductListService.fetchShopList();
        // 店舗一覧から「4546：中古オペレーションセンター」が返ってきていない場合は追加する
        // （商品検索画面の絞り込み条件に表示させるため）
        if (!shopResults.shops.some(shop => shop.shopcode === 4546)) {
          shopResults.shops.push({ shopcode: 4546, shopname: '中古オペレーションセンター' });
        }
        state.shopList = shopResults.shops;
        WebStorage.setSessionStorage(KEY, state.shopList);
      } catch (error) {
        console.error(error);
        state.shopList = [];
      }
    },

    convertToShopText(code: string): string {
      let price = '';
      if (state.shopList && state.shopList.length) {
        const target = state.shopList.find((shop) => `${shop.shopcode}` === code);
        price = target ? target.shopname : '';
      }
      return price;
    }
  };
}

export type ConditionsStore = ReturnType<typeof shopStore>;
