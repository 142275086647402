































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
//import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
//import { ConditionItem } from '@/types/conditions';
//import { ProductItem } from '@/types/product-list';
//import { NEWER_SORT_LIST } from '@/constants/sort-list';
//import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
//import { SEARCH_STATE } from '@/constants/search-state';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'bag',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = 'カメラバッグ・カメラケース特集★一眼レフ/ミラーレス/デジカメ用 | カメラのキタムラ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '登山や普段使いのカメラバッグ、一眼レフ用カメラバッグやミラーレス一眼用カメラケースなどタイプ別・収納量で選べます♪おしゃれなカメラバッグや、おすすめシリーズ・人気ブランドも♪カメラのキタムラ ネットショップへ'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラバッグ・カメラケース特集',
          linkUrl: '/ec/special/camera/bag',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292083064', '4549292083071', '4905524972535', '4549292167382'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
