



























































































































































































































































































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'z_8',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    naviBtn: NaviBtn,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ニコン Z 8 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Z 8  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2023/05/11 10:00',
      fanValidTo: '2023/05/12 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/05/12 10:00',
      reserveValidTo: '2023/05/25 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/05/26 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ニコン Z 7II ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905697',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/5e1/b3b/c7d/3f5/e91/72f/7ec/8ed/f8f/e1a/0cb/cs9/b0j/k/M.jpg',
        txt: 'ニコン Z 8 ',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z_8/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ニコン Z 7II ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905697' },
          { name: 'ニコン Z 6II ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905901' },
          { name: 'ニコン Z 9 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759906168' },
          { name: 'ニコン D850 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759149336' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '多様な撮影シチュエーションで活躍する快適な操作性能',
          contents: [
            'フラッグシップモデル「Z 9」の体積比で約30%削減の小型化を実現したカメラボディーに高い性能と機能を搭載。小型化しつつ、ホールド感に優れたグリップと操作性を損なわない設計を採用。超望遠レンズの使用を含むさまざまな撮影シーンで、軽快で確実なカメラワークが可能',
            '縦横4軸チルト式の画像モニターを搭載し、縦位置、横位置問わずハイアングルおよびローアングル撮影の高い操作性を実現',
            '12bit RAW動画、10bit Apple ProRes 422 HQ動画のカメラ内部収録により、外部レコーダー不要のコンパクトな動画撮影システムを実現'
          ]
        },
        {
          subtitle: '決定的瞬間を逃さない捕捉性能',
          contents: [
            '最長約125分の 4K UHD / 60p 動画、約90分の 8K UHD / 30p 動画を記録でき、ドキュメンタリー撮影やウェディング撮影など、決定的瞬間を逃すことなく、かつ長時間記録が求められる幅広いシーンに対応',
            '静止画・動画撮影ともに9種類の被写体検出に対応。新たに「飛行機」専用モードを搭載。飛行機の大きさに関わらず安定した検出が可能となり、背景に様々なものが映り込むシーンや暗いシーンにおける検出能力が向上',
            '「Z 9」と同等の高速・高精度AF性能を実現。低輝度限界-9EVによって暗いシーンでもAFでピントが合いやすく、逆光時でもライブビュー用画像とAF用画像を個別で露出制御するため、被写体へのピント合わせが容易です',
            'シャッターボタンの全押しから最大1秒間遡って記録ができる「プリキャプチャー」を搭載。例えばシャッターボタンを半押しして狙っていた野鳥が飛び立ってから全押ししても飛び立つ瞬間まで遡って撮影が可能'
          ]
        },
        {
          subtitle: '静止画・動画の表現力を高める優れた描写性能',
          contents: [
            '静止画撮影時に、高画質のままファイル容量を抑えて記録するHEIF形式に対応。10bitの豊かな階調を活かし、階調の豊かな朝焼けや夕焼け、輝度の高い電飾パレードも、より印象的に表現が可能',
            '8Kオーバーサンプリングによる高精細な 4K UHD 60p / 50p / 30p 動画撮影にも対応。「人物印象調整」機能に加え、新たに「美肌効果」機能を搭載し、特にポートレート撮影において、より思い通りの表現が可能'
          ]
        },
        {
          subtitle: 'その他の特長',
          contents: [
            'NIKKOR Z レンズの性能を最大限引き出し、圧倒的な解像感を発揮する有効画素数4571万画素',
            '実際の被写体の動きを途切れずに常に表示する「Real-Live Viewfinder」を搭載',
            '動きの激しい場面でも狙った被写体にピントを合わせ続ける「3D-トラッキング」を搭載',
            '豊富なAFエリアパターンに対応した「カスタムワイドエリアAF」を搭載',
            '最大6.0段の強力な手ブレ補正効果を発揮する「シンクロVR」',
            '「スターライトビュー」機能や「ボタンイルミネーション」など、暗所撮影をサポートする機能を搭載',
            '画像の縦位置再生および再生時の「i メニュー」の縦表示に対応し、縦位置撮影を効率よくサポート',
            '動画撮影時に単焦点レンズを使用してもズーミングが高画質で行える「ハイレゾズーム」に対応',
            '動画編集時の複数の映像や音声の同期作業を容易にするためのタイムコード同期に対応',
            'マイナス10℃でも動作する耐寒性能を実現し、プロフェッショナルの過酷な使用環境下での撮影をサポート',
            'シャッターの耐久回数を気にせず使用できるメカシャッターレス機構を採用',
            '撮像素子のダスト対策として、ダブルコートを採用したほか、センサーシールドを搭載',
            '充給電専用と通信専用の2つのUSB端子を搭載し、充給電しながら通信が可能',
            'マグネシウム合金とプロフェッショナルグレードの新しい炭素繊維複合材料の採用で高い堅牢性・信頼性を実現',
            'NX Studio Ver.1.4.0 でHDRの表示に対応。階調モード「HLG」で撮影した静止画の表示・編集が可能。さらに「人物印象調整」「美肌効果」の調整にも対応'
          ]
        }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取'
        },
        {
          href: '/ec/special/reuse/watch',
          img: 'https://shopimg.kitamura.jp/images/banner/9216.jpg',
          alt: '中古時計販売'
        },
        {
          href: '/ec/special/reuse/smartphone',
          img: 'https://shopimg.kitamura.jp/images/banner/9217.jpg',
          alt: '中古スマホ販売'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'ニコン Z 8',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759909947'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759909947', '4960759905697', '4548736145573', '4549292157345'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736134119'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
