






































































import Vue from 'vue';
import { computed, reactive, ref, toRefs, watch } from '@vue/composition-api';
import AuthService from '@/logic/auth.service';

export default Vue.extend({
  name: 'login-menu',
  setup: (_, context) => {
    const { authorizer, loader } = context.root.$store;
    const emailRef = ref<HTMLImageElement>();
    const passwordRef = ref<HTMLImageElement>();

    const state = reactive({
      email: '',
      password: '',
      errorMessage: '',
      lineLoginUrl: '',
      registerUrl: ''
    });

    // 初期化
    const init = () => {
      // 現在のURL
      const currentUrlParams = new URLSearchParams({
        needsso: 'true'
      });
      let currentUrl = location.origin + context.root.$route.fullPath;
      currentUrl += context.root.$route.query && !!Object.keys(context.root.$route.query).length ? '&' : '?';
      currentUrl += currentUrlParams.toString();

      const urlParamsLineLogin = new URLSearchParams({
        s: process.env.VUE_APP_SITE_ID,
        u: currentUrl
      });
      state.lineLoginUrl = process.env.VUE_APP_API_MEMBER_BASE_URL + 'sso/login.html?' + urlParamsLineLogin.toString();

      const urlParamsRegister = new URLSearchParams({
        rs: process.env.VUE_APP_SITE_ID
      });
      state.registerUrl = process.env.VUE_APP_API_MEMBER_BASE_URL + 'group/account/regist.do?' + urlParamsRegister.toString();

      state.errorMessage = '';
    };

    /**
     * ログインダイアログを表示する際に、すでにログイン状態であるか確認する
     */
    const checkLoginStatus = (): void => {
      init();
      authorizer.openLoginMenu();
    };

    /**
     * ログインを実行する
     */
    async function login(): Promise<void> {
      loader.routeUnset();
      state.errorMessage = '';
      // フォームからフォーカスを外す
      emailRef.value?.blur();
      passwordRef.value?.blur();

      try {
        await AuthService.login(state.email, state.password);
        authorizer.loginMenuOpenState = false;
      } catch (error) {
        console.error(error);
        const status = error.response?.status || -1;
        if (status === 401) {
          state.errorMessage = 'メールアドレスまたはパスワードが正しくありません。';
        } else {
          state.errorMessage =
            'ただいまシステムが混みあっている可能性があります。しばらくお待ちいただきますようお願い申し上げます。ご迷惑をおかけして申し訳ございません。';
        }
      } finally {
        loader.routeLoaded();
      }
    }

    watch(
      () => authorizer.loginMenuOpenState,
      (newValue, oldValue) => {
        // ダイアログの開閉時にそれぞれ初期化する
        if (newValue && !oldValue) {
          init();
        } else if (!newValue && oldValue) {
          state.email = '';
          state.password = '';
          state.errorMessage = '';
        }
      }
    );

    // ダイアログ表示状態
    const isShowMenu = computed({
      get: () => authorizer.loginMenuOpenState,
      set: (openState) => (authorizer.loginMenuOpenState = openState)
    });

    return {
      ...toRefs(state),
      checkLoginStatus,
      emailRef,
      passwordRef,
      login,
      isShowMenu
    };
  }
});
