





























































































































































































































































































































import Vue from 'vue';
import { computed, reactive, SetupContext, toRefs, onMounted, watch, ref } from '@vue/composition-api';
import UserMenu from '@/components/header/user-menu.vue';
import SuggestService from '@/logic/suggest.service';
import { SuggestItem } from '@/types/suggest';
import { SEARCH_STATE } from '@/constants/search-state';
import { NewerCategory, SellCategory, UsedCategory } from '@/types/tsv-config';

export default Vue.extend({
  name: 'search-menu',
  components: {
    'user-menu': UserMenu
  },
  setup: (prop, context: SetupContext) => {
    const { searcher, authorizer, category } = context.root.$store;

    const state = reactive({
      keywordSearchCombobox: ref<HTMLElement>(),
      newOldList: SEARCH_STATE.option,
      selectNewOld: SEARCH_STATE.option[0].value,
      newerCategoryList: [
        {
          text: '全てのカテゴリから',
          value: 'all'
        }
      ],
      usedCategoryList: [
        {
          text: '全てのカテゴリから',
          value: 'all'
        }
      ],
      sellCategoryList: [
        {
          text: '全てのカテゴリから',
          value: 'all'
        }
      ],
      selectCategory: 'all',
      search: '' as string | null,
      suggestItems: [] as any[],
      descriptionLimit: 60,
      entries: [],
      isLoading: false
    });

    /**
     * ユーザー名取得
     */
    const userName = computed(() => {
      if (authorizer.user?.lastName && authorizer.user?.firstName) {
        return authorizer.user.lastName + ' ' + authorizer.user.firstName;
      } else {
        return 'ゲスト';
      }
    });

    /**
     * 検索時の選択カテゴリーリストを生成
     */
    const generateCategorySearchList = async () => {
      // 新品用カテゴリリスト
      if (!category.newerCategory.length) await category.fetchNewerCategories();
      category.newerCategoryOnlyParent.forEach((cate: NewerCategory) => {
        state.newerCategoryList.push({
          text: cate.parentCategoryName,
          value: cate.parentCategoryName
        });
      });

      // 中古在庫用カテゴリリスト
      if (!category.usedCategory.length) await category.fetchUsedCategories();
      category.usedCategoryOnlyParent.forEach((cate: UsedCategory) => {
        state.usedCategoryList.push({
          text: cate.categoryName,
          value: cate.categoryName
        });
      });

      // 買取り用カテゴリリスト
      if (!category.sellCategory.length) await category.fetchSellCategories();
      category.sellCategory.forEach((cate: SellCategory) => {
        state.sellCategoryList.push({
          text: cate.categoryName,
          value: cate.categoryName
        });
      });
    };

    /**
     * 検索時の選択カテゴリーリストを取得
     */
    const categoryList = computed(() => {
      let displayList = [];
      switch (state.selectNewOld) {
        case '2':
          displayList = state.usedCategoryList;
          break;
        case '4':
          displayList = state.sellCategoryList;
          break;
        default:
          displayList = state.newerCategoryList;
          break;
      }
      return displayList;
    });

    /**
     * 新品・中古の選択が変更されたとき、カテゴリの選択を初期化する
     */
    watch(
      () => state.selectNewOld,
      () => {
        state.selectCategory = 'all';
      }
    );

    /**
     * 検索ワードに基づくサジェストを取得
     */
    async function getSuggest() {
      let result = {} as SuggestItem;
      let showItem = [];

      try {
        switch (state.selectNewOld) {
          case '0': // 新品・中古
          case '1': // 新品のみ
            result = await SuggestService.searchNewItem('', String(state.search), 'popularity');
            break;
          case '2': // 中古のみ
            result = await SuggestService.searchUsedItem('', String(state.search));
            break;
          case '4': // 買取り
            result = await SuggestService.searchSellItem('', String(state.search));
            break;
          default:
            // エラー処理
            break;
        }
      } catch (error) {
        console.error(error);
      }

      /*商品名・カテゴリの順番*/
      showItem = [{ header: '商品名で検索する' }] as Array<any>;
      if (!(result?.conditions.length == 0 && result?.items.length == 0)) {
        if (result?.items.length) {
          for (let i = 0; i < Math.min(7, result.items.length); i++) {
            showItem.push({
              name: result.items[i].title,
              keyword: result.items[i].title,
              itemId: result.items[i].jan_code,
              field: 'items'
            });
          }
        }

        if (result?.conditions.length) {
          showItem.push({ header: 'カテゴリーから検索する' });
          for (let i = 0; i < Math.min(3, result.conditions.length); i++) {
            showItem.push({
              name: result.conditions[i].condname,
              keyword: state.search,
              field: result.conditions[i].axis,
              count: result.conditions[i].count
            });
          }
        }
      }
      state.suggestItems = showItem;
    }

    /*カテゴリ・商品名の順番
          if (!(result?.conditions.length == 0 && result?.items.length == 0)) {
            showItem = [{ header: 'カテゴリーから検索する' }] as Array<any>;
            if (result?.conditions.length) {
              for (let i = 0; i < Math.min(3, result.conditions.length); i++) {
                showItem.push({
                  name: result.conditions[i].condname,
                  keyword: state.search,
                  field: result.conditions[i].axis,
                  count: result.conditions[i].count
                });
              }
            }
            showItem.push({ header: '商品名で検索する' });
    
            if (result?.items.length) {
              for (let i = 0; i < Math.min(7, result.items.length); i++) {
                showItem.push({
                  name: result.items[i].title,
                  keyword: result.items[i].title,
                  itemId: result.items[i].jan_code,
                  field: 'items'
                });
              }
            }
          }
          state.suggestItems = showItem;
        }
    */
    watch(
      () => [state.search],
      ([newSearch], [oldSearch]) => {
        // searchの値が変更されたとき
        if (newSearch != oldSearch) {
          if (newSearch) {
            searcher.updateSearchWord(newSearch);
            getSuggest();
          } else {
            searcher.updateSearchWord('');
            state.suggestItems = [];
          }
        }
      }
    );

    // SP: 検索ボックスの固定化解除
    const closeFixedSearch = () => {
      state.search = null;
      state.keywordSearchCombobox?.blur();
      searcher.changeFixed(false);
      state.suggestItems = [];
    };

    /**
     * ネット中古の商品一覧ページに遷移する
     */
    const toChukoProductList = (keyword: string, itemId: string, category: string): void => {
      const urlParams = new URLSearchParams({
        keyword: itemId ? itemId : keyword
      });
      if (category) {
        urlParams.append('category', category);
      }
      const url = process.env.VUE_APP_NET_CHUKO_URL + 'ec/sell/item-list?' + urlParams.toString();

      window.location.href = url;
    };

    // TODO: EC_FRONT-482の対応時コメントアウト解除、遷移処理切り替え
    // /**
    //  * パラメーターをURL用に変換
    //  * @param param パラメーター
    //  */
    // const paramToUrl = (param: { [key: string]: string | number }): string => {
    //   let paramStr = '';
    //   Object.entries(param).forEach(([key, val], index) => {
    //     paramStr = index === 0 ? paramStr + '?' : paramStr + '&';
    //     paramStr = paramStr + `${key}=${val}`;
    //   });
    //   return paramStr;
    // };

    /**
     * 一覧ページへの遷移処理
     * @param itemId サジェストの商品ID（買取の選択時のみ使用）
     */
    const toProductList = (itemId?: string) => {
      // 検索時に選択肢のメニューを閉じるために検索Boxからフォーカスを外す。
      state.keywordSearchCombobox?.blur();
      searcher.changeFixed(false);

      const param: { [key: string]: string } = {};

      // キーワード入力
      if (searcher.searchWord) {
        param['keyword'] = searcher.searchWord;
      } else {
        alert('キーワードを入力してください。\n\n例）商品名・型番 etc');
        return;
      }

      // カテゴリ選択
      if (state.selectCategory !== 'all') {
        param['category'] = state.selectCategory;
      }

      switch (state.selectNewOld) {
        case '2':
          // 中古在庫の場合のパラメーター
          param['type'] = 'u';
          context.root.$router.push({
            name: 'product-list-page',
            query: param
          });
          // window.location.href = '/ec/list' + paramToUrl(param);
          break;
        case '4':
          // 買取りの場合、既存のネット中古ページに遷移させる
          param['itemId'] = itemId ? itemId : '';
          toChukoProductList(param['keyword'], param['itemId'], param['category']);
          break;
        default:
          param['narrow18'] = state.selectNewOld;
          context.root.$router.push({
            name: 'product-list-page',
            query: param
          });
          // window.location.href = '/ec/list' + paramToUrl(param);
          break;
      }
    };

    /**
     * サジェスト選択時
     * @param field 選択したサジェストの区分
     * @param name 選択したサジェスト名
     * @param itemId 選択したサジェストの商品ID
     */
    const selectSuggest = (field: string, name: string, itemId: string) => {
      // カテゴリ選択後の処理
      if (field === 'category') {
        const selectCategoryInfo = category.searchNewerCategoryByConcatenationName(name);
        state.selectCategory = Object.entries(selectCategoryInfo).length ? selectCategoryInfo.parentCategoryName : 'all';
      } else {
        searcher.updateSearchWord(name);
      }
      toProductList(itemId);
    };

    /**
     * キー押下時の事前判定
     * @param keyCode 押下したキーコード
     */
    const keydownWrapper = (keyCode: number) => {
      // 日本語の変換確定時は、遷移しない
      if (keyCode !== 13) return;
      toProductList();
    };

    /**
     * オブジェクトタイプかの判定
     * @param item 判定対象
     */
    function isObjectType(item: unknown): boolean {
      return typeof item === 'object';
    }

    onMounted(() => {
      generateCategorySearchList();
      // ブラウザの戻るボタン検知用（Vue系のものは、反応しないときがあるため）
      window.addEventListener('popstate', function (e) {
        closeFixedSearch();
      });
    });

    return {
      searcher,
      ...toRefs(state),
      getSuggest,
      userName,
      selectSuggest,
      closeFixedSearch,
      toProductList,
      keydownWrapper,
      isObjectType,
      categoryList
    };
  }
});
