// Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import VueMeta from 'vue-meta';

import App from './main.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import globalStore from './store/global';
import ApiService from './logic/api.service';
import './styles/common.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { formatPrice, formatDate } from './logic/utils';
import VueScrollTo from 'vue-scrollto';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueClipboard from 'vue-clipboard2';
import VueLazyload from 'vue-lazyload';
import { fraudbuster } from './directives/fraudbuster';

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;
Vue.prototype.$store = globalStore();
Vue.use(VueMeta);
Vue.use(VueScrollTo, {
  offset: -170,
  duration: 300
});
Vue.use(VueEasyLightbox);
Vue.use(VueClipboard);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/no-image-M.gif')
});

dayjs.locale(ja);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

ApiService.init();

Vue.filter('date', formatDate);
Vue.filter('price', formatPrice);

Vue.directive('fraudbuster', fraudbuster);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
