var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step",class:{
    'mb-4': _vm.$vuetify.breakpoint.mdAndUp,
    'mb-2': _vm.$vuetify.breakpoint.smAndDown
  }},[(!(_vm.$vuetify.breakpoint.mdAndUp && _vm.type !== _vm.ORDER_DETAIL_TYPES.BUY_DELIVERY && _vm.currentStep.no !== 0))?_c('div',{staticClass:"step-text mb-2 font-weight-bold",class:{
      'text-center': _vm.$vuetify.breakpoint.mdAndUp
    }},[_vm._v(" "+_vm._s(_vm.kindName)+"状況："+_vm._s(_vm.currentStep.text)+" ")]):_vm._e(),_c('v-stepper',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.no !== 0),expression:"currentStep.no !== 0"}],key:_vm.currentStep.no,staticClass:"step-bar elevation-0",class:{
      'mx-4': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"alt-labels":"","value":_vm.currentStep.no}},[_c('v-stepper-header',[_vm._l((_vm.displayedStepList),function(step,index){return [_c('v-stepper-step',{key:("step-" + (step.no)),attrs:{"step":step.no}},[_vm._v(_vm._s(step.text))]),(index !== _vm.displayedStepList.length - 1)?_c('v-divider',{key:("separator-" + (step.no)),class:{
            active: step.no < _vm.currentStep.no
          }}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }