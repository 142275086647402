import ApiService from './api.service';
import { AxiosRequestConfig } from 'axios';
import ProductService from './product.service';
import { ProductItem } from '@/types/product-list';
import { ProductDetail, UsedProductDetail } from '@/types/product';
import TsvConfigService from './tsv/tsv-config.service';
import { isLocalHost, splitList } from '@/logic/utils';
import { SPECIAL_ITEM_SORT_LIST } from '@/constants/sort-list';
import { SPECIAL_DISPLAY_CONUT_LIST } from '@/constants/display-conut-list';

const SpecialProductService = {
  convertProduct(productList: any[], secretType = false, usedType = false): ProductItem[] {
    const updatedProductList: ProductItem[] = [];
    productList.forEach((element) => {
      let item: ProductItem | null = null;
      // 中古特集用
      if (usedType) {
        item = {
          title: element.itemName,
          data18: '',
          price: element.price,
          image: element.imageUrl1,
          itemid: element.itemCode,
          narrow1: element.makerName,
          isSalesEnd: false
        };
      } else {
        item = {
          title: element.itemName,
          data18: secretType ? element.rating || 0 : element.ratingTotal,
          price: secretType ? element.secretPrice : element.price,
          image: secretType ? element.imagePath : element.images[0].imagePath,
          itemid: element.janCode,
          narrow1: element.makerName,
          isSalesEnd: secretType ? false : element.isSalesEnd
        };
      }
      if (item) updatedProductList.push(item);
    });
    return updatedProductList;
  },

  /**
   * テンプレートA 特集商品取得
   * @param id 特集ID
   */
  async getSaleSpecialProduct(id: string): Promise<ProductItem[]> {
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + 'special_product';
    const specialProduct = await ApiService.get(url, { params: { id } });

    const janCodes = specialProduct.results.map((item: any) => item.janCode);

    // 商品詳細取得APIはJANコード指定が最大20件のため、分割して取得する
    const splittedJanCodes = splitList(janCodes, 20);
    const responses = splittedJanCodes.map((splittedJanCodes) => ProductService.fetchProducts(splittedJanCodes, true, undefined, undefined, id));
    const promise = await Promise.allSettled(responses);
    const items = promise.reduce((list, res) => {
      if (res.status === 'fulfilled') {
        list = list.concat(res.value.items);
      }
      return list;
    }, new Array<ProductDetail>());

    return this.convertProduct(items);
  },

  /**
   * テンプレートA 特集中古商品取得
   * @param id 特集ID
   */
  async getSaleSpecialUsedProduct(id: string): Promise<ProductItem[]> {
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + 'special_used';
    const specialProduct = await ApiService.get(url, { params: { id } });

    const itemCodes = specialProduct.results.map((item: any) => item.itemCode) as Array<string>;

    const responses = itemCodes.map((itemCode: string) => ProductService.fetchUsedProducts(itemCode));
    const promise = await Promise.allSettled(responses);
    const items = promise.reduce((list, res) => {
      if (res.status === 'fulfilled') {
        list = list.concat([res.value]);
      }
      return list;
    }, new Array<UsedProductDetail>());

    return this.convertProduct(items, false, true);
  },

  /**
   * テンプレートA 限定商品取得
   * @param id 特集ID
   * @param passCode パスコード
   */
  async getSaleSecretProduct(id: string, passcode?: string): Promise<ProductItem[]> {
    const url = process.env.VUE_APP_API_PRODUCT_BASE_URL + 'secret_product';
    const secretProduct = await ApiService.get(url, { params: { id, passcode } });

    return this.convertProduct(secretProduct.results, true);
  },

  /**
   * テンプレートB 特集商品取得
   * @param janCodes 商品JanCode
   */
  async getNewsSpecialProduct(janCodes: Array<string>): Promise<ProductItem[]> {
    // 商品詳細取得APIはJANコード指定が最大20件のため、分割して取得する
    const splittedJanCodes = splitList(janCodes, 20);
    const responses = splittedJanCodes.map((splittedJanCodes) => ProductService.fetchProducts(splittedJanCodes, true));
    const promise = await Promise.allSettled(responses);
    const items = promise.reduce((list, res) => {
      if (res.status === 'fulfilled') {
        list = list.concat(res.value.items);
      }
      return list;
    }, new Array<ProductDetail>());

    const productList = this.convertProduct(items);
    return productList;
  },

  async fetchJsonFile(path: string): Promise<any> {
    const url = process.env.VUE_APP_STATIC_FILE_BASE_URL + 'json/sale/' + path;
    const response = await ApiService.get(url, { headers: { 'Cache-Control': 'no-cache' } }, true, false, true);
    return response;
  },

  async getSpecialJson(path: string): Promise<any> {
    const response = await this.fetchJsonFile(path);
    response.data.footBanners = TsvConfigService.getFilteredValues(response.data.footBanners);
    return response;
  },

  /**
   * サーバー時間取得用
   */
  async getHealthCheck(): Promise<any> {
    const url = isLocalHost() ? 'https://nc-feature9.kitamura.shop/healthcheck' : '/healthcheck';
    const config: AxiosRequestConfig = { headers: { 'Cache-Control': 'no-cache' } };
    config.responseType = 'text';
    const response = await ApiService.get(url, config, true, false, true);

    return response;
  },

  /**
   * ソートリストフィルター
   */
  filterSortList(selectList: Array<string>): Array<{ text: string; value: string }> {
    return selectList.length ? SPECIAL_ITEM_SORT_LIST.filter((sort) => selectList.includes(sort.value)) : SPECIAL_ITEM_SORT_LIST;
  },

  /**
   * 表示件数リストフィルター
   */
  filterDisplayCountList(selectList: Array<number>): Array<{ text: string; value: number }> {
    return selectList.length ? SPECIAL_DISPLAY_CONUT_LIST.filter((sort) => selectList.includes(sort.value)) : SPECIAL_DISPLAY_CONUT_LIST;
  },

  /**
   * 表示件数「すべて」か否か
   */
  isAllCount(selectedCount: number): boolean {
    const allVal = SPECIAL_DISPLAY_CONUT_LIST.find((count) => count.text === 'すべて');
    return allVal?.value === selectedCount;
  }
};
export default SpecialProductService;
