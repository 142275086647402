

































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: '6dmk2',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    kitamuraService: KitamuraService,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    priceAndPurchase: PriceAndPurchase,
    appearanceImage: AppearanceImage,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '好評発売中！キヤノンEOS 6D MarkII | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン EOS 6D MarkII 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '35mmフルサイズ　約2620万画素CMOSセンサー',
          contents: [
            '新たに自社開発・自社生産したフルサイズセンサー',
            '約2620万画素で、被写体のディテールまで鮮鋭に描写。',
            '最新のセンサー技術を導入することで、従来機種から高画素化しながらも豊かな階調表現や低ノイズ化を達成。',
            '新開発有効約2620万画素フルサイズCMOSセンサー×最新映像エンジンDIGIC 7を搭載',
            '高画素化しながらも常用最高ISO40000を実現し、暗所での撮影やスポーツ撮影でも高画質な撮影が可能'
          ]
        },
        {
          subtitle: '「バリアングル＆タッチパネル液晶モニター',
          contents: [
            '前方向約180度、後ろ方向約90度、水平方向約175度に稼働するバリアングル液晶をEOS のフルサイズとして初採用。',
            'EOSフルサイズ一眼としてバリアングル液晶を初採用',
            'ライブビュー撮影時や動画撮影時の操作性や撮影アングルの自由度が高まり、実用性が大幅に向上。'
          ]
        },
        {
          subtitle: 'オールクロス45点AFセンサー',
          contents: [
            '全測距点でF5.6光束対応のクロス（縦／横）測距が可能',
            '中央にはF2.8とF5.6のデュアルクロス測距※２を配置。※１　使用レンズにより、測距点数、デュアルクロス測距点数、クロス測距点数が変動します。※２　AFグループ属性：対応レンズについてはホームページでご確認ください。',
            '上下18点を除いた最大27点の開放値F8対応や、測距エリア選択モードの搭載も実現しました'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/general/highly_recommended/2020/4722/',
          img: 'https://shopimg.kitamura.jp/images/banner/7619.png',
          alt: 'スタッフイチオシ！キヤノン RF70-200mm F2.8 L IS USM'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノンEOS 6D MarkII',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292083897', '4549292084016', '4549292084061'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292083897', '4960999964508', '4549292075748'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292083897', '4549292084016', '4549292084061', '4549292087512'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
