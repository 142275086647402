
























































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'group',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '集合写真撮影のコツ | 撮影のコツ動画＆便利なカメラ写真グッズ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '集合写真撮影のコツを、３分の動画でチェックしましょう♪あると便利なカメラグッズやおすすめの写真アイテムも必見。単調になりがちな集合写真も、楽しく上手に残せる方法をお伝えします★'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '集合写真撮影のコツ',
          disabled: true
        }
      ],
      groupList: [
        {
          title: '集合写真撮影におすすめ！便利なカメラグッズ',
          items: [
            {
              subtitle: 'ゴリラポッド',
              href: '/ec/ct/C008/002/008/',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/group/img_a.jpg',
              txt:
                'どこでも巻きつくコンパクトな三脚。不安定な場所でも、くにゃくにゃ自在に動く足で高さや角度を調整できます。\nカメラマン役が多い私でも、自分の写っている写真が増えました！'
            },
            {
              subtitle: 'リモートコード',
              href:
                '/ec/list?narrow18=0&keyword=ワイヤレスリモコン&category=カメラアクセサリー・用品&limit=100&index=&n20c=完了商品は除く&searchbox=1&q=ワイヤレスリモコン&path=カメラアクセサリー・用品',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/group/img_b.jpg',
              txt:
                'ワイヤレスのリモコンはとっても便利！セルフタイマーに合わせて、カメラの間を急いで往復することもなくなりました。一瞬を逃さず撮れ、手ブレを防止するケーブルレリーズの代わりにもなります♪'
            }
          ]
        },
        {
          title: '写真を楽しむフォトグッズ',
          items: [
            {
              subtitle: 'ラスタープリント',
              href: 'https://www.kitamura-print.com/print/luster/?_ga=2.54015150.729448613.1635119273-279164427.1627285160',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/group/img_c.jpg',
              txt:
                '指紋がつきにくく、落ち着いた仕上がりのラスタープリント。光の写り込み（反射）が少なく、色調の表現力が豊かです！集合写真は2Lサイズでプリント＆プレゼントするのが定番です♪'
            },
            {
              subtitle: '写真袋',
              href:
                '/ec/list?narrow18=0&keyword=写真袋&category=&index=all&index=all&searchbox=1&sort=number20,Number17,Score&q=写真袋&path=&n20c=完了商品は除く',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/group/img_d.jpg',
              txt: '写真をプレゼントする時に便利でかわいい写真袋★お菓子や小物など、ちょっとしたものを贈る時にも使っています♪'
            }
          ]
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
