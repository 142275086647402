













































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'pen_e-p7',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    appearanceImage: AppearanceImage,
    priceAndPopular: PriceAndPopular,
    watchInVideo: WatchInVideo,
    urlcopy: Urlcopy,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'オリンパス PEN E-P7 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'オリンパス PEN E-P7 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'オリンパス PEN E-P7',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350053574', '4545350053581', '4545350053598', '4545350053604'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4545350053574', '4545350044350', '4547410442632', '4548736108486'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4545350053574',
        '4545350053598',
        '4545350053581',
        '4545350053604',
        '4545350044152',
        '4545350042189',
        '4545350053536',
        '4545350053536'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images2' },
        { naviItem: '作例画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'ミラーレスとニコンの伝統的なデザインの融合',
          contents: [
            'フロントとリアの各ダイヤルにはアルミ削り出しのパーツを採用。細部のディテールまで作り込まれた洗練されたデザイン',
            'ボディー単体で337g、標準ズームレンズと組み合わせてもわずか430g。気軽に持ち歩いて軽快に撮影が楽しめます'
          ]
        },
        {
          subtitle: 'クリエイティブな写真表現が楽しめる「プロファイルコントロール」、「アートフィルター」搭載',
          contents: [
            '瞬時に「プロファイルコントロール」に切り替えられる専用のスイッチをボディ前面に配置。自分好みのクリエイティブな写真表現が簡単に楽しめます',
            '個性的な写真表現を簡単に楽しめる「アートフィルター」には、モニターに表示されるスライダーを上下に動かすだけでフィルターの効果を調整できるファインチューンも搭載'
          ]
        },
        {
          subtitle: '「M.ZUIKO」の描写力を生かすLive MOSセンサーと画像処理エンジン、ボディー内5軸手ぶれ補正',
          contents: [
            '2000万画素Live MOSセンサーと、高速処理が可能な画像処理エンジン「TruePic VIII」を搭載。「M.ZUIKO」レンズの持つ高い描写力を最大限に引き出します',
            '最大4.5段分の補正効果を発揮するボディー内5軸手ぶれ補正を搭載。様々なシーンでぶれない高画質を楽しめます',
            'OM-Dシリーズのプロフェッショナル向けモデルで定評ある「顔優先／瞳優先AF」も採用。横顔やうつむいて顔の一部が隠れていても、顔や瞳を自動検出し、ピントを合わせ続けます'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'http://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラメンテナンスサービス'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
