


































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
//import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'summerhomework-moon',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    //watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '夏休み自由研究特集 【理科】天体観測 ~ 月の写真を撮ってみよう | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '夏休み♪理科の自由研究に天体観測★月の写真を撮ろう！天体観測入門におすすめ！月の満ち欠け・月齢を調べながら、天体望遠鏡や一眼レフカメラを使って月撮影にチャレンジ★意外と簡単に月の写真が撮れますよ♪'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '夏休み 自由研究特集',
          linkUrl: '/ec/special/general/summerhomework',
          disabled: false
        },
        {
          path: '【理科】天体観測 ~ 月の写真を撮ってみよう ~',
          disabled: true
        }
      ],
      slrCameraList: [
        {
          subtitle: '1.カメラを固定しよう',
          txt:
            '暗いので手ブレがおこりやすいです。\n三脚を使いましょう。ない場合は、脇をしっかりしめて撮りましょう\n\n※ブレると模様がモヤっとするので注意してね',
          src: 'https://shop.kitamura.jp/ts_rsc/484/images/img_kotei.jpg'
        },
        {
          subtitle: '2.カメラの設定の目安',
          txt: 'ISO：400\n絞り：F8\nシャッタースピード：1/500秒\n\n※露出を調整できる応用撮影モードなどを選びましょう',
          src: 'https://shop.kitamura.jp/ts_rsc/484/images/img_mode.jpg'
        },
        {
          subtitle: '3.露出を微調整しよう',
          txt:
            '撮った写真を液晶モニターで確認しながら進めよう。明るすぎて模様が見えない場合は、露出をマイナス側に補正\n\n※うさぎ模様が撮れるかどうかはココが大切',
          src: 'https://shop.kitamura.jp/ts_rsc/484/images/img_roshutu.jpg'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
