










import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';

export default Vue.extend({
  name: 'my-page-title',
  setup: (_, context) => {
    const { authorizer } = context.root.$store;

    const state = reactive({
      // Tポイント
      tPoint: authorizer.user?.point || 0
    });

    // Tポイント情報を検知
    watch(
      () => authorizer.user,
      (user) => {
        state.tPoint = user?.point || 0;
      }
    );

    return {
      ...toRefs(state)
    };
  }
});
