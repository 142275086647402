





































import Vue from 'vue';
import { FREQUENTLY_USED_LINKS, FREQUENTLY_USED_OTHER_LINKS } from '@/constants/frequently-used-links';

export default Vue.extend({
  name: 'product-link',
  setup: () => {
    return {
      usedLinks: FREQUENTLY_USED_LINKS,
      otherLinks: FREQUENTLY_USED_OTHER_LINKS
    };
  }
});
