




























































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'skillup-01',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'カメラを買ったら最初にすべき設定とは？ | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'カメラを買ったら最初にすべき設定とは？');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラを買ったら最初にすべき設定とは？',
          disabled: true
        }
      ],
      modeList: [
        { mode: 'Pモード', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/skillup/01/p.jpg' },
        { mode: 'A(Av)モード', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/skillup/01/a.jpg' },
        { mode: 'S(Tv)モード', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/skillup/01/s.jpg' },
        { mode: 'Mモード', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/skillup/01/m.jpg' }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
