

































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'highly-recommended-2015-0522',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = 'iPhoneのデータを簡単バックアップ！サンディスク iXpand（アイ・エクスパンド） | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'iPhone・iPadの容量不足を解消！『サンディスク iXpand（アイ・エクスパンド）』／iPhone・iPadのバックアップにおすすめのUSBフラッシュメモリー。お手ごろ価格の16GB、使いやすい32GB、たっぷり保存できる64GBをラインナップ。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'iPhoneのデータを簡単バックアップ！サンディスク iXpand（iPhone・iPad用USBフラッシュメモリー） | スタッフのイチオシ',
          disabled: true
        }
      ],
      backNumberSmartphoneList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-elecom_high-reso_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0814', name: '「意外にいい音」、エレコム ハイレゾイヤホンシリーズ EHP-CH1000 他' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-ztylus_500px.jpg', href: '/ec/special/general/highly_recommended/2015/0710', name: 'iPhone6用レンズアタッチメント ズタイラス RV-2 & ZIP-6LB' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0828/i-item-ct-wpip13_350px.jpg', href: '/ec/special/general/highly_recommended/2015/0619', name: '防水、耐衝撃の強靭なiPhoneケース Catalyst Case for iPhone 6' },
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
