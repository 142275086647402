

















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import OperaglassTitle from '@/components/common/special/operaglass/operaglass-title.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';

export default Vue.extend({
  name: 'operaglass',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    operaglassTitle: OperaglassTitle,
    watchInVideo: WatchInVideo,
    'product-list-by-special-id': ProductListBySpecialId,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'コンサートやライブ、スポーツ観戦におすすめ！双眼鏡の選び方 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'メガネやアイメイクに配慮した双眼鏡やブレずによく見える防振単眼鏡などオペラグラスよりおすすめな双眼鏡をご紹介！野鳥観察や星空鑑賞、美術鑑賞にも便利です'
      );

    const state = reactive({
      naviList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a01.png', href: '#a1', alt: 'コンサート・ライブにおすすめ双眼鏡' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a05.png', href: '#a2', alt: '美術鑑賞・観劇におすすめ双眼鏡' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a02.png', href: '#a3', alt: 'スポーツ観戦におすすめ双眼鏡' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a03.png', href: '#a4', alt: 'アウトドア・バードウォッチングに' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a04.png', href: '#a5', alt: '星空鑑賞におすすめ双眼鏡' },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/operaglass/og-a07.png', href: '#a6', alt: 'ブレずに表情までよく見える防振タイプ' }
      ],
      naviList2: [
        { name: '基礎知識', href: '#a7' },
        { name: '商品一覧', href: '#a8' },
        { name: '特集一覧', href: '#a9' }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/girlscamera',
          img: 'https://shop.kitamura.jp/images/banner/1390.jpg',
          alt: '女子がきゅんとくる ミラーレス一眼カメラ'
        },
        {
          href: '/ec/special/camera/strap',
          img: 'https://shopimg.kitamura.jp/images/banner/1392.gif',
          alt: 'おしゃれなストラップ特集'
        },
        {
          href: '/ec/special/camera/filmcamera',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'チェキ・チェキフィルム、チェキアルバムおすすめ商品'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/general/wirelessimageshare',
          img: 'https://shop.kitamura.jp/images/banner/2785.gif',
          alt: 'デジカメとスマホを無線でつないで写真を楽しもう'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '双眼鏡の選び方',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4955295135417',
        '4955295135424',
        '4955295147236',
        '4955295147243',
        '4571137582289',
        '4571137582296',
        '4571137581022',
        '4571137580384',
        '4955295145829',
        '4955295114917',
        '4545350038298',
        '4545350038311',
        '4545350038304',
        '4545350038328',
        '4549212288784',
        '4549212288791',
        '4549212288807',
        '4549212288814',
        '4549212288821',
        '4549212288838'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
