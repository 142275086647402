



















































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
//import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'om1',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    //expensiveTradeIn: ExpensiveTradeIn,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！OM-1| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'OM-1価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/2/15 14:59',
      fanValidTo: '2023/2/8 14:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/2/8 15:00',
      reserveValidTo: '2023/2/23 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/2/24 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観・作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'オリンパス OM-D E-M1 MarkIII',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9%20OM-D%20E-M1%20MarkIII&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&n1c=%EF%BD%9E3000%E4%B8%87%E7%94%BB%E7%B4%A0&sort=add_date&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/63f/b43/c6d/8d2/40c/a4c/568/442/507/d6a/a1l/chd/oah/n/L.jpg',
        txt: 'OM-1',
        tradeInImg: '/ec/images2/special/camera/feature/omsystem/om1/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'オリンパス OM-D E-M1 MarkIII',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%82%AA%E3%83%AA%E3%83%B3%E3%83%91%E3%82%B9%20OM-D%20E-M1%20MarkIII&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&n1c=%EF%BD%9E3000%E4%B8%87%E7%94%BB%E7%B4%A0&sort=add_date&limit=40'
          },
          {
            name: 'オリンパス OM-D E-M1X',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350052621'
          },
          {
            name: 'オリンパス OM-D E-M1 MarkII',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4545350050849'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: 'センサーサイズの常識を覆す高画質',
          contents: [
            '高い機動力を誇る小型ボディに、新開発「有効画素数約2037万画素裏面照射積層型Live MOSセンサー」と、従来比約3倍の高速化を達成した最新の画像処理エンジン「TruePicX（トゥルーピック エックス）」を搭載することで、センサーサイズの常識を覆す高画質を実現',
            '最新の画像処理技術によって向上したダイナミックレンジにより暗部から明部まで非常に豊かな階調表現が可能',
            'ボディー単体で最大7段、「5軸シンクロ手ぶれ補正」で最大8.0段の高性能な手ぶれ補正効果を実現。新搭載の「手持ち撮影アシスト機能」や新たなノイズ処理技術により達成した常用で最高ISO25600、拡張で最高ISO102400の高感度対応により、これまで三脚を使用したスローシャッターでしか得られなかった表現を手持ちで手軽に撮影可能'
          ]
        },
        {
          subtitle: '一眼カメラの映像表現をさらに広げるコンピュテーショナル フォトグラフィ機能',
          contents: [
            '従来、特殊な機材やパソコンによる画像合成などで実現していた写真表現を、カメラ内の画像処理のみで実現できるコンピュテーショナル フォトグラフィ機能をさらに使いやすく進化',
            '風景写真などで好評の複数枚画像を合成して高解像画像を生成する「5000万画素手持ちハイレゾショット」が進化。約5秒で合成可能になり、さらに活用可能なシーンが拡大',
            '約8000万画素の超高解像画像を実現する「三脚ハイレゾショット」も大幅に処理時間短縮。通常撮影・手持ちハイレゾショット・三脚ハイレゾショットの切り替えを素早くおこなうための専用ボタンも追加され、さらに使いやすく進化',
            'ND64（6段分）まで対応可能な「ライブND」（ND2～ND64）を搭載。スローシャッター効果をファインダーや液晶モニターで事前に確認しながら撮影可能',
            'その他「ライブコンポジット」「深度合成」「HDR撮影」など、カメラ単体で様々な映像表現を可能にする機能を搭載。手軽に幅広い表現が可能'
          ]
        },
        {
          subtitle: '新開発AFとAF/AE追従最高連写が実現する圧倒的な高速性',
          contents: [
            '1053点オールクロス像面位相差クアッドピクセルAF方式を採用。全画素・全撮影領域で、さまざまなパターンの被写体の測距が可能になり、画面内のどの位置に被写体がいても高速かつ高精度なピント合わせが可能',
            'ディープラーニング技術を活用して開発した「AI被写体認識AF」も搭載。従来以上に高速かつ高精度な被写体認識・被写体追従性を実現。また新たに動物（犬・猫）の認識も実現',
            '約2037万画素、AF/AE追従・ブラックアウトフリーで最高50コマ/秒、AF/AE固定で最高120コマ/秒の超高速連写を実現。飛躍的に性能が向上'
          ]
        },
        {
          subtitle: 'クリエーターをサポートする充実した動画機能',
          contents: [
            '高精細で滑らかな映像表現が可能な4K 60p動画に対応。フルHDでは最高240pのハイスピードムービーの撮影も可能。H.264 、H.265、さらにマルチフレームレートにも対応し、30分を超える長時間の動画撮影も実現',
            '手持ちで安定した動画撮影が可能な小型軽量システムと、業界トップレベルの高性能な手ぶれ補正機構により、大きな機材が持ち込めないシーンでもハイクオリティな映像記録が可能',
            'カラーグレーディングにより自由度の高い映像表現が可能な「OM-Log」にも対応。さらに、HDR動画を撮影できる新動画ピクチャーモード「HLG（Hybrid Log Gamma）」を追加'
          ]
        },
        {
          subtitle: '高速・高精細EVFを搭載。防塵・防滴性能を強化した堅剛かつ使いやすさを意識したボディ',
          contents: [
            '約576万ドットの高解像とファインダー倍率0.83倍、表示遅延時間0.005秒 120fpsの高速表示を実現した高速・高精細EVFを搭載。光学ファインダーの様に違和感なく撮影が可能',
            'ボディーの防塵・防滴性能は、軽量かつ堅牢なマグネシウム合金ボディーの適所にシーリング部材を配置。従来機種よりもさらに高度な防塵・防滴保護等級IP53、かつ-10℃の耐低温性能も実現。過酷な環境下での撮影を強力サポート',
            '上下配置で記録メディアが取り出しやすいUHS-II対応のダブルSDカードスロットや、40万回の作動試験をクリアした高耐久シャッターユニット等、堅剛かつ使いやすさを両立'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'OM-1',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350053871', '4545350053864', '4545350053901'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4545350053000', '4547410427875', '4549980499771'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4545350053864', '4545350053000', '4547410427875', '4549980499771'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
