



































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'graduation-entrance-faq',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '【卒園式・入学式】ビデオ・カメラ選びのお悩みごと解決します！  | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'カメラのキタムラへ寄せられる、卒園式・入学式のビデオ・カメラ選びに関する疑問にお答えします!');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '卒業・入学特集',
          linkUrl: '/ec/special/general/graduation_entrance',
          disabled: false
        },
        {
          path: '【卒園式・入学式】ビデオ・カメラ選びのお悩みごと解決します！',
          disabled: true
        }
      ],
      pocketAlbumList: [
        {
          subtitle: 'まずは写真プリント',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/graduation_entrance/faq/q4-2.gif',
          href: 'https://www.kitamura-print.com/digicameprint/',
          txt: '専門店ならではの早くて高品質。\nネット注文がオトク！',
          btntxt: 'プリントサービスを見る'
        },
        {
          subtitle: '大量収納OKのポケットアルバム',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/graduation_entrance/faq/q4-3.gif',
          href: '/ec/special/camera/album/1439',
          txt: '写真を入れるだけのお手軽アルバム。そのときの気持ちをメモすれば、いい思い出アルバムになります♪',
          btntxt: 'ポケットアルバムを見る'
        },
        {
          subtitle: '写真を配る時に人気♪ 写真袋',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/graduation_entrance/faq/q4-4.gif',
          href: '/ec/special/camera/album/1440',
          txt: 'お友達に配るときに便利でかわいい♪ ちょっと送るのに、ちょうどいい写真袋です。',
          btntxt: '写真袋を見る'
        }
      ],
      recommendedFeaturesList: [
        {
          href: 'https://photobook.kitamura.jp/campaign/graduation/',
          img: '/ec/images2/special/general/graduation_entrance/faq/sogo_01.gif',
          alt: '卒業×フォトブック',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura-print.com/special/season/graduation/',
          img: '/ec/images2/special/general/graduation_entrance/faq/sogo_02.gif',
          alt: '卒業生に贈るプレゼント',
          target: 'blank'
        },
        {
          href: '/ec/special/camera/album/feature',
          img: '/ec/images2/special/general/graduation_entrance/faq/sogo_03.gif',
          alt: '手作り卒園アルバム',
          target: 'blank'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage
    };
  }
});
