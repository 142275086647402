













































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'kaden-dcfan',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'この夏、扇風機を買うならDCモーター扇風機！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '人気のDCモーター扇風機をご紹介。ニューモデル＆お買い得モデル取り揃え中♪お近くのカメラのキタムラで、お受け取りいただけます。');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'この夏、扇風機を買うならDCモーター扇風機！',
          disabled: true
        }
      ],
      naviList: [
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_li_01.gif', alt: 'DCモーター扇風機の3大メリット', href: '#dc001' },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_li_02.gif',
          alt: '夏だけじゃない？便利な DCモーター扇風機',
          href: '#dc002'
        },
        { src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_li_03.gif', alt: 'この夏、イチオシ！ コレがベストバイ！', href: '#dc003' }
      ],
      threeMajorBenefitList: [
        { subtitle: '音が静か', txt: '羽根の回る音が今までの扇風機より小さいから、就寝時などにも気になりにくいです。' },
        { subtitle: '微風を作れる', txt: 'DCモーターの得意ワザは「低速回転」。体にやさしい微風を作れます。' },
        { subtitle: '節電効果アリ', txt: '今までの扇風機より消費電力少なめです。夏場の電力消費を抑えるのに最適です。' }
      ],
      fanCategoryList: [
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k01_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k01_on.gif',
          href: '/ec/ct/E007/001/001/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k01-2_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k01-2_on.gif',
          href: '/ec/ct/E007/001/002/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k02_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k02_on.gif',
          href: '/ec/ct/E007/001/003/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k04_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k04_on.gif',
          href: '/ec/ct/E007/001/006/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k05_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k05_on.gif',
          href: '/ec/ct/E007/001/007/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k06_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k06_on.gif',
          href: '/ec/ct/E007/001/008/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k07_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k07_on.gif',
          href: '/ec/ct/E007/001/009/'
        },
        {
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k09_off.gif',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/dcfan/b_s_k09_on.gif',
          href: '/ec/ct/E007/001/011/'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
