





































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';

import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '1dxmk3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    expensiveTradeIn: ExpensiveTradeIn,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '新製品！キヤノン EOS-1D X Mark III 好評発売中 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン 1DXMarkIII 好評発売中！比較や、価格・スペック・お得な情報をチェック！最大60回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/1dxmk3/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/1dxmk3/sp_img_top.jpg',
      naviList: [
        { naviItem: '特徴スペック', href: '#point' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'キタムラ特典', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '新開発のフルサイズCMOSセンサー＆映像エンジン搭載で基本性能が大幅に向上',
          contents: [
            'CMOSセンサーの低ノイズ化と「DIGIC X」によるノイズリダクション性能の向上で、常用ISO感度最高102400まで拡大。暗所でもノイズを抑えた撮影が可能',
            'AF・AE（自動露出制御）追従時でも、光学ファインダー撮影時、最高約16コマ/秒ライブビュー撮影時、最高約20コマ/秒の高速連写を実現',
            '新開発の16点分離ローパスフィルター搭載で従来機種より解像感が向上。斜め方向の色モアレや縦横方向の輝度モアレも効果的に抑制'
          ]
        },
        {
          subtitle: '新開発のAFセンサーとAFアルゴリズムによる高精度AFを実現',
          contents: [
            'さらに進化した新ミラー駆動システムにより、光学ファインダーにおける像消失時間を短縮。被写体を追いやすいファインダー視野を実現',
            '正方画素を高密度に配置した最大191点（クロス測距点最大155点）の測距点により、高い合焦精度を実現し、AF測距輝度範囲が拡大',
            'ディープラーニング技術を用いて開発した頭部検出アルゴリズムにより、顔や瞳が検出できないときでも安定した追尾を実現',
            'ライブビュー撮影時は、被写体の瞳を検出してフォーカスを合わせる「瞳AF」も併用可能'
          ]
        },
        {
          subtitle: 'プロのニーズに対応した通信機能、動画性能',
          contents: [
            '携帯端末へのWi-Fi／Bluetooth®による接続対応。またGPS機能も搭載し、プロが求める効率的な画像管理が可能',
            '有線LAN機能で高速インターフェースを採用し、従来機種より高速に画像データの転送が可能',
            'YCbCr 4：2：2／10bit／Canon Logに対応。階調と色彩の再現性に優れた動画の内部記録が可能'
          ]
        },
        {
          subtitle: 'フラッグシップ機ならではの優れた耐久性と新機能',
          contents: [
            'CMOSセンサーの低ノイズ化と「DIGIC X」によるノイズリダクション性能の向上で、常用ISO感度最高102400まで拡大。暗所でもノイズを抑えた撮影が可能',
            '指の動きを光学的に検知する「スマートコントローラー」を新導入。ボタンから指を離さずに、AF測距点位置の設定が可能',
            'CFexpress用のダブルスロットを搭載。書き込み速度の向上と、RAW＋JPEGでも余裕ある連続撮影可能枚数を実現',
            '電源には「バッテリーパックLP-E19」を使用。電力消費マネジメントの見直しにより、撮影可能枚数が約2,850枚に増加'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'EOS 1D X MarkII',
        href: '/ec/sell/item-list?category=&keyword=4549292060485&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=add_date',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/acb/a38/5f3/aff/d16/659/85f/665/1c9/68c/b9b/dty/10a/6/L.jpg',
        txt: 'キヤノン EOS 1DXMarkIII',
        tradeInImg: '/ec/images2/special/camera/feature/canon/1dxmk3/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS 1D X Mark III',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292146851'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292146851', '4549292060485', '4960759146205', '4548736108288'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4549292146851',
        '4523052020513',
        '4549292148015',
        '4549292060553',
        '4549292060584',
        '4549292068412',
        '4549292066050',
        '4960999581576',
        '4549292059434',
        '4960999780719',
        '4549292118513'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
