






















































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import SnsShare from '@/components/common/special/sns-share.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'rice-cooker-bestbuy',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    tableOfContentsNavi: TableOfContentsNavi,
    'product-list-by-special-id': ProductListBySpecialId,
    shoppingCredit: ShoppingCredit,
    snsShare: SnsShare,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '家電担当者が厳選した各メーカーの炊飯器をおすすめポイントとともにご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'とにかく種類が多い炊飯器。どれを選べばいいのか分からない、自分にあった炊飯器の種類や選び方をわかりやすく紹介しています。ご飯がおいしいこれからの季節！好みの炊飯器選びにぜひ参考にしてみてください。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめ炊飯器特集',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '【ガイド】炊飯器の種類・選び方', href: '#i00' },
        { naviItem: '【ピックアップ】おすすめ炊飯器', href: '#i01' },
        { naviItem: '【まとめ】炊飯器選びには', href: '#i03' }
      ],
      typeRiceCookerList: [
        {
          subtitle: 'マイコン炊飯器',
          txt: '<a href="/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=1&s11=%E3%83%9E%E3%82%A4%E3%82%B3%E3%83%B3%E7%82%8A%E9%A3%AF%E5%99%A8&limit=20&page=1&sort=popularity">マイコン炊飯器</a>はヒーターで釜の底から温める方式です。IH炊飯器に比べ、火力は弱めです。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso01.jpg'
        },
        {
          subtitle: 'IH炊飯器',
          txt: '<a href="/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8:IH%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=1&limit=20&page=1&sort=popularity">IH炊飯器</a>は、内釜全体を直接発熱するため、高火力で炊きムラになりにくい炊飯器です。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso02.jpg'
        },
        {
          subtitle: '圧力IH炊飯器',
          txt: '<a href="/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8:%E5%9C%A7%E5%8A%9BIH%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=1&limit=20&page=1&sort=popularity">圧力IH炊飯器</a>は圧力機構を加え、水の沸点が上昇。炊飯時間が短く、米の芯まで温めます。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso03.jpg'
        }
      ],
      innerPotStructureList: [
        {
          subtitle: '厚釜',
          txt: '内釜を厚くすることで内釜に蓄えられた熱が、お米を包み込むように炊きます。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/bk-km01.jpg'
        },
        {
          subtitle: '多層釜',
          txt: '熱伝導率の高い素材を、何層にも重ね、熱がお米に伝わりやすくなっています。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/bk-km02.jpg'
        },
        {
          subtitle: '真空釜',
          txt: '真空層を持つことで、熱を逃がしにくい構造。内釜が軽いのが特徴です。',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/bk-km03.jpg'
        }
      ],
      materialInnerPotList: [
        {
          materialImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso07.jpg',
          txt: '鉄やステンレスより、熱伝導率のいい素材のため、多層釜にも銅がよく用いられています。'
        },
        {
          materialImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso08.jpg',
          txt:
            '炭は、熱伝導率が高く、遠赤外線効果が強い素材。それにより、短時間でムラのない加熱を実現。<br>また、お米が持つ甘みが引き出されたご飯に炊き上げます。'
        },
        {
          materialImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso09.jpg',
          txt:
            '土鍋は熱を蓄える力があり、遠赤効果も備わっています。時間をかけて熱を伝え、冷めにくいというのが、土鍋の特長。それを生かした高い蒸らし効果で、ふっくらとした炊きあがりになります。'
        },
        {
          materialImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso010.jpg',
          txt:
            '内釜がダイヤモンドの微粒子でコーティグされている釜です。銅釜と比較すると、ダイヤモンドは約7700倍の熱効率。熱量を増やすことで、旨みのあるご飯が生まれます。'
        },
        {
          materialImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/kiso011.jpg',
          txt:
            '鉄釜は発熱性に優れています。約2000度の高温で打ち込む「打ち込み鉄釜」により、蓄熱性や断熱性もアップ。高温スチームは温度ムラを抑え、ムラのない炊きあがりになります。'
        }
      ],
      eatAndCompareList: [
        {
          title: '象印 圧力IH炊飯ジャー 炎舞炊き 濃墨 NW-FC10-BZ [5.5合炊き]',
          href: '/ec/pd/4974305227089',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-a01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-a02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-a03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-a04.jpg'
          ],
          txt:
            '縦横無尽にお米を舞い上げる、激しい対流を実現した「3DローテーションIH構造」\n「鉄」の純度を見直すことで発熱効率が高まった「鉄（くろがね仕込み）豪炎かまど釜」\n家族好みの食感が見つかる121通りの「わが家炊き」メニュー'
        },
        {
          title: '日立 圧力&スチーム IHジャー炊飯器 ふっくら御膳 RZ-V100FM [5.5合炊き]',
          href: '/ec/pd/4549873153896',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-b01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-b02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-b03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-b04.jpg'
          ],
          txt:
            'めざしたのは京の米老舗八代目儀兵衛の「外硬内軟（がいこうないなん）」というひと粒のうまさ。\n「大火力 沸騰鉄釜＆全周断熱」でおいしく炊き上げ。\n置き場所に困らない「蒸気カット」、0.5合から2合までの少量を選べる「少量」専用ボタン、ごはんをしっとり保つ「スチーム24時間保温」'
        },
        {
          title: '象印 IH炊飯器 STAN. NW-SA10 [5.5合炊き]',
          href: '/ec/pd/4974305218223',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-c01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-c02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-c03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-c04.jpg'
          ],
          txt:
            '強火で炊き続け、うまみを引き出す「豪熱沸とうIH」\n30時間おいしく保温できる「うるつや保温」・「高め保温」\n「ベビーごはん(離乳食)」メニュー\nニオイが気になる時に便利「クリーニング」機能'
        },
        {
          title: '象印 IH炊飯器 極め炊き 黒まる厚釜 NW-VD10 [5.5合炊き]',
          href: '/ec/pd/4974305221698',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-d01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-d02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-d03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-d04.jpg'
          ],
          txt:
            '強火で炊き続け、うまみを引き出す「豪熱沸とうIH」\n炊き方が選べる「白米炊き分け3コース（ふつう・やわらかめ・かため）」\n30時間おいしく保温できる「うるつや保温」'
        },
        {
          title: 'シャープ ジャー炊飯器 KS-CF05B [0.5~3合炊き]',
          href: '/ec/pd/4974019149837',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-e01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-e02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-e03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-e04.jpg'
          ],
          txt:
            '「黒厚釜」がお米の芯まで熱を伝え、底面を球状にした「球面炊き」でおいしく炊き上げま。す\n玄米など健康に配慮したメニューを、専用の火加減で炊き分ける「炊飯メニュー」を採用。\n使用時のみ浮かび上がるLED表示。使いやすく、スタイリッシュなデザインにこだわりました。'
        },
        {
          title: 'タイガー 圧力IH炊飯器 炊きたて ご泡火炊き JPI-X100KX',
          href: '/ec/pd/4904710437681',
          images: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-f01.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-f02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-f03.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/rice-cooker_bestbuy/pc-f04.jpg'
          ],
          txt:
            'ご泡火炊きのエントリーモデル「JPI-X100KX」は、「遠赤9層土鍋かまどコート釜」を採用。\n内なべ外側には「かまどコーティング＆土鍋蓄熱コーティング」を、内なべ内側には「遠赤土鍋コーティング」を施すことで、金属釜ながら土鍋のような味わい深いごはんを炊きあげます。\nさらに本製品では、内なべ外側のコーティングの土鍋粉末を増量し、まんべんなく吹きつけることで、ごはんの甘みを閉じ込めてふっくらとしたごはんに炊きあげます。\nまた、保温したごはんのおいしさを保つ「粒立ち保温プログラム」や解凍時のごはんのベタつきをおさえる「冷凍ご飯」メニュー、お茶碗1杯（0.5合）が最短約15分、1合が約17分炊飯できる「少量高速」メニューを搭載し、おいしい便利さにもこだわりました。'
        }
      ],
      recommendedRiceCookerList: [
        {
          name: '象印 炊飯器',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=0&s1=%E8%B1%A1%E5%8D%B0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=newer'
        },
        {
          name: 'タイガー 炊飯器',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=0&s1=%E3%82%BF%E3%82%A4%E3%82%AC%E3%83%BC&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=newer'
        },
        {
          name: 'パナソニック炊飯器',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=0&s1=%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=newer'
        },
        {
          name: '三菱電機炊飯器',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&s1=%E4%B8%89%E8%8F%B1%E9%9B%BB%E6%A9%9F&limit=40&page=1&sort=newer'
        },
        {
          name: '日立炊飯器',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%AD%E3%83%83%E3%83%81%E3%83%B3/%E8%AA%BF%E7%90%86%E5%AE%B6%E9%9B%BB:%E7%82%8A%E9%A3%AF%E5%99%A8&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&s1=%E6%97%A5%E7%AB%8B&limit=40&page=1&sort=newer'
        }
      ],
      // ↓ ---- ショッピングクレジット ----
      installmentPaymentExampleList: [
        { productPrice: '55,000', numberOfDivisions: '15', monthlyPayment: '3,667' },
        { productPrice: '65,780', numberOfDivisions: '20', monthlyPayment: '3,289' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shop.kitamura.jp/images/banner/1921.gif',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: '/ec/special/kaden/bento',
          img: 'https://shop.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当生活におすすめ商品'
        },
        {
          href: '/ec/special/kaden/toaster-oven',
          img: 'https://shop.kitamura.jp/images/banner/6374.jpg',
          alt: 'おすすめ高級トースター特集'
        },
        {
          href: '/ec/special/kaden/home-party',
          img: 'https://shop.kitamura.jp/images/banner/3770.gif',
          alt: 'キッチン家電特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage
    };
  }
});
