





































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'kaden-fanfair',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    tableOfContentsNavi: TableOfContentsNavi,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '夏のマストアイテム！おすすめ扇風機やサーキュレーター特集2024 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'パワフルな風量で部屋の空気をスピード循環させるサーキュレーターや、リモコン付きで離れた場所からでも操作可能な扇風機など、カメラのキタムラ ネットショップの家電担当者がおすすめする扇風機やサーキュレーターをご紹介いたします。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '扇風機特集2024',
          disabled: true
        }
      ],
      // ↓ ---- ナビボタン ----
      naviList: [
        { naviItem: 'DCモーター扇風機とAC扇風機の違いは何？ どちらがおすすめ？', href: '#r00' },
        { naviItem: '扇風機を選ぶ際にチェックしたいこと', href: '#r01' },
        { naviItem: 'カメラのキタムラおすすめの扇風機はこちら！', href: '#r02' }
      ],
      fanList: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_02.gif',
          txt:
            '低速でモーターを回転させることができるため、従来の扇風機では起こせなかった微風をつくれます\n扇風機を使い続けると冷えすぎてしまうという方には、DCモーター扇風機がおすすめです\n商品によって、無段階で風力を決められるものや7段階や10段階など、さまざまあります\n切替風量が少ない機種だとAC扇風機とあまり変わらないので、購入前にチェックしましょう'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_03.gif',
          txt:
            'AC扇風機に比べると消費電力は少ないですが、AC扇風機もエアコンなどに比べれば大幅に消費電力は少ないですから、あまり気に留めなくてもいいかもしれません'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_04.gif',
          txt: 'DCモーター扇風機で特に微風運転時は従来より静音になっている場合が多いです\n扇風機の音が気になる方はDCモーター扇風機がおすすめです'
        }
      ],
      fanCheckList: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_05.jpg',
          subtitle: '羽根の枚数\n\n',
          txt:
            '一般的に、羽根の枚数が少ないほうが1枚当たりの羽根が大きくなるので、少ない回転数でも強い風が出ます。逆に枚数が多いと、ムラの少ない柔らかい風になります。\n\nお好みや使用シーンに合わせて選びましょう。\n'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_06.jpg',
          subtitle: '本体の高さ\n\n',
          txt:
            '高さ100cm前後～それ以上伸ばせる背の高い機種を「ハイポジション扇風機」と呼んでいます。\n\n寝室がベッドの場合やダイニングなど、少し高い位置から扇風機を使う場合は、ハイポジション扇風機を選ぶのがおすすめです\n'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/fan-fair/fan-fair_07.jpg',
          subtitle: '左右だけでなく\n上下の首振りができるか',
          txt:
            '最近では、自動で上下左右に首が動く、立体首振り機能を搭載した機種もあります。\n\n部屋干しの効率を上げるために使う場合や、エアコンと併用して室内の空気を循環させるサーキュレーターのように使う場合におすすめです。'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/waterproof',
          img: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          alt: '防水カメラ・ウェアラブルカメラ特集'
        },
        {
          href: '/ec/special/kaden/air-purifier_fair',
          img: 'https://shopimg.kitamura.jp/images/banner/2393.gif',
          alt: '空気清浄機 | おすすめ新製品でウイルス・花粉対策'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
