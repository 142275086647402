

















































































































































































































































































































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg200 from '@/components/common/special/top-title-img200.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn3 from '@/components/common/special/navi-btn3.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'watch',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg200: TopTitleImg200,
    urlcopy: Urlcopy,
    subText: SubText,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn3: NaviBtn3,
    notfound: Notfound,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  //★スライド
  props: {},
  template: '#app-template',
  data: () => ({
    slides: [
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank01.png',
        image: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank_rolex.jpg',
        linkUrl: '/ec/special/watch/rolex/',
        txt: 'ロレックス\n(ROLEX)',
        name: 'ロレックス(ROLEX)'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank02.png',
        image: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank_omega.jpg',
        linkUrl: '/ec/special/watch/omega/',
        txt: 'オメガ\n(OMEGA)',
        name: 'オメガ(OMEGA)'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank03.png',
        image: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank_tagheuer.jpg',
        linkUrl: '/ec/list?keyword=%E3%82%BF%E3%82%B0%E3%83%BB%E3%83%9B%E3%82%A4%E3%83%A4%E3%83%BC%20%28TAG%20HEUER%29&type=u',
        txt: 'タグ・ホイヤー\n(TAG HEUER)',
        name: 'タグ・ホイヤー(TAG HEUER)'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank04.png',
        image: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank_grandseiko.jpg',
        linkUrl: '/ec/list?keyword=%E3%82%B0%E3%83%A9%E3%83%B3%E3%83%89%E3%82%BB%E3%82%A4%E3%82%B3%E3%83%BC%20%28GRAND%20SEIKO%7CGS%29&type=u',
        txt: 'グランドセイコー\n(GRAND SEIKO|GS)',
        name: 'グランドセイコー(GRAND SEIKO|GS)'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank05.png',
        image: 'https://shop.kitamura.jp/ec/images2/special/reuse/watch/rank_cartier.jpg',
        linkUrl: '/ec/list?keyword=%E3%82%AB%E3%83%AB%E3%83%86%E3%82%A3%E3%82%A8%20%28CARTIER%29&type=u',
        txt: 'カルティエ\n(CARTIER)',
        name: 'カルティエ(CARTIER)'
      }
    ],
    model: null
  }),
  //スライダー
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '中古時計販売｜ロレックス(ROLEX)、オメガ(OMEGA)など中古腕時計・デジカメ等の通販は【カメラのキタムラ】';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ロレックス(ROLEX)、オメガ(OMEGA)、タグ・ホイヤー(TAG HEUER)、アイダブリューシー(IWC)幅広いブランドの腕時計を取り揃えております。中古時計をお探しならカメラのキタムラ。'
      );

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/6/29 13:00',
      fanValidTo: '2024/06/04 17:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/06/04 18:00',
      reserveValidTo: '2024/07/01 10:00',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/07/01 10:01',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList3: [
        { naviItem: 'ロレックス\nROLEX', href: '#' },
        { naviItem: 'オメガ\nOMEGA', href: '#' },
        { naviItem: 'タグホイヤー\nTAG HEUER', href: '#' },
        { naviItem: 'グランドセイコー\nGrandSeiko', href: '#' }
      ],
      naviList: [{ naviItem: '中古時計商品一覧はこちら', href: '#ct01' }],
      recommendedFeaturesList: [
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '中古時計販売',
          disabled: true
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['', '', ''],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品
      mainProductJanCode: [''],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['', '', '', ''],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
