var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"my-page-order-sell-detail"}},[_c('my-page-layout',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}},[(_vm.loaded.detail)?[_c('order-info',{attrs:{"type":_vm.type,"info":{
          odrYmd: _vm.orderDetail.odrYmd,
          odrId: _vm.orderDetail.formatedOdrNum,
          transferAccountUrl: _vm.orderDetail.transferAccountUrl,
          totalPraice: 0
        }}}),_c('div',{class:{
          'mx-12': _vm.$vuetify.breakpoint.mdAndUp
        }},[(_vm.orderDetail.headerStatus != null)?_c('order-step',{attrs:{"kindName":"下取","type":_vm.type,"val":_vm.orderDetail.headerStatus}}):_vm._e(),(_vm.orderDetail.headerStatus === 0)?_c('v-btn',{staticClass:"apply-kit-btn my-8",attrs:{"dark":"","depressed":"","tile":""},on:{"click":function($event){return _vm.offerPackagingKit()}}},[_vm._v(" "+_vm._s(_vm.orderDetail.isNeedPackKit ? '梱包キットを申し込む' : 'トクトク交換を申し込む')+" "),_c('v-icon',{staticClass:"apply-kit-btn-icon",attrs:{"small":""}},[_vm._v("fas fa-chevron-right")])],1):_vm._e(),_c('div',{staticClass:"order-product-list mb-8"},_vm._l((_vm.orderDetail.detailInfo),function(product,index){return _c('order-product',{key:index,attrs:{"type":_vm.type,"product":product}})}),1),(false)?_c('order-total-info',{attrs:{"type":_vm.type,"total-info":{
            count: _vm.orderDetail.detailInfo.length,
            totalPraice: 0,
            gainedPoint: 0
          }}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mb-3"},[_c('router-link',{staticClass:"order-list-link",attrs:{"to":_vm.state.linkUrl}},[_c('v-icon',{staticClass:"order-list-link-icon mr-2",attrs:{"small":""}},[_vm._v("far fa-chevron-left")]),_vm._v(" 対象の購入履歴を見る ")],1)],1):_vm._e()],1)]:(!_vm.loaded.detail)?_c('div',{staticClass:"loading-detail"},[_c('section-loading')],1):_vm._e()],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"to":_vm.state.linkUrl}},[_vm._v(" 対象の購入履歴を見る ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"to":"/ec/mypage"}},[_vm._v(" マイページトップへ戻る ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }