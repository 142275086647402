
























































































































































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'keirou',
  components: {
    breadcrumbs: Breadcrumbs,
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '敬老の日特集 プレゼントにおすすめランキング | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '敬老の日プレゼント特集 |「いつまでも元気でいてね｣ 「体を大切にね」そんな思いから健康家電やマッサージ器。「旅行やお出かけを楽しんで欲しい」そんな思いからカメラ・カメラ用品など、プレゼントにおすすめランキングです。おじいちゃんおばあちゃんに、なかなか会えない人も。同居している人もちろんメッセージも添えて贈りましょう。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '敬老の日特集 プレゼントにおすすめランキング',
          disabled: true
        }
      ],
      healthCareJanCode: ['4549077389909', '4975479415975', '4904785573628', '4904785630307'],
      healthCareList: [] as Array<ProductDetail>,
      digitalCameraJanCoed: ['4547410367669', '4549292056617', '4549292081053', '4545350051457', '4548736001664'],
      digitalCameraList: [] as Array<ProductDetail>,
      photoFrameJanCode: ['4902205237631', '4934122080977', '4974214175563', '4974214171855'],
      photoFrameList: [] as Array<ProductDetail>
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // ヘルスケア商品
        const healthCareResult = await ProductService.fetchProducts(state.healthCareJanCode, true);
        state.healthCareList = healthCareResult.items;
        // デジタルカメラ商品
        const digitalCameraResult = await ProductService.fetchProducts(state.digitalCameraJanCoed, true);
        state.digitalCameraList = digitalCameraResult.items;
        // フォトフレーム商品
        const photoFrameResult = await ProductService.fetchProducts(state.photoFrameJanCode, true);
        state.photoFrameList = photoFrameResult.items;
      } catch (error) {
        // ヘルスケア商品
        state.healthCareList = [] as Array<ProductDetail>;
        // デジタルカメラ商品
        state.digitalCameraList = [] as Array<ProductDetail>;
        // フォトフレーム商品
        state.photoFrameList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
