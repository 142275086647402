import { render, staticRenderFns } from "./parts03.vue?vue&type=template&id=21e45c4a&scoped=true&"
import script from "./parts03.vue?vue&type=script&lang=ts&"
export * from "./parts03.vue?vue&type=script&lang=ts&"
import style0 from "./parts03.vue?vue&type=style&index=0&id=21e45c4a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e45c4a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VCard,VCardTitle,VImg,VSheet})
