



























































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'xh2',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    urlcopy: Urlcopy,
    subText: SubText,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    splitContent: SplitContent,
    characteristic: Characteristic,
    naviBtn: NaviBtn,
    shasha: ShaSha,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'フジフイルム X-H2  | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'フジフイルム X-H2  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間なし ----
      fanValidFrom: '2022/9/14 09:59',
      fanValidTo: '2022/9/14 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/9/14 10:00',
      reserveValidTo: '2022/9/28 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/9/29 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: '新開発「X-Trans CMOS 5 HR」センサーを搭載し「X シリーズ」史上最高画質を実現',
          contents: [
            '新開発の裏面照射型約4020万画素「X-Trans CMOS 5 HR」センサーの搭載と画像処理のアルゴリズムの刷新により、高いS/N比を維持したまま解像度を向上。「X シリーズ」史上最高画質を実現しました',
            '画素構造を改良し、より多くの光を効率的に取り込むことが可能。ISO125を常用感度として利用でき、日中の屋外での撮影や明るいレンズによるボケ味を生かした写真撮影に最適です',
            '新センサーの搭載により露光時間の制御をより高い精度で実現。電子シャッターの最速シャッタースピードを従来モデルの1/32000秒から約2.5段分拡張し1/180000秒に向上させました',
            '4倍の解像力と忠実な色再現による撮影が可能な「ピクセルシフトマルチショット」を「Xシリーズ」として初めて搭載。約1.6億画素の画像を生成できます',
            '肌のレタッチを自動で行う「スムーススキンエフェクト」を「X シリーズ」として初めて搭載。ポートレート写真などで行われるレタッチの手間を軽減することができ、より短時間で完成度の高い画像データの作成が可能となりました',
            '多彩な色調を可能とする「フィルムシミュレーション」に、高彩度かつ柔らかい階調表現を特長とする「ノスタルジックネガ」など全19種類のモードを搭載。被写体やシーンに合わせて写真フィルムを選ぶ感覚で多彩な色表現が楽しめます'
          ]
        },
        {
          subtitle: 'プロの映像制作ニーズに応える充実の動画性能',
          contents: [
            '「X シリーズ」として初めて8K/30P 4:2:2 10bitの映像記録に対応。「X-H2S」同様の放熱構造を採用し、約160分の8K/30P動画撮影が可能です',
            '8Kの豊富なデータ量から高品質な4K映像を生成する「4KHQ」モードも新たに搭載。高画素センサーを生かした解像感の高い映像を実現します',
            '3つのApple ProResコーデックに対応。ProRes撮影時にはProRes 422 Proxyなどのプロキシ撮影も可能。8K動画の編集負荷を軽減し、撮影からポストプロダクションまでワークフロー全体を効率化・省力化できます',
            '動画撮影時に使用可能なデジタルズーム機能を新たに搭載。単焦点レンズ装着時でも、解像感を損なうことなく最大2倍のデジタルズームを実現しました',
            'HDMI経由で最大8K/30P 12bitのRAWデータ出力が可能。さらに、ATOMOS社製の「NINJA V+」でApple ProRes RAW、Blackmagic Design社製の「Video Assist 12G」でBlackmagic RAWとして記録が可能です',
            'ダイナミックレンジを13+stopに広げた「F-Log2」を搭載。階調豊かな映像を記録することができ、ポストプロダクションでの自由度が大きく向上します'
          ]
        },
        {
          subtitle: '被写体を正確にとらえる進化したAFと快適な操作を実現するハードウェア',
          contents: [
            'ディープラーニング技術を用いて開発した被写体検出AFを搭載。動物・鳥・車・バイク・自転車・飛行機・電車をAIで検出することが可能です。ピントを合わせたまま狙った被写体を自動的に追尾するため、シャッターチャンスや構図に集中して撮影ができます',
            'イメージセンサーの高画素化により位相差画素の数が増加したことで、動物の毛や細かい葉っぱなどの高周波な被写体に対してAF-Sの合焦精度が向上。風景撮影やポートレート撮影などで、より正確なピント合わせが行えます',
            'AF/MF時にピントを合わせたい箇所を拡大できるフォーカスアシストの倍率を、従来モデルの2倍となる最大12倍に拡大。また、動画撮影時のMFをより精緻に行うための機能としてフォーカスメーターを新たに搭載し、より正確なピント調整が可能となりました',
            'AFのアルゴリズムを最適化したことで、動画撮影時のAF精度を大幅に向上。より正確なピント合わせが求められる8K動画撮影も安心して行えます',
            '5軸・最大7.0段のボディ内手ブレ補正機能を搭載。夜景などの暗い撮影シーンでも、快適な手持ち撮影を可能にします',
            'ファインダー倍率0.8倍、576万ドットの高倍率・高精細のEVFを搭載。ファインダー使用時に瞳の位置ずれにより発生する像流れや歪みを抑制し、高い視認性を実現します'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],

      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-H2 ',
          disabled: true
        }
      ],

      // ↓ ---- 高値下取り ----
      reserveExpensiveTradeInList: {
        name: 'フジフイルム X-T3 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=X-T3&n4c=%E6%9C%89&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/a2d/51a/d79/4c2/484/a8a/585/e64/4da/6ee/02w/a2d/tw2/7/L.jpg',
        txt: 'フジフイルム X-H2 ',
        tradeInImg: '/ec/images2/special/camera/feature/fujifilm/xh2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'フジフイルム X-T3 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=X-T3&n4c=%E6%9C%89&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword&limit=40'
          },
          {
            name: 'フジフイルム X-T4 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=X-T4&n4c=%E6%9C%89&s4=%E3%83%9C%E3%83%87%E3%82%A3&sort=keyword&limit=40'
          },
          {
            name: 'フジフイルム X-H1 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410365801'
          }
        ]
      },
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['', '', ''],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品
      mainProductJanCode: ['4547410469226', '4547410485837'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410469226', '4547410469172', '4549292185430', '4545350053864'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
