














































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { ORDER_DETAIL_TYPES } from '@/constants//mypage-order-detail-types';

export default Vue.extend({
  name: 'order-total-info',
  props: {
    type: {
      type: Number,
      required: true
    },
    totalInfo: {
      type: Object,
      required: true
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state),
      ORDER_DETAIL_TYPES
    };
  }
});
