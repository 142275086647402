






























































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'z6III',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！ニコン Z6III | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Z6III 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/6/17 21:00',
      fanValidTo: '2024/6/19 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/6/19 10:00',
      reserveValidTo: '2024/7/11 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/7/12 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ニコン Z 6 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901484',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/606/976/ea7/ac0/81d/068/7e9/02f/bce/4f3/b8z/d0i/ay7/i/L.jpg',
        txt: 'ニコン Z6III ',
        day: '2024年7月31日(水)',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z6III/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ニコン Z 6 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901484'
          },
          {
            name: 'ニコン Z 6II ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905901'
          },
          {
            name: 'ニコン Z 7 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759901088'
          },
          {
            name: 'ニコン Z 7II ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759905697'
          },
          {
            name: 'ニコン D850 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759149336'
          },
          {
            name: 'ニコン D750 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759143846'
          },
          {
            name: 'ニコン D7500 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759149084'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '世界初部分積層型CMOSセンサーを搭載',
          contents: [
            '約20コマ/秒の高速連続撮影（拡張）時も表示とびのない滑らかなファインダー像（電子シャッター時）',
            'フルサイズ/FXフォーマットで60fps（C60）、APS-Cサイズ/DXフォーマットで120fps（C120）の高速連写',
            'すべての撮影モードでローリングシャッター歪みを低減',
            '6K 60p N-RAW動画や5.4K 60p H.265動画の内部収録',
            '最大10倍のスロー映像で瞬間をドラマティックに表現できるフルHD 240pのハイフレームレート'
          ]
        },
        {
          subtitle: '撮影に没頭させる、高解像、高輝度、広色域EVF',
          contents: [
            'Z 6II の約4倍、Z 9 / Z 8 をもしのぐ明るさを実現。太陽がまぶしい砂浜や雪原など、周囲が極めて明るい環境でも被写体のディテールまで鮮明に確認可能',
            'ニコン史上最高約576万ドットの高解像度EVF',
            'ニコンの優れたファインダー光学設計により歪みや色収差を最小限に抑え、画面の隅々まで細かく鮮明に描写。再生時だけでなく、ライブビュー（撮影）時の映像がきれい。拡大時のピントの確認がよりしやすい',
            'Z 9 、Z 8 で高く評価された「Real-Live Viewfinder」に近い見えが得られるように設計。EVFの表示とびを抑え、被写体の動きを滑らかに確認可能'
          ]
        },
        {
          subtitle: '暗いシーンもノイズが少なく美しく撮れる優れた高感度性能',
          contents: [
            'EXPEED 7により、最高常用感度ISO 64000を達成（動画はISO 51200まで）',
            '使用頻度の高い中間感度域（ISO 6400～ISO 12800）の画質がさらに向上',
            '質感やディテールを維持しながらも、Z 6II よりノイズを抑えた描写を実現'
          ]
        },
        {
          subtitle: 'AF低輝度限界性能－10EV',
          contents: [
            'Z 9 / Z 8 譲りの高いAF性能（9種類の被写体検出性能、逆光時の高いAF性能、画面長辺比約3％の小さな顔サイズ検出）も継承し、様々なシーンでピント合わせの手間がかからず短時間で撮影可能',
            'Z 9 / Z 8 と同じ飛行機撮影に特化した被写体検出の［飛行機］モードも搭載',
            'Z f 同様、MF時の被写体検出も可能'
          ]
        },
        {
          subtitle: 'その他の特徴',
          contents: [
            'ニコン史上最高の自由度で事前に画づくりができるフレキシブルカラーピクチャーコントロール搭載',
            '夜の手持ち撮影の機会を拡げる8.0段のカメラ内手ブレ補正',
            '狙った被写体をシャープに捉えるニコン独自のフォーカスポイントVR 搭載',
            '約9600万画素の画像制作を可能にするピクセルシフト撮影機能',
            '寒冷地でも安心して撮影ができる－10℃対応 ＆ Z8同等の堅牢性・信頼性',
            'RAW動画（N-RAW、ProRes RAW HQ）のカメラ内収録に対応'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z6III ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759916181', '4960759916433'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4960759905901', '4548736154292', '4549292200508'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759916181', '4960759905901', '4548736154292', '4549292200508'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      //popup
      imgs: [
        '/ec/images2/special/camera/feature/nikon/z6III/pic_01.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_02.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_03.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_04.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_05.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_06.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_07.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_08.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_09.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_010.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_011.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_012.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_013.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_014.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_015.jpg',
        '/ec/images2/special/camera/feature/nikon/z6III/pic_016.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
