

































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import ProductAppeal from '@/components/common/special/productAppeal.vue';
import ShootingInformation from '@/components/common/special/shootingInformation.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'a7r3-a9',
  components: {
    productAppeal: ProductAppeal,
    breadcrumbs: Breadcrumbs,
    shootingInformation: ShootingInformation,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'photo＋Mono特集 Vol.4 | α7RⅢやα9を比較レビューレポート | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '話題の商品・気になる商品の使い心地を気鋭の現役プロカメラマンがレビューレポート。α7RⅡやα9と比較しながらプロカメラマンが実写レビューレポート'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'α7RⅢやα9を比較レビューレポート',
          disabled: true
        }
      ],
      // ↓ ---- Top商品価格 ----
      topProductJanCode: ['4548736075139', '4548736063945'],
      topProductList: [] as Array<ProductDetail>,
      // ↓ ---- second商品価格 ----
      secondProductJanCode: ['4548736074125'],
      secondProductList: [] as Array<ProductDetail>,
      // ↓ ---- third商品価格 ----
      thirdProductJanCode: ['4548736047419'],
      thirdProductList: [] as Array<ProductDetail>,

      backNumberList: [
        {
          href: '/ec/special/general/photo_mono/omd-em1',
          img: 'https://shop.kitamura.jp/images/banner/4841.jpg',
          alt: 'vol.3 OLYMPUS OM-D E-M1 MarkⅡ| 最強の手ぶれ補正性能！手持ちでも本格的な夜景撮影を可能に'
        },
        {
          href: '/ec/special/general/photo_mono/mc11',
          img: 'https://shop.kitamura.jp/images/banner/4741.jpg',
          alt: 'Vol.2 SIGMA MOUNT CONVERTER MC-11 | レンズシステムの拡張性と資産価値を高めるMC-11をレビュー'
        },
        {
          href: '/ec/special/general/photo_mono/sony-a9',
          img: 'https://shop.kitamura.jp/images/banner/4627.jpg',
          alt: 'Vol.1 SONYα９レビューレポート｜驚愕の連写性能とAF追随！ SONY α９でブルーインパルスを撮る'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter/344',
          img: 'https://shop.kitamura.jp/images/banner/2449.jpg',
          alt: 'イルミネーション撮影におすすめクロスフィルター'
        },
        {
          href: '/ec/special/camera/feature/olympus/em1m2',
          img: 'https://shop.kitamura.jp/images/banner/3362.jpg',
          alt: 'OLYMPUS OM-D E-M1 MarkII'
        },
        {
          href: '/ec/list?category=&narrow1=&narrow2=&type=u&r=',
          img: 'https://shop.kitamura.jp/images/banner/4637.gif',
          alt: '新着中古'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // トップ商品
        const topResult = await ProductService.fetchProducts(state.topProductJanCode, true);
        state.topProductList = topResult.items;
        // second商品
        const secondResult = await ProductService.fetchProducts(state.secondProductJanCode, true);
        state.secondProductList = secondResult.items;
        // third商品
        const thirdResult = await ProductService.fetchProducts(state.thirdProductJanCode, true);
        state.thirdProductList = thirdResult.items;
      } catch (error) {
        // トップ商品
        state.topProductList = [] as Array<ProductDetail>;
        // second商品
        state.secondProductList = [] as Array<ProductDetail>;
        // third商品
        state.thirdProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
