import { render, staticRenderFns } from "./simple-product-slider.vue?vue&type=template&id=6eee6cc7&scoped=true&"
import script from "./simple-product-slider.vue?vue&type=script&lang=ts&"
export * from "./simple-product-slider.vue?vue&type=script&lang=ts&"
import style0 from "./simple-product-slider.vue?vue&type=style&index=0&id=6eee6cc7&lang=scss&scoped=true&"
import style1 from "./simple-product-slider.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eee6cc7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VContainer,VRating,VSlideGroup,VSlideItem})
