


























































































































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sel70200gm2',
  components: {
    breadcrumbs: Breadcrumbs,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    watchInVideo: WatchInVideo,
    naviBtn: NaviBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー FE 70-200mm F2.8 GM OSS II | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー FE 70-200mm F2.8 GM OSS II  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      advertisementValidFrom: '2021/10/28 11:30',
      advertisementValidTo: '2021/10/28 11:40',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2021/10/28 11:40',
      reserveValidTo: '2021/11/26 00:00',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2021/11/26 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowAdvertisement: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ソニー FE 70-200mm F2.8 GM OSS II [SEL70200GM2] ',
        href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736038400&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/90a/204/b4a/df6/645/f49/970/bbf/34a/490/5d5/ar9/vbt/x/M.jpg',
        txt: 'ソニー FE 70-200mm F2.8 GM OSS II [SEL70200GM2] ',
        tradeInImg: '/ec/images2/special/camera/feature/sony/sel70200gm2/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー FE 70-200mm F2.8 GM OSS [SEL70200GM]', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736038400&pattern=1' },
          { name: 'ソニー FE 70-200mm F4 G OSS [SEL70200G]', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4905524951356&pattern=1' },
          { name: 'ソニー FE 100-400mm F4.5-5.6 GM OSS [SEL100400GM]', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736058415&pattern=1' },
          { name: 'ソニー FE 200-600mm F5.6-6.3 G OSS [SEL200600G]', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736099791&pattern=1' },
          { name: 'ソニー FE 70-300mm F4.5-5.6 G OSS [SEL70300G]', href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736033047&pattern=1' }
        ]
      },
      releaseExpensiveTradeInList: {
        name: 'ソニー FE 70-200mm F2.8 GM OSS II [SEL70200GM2] ',
        href: 'https://www.net-chuko.com/sell/item-list.do?goodsname=4548736038400&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/e6a/566/223/13d/322/7d3/e9e/d4c/92d/3ba/b4d/q99/qon/e/L.jpg',
        txt: 'ソニー FE 70-200mm F2.8 GM OSS II [SEL70200GM2] ',
        tradeInImg: '/ec/images2/special/camera/feature/sony/sel70200gm2/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      commonCharacteristicList: [
        {
          subtitle: 'フルサイズ用大口径望遠ズームで世界最軽量とズーム全域での高い解像性能を実現',
          contents: [
            '超高度非球面XA（extreme aspherical）レンズや、ED（特殊低分散）非球面レンズを採用した最新の光学設計で、焦点距離70mmから200mmのズーム全域で開放F値2.8から画面周辺部まで緻密に描写する高い解像性能を実現',
            '従来機種（SEL70200GM）より約435g軽い、質量約1,045gを実現。約29%の軽量化を達成。進化するボディの性能を最大限活かせる描写に加え、撮影自由度の向上・持ち運びの快適さを実現',
            'ED（特殊低分散）ガラスを2枚、スーパーEDガラスを2枚搭載。色収差を最小限に抑制し、美しく自然な画像を実現',
            '太陽光などの強い光が入りこむシーンでも、内面反射によるフレアやゴーストの発生を大幅に抑制し、ヌケの良いクリアな画質を実現する「ナノARコーティングII」を採用'
          ]
        },
        {
          subtitle: '「静止画・動画を問わずボディのスピード性能を引き出す最大約4倍のAF高速化を実現',
          contents: [
            'フローティングフォーカス機構に加え独自の4基の高推力なXD（extreme dynamic）リニアモーターを搭載。推力効率が従来機種より大幅に向上し最大約4倍の高速化を実現',
            '高速に近づいてくる被写体を連続撮影する際、ズーム中のフォーカス追随性能が従来機種より約30%と大幅に向上。一瞬のチャンスを逃さない撮影が可能',
            'シビアなピント合わせが求められる被写界深度の浅いスローモーションや、4K、8Kの高精細動画撮影においても、高速・高精度・高追随かつ静粛なAFを実現',
            '新開発絞り駆動機構の搭載で連写で流し撮りをする際や、より被写界深度を深くして被写体を深度に収めながら連写する際などでも絞り値に制限されることなく、動体を高速・高精度AF追随連写で捕捉が可能',
            'フォーカス時のブリージングやフォーカスシフト、ズーム操作に伴う軸ずれを従来よりも大幅に抑制。映像に残る画角の変動や揺れを最小限にし、動画に求められるなめらかな表現が可能'
          ]
        },
        {
          subtitle: '柔らかく美しいぼけ描写と最大撮影倍率0.3倍の高い近接撮影能力',
          contents: [
            '新開発11枚羽根の円形絞りユニットと超高度非球面XA（extreme aspherical）レンズの採用で、被写体に立体感を与える柔らかく美しいぼけ描写を実現',
            '最大撮影倍率0.3倍。70mmで最短0.4m、200mmで最短0.82mの高い近接撮影が可能。レンズが繰り出さないインナーズーム方式の採用で、使い勝手の良さも兼備',
            '1.4倍、2.0倍のテレコンバーター（別売）に対応。装着時も高い描写性能と高速・高精度なAF性能を発揮'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'ソニー FE 70-200mm F2.8 GM OSS II',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736134119'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736134119', '4548736038400'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736134119'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const advertisementValidFrom = dayjs(state.advertisementValidFrom);
      console.log(advertisementValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        console.log(formatToday);
        if (formatToday.isBefore(advertisementValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.advertisementValidFrom, state.advertisementValidTo)) {
          state.isShowAdvertisement = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
