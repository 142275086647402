


















































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';

export default Vue.extend({
  name: 'highly-recommended-2015-0605',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015
  },
  setup: () => {
    document.title = 'おすすめ！コンパクトな三脚「マンフロット MKBFRA4-BH Befree 」 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '旅行におすすめ！コンパクトな三脚「マンフロット MKBFRA4-BH Befree アルミニウム三脚ボール雲台キット」をご紹介！Manfrottoの三脚はカメラのキタムラへお任せください♪')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'おすすめ！コンパクトな三脚「マンフロット MKBFRA4-BH Befree 」 | スタッフのイチオシ',
          disabled: true
        }
      ],
      imageList: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0904/img_exa.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0904/img_exb.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0904/img_exc.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2015/0904/img_exd.jpg'
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
