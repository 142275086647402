






































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';

export default Vue.extend({
  name: 'hatsuuri',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    //urlcopy: Urlcopy,
    subText: SubText,
    facebookAndTwitter: FacebookAndTwitter,
    notfound: Notfound
  },

  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '初売りセール 2023 | ～4万円';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '初売りセール 2023年1月4日18時～1月10日9時まで♪デジカメ・カメラレンズ・ビデオカメラ・家電・カメラ用品・スマートウォッチ・中古品がお買い得☆カメラのキタムラ ネットショップ'
      );
    const state = reactive({
      naviList: [
        { naviItem: '新品から選ぶ', href: '#no1' },
        { naviItem: '価格から選ぶ', href: '#no2' },
        { naviItem: '中古から選ぶ', href: '#no3' }
      ],
      sinpinList: [
        { txt: '初売りセット', href: '/ec/special/sale/hatsuuri#saleitm' },
        { txt: 'カメラ・レンズ', href: '/ec/special/sale/hatsuuri/01#saleitm' },
        { txt: '家電・プリンター', href: '/ec/special/sale/hatsuuri/02#saleitm' },
        { txt: '三脚・撮影用品', href: '/ec/special/sale/hatsuuri/03#saleitm' },
        { txt: 'アルバム', href: '/ec/special/sale/hatsuuri/04#saleitm' },
        { txt: 'スマホ・スマートウォッチ', href: '/ec/special/sale/hatsuuri/05#saleitm' }
      ],
      kakakuList: [
        { txt: '～1万円', href: '/ec/special/sale/hatsuuri/m01#saleitm' },
        { txt: '～4万円', href: '#saleitm' },
        { txt: '～10万円', href: '/ec/special/sale/hatsuuri/m03#saleitm' },
        { txt: '10万円以上', href: '/ec/special/sale/hatsuuri/m04#saleitm' }
      ],
      tyukoList: [
        { txt: '中古カメラ', href: '/ec/special/sale/hatsuuri/u01#saleitm' },
        { txt: '中古レンズ', href: '/ec/special/sale/hatsuuri/u02#saleitm' },
        { txt: '中古時計', href: '/ec/special/sale/hatsuuri/u03#saleitm' },
        { txt: '中古スマホ', href: '/ec/special/sale/hatsuuri/u04#saleitm' }
      ],
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2023/1/1 12:00',
      fanValidTo: '2023/1/4 17:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/1/4 18:00',
      reserveValidTo: '2023/1/10 8:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/1/10 9:00',
      releaseValidTo: '2023/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      reserveExpensiveTradeInList: {
        name: 'ソ',
        href: 'https://www.net-chuko.com/',
        nameImg: '/pd/913/1b2/df1/d52/2b3/d6d/b42/c68/1b7/ba6/b03/n84/0hk/x/L.jpg',
        txt: 'ソ',
        tradeInImg: '/ec/',
        tradeInProducts: [{ name: 'ソ', href: 'https://www.net-chuko.com' }]
      },

      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '初売りセール 2023',
          linkUrl: '/ec/special/sale/hatsuuri',
          disabled: false
        },
        {
          path: '～4万円',
          linkUrl: '/ec/special/sale/hatsuuri/m02',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736133730'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['4548736079625'],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736133730'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
      // ページ内スクロール
      setTimeout(() => {
        const hash = context.root.$route.hash;
        if (hash) context.root.$vuetify.goTo(hash);
      }, 100);
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
