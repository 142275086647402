
















import { reactive, toRefs } from '@vue/composition-api';
export default {
  name: 'naviBtn2',
  props: {
    naviList2: {
      type: Array,
      required: true
    },
    cols: {
      type: Number,
      required: false,
      default: 6
    },
    sm: {
      type: Number,
      required: false,
      default: 3
    },
    height: {
      type: Number,
      required: false,
      default: 55
    },
    hoverColor: {
      type: String,
      required: false,
      default: 'red darken-4'
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  setup: () => {
    const state = reactive({
      activeItem: ''
    });

    return {
      ...toRefs(state)
    };
  }
};
