






























































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'z50',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン Z 50 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン Z 50 発売中！比較や、価格・発売日・スペック・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '「Z 7」「Z 6」のエルゴノミクスデザインを踏襲した小型・軽量ボディー',
          contents: [
            '小型・軽量ボディーで高い描写力と多彩な表現力を実現しながら、小型・軽量ボディーの中にも使いやすさを追求',
            '同時発売のNIKKOR Z DXレンズを含め、システムとしてさらなる小型・軽量化を達成',
            '小型ながらもしっかりとホールドできるグリップ形状や、右手で操作をすべて完結できるボタン配置を実現'
          ]
        },
        {
          subtitle: 'DXフォーマットカメラ最高の、解像感の高い画づくりと高感度低ノイズを実現',
          contents: [
            '画像処理エンジン 「EXPEED 6」の搭載で「Z 7」 「Z 6」と同等の画づくりを実現',
            '多彩な効果を付けることができる全20種類の「Creative Picture Control」搭載',
            '「フォトイラスト」や「トイカメラ風」などのアレンジを加えた表現で個性的な画づくりができる「スペシャルエフェクトモード」もあわせて搭載'
          ]
        },
        {
          subtitle: 'クラス最高レベルのAF性能を実現',
          contents: [
            '像面位相差AF画素搭載CMOSセンサーを採用。動きのある人物でも瞳を正確に認識できる「瞳AF」を実現',
            'クラス最高レベルのｰ４EVまで対応可能な優れた低輝度AF性能を搭載',
            '11コマ/秒の高速連続撮影時にも、AE・AFともに追従'
          ]
        },
        {
          subtitle: '初心者からハイアマチュアまで満足できる多彩な機能を搭載',
          contents: [
            'スマートフォン同様のタッチ操作が可能なタッチパネルを採用で思い描いている表現を簡単に実現できる直感的な操作性を実現',
            '広い範囲で角度を変えられるチルト式画像モニターを搭載で、静止画・動画ともに自分撮り撮影が可能',
            'アプリを使って撮影した静止画・動画をすぐにSNSで共有可能'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'ニコン D7500',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&goodsname=D7500&_ga=2.137861872.1712661879.1629071621-279164427.1627285160&pattern=1',
        nameImg: '/ec/images2/special/camera/feature/nikon/z50/img_5.jpg',
        txt: 'ニコン Z 50',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z50/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/general/highly_recommended/2020/4660',
          img: 'https://shopimg.kitamura.jp/images/banner/7575.png',
          alt: 'スタッフイチオシ！ニコン NIKKOR Z 14-30mm f/4 S'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z 50',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759903617'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759903617', '4549292136401', '4548736092044', '4547410399875'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759903617',
        '4960759903631',
        '4960759903624',
        '4960759903969',
        '4960759148940',
        '4955478181736',
        '4960759149961',
        '4960759900159',
        '4960759900340',
        '4960759900951',
        '4960759900166',
        '4960759900357'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
