






































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'z_5',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン Z 5 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Z 5 好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#point' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '本格的な画づくりを実現する「ニコン Z シリーズ」ならではの高画質と表現力',
          contents: [
            '有効画素数2432万画素、ニコンFXフォーマットCMOSセンサーと画像処理エンジン「EXPEED 6」により、暗所でも質感やディテールを表現しながらノイズを抑えた描写が可能',
            'ボディー内手ブレ補正（VR）を搭載。手ブレ補正ユニットを5軸に駆動させて補正することで、シャッタースピード5.0段分の補正力'
          ]
        },
        {
          subtitle: '狙い通りの撮影を可能にする「瞳AF」「動物AF」「273点ハイブリッドAFシステム」',
          contents: [
            '像面位相差AFとコントラストAFを自動的に切り換える「ハイブリッドAFシステム」を搭載。撮像範囲の水平、垂直約90%の広範囲をフォーカスポイント273点でカバー',
            '「瞳AF」「動物AF」を搭載し、動いている人物の瞳や、犬と猫の瞳を高い精度で捕捉。ライブビュー撮影時だけでなくファインダー撮影時も複数の瞳を検出可能',
            '動画撮影時は人物の顔に加え、動物の顔を検出可能。-3EVまでのAF撮影に対応し、低輝度下でも被写体をしっかり捕捉'
          ]
        },
        {
          subtitle: '優れた操作性と多様な機能により快適な撮影をサポート',
          contents: [
            '「Z 7」「Z 6」と同等の高い防塵・防滴性能、堅牢性を備え、様々な環境下で安心して撮影が可能',
            '新開発の「Li-ionリチャージャブルバッテリー EN-EL15c」は容量が増加。従来のUSB充電に加え、新たに電源ON時のUSB給電にも対応',
            'ユーザーの要望に応え、ニコンのミラーレスカメラとして初めてSDダブルスロットを搭載し、スムーズなデータハンドリングを実現'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'ニコン D750 ボディ',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&goodsname=4960759143846%20&_ga=2.66452910.16120489.1628554499-279164427.1627285160&pattern=1',
        nameImg: '	https://shopimg.kitamura.jp/images/pd/009/b51/5b1/d9b/6cf/2b3/e79/7b4/a00/b9f/61y/sz7/qnw/n/M.jpg',
        txt: 'ニコン Z 5',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z_5/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z 5',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759904485', '4960759904515'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759904485', '4960759901484', '4549292132144', '4548736079625'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759904485',
        '4960759904515',
        '4960759904256',
        '4960759149961',
        '4960759904430',
        '4960759903310',
        '4960759126566',
        '4955478180647',
        '4960759149282',
        '4955478182665',
        '4955478182658',
        '4960759144034',
        '4523052016615'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
