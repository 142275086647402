


































































































































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import { formatPrice } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
const kitamuraIcon = process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/logo_h_kitamura.png';
const yamamotoIcon = process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_rogo.png';
//const minatoIcon = process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_minato.jpg';
//const yashiroIcon = process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_yashiro.jpg';

export default Vue.extend({
  name: 'interview',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'おすすめ精米機特集 山本電気さんインタビュー | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '精米機メーカーの山本電気さんにインタビュー！イチオシの家庭用精米機について、他社と比べたアピールポイントや特徴を詳しく教えてもらいました。お米をもっと美味しくもっと栄養価を保って食べるために、精米機選びのヒントになれば幸いです。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '人気炊飯器 比較 おすすめ炊飯器特集',
          linkUrl: '/ec/special/kaden/rice-cooker_bestbuy',
          disabled: false
        },
        {
          path: 'おすすめ精米機特集 山本電気さんインタビュー',
          disabled: true
        }
      ],
      yamamotoElectricPeopleList: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_shimada.jpg',
          name: '島田 一也 課長',
          affiliation: '技術部　家電技術グループ',
          sidedishes: '\nあじのり、鰹節'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_yashiro.jpg',
          name: '八代 裕太朗',
          affiliation: '営業部　家電営業課',
          sidedishes: 'なめたけ'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/ricemill/interview/yamamoto_minato.jpg',
          name: '秦　龍一　係長',
          affiliation: '営業部　家電営業課',
          sidedishes: '納豆'
        }
      ],
      interviewList: [
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '今日は精米機について勉強させていただきます！\nよろしくお願いいたします。'
        },
        { src: yamamotoIcon, talkingPerson: '山本電気さん', content: 'よろしくお願いいたします。' },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: 'まずは山本電気さんの精米機のアピールをお願いしたいです。\n種類がいろいろありますが、それぞれの特徴を教えてください。'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            'はい！\n\nまずはYE-RC52です料理の鉄人でお馴染みの道場六三郎氏監修のもと作りました。\n「みがき米」機能が特徴です。\n\n続いてYE-RC41です。これは随分進化しています。\n特許技術の精米羽根を使用していまして対流効率を上げ、より優しく繊細な精米を実現しています。\n\nまた栄養豊富な胚芽を残しつつ、白米に近い味わいが得られる「胚芽白米モード」機能を搭載しています。\nフタに遮音性パッキンを取り付けるなど、RC52と比べ静音性も高める工夫をしておりますから音が心配という方にもおすすめです。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '当店イチオシのYE-RC17Aはどうですか？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            'ぬか切れの改善に成功した、新しい精米カゴを使用しています。\n\n基本性能はYE-RC41と一緒ですが、「分付胚芽３・５・７」より繊細な精米機能を搭載しております。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: 'ではやはりイチオシは？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content: '全部！\nと言いたいところですが、やはりYE-RC17Aですね！'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: 'ありがとうございます！\n一致しました！'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content:
            '精米機を作っているメーカーも、ぱっと思いつくだけで5社ほどありますが、山本電気さんのYE-RC17Aが他社の精米機に勝っているというストロングポイントを教えてください。'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content: 'はい！\n\n特許を取っているモーター、精米羽根の技術ですね。\nそれによって生まれている豊富な精米メニュー・最速の精米スピードも強みです。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: 'カメラのキタムラ\n他社は上部が透明の機種も多いようですが？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            'YE-RC17Aはすぐに精米が終わりますから、上から見るひまもないくらいです。\n当社の精米機はレッドなど他のメーカーには少ないカラーの展開もしています。\n\n機能面では、左から順にモードを選択していく仕組みとなっており、また、文字や表示を分かりやすく表記しておりますので、初見でも操作しやすくなっております。\nデザインは外見だけでなく使用感を意識しています。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '他にも使い勝手のうえで工夫したポイントはありますか？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            '前回精米したモードを記憶するため、毎回お気に入りのモードで精米される方は玄米をセットし、スタートを押すだけで開始します。\n\nRC41・RC17Aのぬか受けについては、精米後、精米カゴごと取り出しやすいようにつまみをつけました。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: 'なるほど、ちょっとしたことですが使い手にとって便利ですね'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '高機能な炊飯器を買うのと 精米機を買うことと 美味しいお米を食べるにはどちらがおすすめですか？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            '私、実は入社してから初めて精米機を使った精米したてのお米のご飯を食べたのですが、本当に美味しくて感動しました。\n\nもちろんお米も炊飯器もいいものに越したことはありませんが、よくなればなるほど酸化の状態で味の差がわかりやすいですから「まずは精米機」がおすすめです。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '「高級炊飯器よりは精米機」と考えていたのですが、「高級炊飯器こそ精米機」ということですね。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '精米機の開発にあたって苦労されたことはありますか？'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            '山本電気は家庭用の回転対流式精米機を初めて作った会社です。\n前例のない物をゼロから産み出さねばなりませんでした。\n\n全国のどのお米で精米しても美味しくできるように、北から南まであらゆる玄米を取り寄せて、何度も何度も試験を行ってきました。\n何トンのお米を使ったかわからないほどですね。\n\n最適な精米プログラムを生み出すのに技術部では大変な苦労をしてきましたので、その分製品には自信がありますね。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content:
            '模倣ではなくゼロから創るというは大変なことなんですね、胸が熱くなります。\n今後、こんな精米機を作ってみたいというものがあれば教えてください。'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content: 'これでも以前に比べれば静かにはなりましたが、さらに静音性を向上させた製品を産み出したいです。'
        },
        {
          src: kitamuraIcon,
          talkingPerson: 'カメラのキタムラ',
          content: '作り手の想いがわかり、製品にも愛着がわきました。\n最後に、精米機を買おうかどうか迷っているお客様へメッセージをお願いいたします。'
        },
        {
          src: yamamotoIcon,
          talkingPerson: '山本電気さん',
          content:
            '日本に馴染みの深いご飯ですが、実は「お米」について詳しく知る機会が少ないのです。\n玄米にどれだけの栄養が含まれているのか、胚芽とは？ など、お米について知れば知るほど必然と精米機のメリットにも気づいていただけるのではないかと思います。\n\nお米の魅力を最大限に引き出せるのが家庭用精米機です。\n\nまずは騙されたと思って購入していただきたいです。\n体験いただければ、精米機のよさがすぐにわかっていただけるはずです！'
        },
        { src: kitamuraIcon, talkingPerson: 'カメラのキタムラ', content: 'ありがとうございました。' }
      ],
      mainProductJanCode: ['4582221880839'],
      mainProductList: [] as Array<ProductDetail>
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
