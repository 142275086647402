/**
 * カートオプション
 */
export const CART_OPTIONS = {
  WRAPPING_PRICE: 300,
  WRAPPING_JAN_CODE: '2240480042741'
};

/** 家電オプションコンフィグ */
export const APPLIANCE_OPTION_CONFIG_MAP: {
  [key: string]: {
    name: string;
    description: string;
    linkUrl: string;
  };
} = {
  '2500000444630': {
    name: '真下排水',
    description:
      '排水口が本体の真下（本体の内側）になる場所に設置する場合、真下排水キット（別売品）が必要です。\n真下排水キットを購入の上、真下排水オプションと、洗濯機取付オプションを同時にお申し込みください。',
    linkUrl: 'https://shop.kitamura.jp/ec/guide/setup/washer'
  },
  '2500000444647': {
    name: '全自動洗濯機取付',
    description: '全自動洗濯機の取付作業を実施します。',
    linkUrl: 'https://shop.kitamura.jp/ec/guide/setup/washer'
  },
  '2500000444654': {
    name: 'ドラム式洗濯機取付',
    description: 'ドラム式洗濯機の取付作業を実施します。',
    linkUrl: 'https://shop.kitamura.jp/ec/guide/setup/washer'
  },
  '2500000444661': {
    name: 'テレビ設置',
    description: 'テレビの設置を実施します。',
    linkUrl: 'https://shop.kitamura.jp/ec/guide/setup/television'
  },
  '2500000444678': {
    name: '給水栓取付',
    description: '洗濯機に対応していない蛇口の場合、給水栓の取り付けが必要になります。別途、給水栓のキットをご用意ください。',
    linkUrl: 'https://shop.kitamura.jp/ec/guide/setup/washer'
  }
};

export const RECYCLE_OPTION_DESCRIPTION =
  '手数料とは別に、リサイクル料金・収集運搬料金が発生します。\nリサイクル・収集運搬料は、リサイクル回収時に配達員にお支払いいただきます。';

export const RECYCLE_OPTION_LINK_URL = 'https://shop.kitamura.jp/ec/guide/recycle';
