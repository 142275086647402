



























































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import MyPageLayout from '../common/my-page-layout.vue';
import BackButton from '@/components/common/back-button.vue';
import OrderInfo from '@/components/my-page/order-detail/order-info.vue';
import OrderStep from '@/components/my-page/order-detail/order-step.vue';
import OrderProduct from '@/components/my-page/order-detail/order-product.vue';
import OrderTotalInfo from '@/components/my-page/order-detail/order-total-info.vue';
import ProductSlider from '@/components/product-detail/product-slider.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { ORDER_DETAIL_TYPES } from '@/constants/mypage-order-detail-types';
import { DELIVERY_TIME_ZONE, DELIVERY_BOX_TYPES } from '@/constants/mypage-order-detail-code';
import { PAYMENT_TYPE } from '@/constants/order-register-type';
import { sortByRowNo } from '@/logic/utils';
import UserService from '@/logic/user.service';
import ProductService from '@/logic/product.service';
import { OrderBuyDetail, OrderBuyProductDetail } from '@/types/order-buy-detail';
import { RelatedProduct } from '@/types/product';

export default Vue.extend({
  name: 'order-buy-detail',
  components: {
    'my-page-layout': MyPageLayout,
    'back-button': BackButton,
    'order-info': OrderInfo,
    'order-step': OrderStep,
    'order-product': OrderProduct,
    'order-total-info': OrderTotalInfo,
    'product-slider': ProductSlider,
    'section-loading': SectionLoading
  },
  setup: (props, context) => {
    const { authorizer } = context.root.$store;
    const state = reactive({
      // 画面タイトル
      title: '購入履歴詳細',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: '注文履歴', linkUrl: '/ec/mypage/odr/list' },
        { path: '注文詳細', linkUrl: '' }
      ],
      // 表示タイプ
      type: ORDER_DETAIL_TYPES.BUY_DELIVERY,
      // 購入履歴番号
      orderId: '',
      // 購入履歴詳細
      orderDetail: {} as OrderBuyDetail,
      // 関連商品
      orderProductIds: [] as Array<string>,
      relatedItems: [] as Array<RelatedProduct>,
      // ロード状態
      loaded: {
        detail: false,
        relatedItems: false
      }
    });

    /**
     * 関連商品を取得
     */
    const fetchRelatedProduct = async () => {
      state.relatedItems = [];
      state.loaded.relatedItems = false;
      try {
        for (let index = 0; index < state.orderProductIds.length; index++) {
          const result = await ProductService.fetchProducts([state.orderProductIds[index]], false);
          state.relatedItems = state.relatedItems.concat(result.items[0].relatedItems);
        }
      } catch (error) {
        console.error(error);
      } finally {
        state.loaded.relatedItems = true;
      }
    };

    /**
     * 購入履歴詳細を取得
     * @param id 注文ID
     */
    const fetchOrderDetail = async (id: string) => {
      state.loaded.detail = false;
      try {
        const result = await UserService.fetchOrderDetail(id);
        state.orderDetail = result;

        // 「rowNo」を昇順にソートする
        // ページ毎に「rowNo」が新規に割り振られているため、レスポンス内容の中でソートする
        state.orderDetail.detailInfo = sortByRowNo<OrderBuyProductDetail>(state.orderDetail.detailInfo);

        // 詳細表示タイプの設定
        if (state.orderDetail.rcvMethodId === 0) {
          state.type = ORDER_DETAIL_TYPES.BUY_SHOP;
        } else {
          state.type = ORDER_DETAIL_TYPES.BUY_DELIVERY;
        }

        // 関連商品の取得
        if (state.orderDetail.detailInfo && state.orderDetail.detailInfo.length) {
          state.orderDetail.detailInfo.map((product: OrderBuyProductDetail) => {
            if (product.isChuko !== 2) {
              // 新品
              state.orderProductIds.push(product.janCode);
            } else {
              // 中古
              ProductService.fetchUsedProducts(product.janCode).then((result) => {
                state.orderProductIds.push(result.janCode);
                // 画像パスの置き換え
                product.imagePath = result.imageUrl1;
              });
            }
          });
          await fetchRelatedProduct();
        }
      } catch (error) {
        console.error(error);
        state.orderDetail = {} as OrderBuyDetail;
      } finally {
        state.loaded.detail = true;
      }
    };

    onMounted(() => {
      const params = context.root.$route.params;
      // orderIdが無い場合は、NotFoundPageに遷移させる
      if (params.orderId) {
        state.orderId = `${params.orderId}`;
        if (authorizer.isLoggedIn) fetchOrderDetail(state.orderId);
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchOrderDetail(state.orderId);
      }
    );

    const pruductCount = computed(() => {
      let count = 0;
      let allUndefined = true;
      if (state.orderDetail.detailInfo && state.orderDetail.detailInfo.length) {
        state.orderDetail.detailInfo.forEach((product) => {
          if (product.count !== undefined && product.count !== null) {
            count += product.count;
            allUndefined = false;
          }
        });
      }
      return allUndefined ? undefined : count;
    });

    /**
     * 宅配ボックス利用コードをテキストに変換
     * @param id コード
     */
    const convertDeliveryBoxIdForText = (id: number) => {
      const target = DELIVERY_BOX_TYPES.find((type) => type.code === id);
      return target ? target.text : '';
    };

    /**
     * お届け希望時間帯コードをテキストに変換
     * @param id コード
     */
    const convertDeliveryTimeZoneForText = (id: string) => {
      const target = DELIVERY_TIME_ZONE.find((type) => type.code === id);
      return target ? target.text : '指定なし';
    };

    return {
      PAYMENT_TYPE,
      ...toRefs(state),
      dayjs,
      ORDER_DETAIL_TYPES,
      pruductCount,
      convertDeliveryBoxIdForText,
      convertDeliveryTimeZoneForText
    };
  }
});
