



































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'z7z6',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    kitamuraService: KitamuraService,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン Z 7・Z 6／ニコン初のフルサイズミラーレス一眼 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン Z 7・Z 6好評発売中！新次元の光学性能を追求した「ニコンZ マウントシステム」。比較や、価格・スペック・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images' }
      ],
      expensiveTradeInList: {
        name: '富士フイルム X100F',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=X100F&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/23d/03a/38b/025/e97/5f1/92f/9d7/871/ee7/59b/8ob/laj/r/L.jpg',
        txt: '富士フイルム X100V',
        tradeInImg: ' /ec/images2/special/camera/feature/fujifilm/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: '富士フイルム X100F', href: '' },
          { name: 'リコーGRIII', href: '' },
          { name: 'Leica D-LUX7', href: '' }
        ]
      },
      characteristicList: [
        {
          subtitle: '大口径の新マウント採用のミラーレスカメラ、それに対応するNIKKOR Z レンズ・アクセサリーで構成するシステム',
          contents: [
            'NIKKOR Z レンズは、大口径の新マウントの採用により、レンズ設計の自由度が格段に上がり、光学性能の驚異的な向上を実現しました。',
            'ニコン史上最高の開放F値0.95の極めて明るいレンズなど、多彩な高性能レンズが今後提供される予定です。',
            '「マウントアダプターFTZ」を使用することで、豊富なレンズバリエーションを誇るNIKKOR F レンズが使用可能です。'
          ]
        }
      ],
      characteristicList2: [
        {
          subtitle: '像面位相差AF画素を搭載した新開発の裏面照射型ニコンFXフォーマットCMOSセンサー',
          contents: [
            '「Z 7」「Z 6」ともに、像面位相差AF画素搭載の裏面照射型CMOSセンサーを採用',
            '「Z 7」は、有効画素数4575万画素と高画素ながら、常用感度ISO 64～25600を達成',
            '「Z 6」は、有効画素数2450万画素で、ISO 100～51200の幅広い常用感度域を実現'
          ]
        },
        {
          subtitle: 'ハイブリッドAFと瞳AFで、素早く高精度なピント合わせを実現',
          contents: [
            '「Z 7」は493点、「Z 6」は273 点のフォーカスポイントが、撮像範囲の水平、垂直約90%という広い範囲をカバー',
            'FXフォーマットセンサーに最適化したアルゴリズムで、像面位相差AFとコントラストAFを自動的に切りかえてピントを合わせます',
            '顔の手前に障害物がある場合や動きのある人物でも、追尾する瞳AF機能※を新搭載'
          ]
        }
      ],
      characteristicList3: [
        {
          subtitle: '解像感の高い画作りを実現する新画像処理エンジン「EXPEED 6」と、映像表現をサポートする新機能',
          contents: [
            '「Z 7」「Z 6」は、新画像処理エンジン「EXPEED 6」を搭載',
            'NIKKORレンズが持つ高い解像力を最大限に活用し、被写体をシャープに描写します。',
            '独創的な表現を可能にする、全20種類の「Creative Picture Control」を搭載'
          ]
        },
        {
          subtitle: '約5.0段の高い効果を発揮する、ニコン初のカメラ内センサーシフト式手ブレ補正',
          contents: [
            '「Z 7」「Z 6」ともに、カメラ内手ブレ補正（VR）を搭載',
            '手ブレ補正ユニットを5軸に駆動させて補正し、シャッタースピード最大5.0段分の高い補正効果が得られます',
            '「マウントアダプターFTZ」を介してVR 非搭載レンズを含むNIKKOR Fレンズ使用時にも効果を発揮'
          ]
        },
        {
          subtitle: 'ニコンの優れた光学技術と画像処理プロセスによる、クリアーで自然な見えを提供する電子ビューファインダー',
          contents: [
            '約369万ドット、有機ELパネルを採用した電子ビューファインダー搭載、視野率約100％、ファインダー倍率0.8倍、対角視野角約37.0°',
            'ニコンの高い光学技術を活かした光学系の採用と、画像処理プロセスにより、歪みが少なく隅々までクリアーで自然な見えを実現',
            '接眼保護窓には、汚れの付着やフレア、ゴーストを防ぐフッ素コート採用'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/general/highly_recommended/2020/4660',
          img: 'https://shopimg.kitamura.jp/images/banner/7575.png',
          alt: 'スタッフイチオシ！ニコン NIKKOR Z 14-30mm f/4 S'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z 7・Z 6',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759901095'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759901088', '4960759901484', '4548736075139', '4548736079625'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759901088',
        '4960759901101',
        '4960759901118',
        '4960759901095',
        '4960759901095',
        '4960759901507',
        '4960759901514',
        '4960759901491',
        '4960759149534',
        '4960759149862',
        '4960759900067',
        '4955478180531',
        '4955478180524'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
