





















































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import HeaderConfigsService from '@/logic/tsv/header-configs.service';
import { CategoryLink } from '@/types/tsv-config';

export default Vue.extend({
  name: 'tab-menu',
  setup: () => {
    const state = reactive({
      ctglinkList: [
        { href: '#categoryall' } //ABテストアンカー
      ],
      value: 1,
      showBuyMenu: false,
      showSellMenu: false,
      showServiceMenu: false,
      showGroupMenu: false,
      netChukoUrl: process.env.VUE_APP_NET_CHUKO_URL,
      servises: [
        {
          title: '買取・下取',
          linkUrl: '/special/sale-fair/page/sell/'
        },
        {
          title: 'お支払方法',
          linkUrl: '/sitemap/riyou_shiharai_index.html'
        },
        {
          title: '送料・手数料',
          linkUrl: '/sitemap/riyou_souryou_index.html'
        },
        {
          title: '5年間保証',
          linkUrl: '/sitemap/riyou_hoshou_index.html'
        },
        {
          title: '修理のご案内',
          linkUrl: '/files/syuuri/index.html'
        },
        {
          title: 'なんでも下取り',
          linkUrl: '/sitemap/riyou_shitadori_index.html'
        },
        {
          title: 'トクトク交換',
          linkUrl: '/ec/special/general/tokutokukoukan'
        },
        {
          title: '商品お届け・納期',
          linkUrl: '/sitemap/riyou_otodoke_index.html'
        },
        {
          title: '返品・交換',
          linkUrl: '/sitemap/riyou_henpin_index.html'
        }
      ]
    });

    // 買いたいタブのカテゴリリンクを取得
    let categoryLinks: CategoryLink[] = [];
    HeaderConfigsService.fetchCategoryLinks().then((_categoryLinks) => {
      categoryLinks = _categoryLinks;
    });
    function getCategoryLinksByColumn(column: string): CategoryLink[] {
      return categoryLinks.filter((category) => category.column === column);
    }

    // 売りたいタブのバナーを取得
    let chukoBanners: CategoryLink[] = [];
    HeaderConfigsService.fetchChukoBanners().then((_chukoBanners) => {
      chukoBanners = _chukoBanners;
    });
    function getChukoBannersByColumn(column: string): CategoryLink[] {
      return chukoBanners.filter((banner) => banner.column === column);
    }

    return {
      ...toRefs(state),
      getCategoryLinksByColumn,
      getChukoBannersByColumn
    };
  }
});
