












































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import SnsShare from '@/components/common/special/sns-share.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'toaster-oven',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    // orangeBtn: OrangeBtn,
    // carousels: Carousels,
    'product-list-by-special-id': ProductListBySpecialId,
    // shoppingCredit: ShoppingCredit,
    snsShare: SnsShare,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'おすすめ高級トースター特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'もっと美味しくパンを食べたい方は必見☆高級トースターでトーストを食べ比べ♪おすすめ高級トースター特集');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめ高級トースター特集',
          disabled: true
        }
      ],
      naviList: [
        { name: 'トースターの種類・選び方', href: '#i00' },
        { name: '食べ比べした高級トースターの特徴', href: '#i01' },
        { name: '高級トースターの食べ比べ方法', href: '#i02' },
        { name: '食べ比べの結果発表', href: '#i03' },
        { name: '今回の食べ比べをして', href: '#i04' }
      ],
      //食べ比べした高級トースターの特徴リスト
      featuresOfLuxuryToasterList: [
        {
          isBackground: true,
          name: 'アラジン グラファイト グリル&トースター CAT-G13A（AGT-G13Aも同等機種です）',
          href: '/ec/list?narrow18=0&keyword=AGT-G13&category=&index=&searchbox=1&q=AGT-G13&path=',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/a01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/a02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/a03.jpg'
          ],
          txt:
            '上部に遠赤グラファイトヒーターを使っているのが特徴です。もともと暖房のヒーターの技術だった遠赤グラファイトヒーターを転用しています。設定温度は100～280℃ですが、付属のグリルパンを使うと、最高温度330℃に達するオーブン料理も可能です。\n温度とタイマーだけのシンプル操作なので、直感的に使えますが、調理ごとに『何℃で何分焼くのか』を覚えるか調べるかしないといけないのが難点です。'
        },
        {
          isBackground: false,
          name: '象印 オーブントースター こんがり倶楽部 ET-GM30-BZ',
          href: '/ec/pd/4974305215000',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/z01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/z02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/z03.jpg'
          ],
          txt:
            'テレビ番組で「BALMUDAより美味しい？」と紹介され大ヒットしたトースターが象印のET-GM30です。今回比較したトースターで、最も多い5本のヒーターを搭載しています。\nマイコン制御を使ってさまざまなメニューを最適な温度で焼いてくれます。肝心のトーストは「サクふわトースト」メニューで焼きました。中温で焼く時間を設けて外側はサクッと、中はふんわり仕上がるのが特徴です。また焼き色の選択も可能です。そのせいでトーストに約4分半と時間がかかるのがネックです。\n代わりにタイマー設定可能な時間が最長30分と長めですので、調理の幅が広がります。'
        },
        {
          isBackground: true,
          name: 'siroca プレミアムオーブントースター すばやき ST-2A251（ST-4A251は4枚焼き）',
          href: '/ec/list?narrow18=0&keyword=ST-4A251&category=&index=&searchbox=1&q=ST-4A251&path=',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/si01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/si02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/si03.jpg'
          ],
          txt:
            'コンベクション機能を搭載しており、なんとわずか1分程度でトーストを焼き上げます。\n素早く焼くため、トーストの内部の水分が残り、美味しくいただけるのだそうです。\nコンベクションの有無もダイヤルで選択可能、温度とタイマーもダイヤル式なので、アラジンと同じく、最適な温度と時間を覚えないといけないのがマイナス点です。'
        },
        {
          isBackground: false,
          name: 'シャープ ウォーターオーブン専用機ヘルシオ グリエ AX-H2',
          href: '/ec/list?narrow18=0&keyword=AX-H2&category=&index=&searchbox=1&q=AX-H2&path=',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/s01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/s02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/s03.jpg'
          ],
          txt:
            '唯一無二のウォーターオーブンです。上部に水を入れるタンクがあり、これを使った水蒸気をさらに熱した過熱水蒸気を使って焼くオーブンです。惣菜の温めや料理にも活躍するタイプで、油や塩分が気になる方におすすめのオーブンです。\n一方で過熱水蒸気パワーを3段階に選べるのですが、これらがいったい何℃になっているのか明示されていないので、一般的なレシピに書いてある温度設定を、どのパワーで達成できるのか判別できないのがフレンドリーではないポイントです。'
        },
        {
          isBackground: true,
          name: 'タイガー スチームコンベクションオーブン GRAND X やきたて KAX-X130',
          href: '/ec/pd/4904710421369',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/tg01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/tg02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/tg03.jpg'
          ],
          txt:
            '20種類のオートメニューに70mlの大容量スチームコンベンションオーブン、焼き色調整に30分ロングタイマーと、機能てんこ盛りのオーブントースターです。\n最高設定温度は250℃と、やや低いものの、多機能なのにオートメニューで簡単という便利さが備わった1台です。\n本体サイズが今回の6機種の中で一番大きいので、その点は要注意です。'
        },
        {
          isBackground: false,
          name: 'パナソニック コンパクトオーブン NB-DT51',
          href: '/ec/pd/4549077887047',
          leftImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/p01.jpg',
          rightImages: [
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/p02.jpg',
            process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/p03.jpg'
          ],
          txt:
            '遠赤外線だけではなく近赤外線で、食材の内側も温める「遠近赤外線ダブル加熱」が特徴です。\nオートメニューも7つあります。「ドライ機能」を使えば、湿気ってしまったスナック菓子やせんべい、焼海苔（味海苔はダメ）を、パリッと復活させられるのもポイントです。\n本体が小さいので、ピザなど大きいものを焼くのは苦手です。'
        }
      ],
      //食べ比べの結果発表♪リスト
      resultAnnouncementList: [
        {
          isBackground: false,
          name: 'アラジン グラファイト グリル&トースター CAT-G13A（AGT-G13Aも同等機種です）',
          href: '/ec/list?narrow18=0&keyword=AGT-G13&category=&index=&searchbox=1&q=AGT-G13&path=',
          productImage: 'https://shopimg.kitamura.jp/images/pd/d9b/9be/5b2/822/a06/f6b/f73/8d2/530/33f/dbg/uep/9bz/x/L.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk01.png',
            crispy: 4,
            soft: 6,
            delicious: 12
          },
          txt: '評価項目には入っていませんが、「見た目がかわいい」との声が多数。\nキッチンではなくダイニングに置いておいてもよさそうですよね。'
        },
        {
          isBackground: true,
          name: '象印 オーブントースター こんがり倶楽部 ET-GM30-BZ（AGT-G13Aも同等機種です）',
          href: '/ec/pd/4974305215000',
          productImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/ET-GM30.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk02.png',
            crispy: 12,
            soft: 8,
            delicious: 12
          },
          txt:
            '記事執筆段階の当店販売価格が、一番お求めやすいにもかかわらず「美味しい」の平均点はトップでした。\n表面がサクサクで、中がしっとりという意見が多かったです。'
        },
        {
          isBackground: false,
          name: 'siroca プレミアムオーブントースター すばやき ST-2A251（ST-4A251は4枚焼き）',
          href: '/ec/list?narrow18=0&keyword=ST-4A251&category=&index=&searchbox=1&q=ST-4A251&path=',
          productImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/ST-4A251.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk03.png',
            crispy: 10,
            soft: 7,
            delicious: 12
          },
          txt: 'やはり焼き上げまでのスピードに驚いていました。\n全体のバランスがいいという意見がありました。'
        },
        {
          isBackground: true,
          name: 'シャープ ウォーターオーブン専用機ヘルシオ グリエ AX-H2',
          href: '/ec/list?narrow18=0&keyword=AX-H2&category=&index=&searchbox=1&q=AX-H2&path=',
          productImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/AX-H2.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk04.png',
            crispy: 6,
            soft: 11,
            delicious: 12
          },
          txt: '1番ふっくら・もっちり焼き上がっていたという回答が多数でした。\n水で焼く効果でしょうか？ 甘さを感じたという意見もありました。'
        },
        {
          isBackground: false,
          name: 'タイガー スチームコンベクションオーブン GRAND X やきたて KAX-X130',
          href: '/ec/pd/4904710421369',
          productImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/KAX-X130.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk05.png',
            crispy: 27,
            soft: 2,
            delicious: 10
          },
          txt: '圧倒的なサクサク評価！\n表面がパリッと焼けていたのが、はっきりわかる焼き上がりでした。'
        },
        {
          isBackground: true,
          name: 'パナソニック コンパクトオーブン NB-DT51',
          href: '/ec/pd/4549077887047',
          productImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/NB-DT51.jpg',
          fivePointsOutOfFiveList: {
            src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/toaster-oven/kk06.png',
            crispy: 13,
            soft: 15,
            delicious: 16
          },
          txt:
            '『ふんわり』と『美味しい』の最高評価を、最も集めたのがパナソニック。以前に<a href="/ec/special/kaden/rice-cooker_bestbuy">炊飯器特集</a>で、食べ比べをしたときもそうでしたが、パナソニック製品は好き嫌いがわかれますね…。<br />ポテトチップスの「ドライ」モードを使ったリベイクも試食しましたが、温かいポテトチップスって、すっごく美味しいですね！'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/rice-cooker_bestbuy',
          img: 'https://shop.kitamura.jp/images/banner/2401.jpg',
          alt: 'おすすめ炊飯器特集'
        },
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shop.kitamura.jp/images/banner/1921.gif',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: '/ec/special/kaden/bento',
          img: 'https://shop.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当生活におすすめ商品'
        },
        {
          href: '/ec/special/kaden/home-party',
          img: 'https://shop.kitamura.jp/images/banner/3770.gif',
          alt: 'キッチン家電特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ]
    });

    const linkToOtherWindow = (url: string | undefined) => {
      window.open(url, '_blank');
    };

    return {
      ...toRefs(state),
      noimage,
      linkToOtherWindow
    };
  }
});
