





































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import Characteristic from '@/components/common/special/characteristic.vue';
import SubText from '@/components/common/special/subtext.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'sel35f14gm',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    characteristic: Characteristic,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    shasha: ShaSha,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー FE 35mm F1.4 GM [SEL35F14GM] | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー FE 35mm F1.4 GM [SEL35F14GM] 好評発売中！！比較や、価格・スペック・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      characteristicList: [
        {
          subtitle: '最新の光学設計とG Masterの高い設計基準により、高解像度と小型・軽量を両立',
          contents: [
            '高性能レンズと新開発「ナノARコーティングII」で画面全域での高い解像性能とヌケの良いクリアな画質を実現',
            '小型・軽量設計で、ジンバルやグリップを使用した動画撮影にも最適'
          ]
        },
        {
          subtitle: '高い近接撮影能力と美しいぼけ味で印象的な表現が可能',
          contents: [
            'G Masterならではの柔らかく自然で美しいぼけが表現可能',
            '最大撮影倍率0.23倍（MF時は0.26倍）の高い近接撮影能力で背景を大きくぼかした印象的な撮影が可能'
          ]
        },
        {
          subtitle: '高レベルな描写をサポートするAF性能とプロの要望に応える操作性・信頼性',
          contents: [
            'XDリニアモーターを2基搭し高速・高精度・高追随かつ静粛なAF駆動を実現',
            '動画撮影時も被写体を逃さず捉え続ける高いAF性能を発揮',
            'カスタマイズ可能なフォーカスホールドボタン、フォーカスモードスイッチ、クリック切り換えもできる絞りリングなど高い操作性を実現'
          ]
        }
      ],
      expensiveTradeInList: {
        name: 'ソニー Distagon T* FE 35mm F1.4 ZA [SEL35F14Z]',
        href:
          'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4548736001954&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&pattern=2',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/f95/250/f2c/a74/8df/29e/89b/04d/fd9/3ad/a1f/xdd/2s8/3/L.jpg',
        txt: 'ソニー FE 35mm F1.4 GM [SEL35F14GM]',
        tradeInImg: '/ec/images2/special/camera/feature/sony/sel35f14gm/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー FE 35mm F1.4 GM [SEL35F14GM]',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736123151', '4548736001954'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
