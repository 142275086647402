











































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import UserService from '@/logic/user.service';
import MyPageLayout from '../common/my-page-layout.vue';
import BackButton from '@/components/common/back-button.vue';
import { DeliveryAddressItem } from '@/types/address-list';

export default Vue.extend({
  name: 'delivery-target-list',
  components: {
    'section-loading': SectionLoading,
    'my-page-layout': MyPageLayout,
    'back-button': BackButton
  },
  setup: (props, context) => {
    const { authorizer, confirmDialogStore, errorStore } = context.root.$store;
    const state = reactive({
      // 画面タイトル
      title: 'お届け先確認・変更',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: 'お届け先確認・変更', linkUrl: '' }
      ],
      // ロード状態
      loaded: {
        address: false
      },
      // お届け先
      addressList: [] as Array<DeliveryAddressItem>
    });

    /**
     * お届け先リストを取得
     */
    const fetchAddress = async () => {
      try {
        const resultAddress = await UserService.fetchAddress();
        state.addressList = resultAddress.addrInfo;
      } catch (error) {
        console.error(error);
        state.addressList = [] as Array<DeliveryAddressItem>;
      } finally {
        state.loaded.address = true;
      }
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        fetchAddress();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchAddress();
      }
    );

    // お届け先を削除
    const removeDeliveryTarget = async (address: DeliveryAddressItem) => {
      // 削除確認
      const confirm = await confirmDialogStore.open('削除してよろしいですか？', '削除');
      if (!confirm) {
        return;
      }

      try {
        await UserService.deleteAddress(address.delivAddressId);
        await fetchAddress();
      } catch (error) {
        console.error(error);
      }
    };

    return {
      ...toRefs(state),
      removeDeliveryTarget
    };
  }
});
