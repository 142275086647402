























































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'xh2s',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    urlcopy: Urlcopy,
    subText: SubText,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    splitContent: SplitContent,
    characteristic: Characteristic,
    naviBtn: NaviBtn,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'フジフイルム X-H2S  | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'フジフイルム X-H2S  価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/6/1 10:00',
      fanValidTo: '2022/6/3 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/06/03 10:00',
      reserveValidTo: '2022/07/13 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/07/14 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      commonCharacteristicList: [
        {
          subtitle: 'Xシリーズ史上最高の高速連写を実現。決定的瞬間を逃すことなく写しとめる',
          contents: [
            '読み出し速度を約4倍に高速化した新開発約2616万画素APS-Cサイズセンサー「X-Trans CMOS 5 HS」を搭載。AF/AE追従が可能な40コマ/秒のブラックアウトフリー連写で決定的瞬間を逃さない',
            '大容量のバッファーメモリーの搭載で連写時の連続記録枚数が大幅に増加。電子シャッター使用時、JPEG画像：30コマ/秒、RAWファイル：20コマ/秒の高速連写で1,000枚以上の写真を記録可能',
            '一眼レフのファインダーと比べても遜色のない576万ドットの高倍率・高精細のEVFを搭載。瞳の位置ずれにより発生する像流れや歪みを抑制し、高い視認性を実現。さらに、表示フレームレート約120フレーム/秒の滑らかさで、被写体の速い動きを正確に把握が可能'
          ]
        },
        {
          subtitle: 'アルゴリズムの刷新などによりAF性能が大幅に向上。今まで以上に被写体を正確に捕捉',
          contents: [
            '「X-Trans CMOS 5 HS」「X-Processor 5」の搭載とAFの予測アルゴリズムの刷新により、動体追従性能やゾーンAF、コントラストが低い環境下でのAFの精度が大幅に向上。スポーツ選手や野生動物など動き続ける被写体を正確に捕捉が可能',
            'ディープラーニング技術を用いて開発した被写体検出AFを搭載。新たに動物・鳥・車・バイク・自転車・飛行機・電車をAIで検出することが可能。今まで以上にシャッターチャンスや構図に集中して撮影が可能',
            'AFを作動させた後に手動でピントを微調整できるAF＋MF機能を静止画撮影だけでなく動画撮影にも適用。素早く動き回る被写体でもより正確な追従が可能な動画撮影を実現'
          ]
        },
        {
          subtitle: '充実した動画性能を搭載。多様な映像制作ニーズに対応',
          contents: [
            '6.2K/30Pや4K/120P 4:2:2 10bitの映像記録に対応。4K/120Pのハイスピード動画撮影も実現し、素早く動く被写体の一瞬のアクションも、高精細で滑らかなスローモーション映像で撮影可能',
            '動画撮影時のセンサー読み出し速度を1/180秒まで高速化。ローリングシャッター歪みを抑制することで、動きのある被写体を違和感なく表現',
            '撮影からポストプロダクションまでワークフロー全体を効率化・省力化を実現する「ProRes 422 HQ」「ProRes 422」「ProRes 422 LT」の3つのApple ProResコーデックに新たに対応。ProRes撮影時には、「ProRes 422 Proxy」などのプロキシ撮影も可能',
            'ダイナミックレンジを14+stopに広げた「F-Log2」を新たに搭載。より階調豊かな映像を記録することが可能となり、ポストプロダクションでの自由度が大きく向上',
            '新規放熱構造を採用し、動画撮影の記録時間を大幅に増加。4K/60Pの映像を240分記録可能。別売りアクセサリーの冷却ファン「FAN-001」を装着することで、高温環境下でも長時間の撮影を実現'
          ]
        },
        {
          subtitle: '快適な操作性とあらゆる場所での撮影を可能にする高剛性を実現',
          contents: [
            '被写体がアクティブに動くスポーツシーンや夜景などの暗いシーンでも快適な手持ち撮影を可能にする5軸・最大7.0段の新開発のボディ内手ブレ補正機構を搭載',
            'さまざまな撮影シーンで設定内容の確認が可能な天面の液晶画面や、プロの過酷な撮影にも対応する高剛性ボディなど基本デザインはそのままに、シャッター半押し時のシャッターストロークやAF ONボタンの押し心地の調整、各ボタンの材質の見直しを行い、ボタンの操作性を向上。さらに、大型グリップのデザインをより握りやすい形状に刷新したことで、高いホールド性を発揮し、快適な撮影を実現',
            '撮影からポストプロダクションまでワークフロー全体を効率化・省力化を実現する「ProRes 422 HQ」「ProRes 422」「ProRes 422 LT」の3つのApple ProResコーデックに新たに対応。ProRes撮影時には、「ProRes 422 Proxy」などのプロキシ撮影も可能',
            '動画撮影ボタンの独立配置や、モニターを自在なアングルに調整できるバリアングル構造の162万ドット背面液晶モニターの搭載などにより、意図どおりの動画撮影を実現',
            'CFexpress Type BカードとSDカードに対応したデュアルスロットを採用。「X-H2S」の高速連写性能や動画性能を余すことなく引き出すことが可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],

      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'フジフイルム X-H2S ',
          disabled: true
        }
      ],

      // ↓ ---- 高値下取り ----
      reserveExpensiveTradeInList: {
        name: 'フジフイルム X-T3',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%83%95%E3%82%B8%E3%83%95%E3%82%A4%E3%83%AB%E3%83%A0%20X-T3%20%20&n4c=%E6%9C%89&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&sort=add_date&limit=40',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/3c8/66c/dd0/797/2f8/bbd/aff/d95/42a/36c/c79/a5k/tps/l/L.jpg',
        txt: 'フジフイルム X-H2S  ',
        tradeInImg: '/ec/images2/special/camera/feature/fujifilm/xh2s/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'フジフイルム X-T3',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?keyword=%E3%83%95%E3%82%B8%E3%83%95%E3%82%A4%E3%83%AB%E3%83%A0%20X-T3%20%20&n4c=%E6%9C%89&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&sort=add_date&limit=40'
          },
          {
            name: 'フジフイルム X-T4',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?n4c=%E6%9C%89&category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&keyword=X-T4&sort=add_date&limit=40'
          },
          {
            name: 'フジフイルム X-H1 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410365801'
          }
        ]
      },
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736140264', '4548736100664', '4548736109520'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品
      mainProductJanCode: ['4547410469172'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410469172', '4547410427875', '4549292185430', '4545350053864'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
