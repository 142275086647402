












































































































































































































































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'x-s20',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    naviBtn: NaviBtn,
    shasha: ShaSha,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'フジフイルム X-S20| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'フジフイルム X-S20 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2023/05/24 19:00',
      fanValidTo: '2023/05/30 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2023/05/30 10:00',
      reserveValidTo: '2023/06/28 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/06/29 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'フジフイルム X-S10',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410440348',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/541/1c7/f24/db4/9fb/fdd/4f5/d4d/71f/a43/d2w/gz3/b37/g/L.jpg',
        txt: 'フジフイルム X-S20',
        tradeInImg: '/ec/images2/special/camera/feature/fujifilm/x-s20/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'フジフイルム X-S10', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410440348' },
          { name: 'フジフイルム X-T20 ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410343533' },
          { name: 'フジフイルム X-T30', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4547410399875' }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '約2610万画素センサーと最新の高速画像処理エンジンを搭載し、高画質・高性能AF などを実現',
          contents: [
            '裏面照射型約2610 万画素「X-Trans™ CMOS 4」センサーと最新の高速画像処理エンジン「XProcessor 5」を搭載。従来機より消費電力を抑えながらも、高画質撮影が可能',
            '従来の顔・瞳AFはそのままに、ディープラーニング技術を用いて開発した被写体検出AFを搭載。新たに動物・鳥・車・バイク・自転車・飛行機・電車・昆虫・ドローンをAI で検出',
            'シーンを判別し最適な撮影設定を自動で選択する「AUTOモード」に、新たに「AUTO被写体検出機能」を追加。自動で被写体を検出し、ピントを合わせたまま追従するため、簡単にハイクオリティな静止画・動画を撮影することが可能',
            '多彩な色調を可能とする「フィルムシミュレーション」に、高彩度かつ柔らかい階調表現を特⾧とする「ノスタルジックネガ」など全19種類のモードを搭載。被写体やシーンにあわせて写真フィルムを選ぶ感覚で多彩な色表現が可能'
          ]
        },
        {
          subtitle: '小型軽量ボディに大容量バッテリー・高性能手ブレ補正機能を搭載し、快適な撮影をサポート',
          contents: [
            '「X-S シリーズ」の特⾧である、ホールド性の高い大型グリップを採用しつつ、質量約491g の小型軽量ボディを実現',
            '新たに採用した大容量バッテリー「NP-W235」により、従来機の2 倍以上となる約800 枚の撮影可能枚数を実現',
            '5軸・最大7.0 段のボディ内手ブレ補正機能を新たに搭載。夜景などの暗い撮影シーンでも、快適な手持ち撮影が可能',
            'ファインダー倍率0.62 倍、236 万ドットのEVF を搭載しました。また、モニターを自在なアングルに調整できる「バリアングル構造」を採用した184 万ドットの背面液晶モニターを搭載'
          ]
        },
        {
          subtitle: '6.2K/30P の映像記録などに対応し、充実の動画撮影機能を実現',
          contents: [
            '6.2K/30P 4:2:2 10bit でのカメラ内SD カード記録が可能。4K/60P や1080/240P にも対応し、充実の動画撮影機能を搭載',
            'ダイナミックレンジを13+stop に広げた「F-Log2」を搭載。階調豊かな映像を記録でき、ポストプロダクションでの自由度が大きく向上',
            'HDMI 経由で最大6.2K/30P 12bit の動画RAW データ出力が可能。ATOMOS社製モニター「NINJA V +」でApple ProRes RAW、Blackmagic Design 社製モニター「Video Assist 12G」でBlackmagic RAW として記録可能',
            'φ3.5mm のマイク・イヤホンジャックを搭載。小型軽量ボディながら高い拡張性を備えます。また、Vlog 撮影に最適な「Vlog モード」を新たに追加。ボディ天面のモードダイアルを「Vlog」にあわせることで、自分撮りの際もワンタッチで簡単にカメラ設定を変更可能',
            'ソフトウェアを使用することなく、USB-TypeC ケーブルでPCに接続するだけで、すぐにWEBカメラとして使用可能。ライブストリーミングやオンラインミーティングで独自の「フィルムシミュレーション」を使えるほか、接続中に撮影設定の操作も可能'
          ]
        }

      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取'
        },
        {
          href: '/ec/special/reuse/watch',
          img: 'https://shopimg.kitamura.jp/images/banner/9216.jpg',
          alt: '中古時計販売'
        },
        {
          href: '/ec/special/reuse/smartphone',
          img: 'https://shopimg.kitamura.jp/images/banner/9217.jpg',
          alt: '中古スマホ販売'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'フジフイルム X-S20',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410485950', '4547410485981'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410485950', '4547410440348', '4549292185430', '4545350053949'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4547410485950'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
