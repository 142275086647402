
























































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail, UsedProductsSummary } from '@/types/product';
import { ConditionItem } from '@/types/conditions';
import { ProductItem } from '@/types/product-list';
import { NEWER_SORT_LIST } from '@/constants/sort-list';
import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
import { SEARCH_STATE } from '@/constants/search-state';

export default Vue.extend({
  name: 'sample-component1',
  components: {
    breadcrumbs: Breadcrumbs,
    product: Product
  },
  props: {},
  setup: (props, context) => {
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/10/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α1',
          linkUrl: '',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----

      // ↓ ---- メイン商品 ----
      // 取得するJancode
      mainProductJanCode: '4548736130678',
      // 結果格納用
      mainProduct: {} as ProductDetail,
      // ↑ ---- メイン商品 ----

      // ↓ ---- 比較 ----
      // 取得するJancode
      comparisonJanCodeList: ['4548736130678', '4548736108288', '4549292146851', '4960759903402'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736130678',
        '4548736108288',
        '4548736105546',
        '4548736064539',
        '4548736064621',
        '4548736055704',
        '4548736109520',
        '4548736121249'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----

      // ↓ ---- サンプル：検索APIでの表示 ----
      // 検索条件（項目は、仕様を参照）
      searchCondition: [
        // カテゴリー（valueTextに値を設定）
        {
          paramCode: 'category',
          paramText: '',
          value: '',
          valueText: 'ミラーレス一眼'
        },
        // キーワード
        {
          paramCode: 'keyword',
          paramText: '',
          value: 'EOS',
          valueText: ''
        },
        // メーカー
        {
          paramCode: 's3',
          paramText: '',
          value: 'シルバー',
          valueText: ''
        }
      ] as Array<ConditionItem>,
      // 並び替え (設定値は、NEWER_SORT_LIST参照)
      sort: NEWER_SORT_LIST[0].value,
      // 表示件数 (設定値は、DISPLAY_COUNT_LIST参照)
      displayCount: DISPLAY_COUNT_LIST[0].value,
      // 表示ページ
      page: 1,
      // 価格の表示制御 (option 0:新品・中古、 1:新品、 2:中古)
      displayProductPriceType: SEARCH_STATE.option[1].value,
      // 結果格納用
      results: [] as Array<ProductItem>,
      // 中古価格情報
      usedProductsSummary: [] as Array<UsedProductsSummary>
      // ↑ ---- サンプル：検索APIでの表示 ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts([state.mainProductJanCode], true);
        console.log(mainResult);
        state.mainProduct = mainResult.items[0];
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        console.error(error);
        // メイン商品
        state.mainProduct = {} as ProductDetail;
        // 比較
        state.comparisonDetailList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    /**
     * 検索結果の取得
     */
    const searchNewerProductList = async () => {
      let searchResult;
      // 新品・中古の価格表示情報を検索パラメーターに落とし込み
      state.searchCondition.push({
        paramCode: 'narrow18',
        paramText: '',
        value: state.displayProductPriceType,
        valueText: ''
      });

      // 検索結果取得
      try {
        searchResult = await ProductListService.searchNewItem(state.searchCondition, state.sort, state.displayCount, state.page);
        state.results = searchResult.items;
      } catch (error) {
        console.error(error);
        searchResult = {
          items: [] as Array<ProductItem>,
          wordItems: [] as Array<{
            keyword: string;
            items: Array<ProductItem>;
          }>
        };
      }

      // 新品用中古情報の取得
      if (
        (state.displayProductPriceType === SEARCH_STATE.option[0].value || state.displayProductPriceType === SEARCH_STATE.option[2].value) &&
        searchResult.items.length
      ) {
        const itemIds = [] as Array<string>;
        searchResult.items.forEach((item) => {
          itemIds.push(item.itemid);
        });
        try {
          const usedProductsResult = await ProductService.searchUsedProductsSummary(itemIds);
          state.usedProductsSummary = usedProductsResult.itemInfo;
        } catch (error) {
          console.error(error);
          state.usedProductsSummary = [];
        }
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
      searchNewerProductList();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
