















































































import Vue from 'vue';
import { computed, onMounted, reactive, toRefs, watch } from '@vue/composition-api';
import UserService from '@/logic/user.service';
import QrcodeVue from 'qrcode.vue';

export default Vue.extend({
  name: 'my-page-menu',
  components: {
    'qrcode-vue': QrcodeVue
  },
  props: {
    // API呼び出しが重複しないよう持っている商品の取得が必要かを指定
    requireFetchOwned: {
      type: Boolean,
      default: true
    },
    // API呼び出しが重複しないよう持っているクーポンの取得が必要かを指定
    requireFetchCoupon: {
      type: Boolean,
      default: true
    }
  },
  setup: (props, context) => {
    const { authorizer, mypageMenuStore } = context.root.$store;

    const state = reactive({
      ncBaseUrl: process.env.VUE_APP_NET_CHUKO_URL,
      accountUpdateUrl: process.env.VUE_APP_API_MEMBER_BASE_URL + 'group/account/update.do',
      logoutUrl: '',
      qrcodeDialog: false,
      memberId: ''
    });

    // ログアウトURL
    const urlParams = new URLSearchParams({
      s: process.env.VUE_APP_SITE_ID,
      u: window.location.origin
    });
    const logoutUrl = process.env.VUE_APP_API_MEMBER_BASE_URL + 'sso/logout.html?' + urlParams.toString();
    state.logoutUrl = logoutUrl;

    // 初期化
    const init = (): void => {
      // メンバーIDを取得
      state.memberId = authorizer.user.netMemberId;

      // 持っている商品の数を取得
      if (props.requireFetchOwned) {
        UserService.fetchOwnedList().then((ownedResult) => (mypageMenuStore.ownedCount = ownedResult.count));
      }

      // 持っているクーポンの数を取得（保証延長は特定ユーザーのみ利用できるようにするため、クーポン一覧には表示しない）
      if (props.requireFetchCoupon) {
        UserService.fetchCouponList().then((couponResult) => (mypageMenuStore.couponCount = couponResult.couponInfo.filter((coupon) => coupon.couponType !== 4).length));
      }
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        init();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) init();
      }
    );

    /**
     * 表示する持っている商品の数を取得
     */
    const ownedCount = computed(() => mypageMenuStore.ownedCount);

    /**
     * 表示する持っているクーポンの数を取得
     */
    const couponCount = computed(() => mypageMenuStore.couponCount);

    return {
      ...toRefs(state),
      ownedCount,
      couponCount
    };
  }
});
