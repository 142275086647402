

























































































































































































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
//import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import SplitContent from '@/components/common/special/split-content.vue';
import Notfound from '@/components/common/not-found.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'gh6',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    shasha: ShaSha,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    //priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    notfound: Notfound,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'パナソニック LUMIX DC-GH6 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'パナソニック LUMIX DC-GH6   価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false, //★カテゴリ
      isShow: false,
      // ↓ ---- 予約掲載期間 ----
      fanValidFrom: '2022/2/22 12:00',
      fanValidTo: '2022/3/24 23:59',
      // ↓ ---- 発売掲載期間 ----
      reserveValidFrom: '2022/3/25 00:00',
      reserveValidTo: '2099/3/25 00:00',
      // ↓ ---- キャンペーン掲載期間 ----
      releaseValidFrom: '2022/3/25 10:00',
      releaseValidTo: '2099/5/15 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: true
        },
        {
          path: 'パナソニック LUMIX DC-GH6',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '比較', href: '#hikaku' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '新エンジン・新センサーを採用。プロの要望にもこたえられる新世代システム',
          contents: [
            '新開発2500万画素Pixel Live MOSセンサーの採用により5.7K動画記録や4K120fpsの高速読み出しを実現。静止画では約1億画素のハイレゾ撮影が可能',
            '演算処理能力がフルサイズハイエンドミラーレスS1Hよりも約2倍と大幅に高速化した新世代ヴィーナスエンジンにより画質・AF性能が向上',
            'AF処理速度が約3倍向上。加えて、エッジ部の解像性能や高感度ノイズの改善に寄与。解像感・立体感が改善し、高感度でも自然で立体感のある描写を実現',
            '単一露光で2種類の画像を取得しリアルタイム合成できるダイナミックレンジブースト搭載。動きのある被写体でも使用可能なので、難しい露出条件でも思い通りの静止画表現が可能'
          ]
        },
        {
          subtitle: '音声の記録にもこだわったクリエーターの表現の幅を広げる動画性能',
          contents: [
            '内蔵マイク・外部マイクでの24bit記録かつ、4ch音声収録に対応。内蔵/ 外部マイクの音質差が低減され高品質データをスムーズに編集可能',
            'メーカー初C4K60pの422 10bit記録に対応しグレーディング耐性が向上。また、時間無制限記録により過酷な撮影環境で常用可能'
          ]
        },
        {
          subtitle: '進化した5軸7.5段の動画時でも自然で滑らかな撮影ができる手振れ補正',
          contents: [
            'Gシリーズ史上最高段数の5軸7.5段の手振れ補正を採用。動画時でも自然で滑らかな補正をしてくれるので、様々な手持ち撮影シーンに対応',
            '超高精度ジャイロセンサーの採用とその性能を活かす新アルゴリズムの採用により1億画素手持ちハイレゾ撮影に対応'
          ]
        },
        {
          subtitle: '進化したフルエリアAFとリアルタイム認識AF',
          contents: [
            '新世代エンジンによる高速演算により、AFの基本技術や認識AFの性能が向上。従来の225点から測距点数とカバー率が大幅に向上した315点フルエリアAFで被写体を捕捉',
            '撮影シーンに応じて最適な組み合わせが選択できる、通常のAF方式と認識AFのかけ合わせが可能。さらにAF駆動範囲を限定できるフォーカスリミッターも採用'
          ]
        }
      ],
      mainProductJanCode: ['4549980490693', '4549980490709'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 試し4549292157345----
      comparisonJanCodeList: ['4549980490693', '4549980499771', '4549980624180', '4548736133730'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode 4549980490693 4549980490709
      productJanCodeList: ['4549980490693', '4549980490709'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
