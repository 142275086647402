import { render, staticRenderFns } from "./d6.vue?vue&type=template&id=092589a4&scoped=true&"
import script from "./d6.vue?vue&type=script&lang=ts&"
export * from "./d6.vue?vue&type=script&lang=ts&"
import style0 from "./d6.vue?vue&type=style&index=0&id=092589a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092589a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
