
























































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'product-appeal',
  components: {},
  props: {
    mainProductList: {
      type: Array,
      required: false
    },
    btnColor: {
      type: String,
      default: 'rgba(255, 132, 0, 1)'
    },
    btntxt: {
      type: String,
      default: '商品を見る'
    },
    type: {
      type: Number,
      default: 1
    },
    sm: {
      type: Number,
      required: false,
      default: 12
    },
    maxWidth: {
      type: String,
      required: false,
      default: '254'
    },
    maxHeight: {
      type: String,
      required: false,
      default: '254'
    }
  },
  setup: () => {
    const state = reactive({
    });

    return {
      formatPrice,
      ...toRefs(state)
    };
  }
});
