

















































































































































































import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import { ORDER_DETAIL_TYPES } from '@/constants//mypage-order-detail-types';
import { noimage, convertToUsedStatesText } from '@/logic/utils';
import { BUY_DELIVERY_ORDER_STEP, BUY_SHOP_ORDER_STEP } from '@/constants/mypage-order-step';
import UserService from '@/logic/user.service';
import { APPLIANCE_OPTION_CONFIG_MAP } from '@/constants/cart-options';

export default Vue.extend({
  name: 'order-product',
  props: {
    type: {
      type: Number,
      required: true
    },
    status: {
      type: [String, Number],
      required: false,
      default: ''
    },
    product: {
      type: Object,
      required: true
    }
  },
  setup: (props) => {
    const state = reactive({});

    const completeCheck = computed(() => {
      let targetStatus;
      let completedStatus;
      if (props.type === ORDER_DETAIL_TYPES.BUY_DELIVERY) {
        targetStatus = UserService.convertStepStatus(BUY_DELIVERY_ORDER_STEP, `${props.status}`);
        completedStatus = BUY_DELIVERY_ORDER_STEP.find((step) => step.text === '発送済');
      } else {
        targetStatus = UserService.convertStepStatus(BUY_SHOP_ORDER_STEP, `${props.status}`);
        completedStatus = BUY_SHOP_ORDER_STEP.find((step) => step.text === 'お渡し完了');
      }
      return targetStatus.no === completedStatus?.no ? true : false;
    });

    return {
      ...toRefs(state),
      noimage,
      ORDER_DETAIL_TYPES,
      completeCheck,
      convertToUsedStatesText,
      APPLIANCE_OPTION_CONFIG_MAP
    };
  }
});
