







































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sportsday-photo-knowhow-1',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'パパママ応援運動会特集 運動会撮影のコツ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '運動会特集！当日落ち着いて写真撮影するために事前に準備をしておきましょう！抑えておきたい「撮影のコツ・ポイント」をご紹介');

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'パパママ応援運動会特集 トップ',
          linkUrl: '/ec/special/general/sportsday',
          disabled: false
        },
        {
          path: 'パパ・ママ応援運動会特集！運動会撮影のコツ',
          disabled: true
        }
      ],
      recommendedAlbumList: [
        {
          href: 'https://www.kitamura-print.com/print/',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_print_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_print_off.gif'
        },
        {
          href: 'https://photobook.kitamura.jp/ring/',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_softcov_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_softcov_off.gif'
        },
        {
          href: 'https://photobook.kitamura.jp/yearalbum/',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_year_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/sportsday/photo-knowhow-1/btn_year_off.gif'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/tripod',
          img: 'https://shopimg.kitamura.jp/images/banner/3253.gif',
          alt: 'おすすめ三脚の選び方'
        },
        {
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/bento/',
          img: 'https://shopimg.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当生活におすすめ商品キッチン商品大集合！'
        },
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shopimg.kitamura.jp/images/banner/1921.jpg',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文のご案内'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4977187330878',
        '4975981521294',
        '4977187331745',
        '4977187325935',
        '4977187326031',
        '4902520009609',
        '4977187330045',
        '4977187330663',
        '4977187403510',
        '4930381440033'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
