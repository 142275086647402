





















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'specifications-examples-btn',
  components: {},
  props: {
    btntxt: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'black darken-4'
    },
    height: {
      type: String,
      default: '90px'
    }
  },
  setup: () => {
    const state = reactive({});

    function linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }

    return {
      ...toRefs(state),
      linkToOtherWindow
    };
  }
});
