


































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
//import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
//import { ConditionItem } from '@/types/conditions';
//import { ProductItem } from '@/types/product-list';
//import { NEWER_SORT_LIST } from '@/constants/sort-list';
//import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
//import { SEARCH_STATE } from '@/constants/search-state';
export default Vue.extend({
  name: 'air-purifier',
  components: {
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = '担当者イチオシ！花粉症対策におすすめの空気清浄機特集｜カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '2024年最新情報！カメラのキタムラ ネットショップ家電担当者おすすめ！花粉シーズンに最適な空気清浄機で快適な日々を、こだわりの空気浄化で健康的で爽やかな空間を手に入れましょう。花粉からくる不快な症状を軽減し、清々しい毎日をお楽しみください。'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '花粉対策特集｜おすすめ空気清浄機',
          linkUrl: '/ec/special/kaden/air-purifier_fair',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      independentStudyList: [
        {
          href: '#a01',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_01_on.png',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_01_off.png',
          alt: 'おすすめ空気清浄機'
        },
        {
          href: '#a02',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_04_on.png',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_04_off.png',
          alt: 'コンパクトなボディに大風量 加湿機能なしの空気清浄機'
        },
        {
          href: '#a03',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_03_on.png',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/air-purifier_fair/navi_03_off.png',
          alt: '空気清浄機の豆知識'
        }
      ],
      selectedItem: 0,
      items: [
        { text: 'デザイン', icon: 'fa-check', link: '#hkk1' },
        { text: 'フィルター・加湿機能', icon: 'fa-check', link: '#hkk2' },
        { text: 'お手入れ方法', icon: 'fa-check', link: '#hkk3' },
        { text: '価格・コスト', icon: 'fa-check', link: '#hkk4' },
        { text: '特有の機能', icon: 'fa-check', link: '#hkk5' },
        { text: '商品一覧', icon: 'fa-check', link: '#item' }
      ],
      // ↓ ---- メイン商品 ----
      // 取得するJancode 4549292157345 仮 '4974019179940', '4974019179919', '4573535311928', '4974019182605 ', '4573535311898'
      mainProductJanCode: ['4974019179940', '4974019179919', '4573535311928', '4974019182605', '4573535311898'],
      // 結果格納用
      mainProductList: [] as Array<ProductDetail>,
      // ↑ ---- メイン商品 ----
      // ↓ ---- 価格・人気アクセサリー ----'4974019102863'
      // 取得するJancode
      productJanCodeList: ['4549292157345'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
