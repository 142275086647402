








































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';

export default Vue.extend({
  name: 'highly-recommended-2015-0612',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    titleImage: TitleImage
  },
  setup: () => {
    document.title = '最大90%の復活率！水没したスマホの復活剤『リバイバフォン』 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '水没したスマホを救うリバイバフォンをご紹介。海や川、飲み会の席や突然の豪雨など、注意していても起きてしまったスマホの水没。リバイバフォンに一縷の望みをかけてみてはいかがでしょうか？')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '最大90%の復活率！水没したスマホの復活剤『リバイバフォン』 | スタッフのイチオシ',
          disabled: true
        }
      ],
      howToUseList: ['速やかにモバイル機器の電源を落としてください。 絶対に充電をしないでください。', 'ボトルから溢さないように付属のパウチ袋に注いでください', 'モバイル機器を液体が注がれたパウチ袋に入れて口を密封して、7分間浸してください。', 'パウチ袋からモバイル機器を取り出し、付属の乾燥用トレイの上で24時間自然乾燥させてください。', '24時間経過したら、モバイル機器の電源を入れてください。', 'もし、モバイル機器が復活しない場合は、さらに24時間待ち再び電源を入れてください。']
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
