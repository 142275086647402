












































































































































































































































































































































































































































































import Vue from 'vue';
import SubText from '@/components/common/special/subtext.vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'vuetify',
  components: {
    subText: SubText,
  },
  setup: () => {
    const state = reactive({
      // v-bottom-navigation用
      value: 'recent',
      // v-bottom-sheet用
      sheet: false,
      // v-carousel用
      model: 0,
      colors: [
        'info',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      // v-dialog用
      dialog: false,
      // v-checkbox用
      checkbox: true,
      // v-form用
      valid: false,
      firstname: '',
      lastname: '',
      nameRules: [
        (v: any) => !!v || '名前が必要です',
        (v: string | any[]) => v.length <= 10 || '名前は10文字未満である必要があります',
      ],
      email: '',
      emailRules: [
        (v: any) => !!v || 'メールが必要です',
        (v: string) => /.+@.+/.test(v) || 'メール形式が違います',
      ],
      // v-overflow-btn用
      dropdownEdit: [
        { text: '100%' },
        { text: '75%' },
        { text: '50%' },
        { text: '25%' },
        { text: '0%' },
      ],
      // v-radio用
      radioGroup: 1,
      // v-select用
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      // v-switch用
      switch1: true,
      // v-list用
      selectedItem: 1,
      listItems: [
        { text: 'Real-Time', icon: 'far fa-clock' },
        { text: 'Audience', icon: 'fas fa-user-circle' },
        { text: 'Conversions', icon: 'fas fa-flag' },
      ],
      // v-menu用
      menuItems: [
        { title: 'メニュー１' },
        { title: 'メニュー２' },
        { title: 'メニュー３' },
        { title: 'メニュー４' },
      ],
      // v-overlay用
      absolute: true,
      overlay: false,
      // v-pagination用
      page: 1,
      // v-rating用
      rating: 4,
      // v-snackbar用
      snackbar: false,
      text: `スナックバー`,
      // v-treeview用
      treeItems: [
        {
          id: 1,
          name: 'Applications :',
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' },
          ],
        },
        {
          id: 5,
          name: 'Documents :',
          children: [
            {
              id: 6,
              name: 'vuetify :',
              children: [
                {
                  id: 7,
                  name: 'src :',
                  children: [
                    { id: 8, name: 'index : ts' },
                    { id: 9, name: 'bootstrap : ts' },
                  ],
                },
              ],
            },
            {
              id: 10,
              name: 'material2 :',
              children: [
                {
                  id: 11,
                  name: 'src :',
                  children: [
                    { id: 12, name: 'v-btn : ts' },
                    { id: 13, name: 'v-card : ts' },
                    { id: 14, name: 'v-window : ts' },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 15,
          name: 'Downloads :',
          children: [
            { id: 16, name: 'October : pdf' },
            { id: 17, name: 'November : pdf' },
            { id: 18, name: 'Tutorial : html' },
          ],
        },
        {
          id: 19,
          name: 'Videos :',
          children: [
            {
              id: 20,
              name: 'Tutorials :',
              children: [
                { id: 21, name: 'Basic layouts : mp4' },
                { id: 22, name: 'Advanced techniques : mp4' },
                { id: 23, name: 'All about app : dir' },
              ],
            },
            { id: 24, name: 'Intro : mov' },
            { id: 25, name: 'Conference introduction : avi' },
          ],
        },
      ],
    });
    return {
      ...toRefs(state),
    };
  }

});
