



































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'clearancesale',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ブランドウォッチクリアランスセール -カメラのキタムラ 中野サンモール店-';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '憧れのブランドウォッチが手に入るチャンス！多彩なメーカーラインナップをクリアランス価格でご用意しました。店舗受取り・宅配受取り、どちらでもお好きな方法で便利にお受取り可能です。（一部商品を除く）自分へのご褒美や大切な方へのギフトへおすすめのアイテムが揃っております。中古商品はすべて1点物の数量限定となりますので、この機会をお見逃しなく！ブランド時計で、特別な時間を刻みましょう。'
      );

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/11/01 09:00',
      fanValidTo: '2024/11/29 18:00',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/11/01 09:00',
      reserveValidTo: '2024/11/29 18:00',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/11/01 09:00',
      releaseValidTo: '2024/11/29 18:00',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList3: [
        { naviItem: 'ロレックス\nROLEX', href: '#' },
        { naviItem: 'オメガ\nOMEGA', href: '#' },
        { naviItem: 'タグホイヤー\nTAG HEUER', href: '#' },
        { naviItem: 'グランドセイコー\nGrandSeiko', href: '#' }
      ],

      recommendedFeaturesList: [
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        },
        {
          href: '/ec/special/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: 'おると'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '中古時計販売',
          linkUrl: '/ec/special/reuse/watch',
          disabled: false
        },
        {
          path: '中野サンモール店 中古時計販売',
          linkUrl: '/ec/special/watch/kitamuracamera-nakano/',
          disabled: false
        },
        {
          path: 'ブランドウォッチクリアランスセール 中野サンモール店',
          disabled: true
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['', '', ''],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品
      mainProductJanCode: [''],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['', '', '', ''],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
