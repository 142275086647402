























































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'lensfilter-1104',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '風景写真「流れる夏色 魅惑の渓流・滝写真」NDフィルターで水の流れを撮る！  | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'NDフィルターで水の流れを撮る！辰野清が語る「風景写真」の記事も掲載しております。カメラのキタムラおすすめのND（減光）フィルターを掲載しておりますので、減光フィルターをお探しの方はぜひご覧ください。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '風景写真「流れる夏色 魅惑の渓流・滝写真」NDフィルターで水の流れを撮る！',
          disabled: true
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '話効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/general/photocon-learn',
          img: 'https://shopimg.kitamura.jp/images/banner/2180.gif',
          alt: 'フォトコンテスト受賞作品から学ぶ'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
