

















































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'nikon-arcrest-nd',
  components: {
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ニコン ARCRESTシリーズ ND(減光)フィルターのおすすめポイントをご紹介！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン ARCRESTシリーズから登場したND（減光）フィルターは、高性能なレンズに最適です。レンズ描写を保護しつつ、強い光の中でもスローシャッター撮影を可能にします。ニコン ARCREST NDフィルターのおすすめポイントをご紹介しておりますのでぜひご覧ください。'
      );

    const state = reactive({
      visible: false,
      index: 0, // default: 0 拡大時表示画像
      images: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_01.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_04.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst01.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/performance_02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/performance_03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/image07-01.jpg'
      ],
      resolvingPowerList: [
        {
          txt: '解像力1',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_01.jpg'
        },
        {
          txt: '解像力2',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_02.jpg'
        },
        {
          txt: '解像力3',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_03.jpg'
        },
        {
          txt: '解像力4',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/im_04.jpg'
        }
      ],
      ghostOccurrenceComparisonList: [
        {
          txt: 'ARCREST NDフィルター使用撮影画像',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst01.jpg'
        },
        {
          txt: 'A社NDフィルター使用撮影画像',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst02.jpg'
        },
        {
          txt: 'B社NDフィルター使用撮影画像',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/gst03.jpg'
        }
      ],
      cortList: [
        {
          txt: 'ARCREST ND FILTER',
          new: '【NEW】帯電防止コート',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/performance_02.jpg'
        },
        {
          txt: '他社製 NDフィルター',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest-nd/performance_03.jpg'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest',
          img: 'https://shopimg.kitamura.jp/images/banner/4162.jpg',
          alt: 'ニコン ARCREST 高性能レンズ保護フィルター'
        },
        {
          href: '/ec/special/sale-fair/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    // 画像を拡大して表示する
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    // 拡大画像を閉じる
    const handleHide = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
