













































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'coolshot',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ゴルフのスコアアップに役立つ！ニコン COOLSHOT特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ゴルフのスコアアップにも役立ちます！ニコンの携帯型レーザー距離計COOLSHOTの使い方やおすすめポイントをまとめました。各機種の製品比較も掲載しておりますのでぜひ選定にお役立てください。'
      );

    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'ニコン レーザー距離計 COOLSHOT特集',
          linkUrl: '/ec/special/camera/coolshot',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      visible: false,
      index: 0, // default: 0 拡大時表示画像
      images: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/coolshot/im_01.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/coolshot/im_02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/coolshot/im_03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/coolshot/im_04.jpg'
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文承ります'
        },
        {
          href: '/ec/special/sale-fair/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    // 画像を拡大して表示する
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    // 拡大画像を閉じる
    const handleHide = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
