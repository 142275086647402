





























































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExpensivePurchasesAndShoppingCreditImage from '@/components/common/special/expensivePurchasesAndShoppingCreditImage.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sony-a9',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    naviBtn: NaviBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    expensivePurchasesAndShoppingCreditImage: ExpensivePurchasesAndShoppingCreditImage,
    splitContent: SplitContent,
    categorymenulist: Categorymenulist
  },

  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'SONY フルサイズミラーレス一眼フラッグシップ 新製品 α9[ILCE-9] | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー フルサイズミラーレス新製品｢α9｣特集。ライバル機種との比較や価格・スペック・お得な情報をチェック！カメラ専門店カメラのキタムラのショッピングサイトにおまかせください。'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/a9/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/a9/sp_img_top.jpg',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature//sony/a9',
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '光を捉え続ける革新的な新イメージセンサーにより新次元の高速性能を実現',
          contents: [
            '読み出しスピードが従来機比20倍以上に高速化した、世界初メモリー内蔵35mmフルサイズ積層型CMOSイメージセンサーを搭載',
            '最高20コマ/秒の映像が途切れることなく連続撮影ができる「ブラックアウトフリー撮影」が可能',
            '大容量のバッファメモリーにより、20コマ/秒連写で圧縮RAW241枚/JPEG362枚の連続撮影が可能',
            '最高1/32000秒の歪みを極限まで抑えるアンチディスト－シ'
          ]
        },
        {
          subtitle: '緊張が高まる静かなシーンでも撮影できるサイレント撮影機能',
          contents: [
            '電子シャッターの使用により、サイレント撮影が可能。',
            '極限まで緊張が高まるスポーツシーンや野生動物の撮影など、シャッター音を伴う撮影が憚られるような場面でも撮影でき、無振動のため解像感への影響がなく高画質に撮影できます。'
          ]
        },
        {
          subtitle: 'プロの撮影現場でも活躍する機動性、操作性、信頼性',
          contents: [
            '673gの小型・軽量ボディに、静止画・動画ともに有効な最高5.0段のボディ内5軸手ブレ補正機構搭載',
            'メディア間の同時記録、振り分け記録、コピーなどが可能なSDカードスロット(UHS-II対応)とSD/MSカードスロット',
            '約369万画素 60fps/120fpsで表示可能な高精細・高輝度 Quad-VGA OLED Tru-Finder'
          ]
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α9',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736063945'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736063945', '4549292146851', '4960759146205'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
