
























































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import { isDebugMode, validationPeriod } from '@/logic/utils';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import dayjs from 'dayjs';
import SpecialProductService from '@/logic/special-product.service';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'eosr3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    watchInVideo: WatchInVideo,
    characteristic: Characteristic,
    expensiveTradeIn: ExpensiveTradeIn,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    kitamuraService: KitamuraService,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    priceAndPurchase: PriceAndPurchase,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (props, context) => {
    const query = context.root.$route.query;
    const state = reactive({
      // ↓ ---- 煽り掲載期間 ----
      fanValidFrom: '2021/09/22 00:00',
      fanValidTo: '2021/09/25 23:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2021/09/26 00:00',
      reserveValidTo: '2021/11/26 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2021/11/27 00:00',
      releaseValidTo: '2099/12/31 23:59',
      // ↓ ---- 煽りコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R3',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'キヤノン EOS 5D Mark IV ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292075748&_ga=2.265478278.806725313.1632355099-279164427.1627285160',
        nameImg: '	https://shopimg.kitamura.jp/images/pd/e6a/566/223/13d/322/7d3/e9e/d4c/92d/3ba/b4d/q99/qon/e/L.jpg',
        txt: 'キヤノン EOS R3',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr3/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'キヤノン EOS 5D Mark IV ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292075748' },
          { name: 'キヤノン EOS-1D X MarkIII ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292146851' },
          {
            name: 'キヤノン EOS R6 ボディ',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157345&_ga=2.56772259.806725313.1632355099-279164427.1627285160'
          },
          { name: 'キヤノン EOS R5 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157253' }
        ]
      },
      releaseExpensiveTradeInList: {
        name: 'キヤノン EOS 5D Mark IV ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292075748&_ga=2.265478278.806725313.1632355099-279164427.1627285160',
        nameImg: '	https://shopimg.kitamura.jp/images/pd/e6a/566/223/13d/322/7d3/e9e/d4c/92d/3ba/b4d/q99/qon/e/L.jpg',
        txt: 'キヤノン EOS R3',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr3/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      characteristicList: [
        {
          subtitle: '進化した高速・高感度・高信頼性と直感的に撮影可能な機能を余すことなく搭載',
          contents: [
            '新開発の有効画素数約2410万画素35mmフルサイズ裏面照射積層型CMOSセンサーによる高速読み出しと、映像エンジン「DIGIC X」の高速画像処理により高速連写と高感度を両立。室内や夜景など暗いシーンでもノイズを抑えた動体撮影を実現',
            '電子シャッター撮影時においてAF／AE追従で最高約30コマ/秒の高速連写と、連続撮影中のブラックアウトフリー撮影を実現。加えて、電子シャッターによる像の歪みの大幅な抑制を実現',
            'RFレンズ使用時、レンズ側の光学式手ブレ補正機構と、ボディー内の手ブレ補正機構の協調制御により、世界最高8.0段の手ブレ補正を実現',
            '各画素が撮像と位相差AFの両方の機能を兼ねる「デュアルピクセル CMOS AF AF」を採用。高速・高精度・広範囲なAFを実現',
            'ディープラーニング技術を活用したアルゴリズムの強化で、人物の頭部・瞳の検出機能の向上と新たに胴体の検出を実現。ポートレートや動きが大きいスポーツなどの撮影シーンでも高い被写体追尾性能を発揮。また、人物、動物（犬・猫・鳥）検出に加えて、乗り物（モータースポーツにおける車・バイク）検出にも対応。検出機能の向上を実現',
            '視線入力機能をキヤノンデジタルカメラ初搭載。静止画撮影時、ファインダーを覗いた瞳の動きに合わせてAFフレームを動かすことができるため、ピントを合わせる被写体を素早く切り換えながら快適な撮影が可能'
          ]
        },
        {
          subtitle: '高い信頼性と操作性を踏襲し、過酷なシーンの撮影も安心して行える信頼感',
          contents: [
            '高精細バリアングル液晶モニターを採用しながら、フラッグシップモデル「EOS 1D X Mark III」と同等の防じん・防滴性能を実現',
            '耐衝撃性、耐久性が高く、電磁シールド効果にも優れたマグネシウム合金を外装に採用。高い剛性と「EOS 1D X Mark III」よりも71%程度軽量な質量約1,015gという軽量化も実現',
            '「EOS 1D X Mark III」との共用が可能な「バッテリーパック LP-E19」を採用。CFexpressカード（Type B）とSDメモリーカード（UHS-II対応）のデュアルスロットを搭載',
            '音に関する一括設定を行える「サイレントシャッター機能」を搭載。液晶モニターを常時消灯することもでき、すばやく音と光に配慮した撮影を行うことが可能'
          ]
        },
        {
          subtitle: '多様な制作ニーズに対応する動画性能を搭載',
          contents: [
            '新CMOSセンサーの高速信号読み出しと、映像エンジン「DIGIC X」の高速処理により、6K 60P 動画のRAWデータ（12bit）による内部記録を実現',
            '4K 120P ハイフレームレート動画撮影を実現。滑らかな映像を撮影することができ、4Kの鮮明さはそのままに、きめ細やかで自然なスローモーション効果を得ることが可能',
            '6Kの豊富なデータから、高画質な4K動画を生成可能。クロップ（切り出し）なしのオーバーサンプリングによる 4K 60P 動画を実現し、モアレやノイズを低減した繊細な映像表現が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/general/highly_recommended/2020/4722/',
          img: 'https://shopimg.kitamura.jp/images/banner/7619.png',
          alt: 'スタッフイチオシ！キヤノン RF70-200mm F2.8 L IS USM'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      mainProductJanCode: ['4549292181661'],
      mainProductList: [] as Array<ProductDetail>,
      comparisonJanCodeList: ['4549292181661', '4549292157345', '4549292146851', '4548736130678'],
      comparisonDetailList: [] as Array<ProductDetail>,
      productJanCodeList: [
        '4549292181661',
        '4549292060553',
        '4549292087864',
        '4960999581569',
        '4549292148381',
        '4549292156263',
        '4549292125405',
        '4549292195378',
        '4549292185782',
        '4549292184556',
        '4549292184549',
        '4549292181197',
        '4523052024092',
        '4523052024108',
        '4548736100268',
        '4548736100275'
      ],
      productDetailList: [] as Array<ProductDetail>
    });

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        console.log(response);
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
          document.title = '新製品！キヤノン EOS R3 2021年9月16日10時 予約受付開始 ！ | カメラのキタムラネットショップ';
          document
            .querySelector<any>('meta[name="description"]')
            .setAttribute(
              'content',
              'キヤノン EOS R3 2021年9月16日10時 予約受付開始 ！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
            );
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
          document.title = '新製品！キヤノン EOS R3  ご予約受付中 | カメラのキタムラネットショップ';
          document
            .querySelector<any>('meta[name="description"]')
            .setAttribute(
              'content',
              'キヤノン EOS R3 ご予約受付中！ ！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
            );
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
          document.title = '新製品！キヤノン EOS R3 | カメラのキタムラネットショップ';
          document
            .querySelector<any>('meta[name="description"]')
            .setAttribute(
              'content',
              'キヤノン EOS R3 好評発売中！ ！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
            );
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      verifyDate();
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      verifyDate
    };
  }
});
