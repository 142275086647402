













































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'campaign',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  methods: {
    linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }
  },
  setup: (props, context) => {
    document.title = 'メーカーキャンペーン | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '今が買い時！メーカーキャンペーン！キャッシュバックやプレゼントなど、お得なメーカーキャンペーンをご紹介♪ | カメラのキタムラ');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: 'メーカーキャンペーン',
          disabled: true
        }
      ],
      campaignList: [
        // {
        //   campaignName: '',
        //  img: '',
        //  href: '',
        //  Contents: '',
        //  TargetPeriod: ''
        // },<!--▼-->
        {
          campaignName: 'ソニー α1 II 発売記念キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9773.jpg',
          href: 'https://www.sony.jp/camera/campaign/cb24ff/',
          Contents: '期間中、対象商品を購入し、指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2024年11月26日(火)～2025年1月14日(火)'
        },
        {
          campaignName: 'フジフイルム Xシリーズ＆XFレンズ年末キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9750.jpg',
          href: 'https://fujifilm-x.com/ja-jp/special/cb24-x-winter/',
          Contents: '期間中、対象商品をご購入後に製品登録の上、ご応募された方に最大4万円をキャッシュバック',
          TargetPeriod: '2024年11月19日(火)～2025年1月13日(月)'
        },
        {
          campaignName: 'キヤノン 冬のキャッシュバック ぽかぽかフォトキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9746.jpg',
          href: 'https://personal.canon.jp/product/campaign/camera-winter2024',
          Contents: '期間中に対象商品を購入し、応募した方全員にキャッシュバック(銀行振り込みによる)',
          TargetPeriod: '2024年11月15日(金)～2025年1月14日(火)'
        },
        {
          campaignName: 'ソニー Cinema Line ウィンターキャッシュバックキャンペーン2024',
          img: 'https://shopimg.kitamura.jp/images/banner/9747.jpg',
          href: 'https://www.sony.jp/camera/campaign/cb24cl_winter/',
          Contents: '期間中、対象商品をご購入&指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2024年11月15日(金)～2025年1月14日(火)'
        },
        {
          campaignName: 'ソニー αウィンターキャッシュバックキャンペーン2024',
          img: 'https://shopimg.kitamura.jp/images/banner/9748.jpg',
          href: 'https://www.sony.jp/camera/campaign/cb24ilc_winter/',
          Contents: '期間中、対象商品をご購入し、指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2024年11月15日(金)～2025年1月14日(火)'
        },
        {
          campaignName: 'ソニー 冬旅満喫キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9749.jpg',
          href: 'https://www.sony.jp/vlogcam/campaign/cb24winter_ZV/',
          Contents: '期間中、対象商品を購入し、指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2024年11月15日(金)～2025年1月14日(火)'
        },
        {
          campaignName: 'OM SYSTEM 手ぶれ知らずの冒険が始まる！冬景色堪能キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9725.jpg',
          href: 'https://jp.omsystem.com/campaign/c241101a/index.html',
          Contents: '期間中、対象商品をご購入&製品登録後ご応募された方に最大4万円をプレゼント。さらにカメラとレンズ同時申込で追加で1万円分キャッシュバック',
          TargetPeriod: '2024年11月1日(金)～2025年1月15日(水)'
        },
        {
          campaignName: 'Nikon Creators 応援オータムキャンペーン 2024',
          img: 'https://shopimg.kitamura.jp/images/banner/9717.jpg',
          href: 'https://www.nikon-image.com/event/campaign/autumn_2024/',
          Contents: '期間中、対象製品をご購入の上、所定の方法でご応募された方全員に、最大7万円をキャッシュバック',
          TargetPeriod: '2024年10月25日(金)～2025年1月14日(火)'
        },
        {
          campaignName: 'LUMIX S9 新色＆新レンズ発売記念キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9698.jpg',
          href: 'https://panasonic.jp/dc/contents/campaign/s9-newcolor-campaign.html',
          Contents: '期間中、対象商品をご購入&応募でキャッシュバック！さらに、購入者限定のLUMIX S9 使いこなし上達プログラム 視聴チケットプレゼント',
          TargetPeriod: '2024年10月11日(金)～2025年1月13日(月)'
        },
        {
          campaignName: 'フジフイルム X-T50 セットでおトク！に購入キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9665.jpg',
          href: 'https://fujifilm-x.com/ja-jp/special/cb24-x-t50-autumn/',
          Contents: '期間中、対象商品をご購入後に製品登録の上、ご応募いただいたお客様全員にキャッシュバックを致します',
          TargetPeriod: '2024年8月22日(木)～2024年12月31日(火)'
        },
        {
          campaignName: 'ニコン Z 9 Cfexpressメモリーカードセットキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9573.jpg',
          href: 'https://www.nikon-image.com/event/campaign/z_9_cf_campaign2024/',
          Contents: '期間中、Z 9をご購入された方に『CFexpress type Bメモリーカード660GB MC-CF660G』をプレゼント',
          TargetPeriod: '2024年4月26日(金)～なくなり次第終了'
        },
        {
          campaignName: 'フジフイルム GFレンズ年末キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9751.jpg',
          href: 'https://fujifilm-x.com/ja-jp/special/cb24-gf-winter/',
          Contents: '期間中、対象商品をご購入後に製品登録の上、ご応募いただいたお客様に最大5万円をキャッシュバック',
          TargetPeriod: '2024年11月19日(火)～2025年1月13日(月)'
        },
        {
          campaignName: 'LUMIX 年末・年始レンズキャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9737.jpg',
          href: 'https://panasonic.jp/dc/contents/campaign/campaign2024.html',
          Contents: '期間中、対象商品をご購入・応募で1商品あたり最大2万円をキャッシュバック！',
          TargetPeriod: '2024年11月15日(金)～2025年1月13日(月)'
        },
        {
          campaignName: 'ツァイスレンズキャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9734.jpg',
          href: 'https://www.kenko-tokina.co.jp/lp/campaign2024/zeiss-cb/',
          Contents: '期間中、対象商品をご購入の上、所定の方法でご応募されたお客様全員に最大39,000円のVISAギフトカードをプレゼント',
          TargetPeriod: '2024年11月1日(金)～2024年12月31日(火)'
        },
        {
          campaignName: 'トキナーレンズキャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9735.jpg',
          href: 'https://www.kenko-tokina.co.jp/lp/campaign2024/tokina-cb/',
          Contents: '期間中、対象商品をご購入の上、所定の方法でご応募されたお客様全員に最大1万円のVISAギフトカードプレゼント',
          TargetPeriod: '2024年11月1日(金)～2024年12月31日(火)'
        },
        {
          campaignName: '銘匠光学 ビューファインダープレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/8586.jpg',
          href: 'https://stkb.co.jp/info/?p=18267',
          Contents: '期間中、TTArtisan 21mm f/1.5 ASPH Mマウントレンズを購入でビューファインダーをプレゼント！※なくなり次第終了',
          TargetPeriod: '2021年9月1日(水)～なくなり次第終了'
        },
        {
          campaignName: 'ツァイスロゴ入りグッズプレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9774.jpg',
          href: 'https://www.kenko-tokina.co.jp/lp/campaign2024/zeiss-conquest/',
          Contents: '期間中、対象商品をご購入&ご応募されたの方の中から抽選で20名様にZEISSロゴ入りネックウォーマーと使い捨てレンズクリーナーをプレゼント',
          TargetPeriod: '2024年11月22日(金)～2025年1月31日(金)'
        },
        {
          campaignName: 'Profoto B10Xシリーズ プレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9794.jpg',
          href: 'https://shop.kitamura.jp/ec/pd/compareproducts?prd=7340027558546-7340027558614',
          Contents: '期間中、対象商品をご購入後、同梱されてる申込用紙に記入の上FAXかメールで申請頂くとカメラアクセサリーをプレゼント',
          TargetPeriod: '2024年12月6日(金)～2024年12月25日(水)'
        },
        {
          campaignName: 'ケンコー・トキナー 天体望遠鏡／赤道儀 キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9775.jpg',
          href: 'https://www.kenko-tokina.co.jp/lp/campaign2024/nse-cb/',
          Contents: '期間中、対象商品をご購入&ご応募されたお客様全員に、最大25,000円のVISAギフトカードをプレゼント',
          TargetPeriod: '2024年11月25日(月)～2025年1月14日(火)'
        },
        {
          campaignName: 'トーリハン ドライキャビ QUOカードPayプレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9381.jpg',
          href: 'http://www.dry-cabi.co.jp/c/cam2311.html',
          Contents: '期間中、対象商品ご購入の方全員に最大7,000円分のQUOカードPayをプレゼント',
          TargetPeriod: '2023年11月1日(水)～2024年12月31日(金) '
        },
        {
          campaignName: 'バンガード キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9705.jpg',
          href: 'https://www.vanguardworld.jp/blogs/news/cashback-campaign',
          Contents: '期間中、対象商品をご購入いただいたお客様に、カーボン：5,000円、アルミ：4,000円の現金をキャッシュバックいたします',
          TargetPeriod: '2024年10月18日(金)～2025年1月13日(月)'
        },
        {
          campaignName: 'シャープ SIMフリー AQUOS sense9 デビューキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9732.jpg',
          href:
            'https://k-tai.sharp.co.jp/campaign/sense9cp/simfree/index.html?utm_source=kitamura&utm_medium=kitamuranetshop_350130_sen9cp&utm_campaign=sf_sense9_campaign',
          Contents: '期間中、対象商品をご購入+ご応募でもれなく2,000円キャッシュバック！さらにご紹介で抽選で200組に3万円分のJTBトラベルギフトプレゼント',
          TargetPeriod: '発売日～2024年12月25日(水)'
        },
        {
          campaignName: 'パナソニック ジェットウォッシャー セットでオトクキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9726.jpg',
          href: 'https://panasonic.jp/teeth/campaign/24autumn-jet-doltz.html',
          Contents: '期間中、対象商品をご購入後コース選択の上ご応募いただいた方に、最大1万円の現金または電子ギフトをキャッシュバック！',
          TargetPeriod: '2024年11月1日(金)～2025年1月15日(水)'
        },
        {
          campaignName: 'パナソニック ラムダッシュPRO6枚刃 キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9727.jpg',
          href: 'https://panasonic.jp/shaver/campaign/24winter-lamdashpro6-cashback.html',
          Contents: '期間中、対象品をご購入&ご応募いただいた方に最大5,000円をもれなくプレゼント',
          TargetPeriod: '2024年11月1日(金)～2025年1月31日(金)'
        },
        {
          campaignName: 'ヘアドライヤー ナノケア 最大3,000円分プレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9706.jpg',
          href: 'https://panasonic.jp/beauty/campaign/24autumn-hair-cashback.html',
          Contents: '期間中、対象商品をご購入&WEBでのご応募で最大3,000円分の現金キャッシュバックまたは電子ギフトプレゼント',
          TargetPeriod: '2024年10月23日(水)～2025年1月13日(月)'
        },
        {
          campaignName: 'パナソニックビューティ ご愛顧ありがとうキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9692.jpg',
          href: 'https://panasonic.jp/beauty/campaign/24autumn-thanks.html',
          Contents: '期間中、対象商品をご購入いただくとキャッシュバック、パナソニックビューティ製品をお持ちの方はさらに2000円プレゼント',
          TargetPeriod: '2024年9月18日(水)～2025年1月13日(月)'
        },
        {
          campaignName: 'パナソニック はくだけキュッとリフレ オリジナルホルダープレゼント',
          img: 'https://shopimg.kitamura.jp/images/banner/9681.jpg',
          href: 'https://panasonic.jp/massage_parts/campaign/2409-gift.html',
          Contents: '期間中、対象商品をご購入・応募いただいた方にもれなく専用オリジナルホルダーをプレゼント',
          TargetPeriod: '2024年9月3日(火)～2025年1月31日(金)'
        },
        {
          campaignName: 'パナソニック ラムダッシュ パームイン NAGORI専用置台プレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9563.jpg',
          href: 'https://panasonic.jp/shaver/campaign/24spring-palm-gift.html',
          Contents: '期間中、対象商品を購入後、WEBでの応募で本体と同じ色・素材を使用した専用置台をもれなくプレゼント',
          TargetPeriod: '2024年4月1日(月)～2025年1月31日(金)'
        },
        {
          campaignName: 'パナソニック レンジ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9204.jpg',
          href: 'https://panasonic.jp/range/service/iot-ext-warranty.html',
          Contents: 'レンジを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック 全自動ディーガ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9205.jpg',
          href: 'https://panasonic.jp/diga/service/iot-ext-warranty.html',
          Contents: 'レコーダーを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック オートクッカー IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9203.jpg',
          href: 'https://panasonic.jp/cook/service/iot-ext-warranty.html',
          Contents: 'クッカーを専用アプリにつないぐと、無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
