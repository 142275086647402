




























































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, PropType, ref, computed, watch } from '@vue/composition-api';
import OverlayLoading from '@/components/common/overlay-loading.vue';
import UserService from '@/logic/user.service';
import { prefectureList } from '@/constants/prefecture-list';
import ValidationService from '@/logic/validation.service';

export type RegisterDeliveryTarget = {
  zipCode: string;
  prefectureId: string;
  addr1: string;
  addr2: string;
  addr3: string;
  lastName: string;
  firstName: string;
  lastNameKn: string;
  firstNameKn: string;
  phone: string;
  save?: boolean;
};

const NOT_FOUND_SEARCH_ADDRESS_MSG = '住所が見つかりませんでした。郵便番号が正しいかご確認ください。';

export default Vue.extend({
  name: 'delivery-target-form',
  components: {
    'overlay-loading': OverlayLoading
  },
  props: {
    loadedDeliveryTarget: {
      required: true,
      type: Boolean
    },
    editDeliveryTarget: {
      required: false,
      type: Object as PropType<RegisterDeliveryTarget>
    },
    // 保存有無（レジページにて使用）
    displaySave: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup: (props, context) => {
    const { order, errorStore } = context.root.$store;
    const state = reactive({
      // ロード状態
      loaded: {
        deliveryTarget: true,
        address: true
      },
      // フォームの確認状態
      formConfirming: false,
      // お届け先 ダミーデータ
      enableValidation: false,
      // バリデーションルール（確認ボタンが押された際にルールを適用する）
      rules: {
        lastNameRule: ref<(Function | boolean | string)[]>([]),
        firstNameRule: ref<(Function | boolean | string)[]>([]),
        lastNameKanaRule: ref<(Function | boolean | string)[]>([]),
        firstNameKanaRule: ref<(Function | boolean | string)[]>([]),
        frontZipcodeRule: ref<(Function | boolean | string)[]>([]),
        backZipcodeRule: ref<(Function | boolean | string)[]>([]),
        prefectureRule: ref<(Function | boolean | string)[]>([]),
        address1Rule: ref<(Function | boolean | string)[]>([]),
        address2Rule: ref<(Function | boolean | string)[]>([]),
        address3Rule: ref<(Function | boolean | string)[]>([]),
        phoneAreaCodeRule: ref<(Function | boolean | string)[]>([]),
        phoneCityCodeRule: ref<(Function | boolean | string)[]>([]),
        phoneSubscriberCodeRule: ref<(Function | boolean | string)[]>([])
      },
      prefectures: [{ text: '指定なし', value: null }, ...prefectureList],
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      selectPrefectures: ref<number | null>(null),
      address1: '',
      address2: '',
      address3: '',
      phoneNumber: ['', '', ''] as string[],
      zipCode: ['', ''] as string[],
      save: true,
      form: ref<HTMLFormElement>()
    });

    // バリデーションを初期化する
    state.form?.resetValidation();

    // レジページ処理：代理アカウント制御
    watch(
      () => [props.displaySave, order.proxyAccount],
      () => {
        if (props.displaySave) state.save = !order.proxyAccount;
      },
      { immediate: true }
    );

    // ローディング
    watch(
      () => props.loadedDeliveryTarget,
      (loadedDeliveryTarget) => {
        state.loaded.deliveryTarget = loadedDeliveryTarget as boolean;
      },
      { immediate: true }
    );

    // 編集用データ
    watch(
      () => props.editDeliveryTarget,
      (editDeliveryTarget) => {
        const editDate = editDeliveryTarget as RegisterDeliveryTarget;
        if (editDate && Object.keys(editDate).length) {
          state.zipCode = editDate.zipCode.split('-');
          state.selectPrefectures = +editDate.prefectureId;
          state.address1 = editDate.addr1;
          state.address2 = editDate.addr2;
          state.address3 = editDate.addr3;
          state.lastName = editDate.lastName;
          state.firstName = editDate.firstName;
          state.lastNameKana = editDate.lastNameKn;
          state.firstNameKana = editDate.firstNameKn;
          state.phoneNumber = editDate.phone.split('-');
        }
      },
      { immediate: true }
    );

    /**
     * お届け先の登録
     */
    const registerAddress = () => {
      const deliveryTarget = {
        zipCode: state.zipCode.join('-'),
        prefectureId: String(state.selectPrefectures),
        addr1: state.address1,
        addr2: state.address2,
        addr3: state.address3,
        lastName: state.lastName,
        firstName: state.firstName,
        lastNameKn: state.lastNameKana,
        firstNameKn: state.firstNameKana,
        phone: state.phoneNumber.join('-')
      } as RegisterDeliveryTarget;
      if (props.displaySave) deliveryTarget.save = state.save;
      context.emit('registerAddress', deliveryTarget);
    };

    /**
     * 戻るボタン押下
     */
    const back = () => {
      context.emit('back');
    };

    /**
     * input要素を取得する
     *
     * @param elementId 要素ID
     */
    const getInputElement = (elementId: string): HTMLFormElement => {
      const inputElement = state.form?.inputs.find((element: HTMLElement) => element.id === elementId);
      return inputElement;
    };

    /**
     * 確認状態を切り替える
     * 切替の際にページトップにスクロールする
     */
    const swithFormConfirming = () => {
      context.root.$vuetify.goTo('#page-top', { duration: 0 });
      state.formConfirming = !state.formConfirming;
    };

    /**
     * 登録フォームの確認を実行する
     */
    const confirmForms = () => {
      state.loaded.deliveryTarget = false;
      state.rules = {
        lastNameRule: [ValidationService.range('氏名(姓)', 1, 42)],
        firstNameRule: [ValidationService.range('氏名(名)', 1, 42)],
        lastNameKanaRule: [ValidationService.kana('氏名フリガナ(セイ)'), ValidationService.range('フリガナ(セイ)', 1, 42)],
        firstNameKanaRule: [ValidationService.kana('氏名フリガナ(メイ)'), ValidationService.range('フリガナ(メイ)', 1, 42)],
        frontZipcodeRule: [ValidationService.number('郵便番号前３桁'), ValidationService.length('郵便番号前３桁', 3)],
        backZipcodeRule: [ValidationService.number('郵便番号前４桁'), ValidationService.length('郵便番号前４桁', 4)],
        prefectureRule: [ValidationService.select('都道府県')],
        address1Rule: [ValidationService.range('市区郡', 1, 30)],
        address2Rule: [ValidationService.range('町村名', 1, 32)],
        address3Rule: [ValidationService.range('番地・建物名', 1, 32)],
        phoneAreaCodeRule: [ValidationService.number('市外局番'), ValidationService.range('市外局番', 2, 5)],
        phoneCityCodeRule: [ValidationService.number('市内局番'), ValidationService.range('市内局番', 1, 4)],
        phoneSubscriberCodeRule: [ValidationService.number('加入者番号'), ValidationService.length('加入者番号', 4)]
      };

      // rulesの内容が画面に適用されてからバリデーションチェックする
      setTimeout(async () => {
        try {
          if (state.form?.validate()) {
            // 郵便番号の有無チェック
            const addressResult = await UserService.searchAddress(state.zipCode.join(''));
            if (!addressResult.result) {
              errorStore.errorMessage = NOT_FOUND_SEARCH_ADDRESS_MSG;
            } else {
              swithFormConfirming();
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          state.loaded.deliveryTarget = true;
        }
      });
    };

    /**
     * input要素からエラーメッセージを取得する
     *
     * @param elementId 要素ID
     */
    const getErrorMessage = (elementId: string): string => {
      const inputElement = getInputElement(elementId);
      const exitError = inputElement?.errorBucket && inputElement.errorBucket.length;
      return exitError ? inputElement.errorBucket[0] : '';
    };

    /**
     * 住所の検索
     */
    const searchAddress = async () => {
      state.loaded.address = false;

      // バリデーションチェック
      state.rules.frontZipcodeRule = [ValidationService.number('郵便番号前３桁'), ValidationService.length('郵便番号前３桁', 3)];
      state.rules.backZipcodeRule = [ValidationService.number('郵便番号前４桁'), ValidationService.length('郵便番号前４桁', 4)];

      // rulesの内容が画面に適用されてからバリデーションチェックして進行する
      await new Promise<void>((resolve) => setTimeout(() => resolve()));

      const frontInputElement = getInputElement('delivery-target-zipcode-front');
      const backInputElement = getInputElement('delivery-target-zipcode-front');
      if (!frontInputElement.validate() || !backInputElement.validate()) {
        state.loaded.address = true;
        return;
      }

      try {
        const addressResult = await UserService.searchAddress(state.zipCode.join(''));
        const address = addressResult.result;
        // 郵便番号の有無チェック
        if (address) {
          state.selectPrefectures = address?.prefecture_id || '';
          state.address1 = address?.addr1 || '';
          state.address2 = address?.addr2 || '';
        } else {
          errorStore.errorMessage = NOT_FOUND_SEARCH_ADDRESS_MSG;
        }
      } catch (error) {
        console.error(error);
      } finally {
        state.loaded.address = true;
      }
    };

    /**
     * 選択した都道府県のテキストを取得する
     */
    const getPrefectureText = computed((): string => {
      const prefecture = state.prefectures.find((p) => p.value === state.selectPrefectures);
      return prefecture?.text || '指定なし';
    });

    return {
      ...toRefs(state),
      registerAddress,
      back,
      searchAddress,
      confirmForms,
      getErrorMessage,
      swithFormConfirming,
      getPrefectureText
    };
  }
});
