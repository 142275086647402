













































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
//import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import SpecialProductService from '@/logic/special-product.service';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import dayjs from 'dayjs';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'zfc',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    priceAndPopular: PriceAndPopular,
    splitContent: SplitContent,
    characteristic: Characteristic,
    appearanceImage: AppearanceImage,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
    //expensiveTradeIn: ExpensiveTradeIn
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ニコン Z fc | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン Z fc 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2022/2/6 9:58',
      fanValidTo: '2022/2/9 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/2/9 10:00',
      reserveValidTo: '2023/2/09 9:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2023/2/09 10:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images2' },
        { naviItem: '作例画像', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'ニコン Z 30 ボディ',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759910615',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/06a/b9b/9f6/4b3/69e/9bd/86b/a4a/b4d/512/83j/8nl/se5/x/M.jpg',
        txt: 'ニコン Z fc ブラック',
        tradeInProducts: [
          { name: 'ニコン Z 30 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759910615' },
          { name: 'ニコン Z 50 ボディ', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759903617' },
          { name: 'ニコン Z fc ボディ シルバー', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4960759906311' }
        ],
        tradeInImg: '/ec/images2/special/camera/feature/nikon/zfc/bnr_shitadori_350-130.jpg'
      },
      characteristicList: [
        {
          subtitle: 'ミラーレスとニコンの伝統的なデザインの融合',
          contents: [
            'ボディーのサイズだけでなく、ペンタプリズム部のデザインや黒とシルバーの色バランスなどもFM2に近い印象で再現',
            'モニター背面にはフィルムカメラを想起させる革シボ風の加工を採用。一つ一つのダイヤルは、アルミ削りだしで丁寧に作り込み、天面の表示は全て刻印',
            '1970-80年代のカメラに使用されていたNikonの刻印文字や、円形の接眼目当てを採用。細部までこだわり抜いたデザイン'
          ]
        },
        {
          subtitle: '指先に機械の感触を感じながら楽しみながら撮影できる操作感',
          contents: [
            'ボディーの天面にシャッタースピード、ISO感度、露出補正の３つのダイヤル、そしてF値を表示する小窓を搭載。一目で基本的なカメラ設定を視認することが可能',
            '手応えのあるダイヤルで、一つ一つの設定を丁寧に調節し、シャッターをきるという撮影スタイルが楽しめる操作感を実現'
          ]
        },
        {
          subtitle: '薄型・⼩型軽量システムに充実した基本性能と高画質を凝縮',
          contents: [
            '標準キットレンズで600mlのペットボトルと、ほぼ同等の重さ。いつでも持ち歩けるので、より気軽に、特別な瞬間を切り取ることが可能',
            '動画撮影時にも人物、犬、猫の顔や瞳を検出する瞳AFと動物AFが可能。静止画同様［オートエリアAF］［ワイドエリアAF］に対応',
            '高感度性能が優れた撮像素子と、上位機種にも採用している画像処理エンジン EXPEED 6のノイズ低減よって、静止画、動画を問わずノイズを効果的に抑制。細部や質感も鮮明に描写が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラ・レンズメンテナンス',
          target: 'blank'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z fc',
          disabled: true
        }
      ],

      mainProductJanCode: ['4960759911407', '4960759911421', '4960759911414', '4960759906311', '4960759906335', '4960759906328'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759906311', '4960759906335', '4545350053598', '4547410440362'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759911407',
        '4960759911421',
        '4960759911414',
        '4960759906311',
        '4960759906328',
        '4960759906335',
        '4960759909268',
        '4960759909275',
        '4960759903969',
        '4955478183037',
        '4960759148940',
        '4955478182603',
        '4960759909053',
        '4523052022722',
        '4523052022739'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
