














































































import Vue from 'vue';
import { reactive, toRefs, PropType } from '@vue/composition-api';
import { noimage } from '@/logic/utils';
import ProductFavoriteDialog from '@/components/common/product-favorite-dialog.vue';

export type ProductItem = {
  janCode: string;
  makerName: string;
  itemName: string;
  imagePath: string;
  rating: string;
  isNoticePriceSetting?: boolean;
  isNoticeUsedSetting?: boolean;
};

export default Vue.extend({
  name: 'product',
  components: {
    'product-favorite-dialog': ProductFavoriteDialog
  },
  props: {
    product: {
      type: Object as PropType<ProductItem>,
      required: true,
      default: {}
    },
    canFavorite: {
      type: Boolean,
      required: false,
      default: false
    },
    canPostReview: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowReview: {
      type: Boolean,
      required: false,
      default: true
    },
    linkDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: (_, context) => {
    const state = reactive({
      // hover style制御用
      isHover: false,
      // お気に入り設定
      favoriteDialog: false
    });

    // お気に入り商品登録時
    function registerFavorite() {
      state.favoriteDialog = true;
    }

    // お気に入り登録ダイアログを閉じる
    function onFavoriteDialogClosed() {
      state.favoriteDialog = false;
    }

    // お気に入り登録完了時
    function onFavoriteRegisterd() {
      context.emit('onRegisterd');

      state.favoriteDialog = false;
    }

    return {
      ...toRefs(state),
      noimage,
      registerFavorite,
      onFavoriteDialogClosed,
      onFavoriteRegisterd
    };
  }
});
