import { render, staticRenderFns } from "./rice-cooker-bestbuy.vue?vue&type=template&id=2161aacd&scoped=true&"
import script from "./rice-cooker-bestbuy.vue?vue&type=script&lang=ts&"
export * from "./rice-cooker-bestbuy.vue?vue&type=script&lang=ts&"
import style0 from "./rice-cooker-bestbuy.vue?vue&type=style&index=0&id=2161aacd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2161aacd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VBtn,VCard,VCol,VContainer,VHover,VIcon,VImg,VRow,VSheet})
