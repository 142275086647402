











































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import ProductService from '@/logic/product.service';
import NewsService from '@/logic/news.service';
import { News } from '@/types/news';
import MyPageMenu from './common/my-page-menu.vue';
import NewsList from '@/components/news/news-list.vue';
import MyPageLayout from './common/my-page-layout.vue';
import { SimpleProduct, SliderProduct } from '@/types/product';
import ProductSlider from '../product-detail/product-slider.vue';

export default Vue.extend({
  name: 'top-contents',
  components: {
    'section-loading': SectionLoading,
    'product-slider': ProductSlider,
    'my-page-menu': MyPageMenu,
    'news-list': NewsList,
    'my-page-layout': MyPageLayout
  },
  setup: (props, context) => {
    const { authorizer } = context.root.$store;
    const state = reactive({
      // 画面タイトル
      title: 'マイページ',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '' }
      ],
      // ユーザー名
      userName: 'ゲスト',
      // お知らせ
      newsList: [] as Array<News>,
      // 最近チェックした商品
      checkedProductsRecommendInstance: null,
      checkedProducts: [] as Array<SliderProduct>,
      // あなたにおすすめ
      recommendProductsRecommendInstance: null,
      recommendProducts: [] as Array<SliderProduct>,
      // ロード状態
      loaded: {
        news: false,
        checkedProducts: false,
        recommendProducts: false
      }
    });

    /**
     * 最近チェックした商品一覧を取得する。
     */
    const loadRecentlyChecked = async function () {
      try {
        const resultCheckedProducts = await ProductService.searchRecommendProducts({ recommendId: '12' });
        state.checkedProductsRecommendInstance = resultCheckedProducts.instance;
        const resultCheckedProductItems = resultCheckedProducts.prodDetailList.items || [];
        resultCheckedProductItems.forEach((product: SimpleProduct) => {
          state.checkedProducts.push({
            janCode: product.janCode,
            itemName: product.itemName,
            imagePath: product.images[0].imagePath,
            price: product.price,
            priceDiv: product.priceDiv,
            ratingTotal: product.ratingTotal
          });
        });
      } catch (error) {
        console.error(error);
        state.checkedProductsRecommendInstance = null;
        state.checkedProducts = [] as Array<SliderProduct>;
      } finally {
        state.loaded.checkedProducts = true;
      }
    }

    /**
     * レコメンド商品一覧を取得する。
     */
    const loadRecommended = async function () {
      try {
        const resultRecommendProducts = await ProductService.searchRecommendProducts({ recommendId: '7' });
        state.recommendProductsRecommendInstance = resultRecommendProducts.instance;
        const resultRecommendProductItems = resultRecommendProducts.prodDetailList.items || [];
        resultRecommendProductItems.forEach((product: SimpleProduct) => {
          state.recommendProducts.push({
            janCode: product.janCode,
            itemName: product.itemName,
            imagePath: product.images[0].imagePath,
            price: product.price,
            priceDiv: product.priceDiv,
            ratingTotal: product.ratingTotal
          });
        });
      } catch (error) {
        console.error(error);
        state.recommendProductsRecommendInstance = null;
        state.recommendProducts = [] as Array<SliderProduct>;
      } finally {
        state.loaded.recommendProducts = true;
      }
    }

    const init = async () => {
      // 未ログインの場合は初期表示処理を行わない
      if (!authorizer.isLoggedIn) return;

      // ユーザ名を取得する
      if (authorizer.user?.lastName && authorizer.user?.firstName) {
        state.userName = authorizer.user.lastName + ' ' + authorizer.user.firstName;
      } else {
        state.userName = 'ゲスト';
      }

      // お知らせを取得する
      try {
        const newsList = await NewsService.searchNews(true);
        const maxNewsList = context.root.$vuetify.breakpoint.mdAndUp ? 3 : 2;
        state.newsList = newsList.slice(0, maxNewsList);
      } catch (error) {
        console.error(error);
        state.newsList = [] as Array<News>;
      } finally {
        state.loaded.news = true;
      }

      // レコメンド系はモジュールの初期化が完了してから呼び出す
      ProductService.waitForRecommendInitialized().then(() => {
        loadRecentlyChecked();
        loadRecommended();
      }).catch(() => {
        // モジュールが読み込めなかった場合もローディングは止める
        state.loaded.checkedProducts = true;
        state.loaded.recommendProducts = true;
        console.warn('レコメンドモジュールが読み込めませんでした。');
      });
    };

    onMounted(() => {
      init();

      // ブラウザの戻るボタン検知用（Vue系のものは、反応しないときがあるため）
      window.addEventListener('popstate', function () {
        init();
      });
    });

    watch(() => authorizer.isLoggedIn, init);

    return {
      ...toRefs(state)
    };
  }
});
