






























































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
// import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'momijigari',
  components: {
    breadcrumbs: Breadcrumbs,
    // 'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '紅葉の秋を満喫！紅葉狩り特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '秋は写真撮影がしたくなる！カメラのキタムラ商品担当が紅葉狩りにおすすめのカメラ、レンズなどご紹介。紅葉狩りの準備がまだのかたは、ぜひご覧ください'
      );

    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '紅葉狩り特集',
          linkUrl: '/ec/special/camera/momijigari',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      visible: false,
      index: 0, // default: 0 拡大時表示画像
      images: [
        process.env.VUE_APP_NET_SHOP_URL + '/images/pd/9d2/7a5/a3b/087/0f5/70c/110/7c4/e4f/813/43z/wyg/9en/u/L.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/momijigari/im_02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/momijigari/im_03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/momijigari/im_04.jpg'
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文承ります'
        },
        {
          href: '/ec/special/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    // 画像を拡大して表示する
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    // 拡大画像を閉じる
    const handleHide = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
