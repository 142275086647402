





































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'night-view',
  components: {
    breadcrumbs: Breadcrumbs,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '夜景写真撮影のコツ | 撮影のコツ動画＆便利なカメラ写真グッズ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '夜景写真撮影のコツを、３分の動画でチェックしましょう♪あると便利なカメラグッズやおすすめの写真アイテムも必見。単調になりがちな夜景写真も、楽しく上手に残せる方法をお伝えします★'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '夜景/イルミネーション写真撮影のコツ',
          disabled: true
        }
      ],
      nightViewList: [
        {
          title: '夜景 イルミネーション写真撮影の必須アイテム',
          items: [
            {
              subtitle: '三脚',
              href:
                '/ec/list?narrow18=0&keyword=アルミ4段三脚&category=&n20c=完了商品は除く&index=&sort=number20,Number17,Score&searchbox=1&q=アルミ4段三脚&path=&s1%5B%5D=ベルボン',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_a.jpg',
              txt:
                '初めてでも使いやすい三脚。軽いながらも、ミラーレスカメラや中級機の一眼レフまで対応。水準機や微妙な調整も出来、コストパフォーマンスが高い三脚。',
              hreftxt: 'ベルボン アルミ4段三脚 一覧'
            },
            {
              subtitle: 'リモートコード',
              href: '/ec/special/general/how-to/night-view/776',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_b.jpg',
              txt:
                'シャッターボタンを押した時の手ブレを防ぐリモートコード。シャッターを切った状態でロック可能なタイプなら、星空撮影時などの長時間露光撮影時にも重宝。',
              hreftxt: 'リモートコード商品一覧へ'
            }
          ]
        },
        {
          title: '夜景 イルミネーション写真撮影におすすめ！もっと楽しむカメラアイテム',
          items: [
            {
              subtitle: 'クロスフィルター',
              href: '/ec/special/camera/lensfilter/344',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_c.jpg',
              txt:
                'レンズの前にフィルターを付け、くるくる回すだけで光の線が印象的に。キラキラのイルミネーションがもっと煌く！夜景撮影がもっともっと楽しくなるおすすめアイテム★',
              hreftxt: 'クロスフィルター商品一覧へ'
            },
            {
              subtitle: 'フォトグローブ・手袋',
              href: '/ec/list?narrow18=0&keyword=フォトグローブ&category=&searchbox=1&sort=number20,rank,Score&index=all&path=&q=フォトグローブ&x=0&y=0%2F',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_d.jpg',
              txt:
                'あたたかく、カメラ操作もしやすい撮影用の手袋があれば、寒い中での夜景撮影もへっちゃら！赤のミトン型の手袋をした女の子には、きゅんとしてしまいます♪',
              hreftxt: 'フォトグローブ・手袋商品一覧へ'
            }
          ]
        },
        {
          title: '夜景 イルミネーション写真を楽しむフォトグッズ',
          items: [
            {
              subtitle: 'クリスタルプリント',
              href: 'https://www.kitamura-print.com/premium_print/digitalcrystal/',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_e.jpg',
              txt:
                'クリスタルプリントとは、写真の表面がキラキラと輝くプリント。非常に高い光沢度とクリアな仕上がりで、夜景やイルミネーション写真のプリントにおすすめ♪この質感、ぜひ一度ご体験ください★',
              hreftxt: 'クリスタルプリント詳細へ'
            },
            {
              subtitle: 'ポスターカレンダー',
              href: 'https://www.kitamura-print.com/photogoods/postercalendar/',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_g.jpg',
              txt:
                'お気に入りの一枚を選んで、ポスタータイプのカレンダーにしてみるのもいいですね♪自分で撮った写真で作ると、毎日カレンダーを見るのが楽しい。お部屋のインテリアとしてもGOOD★',
              hreftxt: 'ポスターカレンダー詳細へ'
            },
            {
              subtitle: '水玉柄のアルバム',
              href: 'https://shop.kitamura.jp/ec/pd/4974214166325',
              src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/how-to/night-view/img_h.jpg',
              txt: '表紙だけでなく、台紙もかわいいドット柄★ページを増やしたり、ボールペン・色鉛筆などで直接台紙に書きこみもできる！',
              hreftxt: 'セキセイ カジュアルアルバム ドット フリー台紙タイプ'
            }
          ]
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
