




































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch, computed } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import NewsService from '@/logic/news.service';
import { News } from '@/types/news';
import NewsList from '@/components/news/news-list.vue';
import MyPageLayout from './common/my-page-layout.vue';
import MyPageMenu from './common/my-page-menu.vue';
import BackButton from '@/components/common/back-button.vue';
import MoreItemsButton from '@/components/common/more-items-button.vue';

export default Vue.extend({
  name: 'my-page-news-list',
  components: {
    'section-loading': SectionLoading,
    'news-list': NewsList,
    'my-page-layout': MyPageLayout,
    'my-page-menu': MyPageMenu,
    'back-button': BackButton,
    'more-items-button': MoreItemsButton
  },
  setup: (props, context) => {
    const { authorizer } = context.root.$store;
    const state = reactive({
      // 画面タイトル
      title: 'お知らせ一覧',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: 'お知らせ一覧', linkUrl: '' }
      ],
      // ユーザー名
      userName: 'ゲスト',
      // お知らせ
      newsList: [] as Array<News>,
      // 表示するお知らせの長さ
      showNewsLength: 10,
      // ロード状態
      loaded: {
        news: false
      }
    });

    const init = async () => {
      // 未ログインの場合は初期表示処理を行わない
      if (!authorizer.isLoggedIn) return;

      // ユーザ名を取得する
      if (authorizer.user?.lastName && authorizer.user?.firstName) {
        state.userName = authorizer.user.lastName + ' ' + authorizer.user.firstName;
      } else {
        state.userName = 'ゲスト';
      }

      // お知らせを取得する
      try {
        const newsList = await NewsService.searchNews(true);
        state.newsList = newsList;
      } catch (error) {
        console.error(error);
        state.newsList = [] as Array<News>;
      } finally {
        state.loaded.news = true;
      }
    };

    const getNewsList = computed((): Array<News> => state.newsList.slice(0, state.showNewsLength));

    onMounted(() => {
      init();

      // ブラウザの戻るボタン検知用（Vue系のものは、反応しないときがあるため）
      window.addEventListener('popstate', function() {
        init();
      });
    });

    watch(() => authorizer.isLoggedIn, init);

    return {
      ...toRefs(state),
      getNewsList
    };
  }
});
