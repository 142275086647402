



































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
//import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'heater-best',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    //'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '【最新】家電担当者イチオシ！おすすめ暖房器具2024 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '2024年の最新情報！カメラのキタムラ ネットショップ家電担当者のおすすめ暖房器具ストアです。人気の暖房器具とおすすめする理由や特長を解説しております。寒い季節に効果的なカーボンヒーターやセラミックヒーター、電気毛布など幅広い選択肢をご提供いたします'
      );
    const state = reactive({
      naviList: [
        { naviItem: 'カーボン\nグラファイト', href: '#ct01' },
        { naviItem: 'セラミック\nヒーター', href: '#ct02' },
        { naviItem: 'おすすめ\n電気毛布', href: '#ct07' },
        { naviItem: '石油ファン\nヒーター', href: '#ct03' },
        { naviItem: '石油\nストーブ', href: '#ct04' }
        //{ naviItem: 'その他\n暖房器具', href: '#ct05' },
        //{ naviItem: 'おすすめ\n特集', href: '#ct06' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント注文',
          target: 'blank'
        },
        {
          href: '/ec/special/kaden/airpurifier/aircleanercompare',
          img: 'https://shopimg.kitamura.jp/images/banner/3426.png',
          alt: 'おすすめ加湿空気清浄機'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: ' おすすめ暖房・暖房器具特集2024',
          linkUrl: '/ec/special/kaden/heater-best',
          disabled: false
        }
      ],
      // 取得するJancode
      productJanCodeList: ['4962365031487', '4962365031432', '4962365031388'],
      productDetailList: [] as Array<ProductDetail>, // 結果格納用 カーボン
      productJanCodeList2: ['4550556111652', '4951272034681', '4967576705332'],
      productDetailList2: [] as Array<ProductDetail>, // 結果格納用 セラミック
      productJanCodeList3: ['4951272032946', '4951272031888', '4951272031970'],
      productDetailList3: [] as Array<ProductDetail>, // 結果格納用 石油ファン
      productJanCodeList4: ['4962365016095', '4962365010314', '4962365010321'],
      productDetailList4: [] as Array<ProductDetail>, // 結果格納用 石油ストーブ
      productJanCodeList5: ['4967576703796', '4967576703802', '4580060599332'],
      productDetailList5: [] as Array<ProductDetail> // 結果格納用 電気毛布
      // ↑ 取得するJancode
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
        const productListResult3 = await ProductService.fetchProducts(state.productJanCodeList3, true);
        state.productDetailList3 = productListResult3.items;
        const productListResult4 = await ProductService.fetchProducts(state.productJanCodeList4, true);
        state.productDetailList4 = productListResult4.items;
        const productListResult5 = await ProductService.fetchProducts(state.productJanCodeList5, true);
        state.productDetailList5 = productListResult5.items;
      }
      catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>; // カーボン
        state.productDetailList2 = [] as Array<ProductDetail>; // セラミック
        state.productDetailList3 = [] as Array<ProductDetail>; // 石油ファン
        state.productDetailList4 = [] as Array<ProductDetail>; // 石油ストーブ
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
