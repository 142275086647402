













































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExpensivePurchasesAndShoppingCreditImage from '@/components/common/special/expensivePurchasesAndShoppingCreditImage.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'nikon-d810',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    characteristic: Characteristic,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    naviBtn: NaviBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    expensivePurchasesAndShoppingCreditImage: ExpensivePurchasesAndShoppingCreditImage,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'Nikon D810 ニコンデジタル一眼レフカメラ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン デジタル一眼レフ新製品｢Nikon D810｣特集。ニコン史上最高画質「Nikon D810」。D800との比較レビューや価格・スペックをチェック！全国1000店舗のカメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d810/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d810/img_top.jpg',
      appearanceImagePath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/nikon/d810',
      naviList: [
        { naviItem: '比較レビュー', href: '#revue' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '人気アクセサリー', href: '#acc' },
        { naviItem: '外観画像', href: '#images' }
      ],
      revueList: [
        {
          subtitle: 'ニコン史上最高画質「Nikon D810」D800Eより、D4sより高画質と言われる理由は・・・',
          contents: [
            'ニコンのフラグシックモデルD4sと同じ画像処理エンジンEXPEED 4を搭載し、ローパスフィルターの低減機能を搭載したD800Eとちがって、はじめから光学ローパスフィルターレス仕様',
            'ニコンのサイトから無料でダウンロードできる新しいRAW現像ソフトCapture NX-Dで、明暗、色ともに階調性豊かに仕上げられ、写真をご自分で調整して仕上げることにこだわる上級ユーザーにおすすめ'
          ]
        },
        {
          subtitle: 'さらに進化したISO・機能「Nikon D810」',
          contents: [
            '高画素のカメラに禁物なブレは、電子先幕シャッターで防ぎます。3635万画素の鮮鋭感を活かせます',
            'ISO感度が64～12800になり、特に低感度での撮影の幅が広がりそうです。明るい環境でも、白飛びを抑えた階調豊かな写真が残せます'
          ]
        }
      ],
      characteristicList: [
        {
          subtitle: 'ニコン史上最高画質のフルサイズデジタル一眼レフ「Nikon D810」',
          contents: [
            '圧倒的に鮮鋭感が高く階調性が豊かなニコン史上最高の画質',
            '撮影の確実性を高め撮影領域を拡大した高い信頼性と優れた操作性',
            '1080/60pの高鮮鋭・高画質動画'
          ]
        }
      ],
      specList: [
        {
          subtitle: '',
          contents: [
            'ローパスフィルターレス仕様により3635万画素の鮮鋭感をさらに高めて高画質を実現',
            'EXPEED 4搭載で常用感度ISO64～12800を実現',
            '機構ブレを極力低減する電子先幕シャッター搭載',
            '撮影の確実性を高め撮影領域を拡大した高い信頼性と優れた操作性'
          ]
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン D810',
          disabled: true
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
