
























































































































































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
//import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import SplitContent from '@/components/common/special/split-content.vue';
import Notfound from '@/components/common/not-found.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'rf200800mm-rf24105mm',
  components: {
    breadcrumbs: Breadcrumbs,
    naviBtn: NaviBtn,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    exampleImages: ExampleImages,
    expensiveTradeIn: ExpensiveTradeIn,
    urlcopy: Urlcopy,
    shasha: ShaSha,
    subText: SubText,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPurchase: PriceAndPurchase,
    //priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist,
    notfound: Notfound
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'キヤノン RF200-800mm F6.3-9 IS USM/RF24-105mm F2.8 L IS USM Z | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'キヤノン RF200-800mm F6.3-9 IS USM/RF24-105mm F2.8 L IS USM Z   価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      isShow: false,
      // ↓ ---- 予告 ----
      fanValidFrom: '2023/11/02 10:00',
      fanValidTo: '2023/11/07 9:59',
      // ↓ ---- 予約 ----
      reserveValidFrom: '2023/11/07 10:00',
      reserveValidTo: '2023/12/07 23:59',
      // ↓ ---- 発売 ----
      releaseValidFrom: '2023/12/08 00:00',
      releaseValidTo: '2100/5/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン RF200-800mm F6.3-9 IS USM/RF24-105mm F2.8 L IS USM Z',
          disabled: true
        }
      ],
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: 'お得情報', href: '#otoku' },
        { naviItem: '外観画像', href: '#gaikan' },
        { naviItem: '作例画像', href: '#images' }
      ],

      // ↓ ---- 高値下取り ----
      expensiveTradeInList: {
        name: 'キヤノン RF600mm F11 IS STM',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292162042',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/659/d1a/cc4/5a2/9a4/91b/61e/400/bde/ce2/c9q/q43/up3/0/L.jpg',
        txt: 'キヤノン RF200-800mm F6.3-9 IS USM/RF24-105mm F2.8 L IS USM Z',
        day: '2023年12月31日(日)',
        tradeInProducts: [
          { name: 'キヤノン RF600mm F11 IS STM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292162042' },
          { name: 'キヤノン RF100-500mm F4.5-7.1 L IS USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292168037' },
          { name: 'キヤノン RF100-400mm F5.6-8 IS USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292186765' },
          { name: 'キヤノン EF100-400mm F4.5-5.6L IS II USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292010350' },
          { name: 'キヤノン RF24-70mm F2.8 L IS USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292148381' },
          { name: 'キヤノン RF24-105mm F4L IS USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292115611' },
          { name: 'キヤノン RF28-70mm F2L USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292115642' },
          { name: 'キヤノン EF24-105mm F4L IS II USM', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292063615' }
        ],
        tradeInImg: 'https://shop.kitamura.jp/ec/images2/special/camera/feature/canon/rf200800mm-rf24105mm/bnr_shitadori_350-130.jpg'
      },
      characteristicList: [
        {
          subtitle: '',
          contents: ['', '', '', '']
        }
      ],
      mainProductJanCode: ['4549292222050', '4549292223156'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 試し4549292157345----
      comparisonJanCodeList: ['4549292184464'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode 4549980490693 4549980490709
      productJanCodeList: ['4549292184464', '4549292184471'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        verifyDate();
        fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
