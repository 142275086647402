














import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'user-guide',
  setup: () => {
    const state = reactive({
      guideList: [
        {
          type: '商品・注文について',
          items: [
            { href: '/ec/guide/kakaku', name: '掲載価格・表示価格' },
            { href: '/ec/guide/souryou', name: '送料・手数料' },
            { href: '/ec/guide/shiharai', name: 'お支払い方法' },
            { href: '/ec/guide/otodoke', name: '商品お届け・納期' },
            { href: '/ec/guide/cancel', name: 'キャンセル' },
            { href: '/ec/guide/ryoushuusho', name: '領収書' },
            { href: '/ec/guide/renraku', name: 'お客様へのご連絡' }
          ]
        },
        {
          type: 'キタムラネット会員について',
          items: [
            { href: '/ec/guide/riyou_kaiin', name: 'キタムラネット会員とは' },
            { href: '/ec/guide/riyou_kaiinkakaku', name: 'キタムラネット会員割引きとは' }
          ]
        },
        {
          type: 'サービス',
          items: [
            { href: '/ec/guide/hoshou', name: '5年間保証' },
            { href: '/ec/guide/used_hoshou', name: '中古保証' },
            { href: '/ec/guide/hoshou_line', name: 'LINEで追加保証' },
            { href: '/ec/guide/shitadori', name: 'なんでも下取り' },
            { href: '/ec/guide/afterservice', name: 'アフターサービス' },
            { href: '/ec/guide/henpin', name: '返品・交換' },
            { href: '/ec/guide/syuuri', name: '修理のご案内' },
            { href: '/ec/guide/recycle', name: '家電リサイクル回収' },
            { href: '/ec/guide/m_support', name: 'メーカーサポート' },
            { href: 'https://www.kitamura.jp/service/tcard/', name: 'Vポイント' }
          ]
        },
        {
          type: 'その他',
          items: [
            { href: 'https://ecfaq.kitamura.jp/s/', name: 'よくある質問' },
            { href: '/ec/guide/mypage', name: 'マイページの機能紹介 ' },
            { href: '/ec/guide/promente', name: 'プロメンテとは ' },
            { href: '/ec/guide/security', name: 'セキュリティ／プライバシー' }
          ]
        }
      ]
    });

    return {
      ...toRefs(state)
    };
  }
});
