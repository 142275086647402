import { render, staticRenderFns } from "./z_8-list.vue?vue&type=template&id=6da35023&scoped=true&"
import script from "./z_8-list.vue?vue&type=script&lang=ts&"
export * from "./z_8-list.vue?vue&type=script&lang=ts&"
import style0 from "./z_8-list.vue?vue&type=style&index=0&id=6da35023&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da35023",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VApp,VImg})
