































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'mannenhitsu-nenga',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '万年筆で年賀状(ハガキ)を書いてみよう | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '年賀状を書くときのマナーや、書き方のコツを紹介しています。今年の年賀状は、万年筆で書いてみてはいかがでしょうか?');
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '万年筆で年賀状(ハガキ)を書いてみよう',
          disabled: true
        }
      ],
      naviList: [
        {
          name: '万年筆の選び方',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen01_on.jpg',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen01_off.jpg',
          href: '/ec/special/general/stationery/mannenhitsu/how_to'
        },
        {
          name: '年賀状を書いてみよう',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen02_on.jpg',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen02_off.jpg',
          href: '/ec/special/general/stationery/mannenhitsu/nenga'
        },
        {
          name: 'よくある質問',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen03_on.jpg',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/stationery/mannenhitsu/nenga/navi_fusen03_off.jpg',
          href: '/ec/special/general/stationery/faq'
        }
      ],
      naviList2: [
        { name: '年賀状を書く時のマナー', href: '#a01' },
        { name: '宛て名を書く時のコツ', href: '#a02' },
        { name: 'キタムラの高品質年賀状', href: '#a03' }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
