






































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'general-summerhomework',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '夏休みの宿題に役立つ小学生向けの工作や実験特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラ専門店のスタッフがおすすめする、夏休み自由研究特集。小学生が楽しくひとりでできる工作から、本格的な天体観測や実験も取り揃えております、夏休みの宿題は早めに終わらせて、心おきなく遊びまくろう！'
      );
    const state = reactive({
      independentStudyList: [
        {
          href: '/ec/special/general/summerhomework/camera',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb02_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb02_off.gif'
        },
        {
          href: '/ec/special/general/summerhomework/moon',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb03_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb03_off.gif'
        },
        //{
        // href: '/ec/special/general/summerhomework/recipe',
        // onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb04_on.gif',
        // offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb04_off.gif'
        //},
        {
          href: '/ec/special/general/summerhomework/observation-diary',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb06_on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/summerhomework/ctb06_off.gif'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '安心・納得の電話注文'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '夏休み 自由研究特集',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350055974', '4545350055981', '4549212306396', '4549212306488'],
      mainProductList: [] as Array<ProductDetail>,
      uniqueProductJanCode: ['4961607300916', '4521718553566', '4961607300794', '4521718554457'],
      uniqueProductList: [] as Array<ProductDetail>,
      uniqueProductTextList: [
        'ミクロの世界をスマホで撮れるハンディ顕微鏡',
        '録音した声が高い声や低い声にチェンジ',
        '必要なアイテムがそろったベーシックな顕微鏡セット',
        'クリームソーダの食品サンプルをつくろう'
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // ユニーク商品
        const uniqueResult = await ProductService.fetchProducts(state.uniqueProductJanCode, true);
        state.uniqueProductList = uniqueResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
