






































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'trekking',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    orangeBtn: OrangeBtn,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'フォトトレッキング特集｜おすすめカメラバック｜カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '気になった風景や植物など楽しく写真を撮影する「フォトトレッキング」。登山におすすめのカメラバックやリュック、便利なカメラ用品や必要な装備をご紹介します｜フォトトレッキング特集'
      );

    const state = reactive({
      naviList: [
        {
          name: 'カメラバック',
          href: '#camerabag',
          src: '/ec/images2/special/camera/bag/feature/trekking/ic01.png'
        },
        {
          name: '便利グッズ',
          href: '#goods',
          src: '	/ec/images2/special/camera/bag/feature/trekking/ic02.png'
        },
        {
          name: 'お手入れグッズ',
          href: '#care',
          src: '/ec/images2/special/camera/bag/feature/trekking/ic03.png'
        },
        {
          name: 'プリントサービス',
          href: '#print',
          src: '/ec/images2/special/camera/bag/feature/trekking/ic04.png'
        }
      ],
      wifiCameraList: [
        {
          name: 'コンパクトデジカメ',
          src: 'https://shopimg.kitamura.jp/images/pd/802/7c5/4b3/a69/1f1/93c/e0a/605/af4/a10/73t/0jr/hya/0/TN.jpg',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%82%B3%E3%83%B3%E3%83%91%E3%82%AF%E3%83%88%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%82%AB%E3%83%A1%E3%83%A9&narrow18=0&s5=%E6%90%AD%E8%BC%89&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },
        {
          name: 'ミラーレス一眼',
          src: 'https://shopimg.kitamura.jp/images/pd/a3a/de9/c63/aa8/4c0/a81/838/5e0/00e/a92/9dh/w8x/nql/h/TN.jpg',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%83%9F%E3%83%A9%E3%83%BC%E3%83%AC%E3%82%B9%E4%B8%80%E7%9C%BC&narrow18=0&s5=%E6%90%AD%E8%BC%89&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },
        {
          name: 'デジタル一眼レフ',
          src: 'https://shopimg.kitamura.jp/images/pd/c17/4d2/669/5e0/4c8/989/33b/6f5/69a/37c/18x/u7x/pql/8/TN.jpg',
          href:
            'https://shop.kitamura.jp/ec/list?category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&narrow18=0&s5=%E6%90%AD%E8%BC%89&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/bag',
          img: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          alt: 'カメラバッグ・カメラケース特集'
        },
        {
          href: '/ec/special/camera/bag/trial',
          img: 'https://shopimg.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/camera/waterproof',
          img: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          alt: '防水カメラ・ウェアラブルカメラ特集'
        },
        {
          href: '/ec/special/camera/tripod',
          img: 'https://shopimg.kitamura.jp/images/banner/3253.gif',
          alt: 'おすすめ三脚特集'
        },
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shopimg.kitamura.jp/images/banner/1921.gif',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラバッグ・カメラケース特集',
          linkUrl: '/ec/special/camera/bag',
          disabled: false
        },
        {
          path: 'フォトトレッキング特集｜おすすめカメラバック',
          disabled: false
        }
      ],
      // ↓ ---- 登山におすすめのカメラバックとカメラ一覧 ----
      cameraBagJanCode: ['8024221717679', '8024221650464', '8024221721706', '4549212306488', '4545350055981'],
      cameraBagProductList: [] as Array<ProductDetail>,
      // ↓ ---- 登山におすすめのインナーケース ----
      innerCaseJanCode: ['2500000149030', '4961607571774', '4961607571828', '4975981772894'],
      innerCaseProductList: [] as Array<ProductDetail>,
      // ↓ ---- 登山におすすめカメラ用品一覧 ----
      cameraSuppliesJanCode: ['4977187311808', '0099461003767', '4977187290929', '8024221668315'],
      cameraSuppliesProductList: [] as Array<ProductDetail>,
      // ↓ ---- 大事なカメラやレンズのお手入れにおすすめカメラ用品一覧 ----
      lensCareJanCode: ['4977187325829', '4906238819697', '4975981527999', '4975981529597', '4975981823091'],
      lensCareProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 登山におすすめのカメラバックとカメラ一覧
        const cameraBagResult = await ProductService.fetchProducts(state.cameraBagJanCode, true);
        state.cameraBagProductList = cameraBagResult.items;
        // 登山におすすめのインナーケース
        const innerCaseResult = await ProductService.fetchProducts(state.innerCaseJanCode, true);
        state.innerCaseProductList = innerCaseResult.items;
        // 登山におすすめカメラ用品一覧
        const cameraSuppliesResult = await ProductService.fetchProducts(state.cameraSuppliesJanCode, true);
        state.cameraSuppliesProductList = cameraSuppliesResult.items;
        // 大事なカメラやレンズのお手入れにおすすめカメラ用品一覧
        const lensCareResult = await ProductService.fetchProducts(state.lensCareJanCode, true);
        state.lensCareProductList = lensCareResult.items;
      } catch (error) {
        // 登山におすすめのカメラバックとカメラ一覧
        state.cameraBagProductList = [] as Array<ProductDetail>;
        // 登山におすすめのインナーケース
        state.innerCaseProductList = [] as Array<ProductDetail>;
        // 登山におすすめカメラ用品一覧
        state.cameraSuppliesProductList = [] as Array<ProductDetail>;
        // 大事なカメラやレンズのお手入れにおすすめカメラ用品一覧
        state.lensCareProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    function loadImg(path: string) {
      return require('@/assets/categories/' + path);
    }
    return {
      ...toRefs(state),
      noimage,
      loadImg,
      formatPrice
    };
  }
});
