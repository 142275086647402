






































































































import Vue from 'vue';
import { computed } from '@vue/composition-api';
import UserMenu from '@/components/header/user-menu.vue';

export default Vue.extend({
  name: 'header-top',
  components: {
    'user-menu': UserMenu
  },
  props: {
    welcomeHide: {
      type: Boolean,
      required: false
    },
    narrowSlideFixed: {
      type: Boolean,
      required: false
    },
    simpleHeader: {
      type: Boolean,
      default: false
    }
  },
  setup: (_, context) => {
    /**
     * ユーザー名取得
     */
    const { authorizer } = context.root.$store;
    const userName = computed(() => {
      if (authorizer.user?.lastName && authorizer.user?.firstName) {
        return authorizer.user.lastName + ' ' + authorizer.user.firstName;
      } else {
        return 'ゲスト';
      }
    });

    return {
      userName
    };
  }
});
