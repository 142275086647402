export const CART_ERROR_LIST = [
  {
    code: 'EN101',
    message: '商品価格が更新されていますので、一度ブラウザを閉じてからあらためて表示価格をご確認ください。'
  },
  {
    code: 'EN102',
    message: 'すでに買い物カゴに同一の商品が入っています。数量は「買い物カゴ」ページにてご変更いただけます。'
  },
  {
    code: 'EN103',
    message: '申し訳ございませんが、買い物カゴに投入された商品は現在販売しておりません。'
  },
  {
    code: 'EN104',
    message: 'ご指定の商品は買い物カゴに入りませんでした（トクトク交換のお申込みと同時注文ができない商品となります）'
  },
  {
    code: 'EN105',
    message: 'ご指定の商品は買い物カゴに入りませんでした（トクトク交換のお申込みがある場合、下取り査定はお申込みいただけません）'
  },
  {
    code: 'EN106',
    message: ''
  },
  {
    code: 'EN107',
    message: ''
  },
  {
    code: 'EN108',
    message: 'ご指定の商品は買い物カゴに入りませんでした（下取り査定のお申込みと同時注文ができない商品となります）'
  },
  {
    code: 'EN109',
    message: 'ご指定の商品は買い物カゴに入りませんでした（下取り査定のお申込み時はラッピングでの注文はできません）'
  },
  {
    code: 'EN110',
    message: 'ご指定の商品は買い物カゴに入りませんでした（現在、販売を一時停止させて頂いております）'
  },
  {
    code: 'EN111',
    message: 'ご指定の商品は買い物カゴに入りませんでした（宅配限定の商品と店舗受取りの商品は同時購入ができません）'
  },
  {
    code: 'EN112',
    message: 'ご指定の商品は買い物カゴに入りませんでした（１注文で指定できる数量の上限を超過してます）'
  },
  {
    code: 'EN113',
    message: 'ご指定の商品は買い物カゴに入りませんでした。申し訳ございません。注文可能な在庫がありません。'
  },
  {
    code: 'EN114',
    message: 'ご指定の商品は買い物カゴに入りませんでした（１注文で指定できる商品種類あたりの数量の上限を超過してます）'
  },
  {
    code: 'EN115',
    message: 'ご指定の商品は買い物カゴに入りませんでした（１商品に指定できる数量の上限を超過してます）'
  },
  {
    code: 'EN116',
    message: 'ご指定の商品は買い物カゴに入りませんでした。申し訳ございません。現在ラッピングはご指定頂けません。'
  },
  {
    code: 'EN117',
    message: ''
  },
  {
    code: 'EN118',
    message: 'ご指定の商品は買い物カゴに入りませんでした（指定された購入オプションは現在無効です）'
  },
  {
    code: 'EN133',
    message: 'スマホロジセンターに在庫している「中古スマホ」は、ほかの商品と同時にカートへ投入できません。お手数おかけしますが、カート内商品の注文が完了してから再度カート投入の操作をお願いいたします。'
  },
  {
    code: 'EN134',
    message: 'スマホロジセンターに在庫している「中古スマホ」は、ほかの商品と同時にカートへ投入できません。お手数おかけしますが、カート内商品の注文が完了してから再度カート投入の操作をお願いいたします。'
  },
  {
    code: 'EN135',
    message: 'スマホロジセンターに在庫している「中古スマホ」は、リファービッシュ商品と同時にカートへ投入できません。お手数おかけしますが、カート内商品の注文が完了してから再度カート投入の操作をお願いいたします。'
  }
];
