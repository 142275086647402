






































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'eosr5mk2',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    splitContent: SplitContent,
    expensiveTradeIn: ExpensiveTradeIn,
    exampleImages: ExampleImages,
    shasha: ShaSha,
    watchInVideo: WatchInVideo,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '話題の新製品！キヤノン EOS R5 Mark II | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'キヤノン EOS R5 Mark II 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/7/18 10:00',
      fanValidTo: '2024/7/23 09:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/7/23 10:00',
      reserveValidTo: '2024/8/29 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/8/30 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      naviList2: [
        { naviItem: '価格', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '関連アクセサリー', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'キヤノン EOS R3',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292181661',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/1ae/f1e/096/736/ce6/aa0/afd/3a8/eb4/192/f21/5l3/4uk/v/L.jpg',
        txt: 'ソニー EOSR5MK2 ',
        day: '2024年8月31日(土)',
        tradeInImg: '/ec/images2/special/camera/feature/canon/eosr5mk2/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'キヤノン EOS R3',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292181661'
          },
          {
            name: 'キヤノン EOS R5',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157345'
          },
          {
            name: 'キヤノン EOS R6',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292157253'
          },
          {
            name: 'キヤノン EOS R6 MarkII',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292200508'
          },
          {
            name: 'キヤノン EOS 5D Mark IV',
            href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4549292075748'
          }
        ]
      },

      commonCharacteristicList: [
        {
          subtitle: '決定的な瞬間を逃さない高精度なAFや高速連写の実現',
          contents: [
            '高速解析・高速撮影を実現する新エンジンシステム「Accelerated Capture」とディープラーニング技術の融合による新たなAFシステム「Dual Pixel Intelligent AF」により、優れたトラッキング性能を実現',
            '被写体が交錯するシーンが多いチームスポーツなどでも被写体を追い続けるための強化されたトラッキング性能、人物が小さい場合でも検知可能な「上半身検知」、障害物が被写体の前を横切るシーンなどでも被写体の頭部とその前に重なる障害物を見分ける「頭部領域推定による障害物回避」により精度の高いトラッキングが可能',
            'サッカーのシュートなどの特定の動作の被写体をカメラが自動で認識し、素早くAFフレームを合わせる「アクション優先」機能を搭載',
            '電子シャッター時、AF／AE追従しながら最高約30コマ/秒を実現し、20コマ/秒、10コマ/秒などの中間のコマ速の設定が可能',
            '電子シャッター時のローリングシャッター歪みは「EOS R5」に対して歪み量が約40％に低減され、高速に動く被写体でも少ない歪みで撮影が可能',
            'ファインダーをのぞく瞳の動きでAF操作が行うことができる視線入力AFを搭載し、検出フレームレートを「EOS R3」比約2倍にすることでレスポンスを向上し、光学系も新規設計とすることで目の位置がずれた場合や眼鏡装着時の検出安定性も向上',
            'シャッターボタンを押したタイミングより最大約15コマ分遡って撮影することができる「プリ連続撮影」機能を搭載し、JPEG／RAW／HEIFにおいて切り出し作業なしで記録可能'
          ]
        },
        {
          subtitle: '約4500万画素フルサイズ裏面照射積層CMOSセンサーや「ニューラルネットワークノイズ低減」機能による高画質',
          contents: [
            '約4500万画素の高解像度と常用最高ISO51200（静止画撮影時）の高感度を両立しながら、高画質な静止画撮影を実現',
            'ボディー内５軸手ブレ補正機構を搭載。また、レンズ内光学式手ブレ補正機構を搭載したRFレンズでは協調制御に対応し、中心最大8.5段、周辺最大7.5段の手ブレ補正効果を発揮。さらに、広角特有の画面周辺でのブレを抑制する周辺協調制御にも対応',
            '「カメラ内アップスケーリング」機能では、アプリケーションなどを介することなく、撮影した画像（JPEG／HEIF）から最大約1億7900万画素の画像をカメラ内で生成し、トリミングしたい場合などに有効',
            'RAWで撮影した静止画をカメラ内で処理し、ノイズを低減した高画質な画像をカメラ内で生成する「ニューラルネットワークノイズ低減」機能を搭載'
          ]
        },
        {
          subtitle: '「Cinema EOS SYSTEM」との機能共通化による本格的な映像制作の実現',
          contents: [
            'フルサイズ画角での8K／60P RAW動画や4K／60P SRAW動画、4K／120P MP4動画記録をカメラ内部記録で実現',
            '動画撮影中の静止画記録にも対応し、動画撮影を停止せずに最高約7.5コマ/秒の連続撮影を可能とすることで、機材の簡略化など業務効率の向上に寄与',
            '大容量の編集用データとファイルサイズの軽いProxy動画をCFexpressカードとSDカードに同時記録することが可能。軽量なProxy動画を使用することで、PCへの送付や編集を効率化',
            '「Cinema EOS SYSTEM」と共通の仕様として、用途に応じて画質調整が可能な「カスタムピクチャー」や「Canon Log 3」と比較し、中間から暗部の階調性が高い「Canon Log 2」を搭載し、撮影用途に応じた最適な設定を選択することが可能',
            'カメラから離れた位置でも動画の記録状況を把握することができるタリーランプを搭載',
            '動画撮影ボタンを押す３秒前もしくは５秒前から映像を記録することが可能な「プレ記録」機能を搭載',
            '新製品の「クーリングファン CF-R20E（別売り）」を装着することにより、本体の温度上昇を抑制し、動画撮影可能時間を延長し、8K／30P時、120分以上の動画記録を実現',
            '「RF5.2mm F2.8 L DUAL FISHEYE」（2021年12月発売）を装着することで、180° 3D VR映像を撮影し、カメラ内で仕上がりイメージの確認が可能'
          ]
        },
        {
          subtitle: 'プロ・ハイアマチュアユーザーに求められる快適な操作性や通信機能の追及',
          contents: [
            '0.5型、約576万ドット、「EOS R5」比輝度約2倍の高精細パネルを採用し、快適な見えを実現した高精細電子ビューファインダーを搭載',
            '「EOS R5」同等の防じん・防滴性能を備え、マグネシウム合金を外装に採用することで高い堅牢性を実現',
            'EOSシリーズではじめてIEEE802.11ax規格相当に対応し、高速な無線通信を可能とするだけでなく、6GHz（インフラストラクチャーのみ）に対応することで広い周波数帯が使用可能となり、快適な通信性を実現',
            '新製品「バッテリーグリップBG-R20EP（別売り）」や「クーリングファン CF-R20EP（別売り）」を装着することで有線LAN高速規格2.5GBase-Tに対応し、安定した接続環境で大容量の静止画や動画を送信することが可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'キヤノン EOS R5 Mark II ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549292229141', '4549292229226'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- notice比較 予約開始までお待ちください ----
      noticecomparisonJanCodeList: ['', '', ''],
      // 結果格納用
      noticecomparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- notice比較 ----
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292229141', '4549292157345', '4960759909947', '4548736145573'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      //popup
      imgs: [
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_01.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_02.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_03.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_04.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_05.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_06.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_07.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_08.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_09.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_010.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_011.jpg',
        '/ec/images2/special/camera/feature/canon/eosr5mk2/pic_012.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        //notice 比較予約開始までお待ちください
        const noticecomparisonResult = await ProductService.fetchProducts(state.noticecomparisonJanCodeList, true);
        state.noticecomparisonDetailList = noticecomparisonResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
