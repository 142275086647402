












































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
//import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
//import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'fe24mm50mmf28g',
  components: {
    breadcrumbs: Breadcrumbs,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    //priceAndPopular: PriceAndPopular,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    // characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    watchInVideo: WatchInVideo,
    shasha: ShaSha,
    naviBtn: NaviBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    'product-list-by-special-id': ProductListBySpecialId,
    splitContent: SplitContent,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー FE 24-50mm F2.8 G [SEL2450G]| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー FE 24-50mm F2.8 G [SEL2450G] 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      fanValidFrom: '2024/2/22 0:00',
      fanValidTo: '2024/2/29 9:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2024/2/29 10:00',
      reserveValidTo: '2024/4/18 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2024/4/19 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: '価格', href: '#spec' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '関連アクセサリ-', href: '#acce' },
        { naviItem: '作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ソニー FE 24-105mm F4 G OSS [SEL24105G]',
        href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736074125',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/de5/a6c/3e9/1b8/ed5/6f5/0fb/eeb/ebf/ca7/ech/wq3/vey/3/M.jpg',
        txt: 'ソニー FE 24-50mm F2.8 G [SEL2450G]',
        day: '2024年05月31日(金)',
        tradeInImg: '/ec/images2/special/camera/feature/sony/fe24mm50mmf28g/reserve/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: 'ソニー FE 24-105mm F4 G OSS [SEL24105G]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736074125' },
          { name: 'ソニー Vario-Tessar T* FE 24-70mm F4 ZA OSS [SEL2470Z]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4905524934786' },
          { name: 'ソニー FE 24-70mm F2.8 GM [SEL2470GM]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736019515' },
          { name: 'ソニー FE 28-70mm F3.5-5.6 OSS [SEL2870]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4905524956931' },
          { name: 'ソニー FE 28-60mm F4-5.6 [SEL2860]', href: 'https://www.net-chuko.com/ec/sell/item-list?keyword=4548736121317' }
        ]
      },
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },

        {
          path: 'ソニー FE 24-50mm F2.8 G [SEL2450G]',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736141438'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['', ''],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [''],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //popup
      imgs: [
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/fe24mm50mmf28g/pic_01.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/fe24mm50mmf28g/pic_02.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/fe24mm50mmf28g/pic_03.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/sony/fe24mm50mmf28g/pic_04.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      console.log(fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        console.log(formatToday);

        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
      // ページ内スクロール
      setTimeout(() => {
        const hash = context.root.$route.hash;
        if (hash) context.root.$vuetify.goTo(hash);
      }, 100);
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
