












































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import SubText from '@/components/common/special/subtext.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: '1067',
  components: {
    breadcrumbs: Breadcrumbs,
    subText: SubText,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = '1.0型の高画質『ソニーCyber-shot DSC-RX100M3』 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー高画質デジタルカメラRX100 III新製品特集。SONY RX100M3の価格・スペックやお得な情報をチェック！全国1000店舗のカメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '1.0型の高画質『ソニーCyber-shot DSC-RX100M3』',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----

      newProductList: [
        {
          src: 'https://shopimg.kitamura.jp/images/banner/554.jpg',
          href: '/special/sale-fair/camera/feature/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/713.gif',
          href: '/special/sale-fair/camera/feature/nikon/df/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/1042.gif',
          href: '/special/sale-fair/camera/feature/olympus/em10/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/1193.gif',
          href: '/special/sale-fair/camera/feature/sony/a7s/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/945.jpg',
          href: '/special/sale-fair/camera/feature/sony/4k/'
        },
        {
          src: 'http://shopimg.kitamura.jp/images/banner/1016.jpg',
          href: '/special/sale-fair/camera/feature/sony/actioncam/'
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4905524985917'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
