































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'kaden-bottle-office',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '水筒・ステンレスボトル・タンブラー特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'お子様におすすめの水筒や、スポーツ後にサッと飲める便利な水筒、オフィス用に軽くてスマートな水筒など、おすすめの水筒・ステンレスボトル・タンブラーを集めました。'
      );
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '水筒・ステンレスボトル・タンブラー特集',
          linkUrl: '/ec/special/kaden/bottle',
          disabled: false
        },
        {
          path: 'オフィスにおすすめ水筒一覧',
          disabled: true
        }
      ],
      naviList: [
        {
          href: '/ec/special/kaden/bottle/kids',
          alt: 'お子様用',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot01-on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot01-off.gif'
        },
        {
          href: '/ec/special/kaden/bottle/sports',
          alt: 'スポーツ用',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot02-on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot02-off.gif'
        },
        {
          href: '/ec/special/kaden/bottle/office',
          alt: 'オフィス用',
          onImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot03-on.gif',
          offImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/kaden/bottle/sui_bot03-off.gif'
        }
      ],
      waterBottleSpecialFeatureList: [
        {
          title: '運動会・遠足・通学用に！お子様でも持ち運びやすい♪',
          txt:
            'コップ飲み、ダイレクト飲みの2通り対応で、ホットもアイスもOK！一年中使えます。\nお子様でも持ち運びやすい、軽くてコンパクトなステンレスボトルです。',
          href: '/ec/special/kaden/bottle/kids',
          pointtxt: ['ダイレクト&コップ飲み\n2通り対応！', 'ホットもアイスもOK!\n一年中使える']
        },
        {
          title: 'スポーツシーンや暑い夏の水分補給に！',
          txt:
            '保冷専用クールボトル！いつでも冷たいドリンクが飲める、優れた保冷性能と機能性がおすすめ！\nワンプッシュで開閉できるダイレクト飲み口なので、スポーツ中でもすぐ水分補給ができるのが便利です。\nまた、広い口径で、氷を入れやすく、お掃除も底まで直洗いできます。',
          href: '/ec/special/kaden/bottle/sports',
          pointtxt: ['片手で簡単\nワンタッチオープン！', 'ワイドな口径で\n大きな氷が入る！']
        },
        {
          title: 'オフィスバッグにもすっきり収納！',
          txt:
            'オフィスでも定番のステンレスボトル！\n持ち運びに便利な保温・保冷マグボトルや、デスクで使えるタンブラー！\n性能だけでなく、見た目にもこだわった商品を集めました。\nお気に入りのデザインを選んで、自分らしさを演出しちゃいましょう★',
          href: '/ec/special/kaden/bottle/office',
          pointtxt: ['オフィス用にピッタリの\nエレガントなデザイン', '樹脂カバーつきで\nなめらかな飲み口']
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/kaden/bento',
          img: 'https://shopimg.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当作りにおすすめキッチン商品'
        },
        {
          href: '/ec/special/general/graduation_entrance',
          img: 'https://shop.kitamura.jp/images/banner/1929.gif',
          alt: '卒業・入学特集'
        },
        {
          href: '/ec/special/general/sportsday',
          img: 'https://shop.kitamura.jp/images/banner/2006.gif',
          alt: 'パパ・ママ応援！運動会特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文のご案内'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
