










































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg200 from '@/components/common/special/top-title-img200.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import NaviBtn3 from '@/components/common/special/navi-btn3.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'smartphone',
  components: {
    breadcrumbs: Breadcrumbs,
    TopTitleImg200: TopTitleImg200,
    urlcopy: Urlcopy,
    subText: SubText,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn3: NaviBtn3,
    notfound: Notfound,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  //★スライド
  props: {},
  template: '#app-template',
  data: () => ({
    slides: [
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/smartphone/rank01.png',
        image: 'https://shopimg.kitamura.jp/images/pd/312/cbd/0be/bfc/6ee/6bf/5bf/9d1/a73/0f4/aa3/q8b/jro/n/M.png',
        linkUrl: '/ec/list?keyword3=2239550000163&type=u',
        txt: 'Apple iPhone XR',
        name: 'Apple iPhone XR'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/smartphone/rank02.png',
        image: 'https://shopimg.kitamura.jp/images/pd/ff9/9c5/058/0f4/0d3/4f0/fe9/c9e/c8f/ac1/ad5/sob/1l4/k/M.png',
        linkUrl: '/ec/list?keyword3=2239550000187&type=u',
        txt: 'Apple iPhone 8',
        name: 'Apple iPhone 8'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/smartphone/rank03.png',
        image: 'https://shopimg.kitamura.jp/images/pd/ea0/9b2/39e/3d9/3c4/512/920/e9b/be7/dc5/b7i/8pp/sl4/6/M.png',
        linkUrl: '/ec/list?keyword3=2239550000132&type=u',
        txt: 'Apple iPhone 11',
        name: 'Apple iPhone 11'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/smartphone/rank04.png',
        image: 'https://shopimg.kitamura.jp/images/pd/bf3/621/027/2cf/838/c0c/373/b0d/388/9e5/3as/vlu/rc6/f/M.png',
        linkUrl: '/ec/list?keyword3=2239550000149&type=u',
        txt: 'Apple iPhone XS',
        name: 'Apple iPhone XS'
      },
      {
        iconimage: 'https://shop.kitamura.jp/ec/images2/special/reuse/smartphone/rank05.png',
        image: 'https://shopimg.kitamura.jp/images/pd/667/70c/16b/f81/3ab/790/702/832/178/81b/36s/cjt/8i6/0/M.png',
        linkUrl: '/ec/list?keyword3=2239550000101&type=u',
        txt: 'Apple iPhone SE2',
        name: 'Apple iPhone SE2'
      }
    ],
    model: null
  }),
  //スライダー

  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '中古スマホ販売｜SIMフリー/iPhone(アイフォン)| カメラのキタムラネットショップ (kitamura.jp)';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '中古スマホ販売 ｜SIMフリー/iPhone(アイフォン) 価格・発売日・スペック・お得な情報をチェック！中古のスマートフォンもカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- 1 期間 ----
      fanValidFrom: '2023/01/02 00:00',
      fanValidTo: '2023/06/02 14:59',
      // ↓ ---- 2 期間 ----
      reserveValidFrom: '2023/06/02 15:00',
      reserveValidTo: '2023/06/12 23:59',
      // ↓ ---- 3 期間 ----
      releaseValidFrom: '2023/06/13 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      model: null,

      naviList3: [
        {
          naviItem: 'iPhone 11\nPro',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000118&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone 11\nPro Max',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000125&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone XS',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000149&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone XS\nMax',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000156&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        }
      ],
      naviList: [
        {
          naviItem: 'iPhone 11\nPro',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000118&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone 11\nPro Max',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000125&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone XS',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000149&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        },
        {
          naviItem: 'iPhone XS\nMax',
          href: 'https://shop.kitamura.jp/ec/list?keyword3=2239550000156&n1c=5&type=u&limit=40&page=1&sort=price_asc'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '中古スマホ販売｜SIMフリー（iPhone・Android・iPad）',
          disabled: true
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['', '', ''],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品
      mainProductJanCode: [''],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['', '', '', ''],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
