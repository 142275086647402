


















































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: '7m3',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    exampleImages: ExampleImages,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'SONY フルサイズミラーレスベーシックモデル 新製品 α7 III | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'α7 IIIフルサイズミラーレスベーシックモデル。α7 II、α7R IIIとの比較や、価格・スペック・お得な情報をチェック！最大48回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得な情報', href: '#price' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: '新開発35mmフルサイズ裏面照射型センサー搭載。研ぎ澄まされた描写性能',
          contents: [
            '有効約2420万画素35mmフルサイズ裏面照射型CMOSセンサーと、α7 II比 約1.8倍の高速処理を実現した新画像処理エンジンBIONZ Xを搭載',
            'イメージセンサーからの読み出し速度を約2倍に高速化したフロントエンドLSIを搭載。常用ISO感度最高51200の広い感度域やα7 II比で最大1.5段分の画質向上、約15ストップの広ダイナミックレンジを実現',
            '色再現性をより高め、人物撮影における肌色や、鮮やかな花などを自然な印象で描写'
          ]
        },
        {
          subtitle: '被写体を捉えて離さないAF性能',
          contents: [
            'α9に搭載の、撮像エリア全体の約93%をカバーする693点像面位相差AFセンサーを搭載。',
            'コントラストAF枠が425点に多分割化し、検出精度が大幅に向上。AF速度、追随性能もα7 II比 約2倍に進化',
            '「瞳AF」機能がさらに進化。瞳にピントを合わせるのが難しい場面も被写体を捉え続けます'
          ]
        },
        {
          subtitle: 'AF/AE追随 最高約10コマ/秒連写を実現。決定的瞬間を捉えるスピード性能',
          contents: [
            '最新のシャッターユニットと画像処理システムの進化により、フル解像度で最高約10コマ/秒のAF/AE追随高速連写を実現',
            '最大177枚の連写持続性能で、撮り逃しが許されないシーンでも安心',
            '無音・無振動のサイレント撮影時もフル解像度でAF/AE追随最高約10コマ/秒の連写が可能'
          ]
        },
        {
          subtitle: '撮影の自由度を高める信頼性と操作性',
          contents: [
            'NP-FW50の約2.2倍の容量を持つ高容量バッテリーNP-FZ100に対応。約710枚の静止画撮影が可能',
            '同時記録やリレー記録に対応する2つのメディアスロット(スロット1はUHS-II対応)',
            'ピントを合わせたい被写体を瞬時に選択できるタッチフォーカス機能を搭載'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01l',
          img: 'http://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'SONY フルサイズミラーレスベーシックモデル 新製品 α7 III',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736079625'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736079625', '4548736001657', '2239530006574'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736079625',
        '4548736079717',
        '4548736047419',
        '4548736064638',
        '4548736064621',
        '4548736064522',
        '4548736082045',
        '4548736082052',
        '4548736082069'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
