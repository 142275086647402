







































































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';
import LoginMenu from './login-menu.vue';

export default Vue.extend({
  name: 'user-menu',
  components: {
    'login-menu': LoginMenu
  },
  setup: (_, context) => {
    const state = reactive({
      isLoggedIn: false,
      tPoint: 0,
      isHovering: false,
      cartItemCount: 0
    });

    // Tポイント、カート内商品数を検知
    watch(
      () => [context.root.$store.authorizer.isLoggedIn, context.root.$store.authorizer.user],
      ([isLoggedIn, user]) => {
        state.isLoggedIn = isLoggedIn;
        state.tPoint = user?.point ? user.point : 0;
        state.cartItemCount = user?.cartItemCount;
      }
    );

    return {
      ...toRefs(state)
    };
  }
});
