




















































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'd750',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン Nikon D750 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'ニコン Nikon D750！比較や、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！');
    const state = reactive({
      naviList: [
        { naviItem: '特徴', href: '#point' },
        { naviItem: 'スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images2' }
      ],
      characteristicList: [
        {
          subtitle: 'ホールド性に優れた小型・軽量・薄型ボディ',
          contents: ['内部構造を大幅に見直し、チルト式液晶を搭載しながらもFXフォーマット機最薄・最軽量を実現※2014年9月現在']
        },
        {
          subtitle: 'FXフォーマット機ならではの高画質',
          contents: ['上位機種D810を上回る高感度性能、ワイドなダイナミックレンジ、最新のピクチャーコントロールで撮影シーンを選ばず高画質を実現']
        },
        {
          subtitle: '様々なシャッターチャンスを逃さない高い信頼性とWi-Fi内蔵',
          contents: [
            '肉眼で見えにくい-3EVの暗いシーンでも対応できるAF、最高約6.5コマ/秒のフルサイズ機トップクラスの連写速度、視野率約100％の光学ファインダーで最高の瞬間を逃しません。',
            '撮影後もストレスを感じないFXフォーマット機初の内蔵Wi-Fi機能搭載！'
          ]
        }
      ],
      characteristicList2: [
        {
          subtitle: '小型軽量ボディに高速性と高画質を兼ね揃えた新しいFXフォーマットモデル',
          contents: [
            '有効2432万画素、新開発のニコンFXフォーマットCMOSセンサーと画像処理エンジン「EXPEED 4」の組み合わせによって、高品位画質を実現',
            'ニコンFXフォーマットCMOSセンサーが、幅広いダイナミックレンジ、豊かで滑らかな階調表現、ノイズが少ない優れた高感度性能を実現',
            '高い鮮鋭感を保ったまま、暗部の黒浮き、静止画の色ノイズ、動画のランダムノイズを効果的に低減可能な画像処理エンジン「EXPEED 4」搭載',
            '暗所でも美しい写真が残せる、常用感度ISO 100～12800を実現',
            '撮影シーンの情報を分析し、自動制御機能の精度を高める、91KピクセルRGBセンサー(約91,000ピクセル)搭載',
            '最高約6.5コマ/秒、最大100コマの高速連続撮影が可能',
            '撮影画面を広くカバーする51点のフォーカスポイントで被写体を的確に捉えるAFシステム搭載',
            '自由なアングルで撮影できる、ニコンFXフォーマットモデル初採用のチルト式液晶モニター採用'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Nikon D750',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759143846', '4960759144232', '4960759144553'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759143846', '4960759143532'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759143846',
        '4960759144232',
        '4960759144553',
        '4975981733291',
        '4961607252727',
        '4961607252772',
        '4960759144041',
        '4960759028792',
        '4960759128713',
        '4960759026002',
        '4960759029157'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
