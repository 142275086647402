




















































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { formatPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'recommended-tripod',
  props: {
    itemList: {
      type: Array,
      required: true
    },
    itemList2: {
      type: Array,
      required: false
    },
    itemList3: {
      type: Array,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    src2: {
      type: String,
      required: false
    },
    src3: {
      type: String,
      required: false
    },
    txt: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'red'
    },
    type: {
      type: Number,
      default: 1
    },
    images: {
      type: Array
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state),
      formatPrice
    };
  }
});
