









































































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'album',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '写真アルバム おすすめシリーズ紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '写真アルバム シリーズ紹介｜大量収納アルバムから、プレゼントにおすすめなアルバムまで。写真アルバムの販売はカメラのキタムラネットショップにおまかせください！'
      );

    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '見て選ぶ・見て探す。おすすめ写真アルバム紹介',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----
      category: 'all',
      narrowingList: [
        { src: '/ec/images2/special/camera/album/btn-print-w.png', value: 'all' },
        { src: '/ec/images2/special/camera/album/po.png', value: 'po' },
        { src: '/ec/images2/special/camera/album/fr.png', value: 'fr' },
        { src: '/ec/images2/special/camera/album/tai.png', value: 'tai' },
        { src: '/ec/images2/special/camera/album/gift.png', value: 'gift' },
        { src: '/ec/images2/special/camera/album/tokushu.png', value: 'tokushu' }
      ],
      albumList: [
        {
          category: ['all', 'po'],
          href: '/ec/pd/4547410515046',
          name: 'フジフイルム instax mini 12 チェキアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/62f/a21/ef8/9bf/40a/0c9/ffb/28f/7f2/313/b9p/139/kmp/a/M.jpg',
          txt: 'チェキminiプリントを108枚収納できる手帳型アルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po'],
          href: '/ec/list?category=%E3%82%A2%E3%83%AB%E3%83%90%E3%83%A0&keyword=KT-PA01-L40&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          name: 'オリジナル KT-PA01-L40リネンポケットアルバム40 Lサイズ 40枚',
          src: 'https://shopimg.kitamura.jp/images/pd/bb1/714/e08/b05/f45/215/f8c/bf8/2c0/31e/c5y/wdm/mcj/g/M.jpg',
          txt: 'ソフトな風合いのリネン生地を使ったオリジナルアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/382',
          name: 'オリジナル Lサイズ ミニポケットアルバム KMPL-40',
          src: 'https://shopimg.kitamura.jp/images/pd/f49/bc0/4a8/c8e/4e0/b09/a07/368/3a3/bf5/b0k/ivc/7vc/s/M.jpg',
          txt: 'L判サイズが40枚収納できるポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/pd/4547410455151',
          name: 'フジフイルム HSP-270AL ハーフサイズプリント用ポケットアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/3e1/21f/080/fce/4bd/c6d/8e5/4a4/9c7/caa/83z/rdz/jw0/b/M.jpg',
          txt: 'かわいらしいデザインのハーフサイズ用ポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'tai', 'fr'],
          href: '/ec/special/camera/album/375',
          name: 'ナカバヤシ 20L-89 フエルアルバム 白フリー台紙 20枚 ティディーワークス',
          src: 'https://shopimg.kitamura.jp/images/pd/c5a/0a9/592/123/3fa/045/a3f/881/24b/d25/6es/l8x/s65/l/M.jpg',
          txt: 'Lサイズのビス式フリーアルバム',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'po'],
          href: '/ec/list?keyword=KPH-168&narrow18=0',
          name: 'ナカバヤシ KPH-168 オリジナルフォトフォルダー L・KG・パノラマ判/3段',
          src: 'https://shopimg.kitamura.jp/images/pd/977/20f/e52/90d/9a9/910/ad8/855/a6b/6ac/bes/5ge/v2v/w/M.jpg',
          txt: 'カメラのキタムラオリジナルディズニーポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'tai', 'po'],
          href: '/ec/special/camera/album/1532',
          name: 'セキセイ KP-126 フォトアルバム 高透明 Lサイズ 240枚収納',
          src: 'https://shopimg.kitamura.jp/images/pd/66f/b43/a3b/8a9/4fb/40f/035/511/1a0/710/6aw/ata/pbo/n/M.jpg',
          txt: 'L判写真が240枚収容',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po', 'fr'],
          href: '/ec/special/camera/album/3765',
          name: 'ナカバヤシ A-HR ハルマー アルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/ab6/969/e20/e91/ba8/5a6/f28/217/44f/d04/b4w/hjs/ov6/1/M.jpg',
          txt: '落ち着いた色合いのアルバム',
          type: 'ポケットアルバム/フリーアルバム'
        },
        {
          category: ['all', 'gift'],
          href: '/ec/special/camera/album/3573',
          name: 'ナカバヤシ アKA-SWF フォトクロッキー リング',
          src: 'https://shopimg.kitamura.jp/images/pd/457/593/e63/80f/ef6/e5e/b21/b28/b3d/770/2c6/l5l/x66/6/M.jpg',
          txt: '優しい色合いのリング式アルバム',
          type: 'クラフトアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/3443',
          name: 'セキセイ XP-89P ハーパーハウス ましかくアルバム スペア台紙',
          src: 'https://shopimg.kitamura.jp/images/pd/b13/e43/a53/d23/ee4/d3b/853/335/06a/9d4/bfa/ht4/kyk/5/M.jpg',
          txt: 'ましかくアルバム用クラフト紙のスペア台紙',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'tai', 'po'],
          href: '/ec/special/camera/album/369',
          name: 'ナカバヤシ IA-12-A4N プロフォリオ ザ・オリジナル A4判 24P',
          src: 'https://shopimg.kitamura.jp/images/pd/31a/00d/a77/07f/1a7/25a/270/fb4/f4a/ecd/44s/4fi/y9o/h/M.jpg',
          txt: 'アートや写真の保管に最適',
          type: 'ポケットアルバム'
        },
        //{
        //   category: ['all', 'gift', 'po'],
        //   href: '/ec/special/camera/album/2922',
        //  name: 'セキセイ HK-5783 ホックアルバム ゴールド',
        //  src: 'https://shopimg.kitamura.jp/images/pd/137/3d7/de2/c45/06f/2d8/a13/a84/67a/6bd/96k/k38/hqy/m/M.jpg',
        //  type: 'ポケットアルバム'
        // },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/pd/4902205233930?r=',
          name: 'ナカバヤシ ア-PAL-101-3P ミニポケットアルバム Lサイズ カラフル 24枚3P',
          src: 'https://shopimg.kitamura.jp/images/pd/b88/51f/c44/e53/0bc/ff5/fc4/ad0/5b5/eea/b54/r8f/kyj/e/M.jpg',
          txt: 'ミニポケットアルバム3冊セット',
          type: 'ポケットアルバム'
        },
        //{
        //  category: ['all', 'gift', 'po', 'tai'],
        //  href: '/ec/special/camera/album/2413',
        //  name: 'ナカバヤシ ア-TPL-161-BR 折りたたみアルバム クロス/L判4面160枚 ブラウン',
        //  src: 'https://shopimg.kitamura.jp/images/pd/4aa/1e7/89b/41f/c4c/144/74c/fd8/6e3/ef5/a1y/4k2/vlb/g/M.jpg',
        //  type: 'ポケットアルバム'
        //},
        {
          category: ['all', 'tai', 'po'],
          href: '/ec/special/camera/album/2309',
          name: '万丈 AO-600BR メガアルバム600 ATSUI OMOI ブラウン',
          src: 'https://shopimg.kitamura.jp/images/pd/a1b/bd6/ad1/d6a/7de/e21/ac2/ac1/817/ad8/456/phe/flb/k/M.jpg',
          txt: 'L・KG判の写真が600枚収納できる大容量アルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'tai', 'po'],
          href: '/ec/special/camera/album/561',
          name: 'ナカバヤシ フォトホルダー L判6面 240ポケット PH6L-1024-D ブラック',
          src: 'https://shopimg.kitamura.jp/images/pd/56b/1c3/653/ddb/254/c2e/2b1/49f/019/948/765/el4/x8n/4/M.jpg',
          txt: 'L判写真が見開きで12枚収納可能',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/472',
          name: 'セキセイ XP-2780 フレームポケットアルバム L80枚 リネン',
          src: 'https://shopimg.kitamura.jp/images/pd/5e9/784/90d/ca6/479/159/096/992/2c6/7fe/4ci/ec6/hqe/6/M.jpg',
          txt: 'Lサイズの写真用紙が80枚収容',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/list?keyword=%20TCPK-6L&narrow18=0',
          name: 'ナカバヤシ TCPK-6L-240 セラピーポケットアルバム L判6面',
          src: 'https://shopimg.kitamura.jp/images/pd/1dd/91d/032/153/1fb/135/653/757/a80/330/69m/ujr/asg/7/M.jpg',
          txt: 'L判写真が見開きで12枚収納できるポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/1851',
          name: 'セキセイ XP-4700 ハーパーハウス フレームアルバム L判100枚',
          src: 'https://shopimg.kitamura.jp/images/pd/654/47e/e75/245/99b/eec/df1/3b7/32a/0be/dbd/6oe/1wg/q/M.jpg',
          txt: 'ギフトにも最適、高級感のあるアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/1853',
          name: 'セキセイ XP-5308 ライトフリーアルバム フレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/f6b/5f6/c93/c2d/fb5/68e/8b6/fe7/86f/f86/48v/ckw/gnq/n/M.jpg',
          txt: '台紙が増やせるビスタイプ',
          type: 'フリーアルバム'
        },

        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/396',
          name: 'ナカバヤシ ア-PL-504-1-B 504枚大容量ポケットアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/9f9/b39/556/b58/58e/8f1/f3f/6de/3ed/90d/c2q/cmj/le1/g/M.jpg',
          txt: '504枚収納できる大容量のアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift'],
          href: '/ec/special/camera/album/1192',
          name: 'セキセイ FU-2161 ふうあい スクラップブック コラージュ向き クラフト台紙',
          src: 'https://shopimg.kitamura.jp/images/pd/41b/dc4/01a/536/381/936/fb9/5fd/18f/8ae/2cv/wcl/c5f/v/M.jpg',
          txt: 'コラージュ向きの厚いクラフト台紙',
          type: 'クラフトアルバム'
        },
        {
          category: ['all', 'po'],
          href: '/ec/special/camera/album/1339',
          name: 'キング レザーポケットアルバム L判',
          src: 'https://shopimg.kitamura.jp/images/pd/f76/c1f/429/c33/c3f/aa9/c27/5a6/83d/d3b/9dv/pyk/8uj/m/M.jpg',
          txt: '良質な雰囲気のレザー調アルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/922',
          name: 'ナカバヤシ 浮世絵アルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/3d8/3f3/cda/ed7/850/f34/544/da1/2a9/bb6/639/ax9/gcs/n/M.jpg',
          txt: '浮世絵デザインのアルバム',
          type: 'ポケットアルバム/フリーアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/669',
          name: 'ナカバヤシ スウィートカラーズ 100年台紙アルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/77d/81c/842/2f9/23e/e11/066/139/297/393/d83/hkl/pqx/6/M.jpg',
          txt: '写真が映える黒台紙のアルバム',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'gift'],
          href: '/ec/special/camera/album/775',
          name: 'セキセイ XP-4310 カジュアルアルバム ドット フリー台紙タイプ',
          src: 'https://shopimg.kitamura.jp/images/pd/7e5/cbd/61f/b5c/324/6af/8a7/411/2c0/6ec/85q/gue/bsg/h/M.jpg',
          txt: '台紙がドット柄のかわいいフリーアルバム',
          type: 'クラフトアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/832',
          name: 'ナカバヤシ アカ-CPL PPポケットアルバム キャンディカラー',
          src: 'https://shopimg.kitamura.jp/images/pd/1b5/9a3/eaf/4db/de1/de5/81a/88d/be3/0bc/f8d/csj/k03/w/M.jpg',
          txt: 'プリントを出し入れしやすいエンボス入白台紙',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po', 'tai'],
          href: '/ec/special/camera/album/339',
          name: 'ナカバヤシ BPL-240 ブック式ポケットアルバム 240枚',
          src: 'https://shopimg.kitamura.jp/images/pd/4bc/270/208/ef1/e75/94e/062/683/19f/106/33x/nji/nfm/p/M.jpg',
          txt: '高級感のある背丸製本で台紙',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'po', 'tai'],
          href: '/ec/special/camera/album/340',
          name: 'セキセイ XP-3250 フレームアルバム Lサイズ',
          src: 'https://shopimg.kitamura.jp/images/pd/ed8/01f/d42/712/e70/538/9c4/47e/a27/535/4bq/61o/55w/1/M.jpg',
          txt: 'Lサイズ写真が200枚収容できる大容量タイプ',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po'],
          href: '/ec/special/camera/album/355',
          name: 'セキセイ レミニッセンス ポケットアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/a0e/749/a96/40c/878/b46/f63/d76/f9d/c35/cd4/mak/95f/w/M.jpg',
          txt: '手触りや質感が良い布製表紙',
          type: 'ポケットアルバム'
        },
        //{
        //  category: ['all', 'gift', 'po', 'tai'],
        //  href: '/ec/special/camera/album/390',
        //  name: '万丈 AO-1200PK メガアルバム ATSUI OMOI ピンク',
        //  src: 'https://shopimg.kitamura.jp/images/pd/7f1/c81/b01/c79/893/299/030/c57/f08/7da/31z/66a/d90/f/M.jpg',
        //  type: 'ポケットアルバム'
        //},
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/351',
          name: 'ナカバヤシ COT-A-BK-P ことりっぷ かける～の台紙 ブック式アルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/085/477/86a/539/c7c/799/57b/936/e4e/47b/8c3/9yu/qy1/f/M.jpg',
          txt: '女性に大人気の「ことりっぷ」×ナカバヤシのアルバム',
          type: 'ブック式・ポケットアルバム他'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/359',
          name: 'セキセイ XD-120LP カケルアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/0e6/1e5/499/ad2/4a0/754/094/c4e/9bc/25c/a4h/mtv/mt8/r/L.jpg',
          txt: 'メッセージが書き込めるカケルアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po'],
          href: '/ec/special/camera/album/367',
          name: 'セキセイ フォトアルバム 高透明',
          src: 'https://shopimg.kitamura.jp/images/pd/a7a/92a/bd5/5ef/663/d54/f55/2b3/4d9/1f2/49k/53f/x67/p/M.jpg',
          txt: '写真が映える高透明ポケット採用',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/358',
          name: 'ナカバヤシ アE-MB ブック式アルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/989/048/d6d/251/c11/2d5/5d8/c87/2c9/584/97d/pqe/cqf/7/M.jpg',
          txt: '写真が映えるブラック台紙仕様',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/364',
          name: 'ナカバヤシ Digio デジタルフリーアルバム「プラフィーネ」',
          src: 'https://shopimg.kitamura.jp/images/pd/1b7/37a/dac/36b/79c/fe4/b6f/ac0/3bc/83a/140/fi8/9r4/p/L.jpg',
          txt: '表紙は高級感のある布張りで人気のドット柄',
          type: 'フリーアルバム'
        },
        //{
        //  category: ['all', 'gift'],
        //  href: '/ec/special/camera/album/362',
        //  name: 'チクマ ブックタイプアルバム NEWハートスケープ 窓付きタイプ 2L 6P ホワイト [中枠2L・Lタイプ] (17756-2)',
        //  src: 'https://shopimg.kitamura.jp/images/pd/089/6ea/3d8/b29/f5e/195/db4/2ed/66d/95b/021/0ai/ad4/s/M.jpg',
        //  type: 'ブックタイプアルバム'
        //},
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/388',
          name: 'ナカバヤシ フォトランク フレームケースアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/092/65c/e14/963/141/836/b7d/5bd/84d/b62/b9v/hmq/8gu/w/M.jpg',
          txt: '写真立てのようなアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'tai', 'po'],
          href: '/ec/special/camera/album/1850',
          name: 'ナカバヤシ ポケットアルバム フォトグラフィリア',
          src: 'https://shopimg.kitamura.jp/images/pd/df0/dbb/a05/32e/ce5/291/4b1/a24/6d6/397/f49/kb2/mqd/f/M.jpg',
          txt: 'PP製黒台紙のポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po', 'fr', 'gift'],
          href: '/ec/special/camera/album/379',
          name: 'ナカバヤシ 外ビス式 布クロスフリーアルバム「テラコッタ」',
          src: 'https://shopimg.kitamura.jp/images/pd/281/a03/be9/2bc/cb0/1ee/259/300/f0c/fbf/3ff/56f/xqn/j/M.jpg',
          txt: 'クラフト台紙を使用した雑貨スタイルアルバム',
          type: 'ポケットアルバム/フリーアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/342',
          name: 'ナカバヤシ アH-A4B-170-B ブック式アルバム クラシック A4 ブルー',
          src: 'https://shopimg.kitamura.jp/images/pd/65d/b70/99a/95d/b16/5f7/322/6eb/b83/7a3/389/auv/rk3/4/L.jpg',
          txt: '耐久性にすぐれた100年台紙',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/374',
          name: 'ナカバヤシ フエル誕生用 Lサイズ',
          src: 'https://shopimg.kitamura.jp/images/pd/0a8/3be/0f5/82b/c0e/775/11f/e17/cb1/a8e/71x/4j8/cjl/4/L.jpg',
          txt: '誕生記念ギフトにピッタリなアルバム',
          type: 'フリーアルバム'
        },

        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/392',
          name: 'ナカバヤシ フォトホルダー',
          src: 'https://shopimg.kitamura.jp/images/pd/882/229/f82/d74/27f/84c/fdd/fde/82b/0ea/ce7/oiy/d6p/b/M.jpg',
          txt: '手軽に写真収納できるポケットアルバム',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'gift', 'fr'],
          href: '/ec/special/camera/album/395',
          name: 'ナカバヤシ ドゥファビネ フエルアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/017/92b/fa6/f01/558/35c/a76/ad1/cb2/ba8/0fy/gcl/kr7/i/M.jpg',
          txt: '雑貨テイストのおしゃれなアルバム',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/398',
          name: 'フジカラー プロフェッショナルプリントファイルA4・ワイド6切',
          src: 'https://shopimg.kitamura.jp/images/pd/ea5/2c2/d6d/a3e/d62/f78/78a/c5c/3fa/359/659/h2o/lkb/5/M.jpg',
          txt: 'ポリプロピレン製',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/list?keyword=PH6TL-1240&narrow18=0',
          name: 'ナカバヤシ PH6TL-1240 L判タテ6面フォトホルダー クリア',
          src: 'https://shopimg.kitamura.jp/images/pd/c01/26a/630/f59/a51/3a4/69d/e2a/6f2/10e/c16/scq/bge/7/M.jpg',
          txt: 'L判タテ写真を大量収納できるフォトホルダー',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/393',
          name: 'ナカバヤシ セラピーカラー ポケットアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/2ec/cb8/5bb/4e6/4eb/38c/194/509/999/e19/b8k/7rz/2hr/3/M.jpg',
          txt: 'デスク周りをお気に入りカラーでコーディネート',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'fr'],
          href: '/ec/special/camera/album/1854',
          name: 'ナカバヤシ フリーアルバム Wリングかける～の',
          src: 'https://shopimg.kitamura.jp/images/pd/025/492/350/b6d/3e1/04f/37a/423/f7a/f1e/52d/8dx/0tq/0/M.jpg',
          txt: 'Wリングのセパレートタイプ',
          type: 'フリーアルバム'
        },
        {
          category: ['all', 'gift', 'po'],
          href: '/ec/special/camera/album/381',
          name: 'ハクバ APNP Pポケットアルバム',
          src: 'https://shopimg.kitamura.jp/images/pd/cf2/676/d0e/14a/ef4/248/996/22a/2bc/ee5/49l/679/f1t/y/M.jpg',
          txt: 'プレゼントするのにも最適',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'po', 'tai'],
          href: '/ec/special/camera/album/397',
          name: 'ハクバ SF プリントファイル',
          src: 'https://shopimg.kitamura.jp/images/pd/ee5/219/c04/027/a94/ec9/44f/660/98b/1d2/ebm/3o4/l6d/1/M.jpg',
          txt: 'バインダー式の台紙差し替え',
          type: 'ポケットアルバム'
        },
        {
          category: ['all', 'fr', 'tai'],
          href: '/ec/special/camera/album/373',
          name: 'ナカバヤシ フエルアルバム エスティーム ア-LG-301',
          src: 'https://shopimg.kitamura.jp/images/pd/9de/c12/1e0/075/1d8/92f/ed8/957/28e/b38/69c/8me/r34/9/M.jpg',
          txt: '贈答にも最適なベーシックスタイル',
          type: 'フリーアルバム'
        }
        //{
        //  category: ['all', 'gift', 'po'],
        //  href: '/ec/special/camera/album/399',
        //  name: 'セキセイ PKA-718 パックン カバーアルバム 2L判 浮世絵 歌舞伎',
        //  src: 'https://shopimg.kitamura.jp/images/pd/00a/c6c/ea9/b22/4dc/616/ea9/69a/08c/0d3/65y/hiq/oux/u/M.jpg',
        //  type: 'ポケットアルバム'
        //}
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/album/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/3597.jpg',
          alt: '卒園アルバムをかわいく作るコツ'
        },
        {
          href: '/ec/special/camera/frame/shacolla',
          img: 'https://shopimg.kitamura.jp/images/banner/3099.gif',
          alt: 'シャコラ'
        },
        {
          href: '/ec/special/camera/album/original/2052',
          img: 'https://shopimg.kitamura.jp/images/banner/2741.gif',
          alt: 'キタムラオリジナルベビーアルバム'
        },
        {
          href: '/special/sale-fair/sale/lotbuying/',
          img: 'https://shopimg.kitamura.jp/images/banner/3773.gif',
          alt: 'まとめ買いコーナー'
        },
        {
          href: '/ec/special/general/print-service/square',
          img: 'https://shopimg.kitamura.jp/images/banner/7197.jpg',
          alt: 'ましかくプリントにおすすめ'
        },
        {
          href: '/ec/special/camera/album/1440',
          img: 'https://shopimg.kitamura.jp/images/banner/7199.jpg',
          alt: 'プレゼントにおすすめ'
        },
        {
          href: '/ec/special/camera/album/1826',
          img: 'https://shopimg.kitamura.jp/images/banner/7201.jpg',
          alt: '大量収納におすすめアルバム'
        },
        {
          href: '/ec/special/camera/album/1439',
          img: 'https://shopimg.kitamura.jp/images/banner/7200.jpg',
          alt: '大量収納におすすめアルバム'
        }
      ]
    });

    const filterByCategory = computed(() => {
      const result = state.albumList.filter((value) => {
        if (value.category.indexOf(state.category) !== -1) {
          return value;
        }
      });
      return result;
    });

    const searchByCategory = (value: string) => {
      state.category = value;
    };

    return {
      ...toRefs(state),
      filterByCategory,
      searchByCategory
    };
  }
});
