























































































































































































































































































































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import { formatPrice } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'ricemill',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '精米したてのお米を家庭で！家電担当者おすすめの家庭用精米機特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '精米したてのお米を家庭で楽しむために「精米機」が人気です。もっとお米を美味しく食べるために、家電担当者が家庭用精米機のおすすめの理由と選び方をご紹介します。'
      );
    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '人気炊飯器 比較 おすすめ炊飯器特集',
          linkUrl: '/ec/special/kaden/rice-cooker_bestbuy',
          disabled: false
        },
        {
          path: 'おすすめ家庭用精米機特集',
          disabled: true
        }
      ],
      mainProductJanCode: ['4582221880839'],
      mainProductList: [] as Array<ProductDetail>
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
