
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリ
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'dslr',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (props, context) => {
    document.title = 'カメラを始めようと考えている方へおすすめ！各メーカーのデジタル一眼レフやミラーレスカメラ特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'デジタル一眼レフやミラーレス一眼を購入しカメラを始めてみたいけれど色んなメーカーがあって迷う！カメラ初心者でも使いやすい機種を知りたい！という方へキタムラおすすめの人気デジタル一眼レフ・ミラーレス一眼とおすすめポイントをご紹介'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'デジタル一眼レフ/ミラーレスカメラ特集',
          linkUrl: '/ec/special/camera/dslr',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      productList1: [
        {
          id: 'mra1',
          type: 'おすすめミラーレス',
          JanCodeList: ['4545350053598'],
          resultproductList1: [] as Array<ProductDetail> //結果格納用
        }
      ],
      productList2: [
        {
          id: 'mra2',
          type: 'おすすめミラーレス',
          JanCodeList: ['4960759906335'],
          resultproductList2: [] as Array<ProductDetail> //結果格納用
        }
      ],
      productList3: [
        {
          id: 'iti1',
          type: 'おすすめ一眼レフ',
          JanCodeList: ['4549292132694'],
          resultproductList3: [] as Array<ProductDetail> //結果格納用
        }
      ],
      productList4: [
        {
          id: 'iti2',
          type: 'おすすめ一眼レフ',
          JanCodeList: ['4549292111828'],
          resultproductList4: [] as Array<ProductDetail> //結果格納用
        }
      ],
      // ↓ ランキング取得するJancode
      productJanCodeList: ['4960759906335', '4548736133730', '4549292200508', '4548736128521', '4545350053598', '4545350056063'],
      productDetailList: [] as Array<ProductDetail>, // 結果格納用
      productJanCodeList2: ['4549292111828', '4549212305153', '4960759149084', '4549292138313', '4549212302336', '4960759149336'],
      productDetailList2: [] as Array<ProductDetail> // 結果格納用
      // ↑ 取得するJancode
    });
    /**↓ 商品詳細を取得する**/
    const fetchProduct = async () => {
      try {
        // 商品
        for (let i = 0; i < state.productList1.length; i++) {
          const mainResult = await ProductService.fetchProducts(state.productList1[i].JanCodeList, true);
          state.productList1[i].resultproductList1 = mainResult.items;
        }
        for (let i = 0; i < state.productList2.length; i++) {
          const mainResult = await ProductService.fetchProducts(state.productList2[i].JanCodeList, true);
          state.productList2[i].resultproductList2 = mainResult.items;
        }
        for (let i = 0; i < state.productList3.length; i++) {
          const mainResult = await ProductService.fetchProducts(state.productList3[i].JanCodeList, true);
          state.productList3[i].resultproductList3 = mainResult.items;
        }
        for (let i = 0; i < state.productList4.length; i++) {
          const mainResult = await ProductService.fetchProducts(state.productList4[i].JanCodeList, true);
          state.productList4[i].resultproductList4 = mainResult.items;
        }
        // ランキング商品
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
      } catch (error) {
        console.error(error);
        state.productDetailList = [] as Array<ProductDetail>; // ランキング商品1
        state.productDetailList2 = [] as Array<ProductDetail>; // ランキング商品2
      }
    };
    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
