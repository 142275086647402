


























































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import SubText from '@/components/common/special/subtext.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
export default Vue.extend({
  name: 'highly-recommended-2019-4318',
  components: {
    breadcrumbs: Breadcrumbs,
    priceAndPurchase: PriceAndPurchase,
    subText: SubText,
    tableOfContentsNavi: TableOfContentsNavi,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    kitamuraService: KitamuraService,
    recommendedFeatures: RecommendedFeatures,
    shasha: ShaSha,
    urlcopy: Urlcopy,
    titleImage: TitleImage,
    watchInVideo: WatchInVideo,
  },
  setup: () => {
    document.title = 'スタッフイチオシ！ソニー Cyber-Shot DSC-RX100M7 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'スタッフイチオシ！スナップや風景だけでなく、スポーツ撮影にも対応おすすめのデジカメです')
    const state = reactive({
      exampleImagesPath: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/general/highly_recommended/2019/4318/',
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: 'ソニー Cyber-Shot DSC-RX100M7',
          disabled: true
        }

      ],
      // ↓ ---- 話題の新製品バックナンバー＆おすすめの特集 ----
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      // ↓ ---- ナビボタン ----
      naviList: [
        { naviItem: 'おすすめの理由その１', href: '#r01' },
        { naviItem: 'おすすめの理由その２', href: '#r02' },
        { naviItem: 'ソニー Cyber-Shot DSC-RX100M7とDSC-RX100M6比較', href: '#r03' },
        { naviItem: 'ShaSha：実際に使ってみました！ソニー Cyber-Shot DSC-RX100M7作例', href: '#r04' },
        { naviItem: '動画で見る！ソニー Cyber-Shot DSC-RX100M7', href: '#r05' },
        { naviItem: '関連商品一覧', href: '#r06' }
      ],
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736106284', '4548736083264', '4549292137743'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      productJanCodeList: [
        '4548736106390',
        '4961607733509',
        '4548736105560',
        '4548736105553',
        '4548736088924',
        '4548736065956',
        '4548736064621',
        '4548736039063',
        '4548736039056',
        '4548736020146',
        '4548736004269',
        '4548736016989',
        '4905524991079',
        '4905524984682',
        '4905524948813',
        '4905524949759',
        '4905524941388',
        '4905524899054',
        '4905524929379',
        '4905524924725',
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      mainProductJanCode: ['4548736106284'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
