













import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'kitamura-service',
  setup: () => {
    const state = reactive({
      kitamuraServiceList: [
        { txt: 'おすすめ', href: '/ec/guide/souryou.html', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/eosr5_r6/pr-01.gif' },
        { txt: '安心', href: '/ec/guide/hoshou', src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/canon/eosr5_r6/pr-02.gif' },
        { txt: '選ばれています！', href: '/ec/guide/s_credit_01', src: 'https://shopimg.kitamura.jp/images/banner/4801.png' },
        { txt: '貯まる・使える', href: 'https://www.kitamura.jp/service/tcard/', src: 'https://shopimg.kitamura.jp/images/banner/9572.gif' },
      ]
    });

    return {
      ...toRefs(state)
    };
  }
});
