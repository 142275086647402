












































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'sns-share',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup: () => {
    const state = reactive({
      facebookIcon: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/okamoto-yutaka/ic_f.png',
      twitterIcon: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/okamoto-yutaka/ic_t.png',
      hatenaIcon: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/okamoto-yutaka/ic_h.png',
      lineIcon: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/okamoto-yutaka/ic_l.png',
      pocketIcon: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/okamoto-yutaka/ic_p.png',
    });

    const linkToOtherWindow = (url: string | undefined) => {
      window.open(url, '_blank');
    };

    return {
      ...toRefs(state),
      linkToOtherWindow
    };
  }
});
