



















































































































import { CAMERA_CATEGORY_LIST, APPLIANCES_CATEGORY_LIST } from '@/constants/categories'; //★カテゴリ
import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import { noimage, formatPrice } from '@/logic/utils';
const CAMERA_CATEGORY_MIN_SIZE = 10; //★カテゴリ
const APPLIANCES_CATEGORY_MIN_SIZE = 10; //★カテゴリ
export default Vue.extend({
  name: 'categorymenulist',
  setup: (props, context) => {
    const state = reactive({
      isMoreCameraCategories: false, //★カテゴリ
      isMoreAppliancesCategories: false //★カテゴリ
    });
    /**
     * カメラカテゴリーリストをフィルタリングする(SP用) ★カテゴリ
     */
    const filterCameraCategories = computed(() =>
      state.isMoreCameraCategories ? CAMERA_CATEGORY_LIST : CAMERA_CATEGORY_LIST.slice(0, CAMERA_CATEGORY_MIN_SIZE)
    );
    /**
     * 家電カテゴリーリストをフィルタリングする(SP用) ★カテゴリ
     */
    const filterAppliancesCategories = computed(() =>
      state.isMoreAppliancesCategories ? APPLIANCES_CATEGORY_LIST : APPLIANCES_CATEGORY_LIST.slice(0, APPLIANCES_CATEGORY_MIN_SIZE)
    );
    /**
     * 画像読込 ★カテゴリ
     */
    function loadImg(path: string) {
      return require('@/assets/categories/' + path);
    }
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      loadImg, //★カテゴリ
      filterCameraCategories, //★カテゴリ
      filterAppliancesCategories, //★カテゴリ
      CAMERA_CATEGORY_LIST, //★カテゴリ
      APPLIANCES_CATEGORY_LIST //★カテゴリ
    };
  }
});
