



































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'wonderphotobox',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'フジフイルム ワンダーフォトボックスBOX PB-20 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'たまった画像の整理ができなくてお困りのあなたへ。”つなぐ”と”押す”だけで「魔法の写真箱」がすべて解決！日本最大級のカメラ専門店カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'フジフイルム ワンダーフォトボックスBOX PB-20',
          disabled: true
        }
      ],
      onayamiList: [
        'こんなお悩みありませんか?',
        '家族みんなが撮ったたくさんの写真を整理したい たまった写真を簡単にプリント・フォトブックにしたい スマホやカメラの写真をシェアして一緒にみて楽しみたい',
        '',
        'そんなあなたに魔法の写真箱 デジタルフォトアルバム Wonder Photo Box ワンダーフォトボックス'
      ],
      jidouseiriList: [
        '1 ラクラク自動整理 ワンステップで使える3つの自動整理機能が便利!',
        '',
        '自動整理機能1 撮影日、撮影場所、被写体ごとに自動で整理!',
        '自動整理機能2 写真の顔を認識して人物別に自動でフォルダ分け!',
        '自動整理機能3 大量の画像からベストショットを自動で選んで表示!'
      ],
      kyouyuuList: [
        '3いつでもどこでも画像共有 みんなの写真をまとめて保存。シェアしてみんなで楽しめる!',
        '家族みんなの画像を一台に!',
        'テレビ・スマホ・タブレットみんなつながる! 離れた場所からでも楽しめる!'
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
