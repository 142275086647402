
























import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import ProductService from '@/logic/product.service';

export default Vue.extend({
  name: 'product-favorite-dialog',
  props: {
    // 中古かどうか
    isUsed: {
      required: false,
      type: Boolean,
      default: false
    },
    janCode: {
      required: true,
      type: String
    },
    isFavorite: {
      required: true,
      type: Boolean
    },
    isNoticePriceSetting: {
      required: false,
      type: Boolean,
      default: true
    },
    isNoticeUsedSetting: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const { errorStore, confirmDialogStore } = context.root.$store;

    const state = reactive({
      noticePrice: props.isNoticePriceSetting as boolean,
      noticeUsed: props.isNoticeUsedSetting as boolean
    });

    const registerFavorite = async (isDelete?: boolean) => {
      if (typeof isDelete === 'undefined') {
        isDelete = false;
      } else if (isDelete) {
        // 削除確認
        const confirm = await confirmDialogStore.open('削除してよろしいですか？', '削除');
        if (!confirm) {
          return;
        }
      }

      try {
        await ProductService.registerFavorite(props.janCode as string, state.noticePrice, state.noticeUsed, isDelete);
        context.emit('onRegisterd');
      } catch (error) {
        console.error(error);
        errorStore.errorMessage =
          error.response.data.details && error.response.data.details.length > 0 && error.response.data.details[0].errorCode === 'EN005'
            ? 'お気に入り登録が上限数に達しています、マイページより他の商品をお気に入りから外していただき再度登録をお願いします。'
            : 'ただいまシステムが混みあっている可能性があります。しばらくお待ちいただきますようお願い申し上げます。ご迷惑をおかけして申し訳ございません。';
      }
    };

    const close = () => {
      context.emit('onClosed');
    };

    return { ...toRefs(state), registerFavorite, close };
  }
});
