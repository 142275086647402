



































import Vue, { PropType } from 'vue';
import { watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import MyPageMenu from '@/components/my-page/common/my-page-menu.vue';
import MyPageTitle from '@/components/my-page/common/my-page-title.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'my-page-layout',
  props: {
    title: {
      required: true,
      type: String
    },
    breadcrumbs: {
      required: true,
      type: Array as PropType<Array<BreadcrumbItem>>,
      default: []
    },
    requireFetchOwned: {
      type: Boolean,
      default: true
    },
    requireFetchCoupon: {
      type: Boolean,
      default: true
    }
  },
  components: {
    breadcrumbs: Breadcrumbs,
    'my-page-menu': MyPageMenu,
    'my-page-title': MyPageTitle
  },
  setup: (props) => {
    // 画面タイトル設定
    document.title = `【カメラのキタムラ】${props.title} | マイページ`;
    watch(
      () => props.title,
      (title) => (document.title = `【カメラのキタムラ】${title} | マイページ`)
    );

    return {};
  }
});
