






























































































































































import Vue from 'vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'z100-400mmf45-56vrs',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    kitamuraService: KitamuraService,
    notfound: Notfound,
    characteristic: Characteristic,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    naviBtn: NaviBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S / NIKKOR Z 24-120mm f/4 S| カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S / NIKKOR Z 24-120mm f/4 S 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- プレ掲載期間 ----
      advertisementValidFrom: '2021/10/29 11:30',
      advertisementValidTo: '2022/01/27 23:59',
      // ↓ ---- 予約掲載期間 ----
      reserveValidFrom: '2022/01/28 00:00',
      reserveValidTo: '2022/02/03 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/02/04 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowAdvertisement: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      naviList: [
        { naviItem: 'お得情報', href: '#otoku' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '外観', href: '#images2' },
        { naviItem: '作例', href: '#images' }
      ],
      reserveExpensiveTradeInList: {
        name: 'ニコン AF-S NIKKOR 80-400mm f/4.5-5.6G ED VR',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759027542&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/7fc/79b/271/4cc/13f/45f/c2c/61a/5fd/667/eax/zhc/q5y/c/M.jpg',
        txt: 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z100-400mmf45-56vrs/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ニコン AF-S NIKKOR 80-400mm f/4.5-5.6G ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759027542&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 200-500mm f/5.6E ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759145765&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 70-200mm f/2.8E FL ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759146595&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 70-200mm F2.8G ED VR II',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759025913&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          }
        ]
      },
      releaseExpensiveTradeInList: {
        name: 'ニコン AF-S NIKKOR 80-400mm f/4.5-5.6G ED VR',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759027542&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/7fc/79b/271/4cc/13f/45f/c2c/61a/5fd/667/eax/zhc/q5y/c/M.jpg',
        txt: 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S ',
        tradeInImg: '/ec/images2/special/camera/feature/nikon/z100-400mmf45-56vrs/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ニコン AF-S NIKKOR 80-400mm f/4.5-5.6G ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759027542&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 200-500mm f/5.6E ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759145765&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 70-200mm f/2.8E FL ED VR',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759146595&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          },
          {
            name: 'ニコン AF-S NIKKOR 70-200mm F2.8G ED VR II',
            href:
              'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4960759025913&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=keyword'
          }
        ]
      },
      commonCharacteristicList: [
        {
          subtitle: '様々な被写体を幅広いズーム範囲でカバーできる超望遠ズームレンズ',
          contents: [
            '焦点距離100mmから400mmの広い範囲をカバーしているので、遠く離れた野鳥や花や昆虫のクローズアップ撮影など、さまざまな被写体の撮影が可能',
            '別売りのテレコンバーターに対応。テレコンバーター使用で焦点距離が拡大するだけでなく、高い解像感を維持。遠く離れた被写体も高画質で快適に撮影可能'
          ]
        },
        {
          subtitle: 'S-Lineならではの静止画・動画を問わない圧倒的な光学性能でクリアーな画像を実現',
          contents: [
            '倍率色収差をRAWデータの段階で光学的に良好に補正。色にじみを大幅に低減し、絞り開放からクリアーな画像を実現',
            'カメラの画像処理と相性がいいため、JPEG状態では色にじみが一段と低減。EDレンズ、スーパーEDレンズにより、無限遠から至近までの軸上色収差を大幅に低減',
            'ニコン独自の反射防止コーティング技術「ナノクリスタルコート」と「アルネオコート」を採用。あらゆる方向からの入射光が引き起こすゴーストとフレアを効果的に低減',
            'レンズ交換式カメラ用「NIKKOR」レンズ史上最高の5.5段の高い手ブレ補正効果を発揮するレンズシフト方式VR機構を内蔵。望遠撮影や光量の少ないシーンでの撮影でも、安心して撮影が可能'
          ]
        },
        {
          subtitle: 'クラス最軽量のボディと新技術で、高い操作性と携行性を実現',
          contents: [
            '質量はクラス最軽量の1355g（三脚座を除く）。長時間の持ち運びや、機動的な撮影の負担を軽減。厳しい状況下でもアクティブに撮影可能',
            'ズーミングしてもレンズの重心位置をほぼ一定に保つ、ニコン初の「重心移動レス機構」を搭載。レンズ全体の重心移動を最小限に抑制するとともに、自重によるレンズの伸縮を防止',
            'ズームリングの回転角はクラス最小の80度。リングを持ち直すことなくズーム操作が可能'
          ]
        }
      ],
      commonCharacteristicList2: [
        {
          subtitle: 'S-Lineレンズの優れた光学性能によりズーム全域で絞り開放から高い解像力を発揮',
          contents: [
            'ED非球面レンズ1枚とEDレンズ3枚、マルチフォーカス方式を採用した光学系により、至近から無限遠まで軸上色収差を効果的に補正し、ピント面前後の色にじみを抑制',
            '焦点距離を変えても開放F値が一定のため、ズーミングによる露出変動を気にせず、静止画、動画ともに撮影者の意図に沿った表現が可能',
            '斜めからの入射光に有効なナノクリスタルコートと、垂直に入射する光に有効なアルネオコートを採用。逆光時のゴーストやフレアを効果的に抑制',
            '最大撮影倍率0.39倍、最短撮影距離0.35mを実現。花や昆虫などのクローズアップ撮影が可能。至近距離にある被写体の撮影時にも高い結像性能を発揮'
          ]
        },
        {
          subtitle: '焦点距離120mmまでをカバーする軽量5倍標準ズームレンズ',
          contents: [
            '使用頻度の高い24mmから120mmまでの焦点距離をカバー。携行性にも優れているため気軽に持ち歩くことができ、レンズ1本で多彩な被写体やシーンに対応可能',
            '望遠側120mmまでの焦点域とクラス最軽量約630gの軽量ボディーを両立。さまざまなシーンをアクティブかつ軽快に撮影することが可能',
            '埃や水滴の侵入を防ぐシーリングを鏡筒の可動部分をはじめとする随所に施し、さらにレンズマウントゴムリングを採用。高い防塵・防滴性能を実現することにより過酷な環境下でも安心して撮影が可能'
          ]
        },
        {
          subtitle: '静止画撮影だけでなく動画撮影でも高い操作性を追求',
          contents: [
            '高解像な映像はもちろん、AF機構と絞り機構にSTM（ステッピングモーター）を搭載することで、静粛なAF駆動を実現するとともにクリックレスのコントロールリングで操作音を抑制',
            '滑らかな操作感のコントロールリングにより、ピントや明るさがゆっくりと滑らかに変化する自然な映像表現を実現',
            '画角変動（フォーカスブリージング）に配慮した設計。同一構図でピント位置を移動させる映像表現でも不自然な画角変化をおこさず、自然な映像撮影が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラのメンテナンスサービス',
          target: 'blank'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン NIKKOR Z 100-400mm f/4.5-5.6 VR S / NIKKOR Z 24-120mm f/4 S',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759906557', '4960759906274'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759906557', '4960759906274'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const advertisementValidFrom = dayjs(state.advertisementValidFrom);
      console.log(advertisementValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        console.log(formatToday);
        if (formatToday.isBefore(advertisementValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.advertisementValidFrom, state.advertisementValidTo)) {
          state.isShowAdvertisement = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;

        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
