

















































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'lensfilter-nikon-arcrest',
  components: {
    'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ナノクリスタルコートを搭載したニコンのNIKKORレンズにおすすめの保護フィルター ARCRESTをご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン ARCREST 保護フィルターは、ナノクリスタルコートを採用したNIKKORレンズに最適です。ニコン ARCREST 保護フィルターの性能を詳しくご紹介しております。カメラのキタムラネットショップでニコン純正の高性能保護フィルターをお得に購入しましょう！'
      );

    const state = reactive({
      visible: false,
      index: 0, // default: 0
      images: [
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image02-a.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image02-b.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image03-00.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image03-01.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image03-02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image03-03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image03-04.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image07-00.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/image07-01.jpg'
      ],
      resolvingPowerList: [
        {
          txt: 'ARCREST',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-resolution.jpg'
        },
        {
          txt: 'A社保護フィルター\n(同等クラス品)',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-resolution-a.jpg'
        },
        {
          txt: 'B社保護フィルター\n(同等クラス品)',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-resolution-b.jpg'
        }
      ],
      ghostOccurrenceComparisonList: [
        {
          txt: 'フィルターなし',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-ghost00.jpg'
        },
        {
          txt: 'ARCREST',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-ghost01.jpg'
        },
        {
          txt: '一般的な保護フィルター',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-ghost02.jpg'
        },
        {
          txt: 'A社保護フィルター\n(同等クラス品)',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-ghost03.jpg'
        },
        {
          txt: 'B社保護フィルター\n(同等クラス品)',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-ghost04.jpg'
        }
      ],
      spotlightList: [
        {
          txt: 'フィルターなし',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-light00.jpg'
        },
        {
          txt: 'ARCREST',
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lensfilter/nikon-arcrest/arcrest-light01.jpg'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest-nd',
          img: 'https://shopimg.kitamura.jp/images/banner/7485.png',
          alt: 'ニコン ARCREST NDフィルター'
        },
        {
          href: '/ec/special/sale-fair/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    // 画像を拡大して表示する
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    // 拡大画像を閉じる
    const handleHide = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
