




















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'printer-3133',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (context) => {
    document.title = 'こだわりの高画質で印刷したい方におすすめのプリンター | カメラのキタムラネットショップ';
    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめプリンター特集',
          linkUrl: '/ec/special/camera/printer',
          disabled: false
        },
        {
          path: 'こだわりの高画質で印刷したい方におすすめのプリンター',
          linkUrl: '/ec/special/camera/printer/3133',
          disabled: true
        }
      ]
    });
    return {
      ...toRefs(state)
    };
  }
});
