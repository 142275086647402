































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sigma-fp_l',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    characteristic: Characteristic,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    expensiveTradeIn: ExpensiveTradeIn,
    splitContent: SplitContent,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'SIGMA シグマ fp L | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'SIGMA シグマ fp L 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: 'fp L 外観', href: '#images' }
      ],
      expensiveTradeInList: {
        name: 'シグマ fp ボディ',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&goodsname=4549292075748&_ga=2.222765533.714669828.1626591933-721903692.1626267770&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/acf/1eb/e9e/696/1d5/94b/578/c1a/843/2e2/90x/7ch/kkm/8/L.jpg',
        txt: 'シグマ fp L',
        tradeInImg: '/ec/images2/special/camera/feature/sigma/fp_l/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      CharacteristicList: [
        {
          subtitle: '約6100万画素センサー搭載により高精細な描写と色彩表現を実現',
          contents: [
            'シグマ史上最高画素数となる約6100万画素裏面照射型ベイヤーセンサーを搭載。高画素ならではの高精細な描写と豊かな色彩表現を実現',
            'ローパスフィルターを搭載することで、モアレの発生を極小まで抑制',
            '高画素を活かし、静止画・動画共通で使えるクロップズーム機能を搭載。最大の5倍ズーム時でもデジタル補完による画像劣化無しのFHD解像度記録が可能'
          ]
        },
        {
          subtitle: '高精度コントラストAFに加え像面位相差AFも搭載し、快適なオートフォーカスを実現',
          contents: [
            '現行の高精度なコントラストAFに加え、速度に優れた像面位相差AFを採用',
            '静止画、動画ともにハイブリットAFにより高精度・高速かつ動体への追従も高い快適なAF撮影が可能'
          ]
        },
        {
          subtitle: 'カメラ起動中のUSB給電に対応。様々なシーンで長時間の使用が可能',
          contents: [
            '屋外などのコンセントがない環境でもモバイルバッテリーなどを使い給電しながら途切れなく撮影が可能',
            'Webカメラとして使用の際には、PCとUSB接続で映像・音声と同時に給電供給されるためACアダプター無しで長時間の使用が可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },

        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'SIGMA シグマ fp L',
          disabled: true
        }
      ],
      mainProductJanCode: ['0085126939038', '0085126939076'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['0085126939038', '0085126937300', '4549980624180', '4548736121614'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '0085126939038',
        '0085126939076',
        '0085126930394',
        '0085126937331',
        '0085126937348',
        '0085126937379',
        '0085126937355',
        '0085126937362',
        '4975981737596',
        '4975981756498',
        '4523052016639'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
