











































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SubText from '@/components/common/special/subtext.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'z6II_z7II',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    naviBtn: NaviBtn,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    shasha: ShaSha,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ニコン Z 6II ・ Z 7II 好評発売中！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ニコン Z 6II ・ Z 7II 好評発売中！比較や、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );
    const state = reactive({
      naviList: [
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '特徴', href: '#point' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '外観画像', href: '#images2' }
      ],
      characteristicList: [
        {
          subtitle: '2年前に発売された初期型でユーザーから寄せられた要望をカタチにした第二世代モデル',
          contents: [
            'メモリーカードスロットをXQD(CFexpress Type B)のシングルスロットから、SDカード使用可能なスロットを搭載した2スロット構成に変更。汎用性の高いSDカードが使えるようになりました',
            '新型バッテリー「EN-EL15c」を採用することにより、外部バッテリーからの充電および給電に対応。また、別売り縦位置グリップも同時発表。拡張性がさらに向上しました',
            '撮影画面のアイコンや撮影情報を非表示にし、画面の隅々まで確認できる［ライブビュー情報表示の消灯］機能を新搭載。より構図を決めやすくなりました',
            '動きのある被写体の撮影で安定したピント合わせができる［ワイドエリアAF］にも対応し、さらに使いやすくなった瞳AF/動物AF。静止画はもちろん動画でも使用できます',
            '高速連写性能を加速した「デュアルEXPEED 6」を新採用。決定的な瞬間をより確実に捉えることが可能になりました'
          ]
        },
        {
          subtitle: '連写、動画に強い「Z 6II」の進化したポイント',
          contents: [
            '高速連続撮影と連続撮影コマ数が向上。約14コマ/秒で最大124コマまでの高速連続撮影が可能。AF/AEもすべての撮影コマで追従します',
            '4K UHD/30p動画を［FXベースの動画フォーマット］フルフレームで撮影可能。4K UHD動画は全画素読み出しによる豊富な情報を活用。画像処理エンジンの働きで解像感の高い画づくりを実現しています'
          ]
        },
        {
          subtitle: '高画素・高精細な「Z 7II」の進化したポイント',
          contents: [
            '有効画素数約4575万画素と進化した瞳AF、493点で素早く高精度にピントを合わせるハイブリッドAFでこれまでを超える美しいポートレートが撮影できます',
            '約10コマ/秒で最大77コマまでの高速連続撮影が可能。わずかなニュアンスの違いが決定的な差を生むポートレート撮影やネイチャー撮影、鉄道撮影などを高速連続撮影が快適にサポートします'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/guide/s_credit_01',
          img: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          alt: 'ショッピングクレジット'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ニコン Z 6II / Z 7II',
          disabled: true
        }
      ],
      mainProductJanCode: ['4960759905901', '4960759905918', '4960759905697'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4960759905697', '4960759905901', '4960759901088', '4549292157253', '4548736121614'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4960759905697',
        '4960759905901',
        '4960759904430',
        '4960759904874',
        '4960759905246',
        '4960759905239',
        '4960759149961',
        '4960759903495',
        '4960759903570',
        '4960759902887',
        '4955478180647',
        '4960759126566',
        '4523052016622',
        '4955478180531',
        '4523052023392'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
