var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isShow)?_c('div',{staticClass:"wh-1000xm4lm"},[(_vm.errorMsg)?_c('notfound',{attrs:{"errorMessage":_vm.errorMsg}}):(!_vm.errorMsg)?_c('div',{staticClass:"main-contents-wrap"},[_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('urlcopy'),_c('topTitleImg',{attrs:{"pcBackgroundImg":_vm.isShowFan
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/fan/1bg_top.jpg'
            : _vm.isShowReserve
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/reserve/1bg_top.jpg'
            : _vm.isShowRelease
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/release/1bg_top.jpg'
            : '',"spBackgroundImg":_vm.isShowFan
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/fan/1sp_img_top.jpg'
            : _vm.isShowReserve
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/reserve/1sp_img_top.jpg'
            : _vm.isShowRelease
            ? '/ec/images2/special/camera/feature/sony/wh-1000xm4lm/release/1sp_img_top.jpg'
            : '',"topTitle":'ソニー ノイズキャンセリング機能搭載 密閉型 ワイヤレス ヘッドホン \nWH-1000XM4-LM ミッドナイトブルー',"topTitleText":'',"titleColor":'#ffffff',"textColor":'#ffffff',"padding":'170px 0px 15px 30px',"textPadding":'10px 0px 0px 0px'}}),_c('p',{staticClass:"red darken-4 text-center mb-2"},[_c('span',{staticClass:"white--text text-h6 font-weight-bold"},[_vm._v(_vm._s(_vm.isShowFan ? '2021年11月29日10時 予約受付開始！' : _vm.isShowReserve ? 'ご予約受付中！' : _vm.isShowRelease ? 'ご予約受付終了致しました' : ''))])]),(!_vm.isShowFan)?_c('div',[_c('subText',{attrs:{"textItem":'ソニー ワイヤレスイヤホンヘッドホン WH-1000XM4 LM ミッドナイトブルー価格・ご予約'}}),_c('priceAndPurchase',{attrs:{"mainProductList":_vm.mainProductList,"btntxt":_vm.isShowRelease ? '商品詳細はこちら' : 'ご予約はこちら'}})],1):_vm._e(),_c('facebookAndTwitter'),_c('kitamuraService'),_c('subText',{attrs:{"textItem":'話題の新製品バックナンバー＆おすすめの特集'}}),_c('recommendedFeatures',{attrs:{"recommendedFeaturesList":_vm.recommendedFeaturesList}})],1):_vm._e()],1):_vm._e(),_c('categorymenulist')],1)}
var staticRenderFns = []

export { render, staticRenderFns }