















































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'g100',
  components: {
    breadcrumbs: Breadcrumbs,
    subText: SubText,
    characteristic: Characteristic,
    recommendedFeatures: RecommendedFeatures,
    topTitleImg: TopTitleImg,
    priceAndPurchase: PriceAndPurchase,
    urlcopy: Urlcopy,
    'product-list-by-special-id': ProductListBySpecialId,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'パナソニック LUMIX DC-G100 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'パナソニック LUMIX DC-G100 好評発売中！！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'パナソニック LUMIX DC-G100',
          disabled: true
        }
      ],
      mainProductJanCode: ['4549980632239', '4549980632253'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549980632239'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----

      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      naviList: [
        { naviItem: '価格', href: '#price' },
        { naviItem: 'お得情報', href: '#price2' },
        { naviItem: '特徴', href: '#spec' },
        { naviItem: '外観画像', href: '#images' }
      ],
      characteristicList: [
        {
          subtitle: 'Vlog撮影の表現の幅を広げる充実の動画性能',
          contents: [
            '高音質・臨場感あふれる音声記録が可能なマイク「OZO Audio by NOKIA」採用',
            '業界初「顔・瞳認識AF」に連動し、自動で収音範囲を調整する内蔵マイクモード搭載',
            '歩き撮りなどでおこる大きなブレの補正力を強化した、新たな5軸ハイブリッド手ブレ補正',
            '自分撮り時のピントや露出を自動調整する動画自撮りモード搭載'
          ]
        },
        {
          subtitle: '自分撮りなど日常の持ち歩きに便利な小型・軽量ボディ',
          contents: ['旅先でも軽快に持ち歩ける約412gの小型・軽量ボディ', '自分撮り時に便利に操作できるトライポッドグリップ付きセットも設定']
        },
        {
          subtitle: '一眼ならではの高画質撮影が可能な静止画性能',
          contents: [
            'ローパスフィルターレス2030万画素LiveMOSセンサー、ヴィーナスエンジン搭載',
            'Vlogカメラとしてだけでなく、ミラーレス一眼としても撮影を楽しめます',
            '多くの交換レンズが用意されており、撮影シーンによって最適なレンズを選択可能'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'http://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'カメラ・レンズ メンテナンスサービス'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/special/camera/lens',
          img: 'http://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
