



















































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sel24f28g',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    subText: SubText,
    exampleImages: ExampleImages,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    topTitleImg: TopTitleImg,
    expensiveTradeIn: ExpensiveTradeIn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    appearanceImage: AppearanceImage,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー FE 24mm F2.8 G / FE 40mm F2.5 G / FE 50mm F2.5 G | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー FE 24mm F2.8 G / FE 40mm F2.5 G / FE 50mm F2.5 G  好評発売中！比較や、価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      expensiveTradeInList: {
        name: 'ソニー FE 28mm F2 [SEL28F20]',
        href:
          'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4548736001985&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&pattern=1&_ga=2.100577758.1712661879.1629071621-279164427.1627285160',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/9ef/9ca/fc9/cd6/bc5/632/3c9/906/266/19b/a0q/qb4/2ba/o/M.jpg',
        txt: 'ソニー FE 24mm F2.8 G / FE 40mm F2.5 G / FE 50mm F2.5 G',
        tradeInImg: '/ec/images2/special/camera/feature/sony/sel24f28g/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          {
            name: 'ソニー FE 28mm F2 [SEL28F20]',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4548736001985&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=&lc=40&quick=&pattern=1'
          },
          {
            name: 'ソニー FE 35mm F1.8 [SEL35F18F]',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4548736099838&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=kw&lc=40&quick=&pattern=1'
          },
          {
            name: 'ソニー FE 50mm F1.8 [SEL50F18F]',
            href:
              'https://www.net-chuko.com/sell/item-list.do?kindName=&makerName=&goodsname=4548736033078&ecmount=&eccolor=&ectype=&eclan=&ecundai=&eczoom=&ecwater=&ecdust=&ecfilter=&echeight=&ecpixel=&ob=kw&lc=40&quick=&pattern=1'
          }
        ]
      },
      recommendedFeaturesList: [
        {
          href: '/special/sale-fair/camera/feature/backnumber/',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/special/sale-fair/',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/special/sale-fair/general/tokutokukoukan/',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: 'https://shop.kitamura.jp/ec/special/camera/feature/',
          disabled: false
        },
        {
          path: 'ソニー FE 24mm F2.8 G / FE 40mm F2.5 G / FE 50mm F2.5 G',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736130593', '4548736130623', '4548736130654'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
