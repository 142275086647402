















































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'x100v',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    watchInVideo: WatchInVideo,
    splitContent: SplitContent,
    characteristic: Characteristic,
    shasha: ShaSha,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    expensiveTradeIn: ExpensiveTradeIn,
    kitamuraService: KitamuraService,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '好評発売中！富士フイルム X100V | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '富士フイルム X100V ご好評発売中！比較や、価格・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！ X100Vをご購入時、富士フイルム X100F高値下取り実施中'
      );

    const state = reactive({
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観画像', href: '#images' }
      ],
      expensiveTradeInList: {
        name: '富士フイルム X100F',
        href:
          'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=X100F&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/23d/03a/38b/025/e97/5f1/92f/9d7/871/ee7/59b/8ob/laj/r/L.jpg',
        txt: '富士フイルム X100V',
        tradeInImg: ' /ec/images2/special/camera/feature/fujifilm/x100v/bnr_shitadori_350-130.jpg',
        tradeInProducts: [
          { name: '富士フイルム X100F', href: '' },
          { name: 'リコーGRIII', href: '' },
          { name: 'Leica D-LUX7', href: '' }
        ]
      },
      characteristicList: [
        {
          subtitle: '高画質を実現する、新開発高性能レンズ・最新のイメージセンサー・画像処理エンジン',
          contents: [
            '新設計の23mmF2レンズを搭載。 絞り開放から高い解像力を発揮し、最短撮影距離から無限遠まで極めて鮮鋭な像を形成',
            '裏面照射型構造を採用した「X-Trans CMOS 4」と、高速処理&高い演算能力を誇る「X-Processor 4」を搭載。人間の記憶に残る鮮やかな色「記憶色」による高画質を実現'
          ]
        },
        {
          subtitle: '撮影に集中出来る、世界で唯一無二のファインダー',
          contents: [
            'X100シリーズの代名詞とも言える「ハイブリッドビューファインダー」で、「ファインダーを覗いて撮る」を追求',
            '肉眼で被写体を直接視認出来る「OVF」と露出やホワイトバランス、フィルムシミュレーションによる色再現などが全て反映される「EVF」をレバーひとつで切り替え可能'
          ]
        },
        {
          subtitle: '長年の写真フィルム開発の歴史と経験が生み出す伝統の色再現',
          contents: [
            '写真フィルムを開発してきた富士フイルムの技術と経験を詰め込んだ、「フィルムシミュレーション」を全17種類搭載',
            'VelviaやPROVIA、ACROSといった長年世界中で愛されている素晴らしいフィルム写真の世界を再現'
          ]
        },
        {
          subtitle: '新たに外装にアルミニウムを採用。機能美溢れる洗練されたデザインのボディと受け継がれる原体験',
          contents: [
            '塗装されたボディでは感じにくい金属の質感を指先に伝えてくれるアルミニウムを採用した外装',
            '操作するたびに信頼と安心を与える、アルミ切削加工によるシャッタースピードダイヤルと露出補正ダイヤル'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: '富士フイルム X100V',
          disabled: true
        }
      ],
      mainProductJanCode: ['4547410423419'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4547410423419', '4547410339017', '4549212300202', '4547410421767'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4547410423419',
        '4547410423433',
        '4547410429084',
        '4547410334128',
        '4547410378139',
        '4547410378122',
        '4547410160727',
        '4547410268201',
        '4547410339079',
        '4547410339055',
        '4547410339086',
        '4547410339062',
        '4547410160758',
        '4547410268102'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
