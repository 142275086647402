type Category = {
  text: string;
  url: string;
  image: string;
  linkType?: 'internal' | 'external';
};

export const CAMERA_CATEGORY_LIST: Category[] = [
  {
    text: '交換レンズ',
    url: '/ec/ct/C004',
    image: 'img_topctC01.png'
  },
  {
    text: 'ミラーレス一眼',
    url: '/ec/ct/C002',
    image: 'img_topctC02.png'
  },
  {
    text: 'デジタル一眼レフ',
    url: '/ec/ct/C001',
    image: 'img_topctC03.png'
  },
  {
    text: 'コンパクトデジタルカメラ',
    url: '/ec/ct/C003',
    image: 'img_topctC04.png'
  },
  {
    text: '中古カメラ・レンズ',
    url: '/ec/ct/used/list',
    image: 'img_topctC05.png'
  },
  {
    text: 'ビデオカメラ',
    url: '/ec/ct/C005',
    image: 'img_topctC06.png'
  },
  {
    text: 'フィルムカメラ',
    url: '/ec/ct/C018',
    image: 'img_topctC07.png'
  },
  {
    text: '交換レンズアクセサリー',
    url: '/ec/ct/C009',
    image: 'img_topctC08.png'
  },
  {
    text: '三脚',
    url: '/ec/ct/C008',
    image: 'img_topctC09.png'
  },
  {
    text: 'カメラバッグ・ケース',
    url: '/ec/ct/C011',
    image: 'img_topctC10.png'
  },
  {
    text: 'メモリーカード',
    url: '/ec/ct/C006',
    image: 'img_topctC11.png'
  },
  {
    text: 'ストラップ',
    url: '/ec/ct/C012',
    image: 'img_topctC12.png'
  },
  {
    text: 'カメラアクセサリー・用品',
    url: '/ec/ct/C007',
    image: 'img_topctC13.png'
  },
  {
    text: '写真台紙',
    url: '/ec/ct/C016',
    image: 'img_topctC14.png'
  },
  {
    text: 'アルバム',
    url: '/ec/ct/C014',
    image: 'img_topctC15.png'
  },
  {
    text: 'フレーム',
    url: '/ec/ct/C015',
    image: 'img_topctC16.png'
  },
  {
    text: '防湿庫',
    url: '/ec/ct/C010',
    image: 'img_topctC17.png'
  },
  {
    text: 'プリンター・スキャナー',
    url: '/ec/ct/C022',
    image: 'img_topctC18.png'
  },
  {
    text: 'インク',
    url: '/ec/ct/C023',
    image: 'img_topctC19.png'
  },
  {
    text: '用紙',
    url: '/ec/ct/C024',
    image: 'img_topctC20.png'
  },
  {
    text: '双眼鏡/望遠鏡',
    url: '/ec/ct/C013',
    image: 'img_topctC21.png'
  },
  {
    text: 'フィルム',
    url: '/ec/ct/C017',
    image: 'img_topctC22.png'
  },
  {
    text: '電池',
    url: '/ec/ct/C019',
    image: 'img_topctC23.png'
  },
  {
    text: 'アウトレット',
    url: '/ec/sale/outlet/',
    image: 'img_topctCoutlet.png',
    linkType: 'external'
  }
];

export const APPLIANCES_CATEGORY_LIST: Category[] = [
  {
    text: '生活/家事家電',
    url: '/ec/ct/E004',
    image: 'img_topctK01.png'
  },
  {
    text: 'キッチン/調理家電',
    url: '/ec/ct/E005',
    image: 'img_topctK02.png'
  },
  {
    text: '空調家電',
    url: '/ec/ct/E006',
    image: 'img_topctK03.png'
  },
  {
    text: '季節家電',
    url: '/ec/ct/E007',
    image: 'img_topctK04.png'
  },
  {
    text: 'テレビ/レコーダー/プロジェクター',
    url: '/ec/ct/E008',
    image: 'img_topctK05.png'
  },
  {
    text: 'オーディオ',
    url: '/ec/ct/E011',
    image: 'img_topctK06.png'
  },
  {
    text: '健康家電',
    url: '/ec/ct/E009',
    image: 'img_topctK07.png'
  },
  {
    text: '理美容家電',
    url: '/ec/ct/E010',
    image: 'img_topctK08.png'
  },
  {
    text: '情報通信',
    url: '/ec/ct/E012',
    image: 'img_topctK09.png'
  },
  {
    text: 'アウトドア用品',
    url: '/ec/ct/E022',
    image: 'img_topctK10.png'
  },
  {
    text: 'ドライブレコーダー/カー用品',
    url: '/ec/ct/E020',
    image: 'img_topctK11.png'
  },
  {
    text: '玩具',
    url: '/ec/ct/E024',
    image: 'img_topctK19.png'
  },
  {
    text: 'ペット関連用品',
    url: '/ec/ct/E025',
    image: 'img_topctK20.png'
  },
  {
    text: 'スマートフォン・アクセサリー',
    url: '/ec/ct/E013',
    image: 'img_topctK13.png'
  },
  {
    text: 'パソコン・PCソフト・周辺機器 ',
    url: '/ec/ct/E015',
    image: 'img_topctK14.png'
  },
  {
    text: 'オフィス用品',
    url: '/ec/ct/E018',
    image: 'img_topctK15.png'
  },
  {
    text: '時計・スマートウォッチ',
    url: '/ec/ct/E019',
    image: 'img_topctK16.png'
  },
  {
    text: '中古時計',
    url: '/ec/special/reuse/watch',
    image: 'img_topctK17.png',
    linkType: 'external'
  },
  {
    text: '中古スマホ',
    url: '/ec/special/reuse/smartphone',
    image: 'img_topctK18.png',
    linkType: 'external'
  },
  {
    text: '中古パソコン',
    url: '/ec/special/reuse/usedpc/',
    image: 'img_topctK21.png',
    linkType: 'external'
  }
];
