


















































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'nikon1',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = 'カメラ女子×Nikon１ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラ女子におすすめ！Nikon×カメラのキタムラ特別企画「カメラ女子 × Nikon１」。人気のNikon J5の魅力を当店女性バイヤーが紹介します。'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'カメラ女子×Nikon１',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----

      newProductList: [
        {
          src: 'https://shop.kitamura.jp/images/banner/1390.jpg',
          href: '/special/sale-fair/camera/girlscamera/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          href: '/ec/special/camera/bag'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/1392.gif',
          href: '/special/sale-fair/camera/strap/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/1878.jpg',
          href: '/special/sale-fair/general/pet/'
        },
        {
          src: 'https://shopimg.kitamura.jp/images/banner/3498.jpg',
          href: 'https://www.net-chuko.com/static/contents/page/tell_me_chuuban.html',
          target: '_blank'
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759174680'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
