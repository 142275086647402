






















import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';

export default Vue.extend({
  name: 'confirm-dialog',
  setup: (_, context) => {
    const { confirmDialogStore } = context.root.$store;

    const state = reactive({
      confirmDialog: false,
      message: confirmDialogStore.message,
      okText: confirmDialogStore.okText,
      cancelText: confirmDialogStore.cancelText,
      okAction: confirmDialogStore.okAction,
      cancelAction: confirmDialogStore.cancelAction
    });

    /** ダイアログを閉じる */
    const close = () => {
      state.confirmDialog = false;
      confirmDialogStore.clear();
    };

    /** OK時の処理を行ってダイアログを閉じる */
    const ok = () => {
      state.okAction();
      close();
    };

    /** キャンセル時の処理を行ってダイアログを閉じる */
    const cancel = () => {
      state.cancelAction();
      close();
    };

    /** エラーメッセージが更新されたダイアログを表示する */
    watch(
      () => confirmDialogStore.message,
      (message) => {
        if (message) {
          state.message = message;
          state.okText = confirmDialogStore.okText;
          state.cancelText = confirmDialogStore.cancelText;
          state.okAction = confirmDialogStore.okAction;
          state.cancelAction = confirmDialogStore.cancelAction;
          state.confirmDialog = true;
        }
      }
    );
    return {
      ...toRefs(state),
      ok,
      cancel
    };
  }
});
