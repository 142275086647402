



























































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Carousels from '@/components/common/special/carousels.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sonye',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    carousels: Carousels,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = 'ソニーαを持って旅に出ましょう！「旅レンズ」におすすめなEマウントレンズをご紹介します｜カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラのキタムラ ネットショップ担当者厳選！おすすめのソニーαシリーズ用Eマウントレンズを集めました。望遠や単焦点など旅行のお供に便利なレンズをご紹介しています♪ソニーのレンズやカメラはカメラのキタムラ ネットショップへおまかせください'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'ソニー Eマウントレンズ α用「旅レンズ」',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----
      // ↓ ---- カルーセル(タイプ２) ----
      carousels: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_01_01.jpg',
          txt: '●シャッタースピード：1/640秒  ●F値：F2.0  ●ISO1600'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_01_02.jpg',
          txt: '●シャッタースピード：1/1250秒  ●F値：F2.2  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_01_03.jpg',
          txt: '●シャッタースピード：1/2秒  ●F値：F11  ●ISO100'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_01_04.jpg',
          txt: '●シャッタースピード：1/20秒  ●F値：F9.0  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_01_05.jpg',
          txt: '●シャッタースピード：1/400秒  ●F値：F9.0  ●ISO200'
        }
      ],
      carousels2: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_02_01.jpg',
          txt: '●シャッタースピード：1/125秒  ●F値：F7.1  ●ISO100'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_02_02.jpg',
          txt: '●シャッタースピード：1/2秒  ●F値：F11  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_02_03.jpg',
          txt: '●シャッタースピード：1/1000秒  ●F値：F8  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_02_04.jpg',
          txt: '●シャッタースピード：1/100秒  ●F値：F8  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_02_05.jpg',
          txt: '●シャッタースピード：1/100秒  ●F値：F8  ●ISO200'
        }
      ],
      carousels3: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_03_01.jpg',
          txt: '●シャッタースピード：1/2500秒  ●F値：F8  ●ISO100'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_03_02.jpg',
          txt: '●シャッタースピード：1/160秒  ●F値：F5.6  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_03_03.jpg',
          txt: '●シャッタースピード：1/200秒  ●F値：F8  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_03_04.jpg',
          txt: '●シャッタースピード：1/100秒  ●F値：F11  ●ISO200'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_03_05.jpg',
          txt: '●シャッタースピード：1/160秒  ●F値：F11  ●ISO100'
        }
      ],
      carousels4: [
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_04_01.jpg',
          txt: '●シャッタースピード：1/320秒  ●F値：F6.3  ●ISO160'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_04_02.jpg',
          txt: '●シャッタースピード：20秒  ●F値：F10  ●ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_04_03.jpg',
          txt: '●シャッタースピード：1/320秒  ●F値：F6.3  ●ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_04_04.jpg',
          txt: '●シャッタースピード：4秒  ●F値：F9  ●ISO400'
        },
        {
          src: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/lens/sonye/lens_04_05.jpg',
          txt: '●シャッタースピード：1/250秒  ●F値：F6.3  ●ISO600'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4960759028921'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
