







































































import Vue from 'vue';
import { formatPrice } from '@/logic/utils';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'split-content',
  props: {
    ProductList: {
      type: Object,
      required: true
    },
    numberOfTimes: {
      type: String,
      required: false,
      default: '48'
    },
    maximumInstallmentPayment: {
      type: String,
      required: false,
      default: '48'
    },
    amount: {
      type: String,
      required: true
    },
    secondAmount: {
      type: String,
      default: '0'
    },
    commission: {
      type: String,
      required: false,
      default: '0'
    },
    isText: {
      type: Boolean,
      required: false,
      default: true
    },
    targetDate: {
      type: String,
      required: true
    },
    isCreditBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    isMax60Txt: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup: () => {
    const state = reactive({});

    return {
      formatPrice,
      ...toRefs(state)
    };
  }
});
