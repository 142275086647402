































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import Carousels from '@/components/common/special/carousels.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'touit',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    'product-list-by-special-id': ProductListBySpecialId,
    carousels: Carousels,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'カールツァイス Touit 2.8/12mm 1.8/32mm レビュー | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', 'Carl Zeiss(カールツァイス) Touit2.8/12mmと1.8/32mm Eマウント・Xマウント用レンズをレビュー【カメラのキタムラ】');
    const state = reactive({
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.png',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/ec/service/tokutoku-kaitori.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      carousels2: [
        {
          src: '/ec/images2/special/camera/lens/touit/touit-slide_01.jpg',
          txt: 'FUJIFILM X-E1 × Carl Zeiss Touit 2.8/12 （ f/11 ISO/200 1/500秒 ）'
        },
        {
          src: '/ec/images2/special/camera/lens/touit/touit-slide_02.jpg',
          txt: 'FUJIFILM X-Pro1 × Carl Zeiss Touit 2.8/12 （ f/16 ISO/200 4秒 ）'
        },
        {
          src: '/ec/images2/special/camera/lens/touit/touit-slide_03.jpg',
          txt: 'FUJIFILM X-E1 × Carl Zeiss Touit 2.8/12 （ f/13 ISO/640 18秒 ）'
        },
        {
          src: '/ec/images2/special/camera/lens/touit/touit-slide_04.jpg',
          txt: 'SONY NEX-7 × Carl Zeiss Touit 1.8/32 （ f/1.8 ISO/100 1/640秒 ）'
        },
        {
          src: '/ec/images2/special/camera/lens/touit/touit-slide_05.jpg',
          txt: 'SONY NEX-7 × Carl Zeiss Touit 1.8/32 （ f/1.8 ISO/100 1/2500秒 ）'
        }
      ],
      visible: false,
      isReinforcedParts: false,
      visibleGallery: false,
      index: 0,
      galleryList: [
        {
          src: '/ec/images2/special/camera/lens/touit/photo_1.jpg',
          title: '歪みが少なく、どこまでも美しい直線',
          txt:
            'APS-Cミラーレス用としては最広角となるレンズでありながら、広角レンズ特有の歪みが少なく、写真の端から端までどこまでも美しい直線の描写が可能。\n\n超広角を活かして広大な風景写真を撮るもよし、被写体まで距離が取りづらい直線的な都会の風景を撮るもよし。このレンズなら今までにない表現を楽しむことができる。'
        },
        {
          src: '/ec/images2/special/camera/lens/touit/photo_2.jpg',
          title: 'ヌケが良く、夜景撮影もシャープ',
          txt:
            ' 非常にヌケが良く、シャープでノイズも少ない印象を受けた。\n\nシャープと言っても立体感が損なわれないので、被写体の角度など、あれこれ考えずに気軽にシャッターを切れる。\n\n 開放時の明るさを活かして、広角で撮影する夜景写真も面白い。'
        }
      ],
      visibleGallery2: false,
      index2: 0,
      galleryList2: [
        {
          doosrc: '/ec/images2/special/camera/lens/touit/photo_3.jpg',
          dootitle: 'カールツァイス伝統のプラナー\n愛され続ける、柔らかいボケ味',
          dootxt:
            '先に紹介したTouit 2.8/12とは打って変わって、Touit 1.8/32はカールツァイス伝統のPlanarで、なんとも言えない柔らかいボケ味が魅力だ。\nミラーレス機×Planarの組み合わせは、なんとも魅力的で贅沢にさえ思える。'
        },
        {
          doosrc: '/ec/images2/special/camera/lens/touit/photo_6.jpg',
          dootitle: '異なったイメージの画作りが可能',
          dootxt: 'F1.8と明るいレンズのため、解放時と絞り時で仕上がりのイメージが大きく変わる。\nシーンに合わせて自分なりの表現を楽しむことができる。'
        },
        {
          doosrc: '/ec/images2/special/camera/lens/touit/photo_7.jpg'
        },
        {
          doosrc: '/ec/images2/special/camera/lens/touit/photo_4.jpg',
          dootitle: 'さまざまなシーンに使えます',
          dootxt: '先ほど説明した絞りによる変化と、使いやすい焦点距離で風景写真からポートレートまでさまざまなシーンで活躍。'
        },
        {
          doosrc: '/ec/images2/special/camera/lens/touit/photo_5.jpg'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カールツァイス Touit 2.8/12mm 1.8/32mm レビュー',
          disabled: true
        }
      ],

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4047865800686'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    const handleHide = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery = false;
    };
    const showGalleryImg = (index: number) => {
      state.index = index;
      state.visibleGallery = true;
    };
    const handleHide2 = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery2 = false;
    };
    const showGalleryImg2 = (index2: number) => {
      state.index2 = index2;
      state.visibleGallery2 = true;
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      handleHide,
      showGalleryImg,
      handleHide2,
      showGalleryImg2,
      formatPrice
    };
  }
});
