





























































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch, computed } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import UserService from '@/logic/user.service';
import MyPageLayout from '../common/my-page-layout.vue';
import { ReviewItem } from '@/types/review-list';
import Product from '@/components/common/product.vue';
import ProductService from '@/logic/product.service';
import { formatPrice, splitList } from '@/logic/utils';
import BackButton from '@/components/common/back-button.vue';
import MoreItemsButton from '@/components/common/more-items-button.vue';
import { ProductDetail } from '@/types/product';
import ReviewRatingTable from './review-rating-table.vue';

type ReviewItemOnScreen = ReviewItem & { makerName?: string; imagePath?: string };

export default Vue.extend({
  name: 'review-list',
  components: {
    'section-loading': SectionLoading,
    'my-page-layout': MyPageLayout,
    product: Product,
    'back-button': BackButton,
    'more-items-button': MoreItemsButton,
    'review-rating-table': ReviewRatingTable
  },
  setup: (props, context) => {
    const { authorizer, errorStore, confirmDialogStore } = context.root.$store;

    const state = reactive({
      // 画面タイトル
      title: '投稿したレビュー',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: '投稿したレビュー', linkUrl: '' }
      ],
      // レビュー投稿リスト
      reviews: [] as Array<ReviewItemOnScreen>,
      showReviewAddLength: 9,
      showReviewLength: 9,
      // 取得した投稿したレビューのページ番号
      reviewPage: 1,
      // ロード状態
      loaded: {
        reviews: false
      }
    });

    /**
     * レビュー投稿リストの取得
     */
    const fetchReviewList = async (page?: number) => {
      state.loaded.reviews = false;

      try {
        const isInit = page == null;
        if (isInit) {
          // 初期化
          state.reviewPage = 1;
        }

        // 投稿したレビュー一覧を取得
        const result = await UserService.fetchReviewList(page);
        const resultReviews: Array<ReviewItemOnScreen> = result.reviewInfo;

        // 取得した商品のJANコード一覧
        const janCodeList = resultReviews.map((product) => product.janCode);

        // 商品詳細より「メーカー名」と「画像パス」を取得
        // 商品詳細取得APIはJANコード指定が最大20件のため、分割して取得する
        const splittedJanCodes = splitList(janCodeList, 20);
        const responses = splittedJanCodes.map((splittedJanCodes) => ProductService.fetchProducts(splittedJanCodes, true));
        const productDetails = (await Promise.all(responses)).reduce((list, value) => list.concat(value.items), new Array<ProductDetail>());
        for (const { janCode, makerName, images } of productDetails) {
          const review = resultReviews.find((item) => item.janCode === janCode);
          if (review) {
            review.makerName = makerName;
            review.imagePath = images && images.length > 0 ? images[0].imagePath : undefined;
          }
        }

        // 初期化の場合は配列を置き換える。追加の場合は配列を連結する。
        state.reviews = isInit ? resultReviews : state.reviews.concat(resultReviews);
      } catch (error) {
        console.error(error);
        // 追加取得時は、エラーになっても元の商品表示が消えないようにする
        if (page == null) {
          state.reviews = [] as Array<ReviewItem>;
        }
      } finally {
        state.loaded.reviews = true;
      }
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        fetchReviewList();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchReviewList();
      }
    );

    // 投稿したレビューを削除
    const removeReview = async (review: ReviewItem) => {
      // 削除確認
      const confirm = await confirmDialogStore.open('削除してよろしいですか？', '削除');
      if (!confirm) {
        return;
      }

      try {
        await ProductService.deleteReview(review.reviewId, review.janCode);
        await fetchReviewList();
      } catch (error) {
        console.error(error);
        errorStore.errorMessage =
          'ただいまシステムが混みあっている可能性があります。しばらくお待ちいただきますようお願い申し上げます。ご迷惑をおかけして申し訳ございません。';
      }
    };

    // 表示する投稿したレビューを増やす
    const addShowReviewLength = () => {
      state.showReviewLength += state.showReviewAddLength;

      // サーバーにまだ取得していない投稿したレビューがないか確認する
      if (state.reviews.length < state.showReviewLength) {
        state.reviewPage++;
        fetchReviewList(state.reviewPage);
      }
    };

    /**
     * 画面表示する投稿したレビューを取得する
     */
    const getReviews = computed(() => {
      const reviews = state.reviews.slice(0, state.showReviewLength);
      return reviews;
    });

    return {
      ...toRefs(state),
      formatPrice,
      removeReview,
      getReviews,
      addShowReviewLength
    };
  }
});
