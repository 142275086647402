




























































































import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Notfound from '@/components/common/not-found.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'inzone_h9_h7_h3_m9',
  components: {
    breadcrumbs: Breadcrumbs,
    appearanceImage: AppearanceImage,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    notfound: Notfound,
    priceAndPopular: PriceAndPopular,
    watchInVideo: WatchInVideo,
    characteristic: Characteristic,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = 'ソニー ゲーミングヘッドセット/ゲーミングモニター INZONE H9/H7/H3/M9 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー ゲーミングヘッドセット/ゲーミングモニター INZONE H9/H7/H3/M9 価格・発売日・スペック・お得な情報をチェック！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- 予約期間 ----
      reserveValidFrom: '2022/06/29 10:00',
      reserveValidTo: '2022/07/07 23:59',
      // ↓ ---- 発売掲載期間 ----
      releaseValidFrom: '2022/07/08 00:00',
      releaseValidTo: '2999/10/20 23:59',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',

      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '話題の新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー ゲーミングヘッドセット/ゲーミングモニター INZONE H9/H7/H3/M9',
          disabled: true
        }
      ],
      commonCharacteristicList: [
        {
          subtitle: 'ソニー 密閉型 ノイズキャンセリング機能搭載 ワイヤレス ゲーミングヘッドセット INZONE H9 WH-G900N WZ',
          contents: [
            'プレイヤーを没入と勝利に導くSpatial Sound(立体音響)　敵を正確に察知する音の定位感/明瞭度',
            'ゲームへの没入をサポートするノイズキャンセリング',
            '長時間快適にプレイするためのヘッドセット設計',
            'ストレスフリーな低遅延2.4GHzワイヤレス接続',
            'クリアなボイスチャットを可能にするブームマイク'
          ]
        },
        {
          subtitle: 'ソニー 密閉型 ワイヤレス ゲーミングヘッドセット INZONE H7 WH-G700 WZ',
          contents: [
            'プレイヤーを没入と勝利に導くSpatial Sound(立体音響)  敵を正確に察知する音の定位感/明瞭度',
            '長時間快適にプレイするためのヘッドセット設計',
            'ストレスフリーな低遅延2.4GHzワイヤレス接続',
            'クリアなボイスチャットを可能にするブームマイク'
          ]
        },
        {
          subtitle: 'ソニー 開放型 有線 ゲーミングヘッドセット INZONE H3 MDR-G300 WZ',
          contents: [
            'プレイヤーを没入と勝利に導くSpatial Sound(立体音響)　敵を正確に察知する音の定位感/明瞭度',
            '長時間快適にプレイするためのヘッドセット設計',
            'クリアなボイスチャットを可能にするブームマイク'
          ]
        }
      ],
      commonCharacteristicList2: [
        {
          subtitle: 'ソニー 27インチ ゲーミングモニター INZONE M9 SDM-U27M90 [4K/1ms/144Hz]',
          contents: [
            '144Hzのリフレッシュレート、応答速度1msによるなめらかな映像表現',
            'NVIDIA ® G-SYNC®Compatible、VRR(HDMI2.1)に両対応し、PCでもPS5™でもカクツキを防ぐ',
            '4K IPS液晶パネルによる高解像度と視野角を実現',
            '直下型LED部分駆動搭載により、暗所部分もくっきり映し出す高コントラスト',
            'DCI-P3 95%以上、最大色表示10.7億色による鮮やかな色表現'
          ]
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736133358', '4548736133402', '4548736133457', '4548736140523'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      //メイン商品 かり '4548736140264',
      mainProductJanCode: ['4548736133358', '4548736133402', '4548736133457', '4548736140523'],
      mainProductList: [] as Array<ProductDetail>
    });
    /**
      reserveValidFrom: '2022/06/29 10:00',
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const reserveValidFrom = dayjs(state.reserveValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(reserveValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
          document.title = '掲載準備中 | カメラのキタムラネットショップ';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
