














































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import MomoBtn from '@/components/common/special/momoBtn.vue';

export default Vue.extend({
  name: 'shopping-credit',
  components: {
    momoBtn: MomoBtn,
  },
  props: {
    installmentPaymentExampleList: {
      type: Array,
      required: true
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: 'デジカメ'
    },
    isMomoBtn: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state)
    };
  }
});
