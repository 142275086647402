































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import IntenseRedBtn from '@/components/common/special/intenseRedBtn.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'sony-9ll',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    splitContent: SplitContent,
    characteristic: Characteristic,
    priceAndPopular: PriceAndPopular,
    appearanceImage: AppearanceImage,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    naviBtn: NaviBtn,
    intenseRedBtn: IntenseRedBtn,
    exampleImages: ExampleImages,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '新製品 SONY ソニーα9Ⅱ 発売中！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー α9II 好評発売中！比較や、価格・スペック・お得な情報をチェック！最大60回まで分割金利手数料0円！新製品ならカメラのキタムラにおまかせください！'
      );

    const state = reactive({
      pcBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/9II/bg_top.jpg',
      spBackgroundImg: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/9II/sp_img_top.jpg',

      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '外観', href: '#images' }
      ],
      expensiveTradeInList: [
        {
          name: 'ソニー α9 ボディ [ILCE-9]',
          href:
            'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4548736063945&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
          productImage: 'https://shopimg.kitamura.jp/images/pd/dc6/362/87a/cc7/ceb/ba3/728/cd9/f2e/4cc/0a9/pxz/aon/r/L.jpg',
          expensiveTradeImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/9II/bnr_shitadori_350-130.jpg'
        },
        {
          name: 'ソニー α7R III ボディ [ILCE-7RM3] ',
          href:
            'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4548736075139&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
          productImage: '	https://shopimg.kitamura.jp/images/pd/d1c/dc4/57f/edf/af1/9fc/d49/8bb/a0a/949/057/c63/gt4/s/L.jpg',
          expensiveTradeImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/9II/bnr_shitadori_350-130-1.jpg'
        },
        {
          name: 'ソニー α7III ボディ [ILCE-7M3]',
          href:
            'https://www.net-chuko.com/ec/sell/item-list?category=&keyword=4548736079625&maker=&s2=&s3=&s4=&s5=&s7=&n2c=&n3c=&n4c=&n5c=&n6c=&n1c=&limit=&tokutoku=0&sort=',
          productImage: 'https://shopimg.kitamura.jp/images/pd/913/1b2/df1/d52/2b3/d6d/b42/c68/1b7/ba6/b03/n84/0hk/x/L.jpg',
          expensiveTradeImage: process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/feature/sony/9II/bnr_shitadori_350-130-2.jpg'
        }
      ],
      characteristicList1: [
        {
          subtitle: '進化した高速通信性能で、スポーツや報道のプロフェッショナルの撮影とワークフローを強力にサポート',
          contents: [
            '「α９」の革新的な高速撮影性能に加え、プロの現場での撮影から納品までを強力にサポートする、リモート撮影やデータ納品時の高速データ転送を実現',
            ' 強化されたスピード性能や追従性・精度が向上したAF性能による更なる撮影領域の拡大'
          ]
        }
      ],
      characteristicList2: [
        {
          subtitle: '撮影から納品まで、プロフェッショナルの現場におけるワークフローの高速化を実現する通信性能',
          contents: [
            'リモート撮影や、撮影中および撮影後のデータ転送、転送時の使い勝手を強化。現場のプロからの要望に応えられる利便性',
            '新たに音声メモ機能を搭載。撮影現場にて撮影シーンや位置などの情報を音声データとして画像に付与が可能',
            '最高1Gbpsの高い転送速度を実現する規格1000BASE-Tに対応したLAN端子を内蔵',
            '無線LAN（Wi-Fi）機能は「IEEE 802.11ac」規格にも対応し、『α9』同様の2.4GHzに加え、5GHz帯域での高速なデータ転送も可能'
          ]
        },
        {
          subtitle: '強化されたスピード性能や追従性・精度が向上したAF性能による更なる撮影領域の拡大',
          contents: [
            '最高20コマ/秒のブラックアウトフリー連続撮影や最大60回/秒の演算によるAF/AE追従などの高速性能を装備',
            'メカシャッター使用時でも最高約10コマ/秒の高速連写が可能',
            '歪みを極限まで抑えるアンチディスト－ションシャッターで高速撮影時の画像補正も強化',
            '蛍光灯などの人工光源の点滅によるフリッカーの影響が少ないタイミングを検出し、シャッターを切ることができるフリッカーレス撮影にも対応'
          ]
        },
        {
          subtitle: 'さらに進化したAF性能',
          contents: [
            '693点の像面位相差AFセンサーを撮像エリアの約93%に高密度に配置。動きと速度に緩急のある動体に対しても、追従安定性と精度の向上を実現',
            '即時に瞳情報を検出しフォーカスを合わせ続けるリアルタイム瞳AF。被写体の顔が見えていない場面や被写体が人物以外の場合でも、撮影被写体を自動で追尾'
          ]
        },
        {
          subtitle: 'プロフェッショナルの撮影現場でも活躍する堅牢性、操作性、信頼性',
          contents: [
            '静止画・動画ともに有効な最高5.5段の光学式5軸ボディ内手ブレ補正機構',
            '重量レンズ装着時や長時間の使用時に、撮影者の負担を軽減するホールド性を向上させたグリップ形状へ変更',
            '露出補正ダイヤルロックボタンの追加や後ダイヤルの操作性の改善など、確実な操作をサポートするデザイン'
          ]
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー'
        },
        {
          href: '/ec/special/camera/lens',
          img: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          alt: 'おすすめレンズ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'ソニー α9II',
          disabled: true
        }
      ],
      mainProductJanCode: ['4548736108288'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4548736108288', '4548736108264', '4549292060485', '4960759146205'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4548736064522', '4548736105546', '4548736019515', '4548736099791'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
