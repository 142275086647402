














































import Vue, { PropType } from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';

// TODO: 各ページで定義しているものを以下に修正
export type sliderItem = {
  isUsed?: boolean;
  janCode: string;
  images: object;
  itemName: string;
  price: number;
  ratingTotal: number;
};

export default Vue.extend({
  name: 'simple-product-slider',
  components: {},
  props: {
    recommend: {
      required: false
    },
    items: {
      type: Array as PropType<Array<sliderItem>>,
      required: true
    },
    ratingColor: {
      type: String,
      required: false
    }
  },
  setup: (props, context) => {
    const state = reactive({
      model: null
    });

    /**
     * 遷移処理
     */
    const navigate = (item: sliderItem) => {
      const path = item.isUsed ? `/ec/used/${item.janCode}` : `/ec/pd/${item.janCode}`;
      const recommend = (props.recommend as { _click: any }) || undefined;
      if (recommend && Object.keys(recommend).length > 0) {
        recommend._click(path, item.janCode);
      } else {
        context.root.$router.push({ path });
      }
    };

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      navigate
    };
  }
});
