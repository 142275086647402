























































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TitleImage from '@/components/common/special/highly-recommended/title-image.vue';
import Content2015 from '@/components/common/special/highly-recommended/content.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import BackNumbers from '@/components/common/special/highly-recommended/back-numbers.vue';
export default Vue.extend({
  name: 'highly-recommended-2015-0626',
  components: {
    breadcrumbs: Breadcrumbs,
    titleImage: TitleImage,
    content2015: Content2015,
    backnumbers: BackNumbers
  },
  setup: () => {
    document.title = '本格カーボン三脚に新ブランドが登場! マセス（Marsace）カーボン三脚 | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '軽量・高耐久・高精度。カーボン繊維多層構造、アルミ切削加工、アルカスイス互換プレートを採用した本格カーボン三脚。')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'スタッフのイチオシ',
          linkUrl: '/ec/special/general/highly_recommended',
          disabled: false
        },
        {
          path: '本格カーボン三脚に新ブランドが登場! マセス（Marsace）カーボン三脚 | スタッフのイチオシ',
          disabled: true
        }
      ],
      backnumberList: [
        {
          src: 'https://shop.kitamura.jp/ts_rsc/617/images/i-item_canoncs100_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0605',
          productName: 'Connect Station CS100 ',
          txt: ''
        },
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/ld-120_350px.jpg.jpg',
          href: '/ec/special/general/highly_recommended/2015/0529',
          productName: 'LEDライト搭載 みせる防湿庫 東洋リビングLD-120',
          txt: 'ドア連動型の18連LEDライトを搭載。\nカメラを守るだけでなく、\nみせる防湿庫が登場。眺めて楽しく、\n出し入れしやすいプレミアムモデル。'
        },
        {
          src: 'https://shop.kitamura.jp/ts_rsc/585/images/i-item_fujin_350px.jpg',
          href: '/ec/special/general/highly_recommended/2015/0508',
          productName: 'レンズ型カメラ掃除機 Fujin「風塵（ふうじん）」 EF …',
          txt: 'カメラメンテナンス・クリーニングの決定版！ 1分間でイメージセンサーについたホコリを掃除できるレンズ型カメラ掃除機。運動会後や撮影前後のケ…【続き】'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
