var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"my-page-order-sell-detail"}},[_c('my-page-layout',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}},[(_vm.loaded.detail)?[_c('order-info',{attrs:{"type":_vm.type,"info":{
          odrId: _vm.orderDetail.formatedOdrNum,
          odrYmd: _vm.orderDetail.odrYmd
        }}}),_c('div',{class:{
          'mx-12': _vm.$vuetify.breakpoint.mdAndUp
        }},[_c('div',{staticClass:"order-product-list"},_vm._l((_vm.orderDetail.detailInfo),function(product){return _c('order-product',{key:product.janCode,attrs:{"type":_vm.type,"product":product}})}),1),_c('order-total-info',{attrs:{"type":_vm.type,"total-info":{
            count: _vm.orderDetail.detailInfo.length
          }}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mb-3"},[_c('router-link',{staticClass:"order-list-link",attrs:{"to":"/ec/mypage/odr/list?type=sell"}},[_c('v-icon',{staticClass:"order-list-link-icon mr-2",attrs:{"small":""}},[_vm._v("far fa-chevron-left")]),_vm._v(" ご利用履歴(買取・下取)へ戻る ")],1)],1):_vm._e()],1)]:(!_vm.loaded.detail)?_c('div',{staticClass:"loading-detail"},[_c('section-loading')],1):_vm._e()],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"to":"/ec/mypage/odr/list?type=sell"}},[_vm._v(" ご利用履歴(買取・下取)へ戻る ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"to":"/ec/mypage"}},[_vm._v(" マイページトップへ戻る ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }