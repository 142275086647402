










































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch, computed } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import UserService from '@/logic/user.service';
import MyPageLayout from './common/my-page-layout.vue';
import { CouponItem, CouponTargetItem } from '@/types/coupon-list';
import { formatPrice, sortByRowNo, splitList } from '@/logic/utils';
import BackButton from '@/components/common/back-button.vue';
import MoreItemsButton from '@/components/common/more-items-button.vue';
import SimpleProductSlider from '@/components/common/simple-product-slider.vue';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import ProductListService from '@/logic/product-list.service';

const LIMIT_COUNT = 100;

// TODO: 共通スライダーのアイテムなので、共通化
type sliderItem = {
  isUsed?: boolean;
  janCode: string;
  images: object;
  itemName: string;
  price: number;
  ratingTotal: number;
};

export default Vue.extend({
  name: 'coupon-list',
  components: {
    'section-loading': SectionLoading,
    'my-page-layout': MyPageLayout,
    'back-button': BackButton,
    'more-items-button': MoreItemsButton,
    'simple-product-slider': SimpleProductSlider
  },
  setup: (props, context) => {
    const { authorizer, mypageMenuStore } = context.root.$store;

    const state = reactive({
      // 画面タイトル
      title: '持っているクーポン',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: '/ec/mypage' },
        { path: '持っているクーポン', linkUrl: '' }
      ],
      // 持っているクーポンリスト
      coupons: [] as Array<CouponItem & { targetProducts?: Array<sliderItem> }>,
      showCouponLength: 9,
      // 取得した持っているクーポンのページ番号
      couponPage: 1,
      // ロード状態
      loaded: {
        coupons: false
      }
    });

    /**
     * 対象商品を取得する
     * @param targetItem JANコード
     */
    const fetchProduct = async (targetItem: CouponTargetItem[] = []) => {
      try {
        const janCodeList = targetItem.map((item) => item.janCode);

        // 商品詳細取得APIはJANコード指定が最大20件のため、分割して取得する(取得制限100件)
        const splittedJanCodes = splitList(janCodeList.slice(0, LIMIT_COUNT), 20);
        const responses = splittedJanCodes.map((janCodes) => ProductService.fetchProducts(janCodes, true));
        const items = (await Promise.all(responses)).reduce((list, value) => list.concat(value.items), new Array<ProductDetail>());

        // sliderItemに置き換え
        const sliderArray = items.map((item) => ({
          isUsed: false,
          janCode: item.janCode,
          images: item.images,
          itemName: item.itemName,
          price: item.price,
          ratingTotal: item.ratingTotal
        }));
        return sliderArray;
      } catch (error) {
        console.error(error);
        return [] as Array<sliderItem>;
      }
    };

    /**
     * 対象中古商品を取得する
     * @param targetItem JANコード
     */
    const fetchUsedProduct = async (targetItem: CouponTargetItem[] = []) => {
      try {
        const janCodeList = targetItem.map((item) => item.janCode);

        //  中古商品検索APIはkeyword指定が最大8件のようなので、分割して取得する(取得制限100件)
        const splittedJanCodes = splitList(janCodeList.slice(0, LIMIT_COUNT), 8);
        const responses = splittedJanCodes.map((janCodes) =>
          ProductListService.searchUsedItem(
            [
              {
                paramCode: 'keyword',
                paramText: '',
                value: janCodes.join(','),
                valueText: ''
              }
            ],
            'newer',
            LIMIT_COUNT,
            1
          )
        );

        const items = (await Promise.all(responses)).reduce((list, result) => {
          let sliderArray = [] as Array<sliderItem>;
          // sliderItemに置き換え(wordItemsにレスポンスがある場合がある)
          if (result.items && result.items.length) {
            sliderArray = result.items.map((item) => ({
              isUsed: true,
              janCode: item.itemid,
              images: [{ imagePath: item.image }],
              itemName: item.title,
              price: +item.price,
              ratingTotal: +item.data18
            }));
          } else if (result.wordItems && result.wordItems.length) {
            result.wordItems.forEach((wordItem) => {
              wordItem.items.forEach((item) => {
                sliderArray.push({
                  isUsed: true,
                  janCode: item.itemid,
                  images: [{ imagePath: item.image }],
                  itemName: item.title,
                  price: +item.price,
                  ratingTotal: +item.data18
                });
              });
            });
          }
          return list.concat(sliderArray);
        }, new Array<sliderItem>());

        return items;
      } catch (error) {
        console.error(error);
        return [] as Array<sliderItem>;
      }
    };

    /**
     * 持っているクーポンリストの取得
     */
    const fetchCouponList = async () => {
      try {
        // 持っているクーポン一覧を取得
        const result = await UserService.fetchCouponList();

        // 「rowNo」を昇順にソートする
        // ページ毎に「rowNo」が新規に割り振られているため、レスポンス内容の中でソートする
        const coupons = sortByRowNo<CouponItem>(result.couponInfo);
        // 保証延長は特定ユーザーのみ利用できるようにするため、クーポン一覧には表示しない
        state.coupons = coupons.filter((coupon) => coupon.couponType !== 4);

        // 対象商品を取得する
        state.coupons.forEach(async (coupon, index) => {
          const targetProducts = coupon.targetType === 5 ? await fetchUsedProduct(coupon.targetItem) : await fetchProduct(coupon.targetItem);
          // $setを使用して動的に値を検知されるようにしている
          context.root.$set(state.coupons[index], 'targetProducts', targetProducts);
        });

        // メニューにクーポン数を表示する
        mypageMenuStore.couponCount = state.coupons.length;
      } catch (error) {
        console.error(error);
        state.coupons = [] as Array<CouponItem>;
      } finally {
        state.loaded.coupons = true;
      }
    };

    onMounted(() => {
      if (authorizer.isLoggedIn) {
        fetchCouponList();
      }
    });

    watch(
      () => authorizer.isLoggedIn,
      () => {
        if (authorizer.isLoggedIn) fetchCouponList();
      }
    );

    /**
     * 画面表示する持っているクーポンを取得する
     */
    const getCoupons = computed(() => {
      const coupons = state.coupons.slice(0, state.showCouponLength);
      return coupons;
    });

    /**
     * クーポン説明を強調表示する
     * @param type クーポン種類
     * @param text クーポン説明文
     */
    const showEmphasisCouponContents = (type: number, text: string) => {
      let regex: RegExp;
      switch (type) {
        case 1:
          regex = /\b(\d)+(,)?(\d)+\b円/g;
          break;
        case 2:
          regex = /\b(\d)+(.)?(\d)*\b(％|%)/g;
          break;
        case 3:
          regex = /送料無料/g;
          break;
        default:
          return text;
      }
      return text.replace(regex, '<span class="emphasis">$&</span>');
    };

    /**
     * 表示する持っているクーポンを増やす
     */
    const addShowCouponLength = () => {
      state.showCouponLength += 9;
    };

    return {
      ...toRefs(state),
      formatPrice,
      getCoupons,
      showEmphasisCouponContents,
      addShowCouponLength
    };
  }
});
