





















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sportsday-532',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: () => {
    document.title = '運動会におすすめ！高倍率ズームレンズ | カメラのキタムラネットショップ';
    const state = reactive({
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '運動会におすすめ！高倍率ズームレンズ',
          disabled: true
        }
      ]
      // ↑ ---- パンくず情報 ----
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
