import { render, staticRenderFns } from "./x-100vi.vue?vue&type=template&id=3275f928&scoped=true&"
import script from "./x-100vi.vue?vue&type=script&lang=ts&"
export * from "./x-100vi.vue?vue&type=script&lang=ts&"
import style0 from "./x-100vi.vue?vue&type=style&index=0&id=3275f928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3275f928",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
