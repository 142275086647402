






















































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage } from '@/logic/utils';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'a5100',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'ソニー α5100 ミラーレス一眼カメラ【生産完了】 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ソニー α5100 ミラーレス一眼カメラ特集ページ。機能や価格をチェック！「タッチで、いい瞬間をつかまえよう」。SONY α（アルファ）シリーズ！'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'ソニー α5100 ミラーレス一眼カメラ【生産完了】',
          disabled: true
        }
      ],
      redBtnList: [
        {
          href:
            '/ec/list?narrow18=0&keyword=α5100&category=ミラーレス一眼&index=&sort=number20,Number17,Score&searchbox=1&s1%5B%5D=ソニー&q=α5100&path=ミラーレス一眼',
          txt: 'ソニー α5100 商品一覧を見る'
        },
        { href: '/ec/ct/C002/001/003/', txt: 'ソニー ミラーレス一眼はこちら\n一覧を見る' }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
