


























import Vue, { PropType } from 'vue';
import { News } from '@/types/news';
import { formatDate } from '@/logic/utils';
import { NEWS_TYPE_LIST } from '@/constants/news';

export default Vue.extend({
  name: 'news-list',
  props: {
    newsList: {
      required: true,
      type: Array as PropType<Array<News>>,
      default: []
    }
  },
  setup() {
    const convertNewsTypetoText = (type: number) => {
      return NEWS_TYPE_LIST[type - 1] ? NEWS_TYPE_LIST[type - 1] : '';
    };

    /**
     * ニュースの遷移先URLを取得する
     */
    function getNewsLinkUrl(news: News): string {
      let linkUrl = news.linkType === 1 ? `${news.linkUrl}` : `/ec/news/detail/${news.newsNo}`;

      // 「$newsid$」がある場合はニュース番号で置換する
      if (news.newsNo != null) {
        linkUrl = news.linkUrl.replace(/\$newsid\$/g, String(news.newsNo));
      }
      return linkUrl;
    }

    /**
     * リンクを開く際のタブの挙動方法を取得する
     * 1：別タブにする 2：同一タブ内
     */
    function getLinkTarget(linkTab: 1 | 2) {
      return linkTab === 2 ? '_self' : '_blank';
    }

    return {
      formatDate,
      convertNewsTypetoText,
      getNewsLinkUrl,
      getLinkTarget
    };
  }
});
